import * as ActionTypes from './actionTypes';

export function tagsGet(payload){
  return {
    type: ActionTypes.TAGS_GET,
    payload
  }
}

export function tagsGetSuccess(payload){
  return {
    type: ActionTypes.TAGS_GET_SUCCESS,
    payload
  }
}

export function tagsGetFail(payload){
  return {
    type: ActionTypes.TAGS_GET_FAIL,
    payload
  }
}

export function termsGet(payload){
  return {
    type: ActionTypes.TERMS_GET,
    payload
  }
}

export function termsGetSuccess(payload){
  return {
    type: ActionTypes.TERMS_GET_SUCCESS,
    payload
  }
}

export function termsGetFail(payload){
  return {
    type: ActionTypes.TERMS_GET_FAIL,
    payload
  }
}

export function createTag(payload){
  return {
    type: ActionTypes.CREATE_TAG,
    payload
  };
}

export function createTagSuccess(payload){
  return {
    type: ActionTypes.CREATE_TAG_SUCCESS,
    payload
  };
}

export function createTagFail(payload){
  return {
    type: ActionTypes.CREATE_TAG_FAIL,
    payload
  };
}

export function getTag(payload){
  return {
    type: ActionTypes.GET_TAG,
    payload
  };
}

export function getTagSuccess(payload){
  return {
    type: ActionTypes.GET_TAG_SUCCESS,
    payload
  };
}

export function getTagFail(payload){
  return {
    type: ActionTypes.GET_TAG_FAIL,
    payload
  };
}

export function getTagByName(payload){
  return {
    type: ActionTypes.GET_TAG_BY_NAME,
    payload
  };
}

export function getTagByNameSuccess(payload){
  return {
    type: ActionTypes.GET_TAG_BY_NAME_SUCCESS,
    payload
  };
}

export function getTagByNameFail(payload){
  return {
    type: ActionTypes.GET_TAG_BY_NAME_FAIL,
    payload
  };
}

export function getTagName(payload){
  return {
    type: ActionTypes.GET_TAG_NAME,
    payload
  };
}

export function getTagNameSuccess(payload){
  return {
    type: ActionTypes.GET_TAG_NAME_SUCCESS,
    payload
  };
}

export function getTagNameFail(payload){
  return {
    type: ActionTypes.GET_TAG_NAME_FAIL,
    payload
  };
}

export function getVocabulary(payload){
  return {
    type: ActionTypes.GET_VOCABULARY,
    payload
  };
}

export function getVocabularySuccess(payload){
  return {
    type: ActionTypes.GET_VOCABULARY_SUCCESS,
    payload
  };
}

export function getVocabularyFail(payload){
  return {
    type: ActionTypes.GET_VOCABULARY_FAIL,
    payload
  };
}
