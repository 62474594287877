import React from 'react';
import {
  FlatList,
  TouchableOpacity,
  Text,
  View
} from 'react-native';
import Colors from '../constants/Colors';

class EmptySearchScreenComponent extends React.Component {
  state={
    currentSuggestions: [],
    allSuggestions: [
      "Bad",
      "Badezimmer",
      "Balkon",
      "Besta",
      "Bett",
      "Esszimmer",
      "Flur",
      "Garten",
      "Ikea",
      "Jugendzimmer",
      "Kamin",
      "Kinderzimmer",
      "Küche",
      "Schlafzimmer",
      "Terrasse",
      "Wohnzimmer",
      "DIY",
      "Arbeitszimmer",
      "Wandfarbe",
      "Altbau",
      "Deko",
      "Wanddeko",
    ]
  };

  getRandom = (arr, n) => {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };

  _renderItem = (data) => {
    return (
      <TouchableOpacity
        onPress={() => this.props.onPressSearchTerm(data.item)}
        style={{
          marginVertical: 15,
        }}
      >
        <Text
          style={{
            textDecorationLine: 'underline'
          }}
        >
          {data.item}
        </Text>
      </TouchableOpacity>
    );
  };

  componentDidMount() {
    this.setState({
      currentSuggestions: this.getRandom(this.state.allSuggestions, 6)
    })
  };

  render()  {
    return (
      <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <FlatList
          backgroundColor={Colors.background}
          data={this.state.currentSuggestions}
          ListHeaderComponent={
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 15,
              }}
            >
              <Text
                style={{
                  fontFamily: 'OpenSansBold',
                }}
              >
                Beliebte Suchanfragen:
              </Text>
            </View>
          }
          keyExtractor={data => data}
          style={{
            alignSelf: 'center',
            flex:1
          }}
          contentContainerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          renderItem={this._renderItem}
        />

      </View>
    );
  }
}

export default EmptySearchScreenComponent;
