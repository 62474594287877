import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import Collapsible from 'react-native-collapsible';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import * as Config from '../Config';
import shoppingCategories from '../assets/data/shopping-terms';
import { Icon } from './Icon';

class MenuDynamicItem extends React.Component {

  setChosenitem = () => {
    this.props.setChosenCategoryAndClose(this.props.item);
  };

  expandItem = () => {
    this.props.setCurrentItem(this.props.item);
  };
  
  render () {
    const { hasChildren, item } = this.props;
    const isCurrentStyle = this.props.isCurrent ? { fontWeight: 'bold' } : {};
    return (
      <View style={styles.line}>
        <TouchableOpacity underlayColor="#e2e2e2" onPress={this.setChosenitem} style={styles.containerStyle}>
          <Text style={[styles.text, isCurrentStyle]}>
            {item.title}
          </Text>
        </TouchableOpacity>
        { hasChildren &&
        <TouchableOpacity style={styles.icon} onPress={this.expandItem}>
          <Icon name='up_arrow' color='black' onPress={null} size={Config.SIZE_ICON} />
        </TouchableOpacity>
        }
      </View> 
    );
  }
}
const styles = StyleSheet.create({
  containerStyle: {
    alignSelf: 'center',
    flexGrow: 2,
  },
  line: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.border,
    flexDirection: 'row',
    minHeight: 50,
    padding: Config.STANDARD_MARGIN,
    width: Layout.window.width,
  },
  icon: {
    borderColor: Colors.border,
    borderBottomWidth: StyleSheet.hairlineWidth,
    transform: [{ rotate: '90deg'}],
  },
  text: {
    alignSelf: 'flex-start',
    color: Colors.darkText,
    fontSize: Config.FONT_SIZE_NORMAL,
  },
});

export default MenuDynamicItem;
