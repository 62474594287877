import { priceFormat100 } from '../../helpers/formatNumbers'

const flattenCartShippingItem = (item) => {
  return !item.id ? false : {
    shipping_id: item.id ?? undefined,
    shipping_price_unit: item.price_unit ? priceFormat100(item.price_unit) : 0,
    shipping_price_total: item.price_total ? priceFormat100(item.price_total): 0,
    shipping_service: item.service ?? undefined,
    shipping_quantity: item.quantity ?? undefined,
  }
};

export default flattenCartShippingItem;
