const connectionTypes = {
  NONE: 'none',
  UNKNOWN: 'unknown',
  CELLULAR: 'cellular',
  WIFI: 'wifi',
  BLUETOOTH: 'bluetooth',
  ETHERNET: 'ethernet',
  WIMAX: 'wimax',
  VPN: 'vpn',
  OTHER: 'other',
};

export default connectionTypes;

