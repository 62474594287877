import * as ActionTypes from './actionTypes';

export function doNothing(payload){
  return {
    type: ActionTypes.DO_NOTHING,
    payload
  }
}

export function entityUpdate(payload){
  return {
    type: ActionTypes.ENTITY_UPDATE,
    payload
  }
}

export function entityUpdateSuccess(payload){
  return {
    type: ActionTypes.ENTITY_UPDATE_SUCCESS,
    payload
  }
}

export function entityUpdateFail(payload){
  return {
    type: ActionTypes.ENTITY_UPDATE_FAIL,
    payload
  }
}

export function entityDel(payload){
  return {
    type: ActionTypes.ENTITY_DEL,
    payload
  }
}

export function entityDelSuccess(payload){
  return {
    type: ActionTypes.ENTITY_DEL_SUCCESS,
    payload
  }
}

export function entityDelFail(payload){
  return {
    type: ActionTypes.ENTITY_DEL_FAIL,
    payload
  }
}

export function pushToken(payload){
  return {
    type: ActionTypes.PUSH_TOKEN,
    payload
  }
}

export function pushTokenSuccess(payload){
  return {
    type: ActionTypes.PUSH_TOKEN_SUCCESS,
    payload
  }
}

export function pushTokenFail(payload){
  return {
    type: ActionTypes.PUSH_TOKEN_FAIL,
    payload
  }
}


export function errorReceived(payload){
  return {
    type: ActionTypes.ERROR_RECEIVED,
    payload
  };
}

export function facebookConnect(payload){
  return {
    type: ActionTypes.FACEBOOK_CONNECT,
    payload
  }
}

export function facebookConnectSuccess(payload){
  return {
    type: ActionTypes.FACEBOOK_CONNECT_SUCCESS,
    payload
  }
}

export function facebookConnectFail(payload){
  return {
    type: ActionTypes.FACEBOOK_CONNECT_FAIL,
    payload
  }
}

export function handleReceivedNotification(payload){
  return {
    type: ActionTypes.HANDLE_RECEIVED_NOTIFICATION,
    payload
  };
}

export function handleNavigation(payload){
  return {
    type: ActionTypes.HANDLE_NAVIGATION,
    payload
  };
}

export function menuGet(payload){
  return {
    type: ActionTypes.MENU_GET,
    payload
  }
}

export function menuGetSuccess(payload){
  return {
    type: ActionTypes.MENU_GET_SUCCESS,
    payload
  }
}

export function menuGetFail(payload){
  return {
    type: ActionTypes.MENU_GET_FAIL,
    payload
  }
}

export function submitReportForm(payload){
  return {
    type: ActionTypes.SUBMIT_REPORT_FORM,
    payload
  }
}

export function submitReportFormSuccess(payload){
  return {
    type: ActionTypes.SUBMIT_REPORT_FORM_SUCCESS,
    payload
  }
}

export function submitReportFormFail(payload){
  return {
    type: ActionTypes.SUBMIT_REPORT_FORM_FAIL,
    payload
  }
}

export function submitFeedbackForm(payload){
  return {
    type: ActionTypes.SUBMIT_FEEDBACK_FORM,
    payload
  }
}

export function submitFeedbackFormSuccess(payload){
  return {
    type: ActionTypes.SUBMIT_FEEDBACK_FORM_SUCCESS,
    payload
  }
}

export function submitFeedbackFormFail(payload){
  return {
    type: ActionTypes.SUBMIT_FEEDBACK_FORM_FAIL,
    payload
  }
}
