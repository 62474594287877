const tintColor = '#fba9b5';

export default {
  actions: '#007AFF',
  background: '#FFF',
  border: '#CCC',
  borderProduct: '#EEE',
  darkText: '#000',
  errorBackground: 'red',
  errorText: '#fff',
  facebook: '#3B5998',
  google: '#4285F4',
  helpText: '#4A4A4A',
  iconDefault: '#000',
  iconLightGrey: '#dadada',
  iconSelected: tintColor,
  noticeBackground: tintColor,
  noticeText: '#fff',
  onSaleRed: '#dd043f',
  placeholder: '#CCC',
  tabBar: '#fefefe',
  tagBackground: '#eeeeee',
  tintColor,
  tintColorFill: '#fcb6bf',
  transparentBackground: 'rgba(255, 255, 255, 0.5)',
  transparentBackgroundDark: 'rgba(0, 0, 0, 0.2)',
  warningBackground: '#EAEB5E',
  warningText: tintColor
};
