import React, { PureComponent } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Collapsible from 'react-native-collapsible';

import Colors from "../constants/Colors";
import { Icon } from "../components/Icon";

/**
 * Contains info for the DBS shop.
 *
 */
export default class CommerceShopInfo extends PureComponent {

  state = {
    collapsed: true,
  }

  toggleExpanded = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <View>
        <TouchableOpacity onPress={this.toggleExpanded} style={{ paddingTop: 10, paddingBottom: 10, borderTopWidth: 1, borderBottomWidth: this.state.collapsed ? 1 : 0, borderColor: '#a9a9a9', marginTop: 10, marginBottom: 10}}>
          <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Text>Verkauf & Versand durch <Text style={{fontWeight: 'bold'}}>design-bestseller.de</Text></Text>
            <Icon
              name='up_arrow'
              size={30}
              color='black'
              style={{transform: [{ rotateX: this.state.collapsed ? "180deg" : "0deg" }]}}
            />
          </View>
        </TouchableOpacity>
        <Collapsible collapsed={this.state.collapsed} align="center">
          <View style={{ marginBottom: 10 }}>
            <Text style={{paddingTop: 5, paddingBottom: 5}}><Text style={{fontWeight: "bold"}}>Mathes Design GmbH,</Text> Büchel 29-31, 52062 Aachen, DE</Text>
            <TouchableOpacity onPress={() => this.navigateToWeb('widerrufsrecht/')}><Text style={styles.webLink}>Widerruf & Rückgabe</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToWeb('agb/')}><Text style={styles.webLink}>AGB</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToWeb('datenschutz/')}><Text style={styles.webLink}>Datenschutz</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToWeb('impressum/')}><Text style={styles.webLink}>Shop Impressum</Text></TouchableOpacity>
          </View>
        </Collapsible>
      </View>
    );
  }

  navigateToWeb = (page) => {
    const dbs_url = "https://www.design-bestseller.de/";
    this.props.navigation.navigate(
      'Webpage',
      {
        data: {uri: dbs_url + page}
      }
    )
  }

}

const styles = StyleSheet.create({
  webLink: { color: '#1068bf', paddingTop: 5, paddingBottom: 5 },
});
