import flattenInboxMessage from "./flattenInboxMessage";

const processInboxMessages = (stateMessages, stateMessagesById, data, thisUserId) => {
  let newMessages = {...stateMessages};
  let messagesById = {...stateMessagesById};
  try {
    data.forEach((msg) => {
      let user_id = msg.user.id;
      if (thisUserId === user_id)
        if (msg.recipient && msg.recipient.id)
          user_id = msg.recipient.id;
        else
          user_id = 0;
      if (user_id) {
        messagesById[user_id] = messagesById[user_id] ? messagesById[user_id].slice() : [];
        if (!messagesById[user_id].includes(String(msg.mid))) {
          messagesById[user_id].push(String(msg.mid));
        }
      }
      newMessages = {
        ...newMessages,
        [msg.mid]: {
          ...newMessages[msg.mid],
          ...flattenInboxMessage(msg),
        }
      };

    });
  }catch (e) {
    //TODO: return some message?
  }
  return {
    newMessages,
    messagesById,
  }
};
export default processInboxMessages;
