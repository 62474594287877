import  React, {Component} from 'react';
import {
  ScrollView,
  Text,
  View,
  TouchableOpacity, Platform,
  Alert
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import CustomIndicator from "../components/CustomIndicator";
import IndicatorPage from '../components/general/IndicatorPage';
import Colors from '../constants/Colors';
import CustomTextInput from "./CustomTextInput";
import Styles from "../constants/Styles";
import * as Config from "../Config";
import CustomTextArea from "./CustomTextArea";
import CustomCheckbox from "./CustomCheckbox";
import CustomButton from './general/CustomButton';
import Layout from '../constants/Layout';
import { Thumbnail } from "../components/Thumbnail";
import { chooseFileMethod, pickAndCompressImage } from '../helpers/images';

class UserEditComponent extends Component {

  state = {
    activeMenu: this.props.navigation.getParam('tab', false) || 'profile',
    agreeSocial: false,
    birthday: '',
    blog: '',
    currentPassword:'',
    description: '',
    editingImage: false,
    email: '',
    errorMessage: null,
    etsy: '',
    facebook: '',
    instagram: '',
    label: '',
    newsletter: false,
    notifyComment: false,
    notifyMention: false,
    notifyNode: false,
    notifyPm:false,
    notifyReminder: false,
    password: '',
    pinterest: '',
    rePassword: '',
    savePressed: false,
    weeklyReminder: false,
    showAppleAlertMessage: this.props.navigation.getParam('showAppleAlertMessage', false)
  };

  componentDidMount() {
    this.setState({
      agreeSocial: this.props.user.agreeSocial,
      birthday: this.props.user.birthday,
      blog: this.props.user.blog,
      description: this.props.user.textValue,
      email: this.props.user.mail,
      etsy: this.props.user.etsy,
      facebook: this.props.user.facebook,
      instagram: this.props.user.instagram,
      label: this.props.user.label,
      newsletter: this.props.user.newsletter,
      notifyComment: this.props.user.notifyComment == 1,
      notifyMention: this.props.user.notifyMention,
      notifyNode: this.props.user.notifyNode == 1,
      notifyPm: this.props.user.notifyPm == 1,
      notifyReminder: this.props.user.notifyReminder,
      pinterest: this.props.user.pinterest,
      weeklyReminder: this.props.user.weeklyReminder,
    });
    if (Platform.OS === 'ios' && this.state.showAppleAlertMessage) {
      this.setState({showAppleAlertMessage: false}, this.showPasswordSetAlert)
    }
  };

  showPasswordSetAlert = () => {
    Alert.alert(
      "",
      "Bitte setze ein Passwort um den Zugriff auf dein Konto auch über andere Plattformen (z.B. die Internetseite) zu ermöglichen.",
      [
        {
          text: 'OK',
          onPress: this.props.appleConnectRedirect
        }
      ],
      { cancelable: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.userUpdateSucceeded && prevProps.user.isUserUpdating && !this.props.user.isUserUpdating) {
      this.props.navigation.goBack();
    }
  };

  userEditSubmit = () => {
    EventRegister.emit('analyticEvent', {
      category: 'UserEdit',
      action: 'Save updated profile',
      label: 'User pressed save on userEdit page'
    });
    if (this.state.label === null || this.state.label === ''){
      this.setState({errorMessage: 'username error'})
    }
    else if (this.state.currentPassword === null){
      this.setState({errorMessage: 'password error'})
    }
    else if (this.state.email===null || this.state.email===''){
      this.setState({errorMessage: 'email error'})
    }
    else if (this.state.password!==this.state.rePassword){
      this.setState({errorMessage: 'Die eingegebenen Passwörter stimmen nicht überein.'})
    }
    else {
      let updatedDescription = this.state.description;
      const params = {
        activeMenu: this.state.activeMenu,
        agree_social: this.state.agreeSocial,
        birthday: this.state.birthday,
        blog: this.state.blog && this.state.blog.length ? this.state.blog.trimLeft() : '',
        description: updatedDescription && updatedDescription != null ? updatedDescription.replace(/(?:\r\n|\r|\n)/g, '<br>') : "",
        etsy: this.state.etsy && this.state.etsy.length ? this.state.etsy.trimLeft() : '',
        facebook: this.state.facebook && this.state.facebook.length ? this.state.facebook.trimLeft() : '',
        instagram: this.state.instagram && this.state.instagram.length ? this.state.instagram.trimLeft() : '',
        label: this.state.label,
        mail: this.state.email,
        newsletter: this.state.newsletter,
        notify_comment: this.state.notifyComment,
        notify_mention: this.state.notifyMention,
        notify_node: this.state.notifyNode,
        notify_pm: this.state.notifyPm,
        notify_reminder: this.state.notifyReminder,
        picture: this.props.upload.image_id,
        pinterest: this.state.pinterest && this.state.pinterest.length ? this.state.pinterest.trimLeft() : '',
        self: this.props.user.self,
        token: this.props.user.token,
        weeklyReminder: this.state.weeklyReminder,
      };
      if (this.state.password) {
        params.password = this.state.password;
      }
      this.setState({savePressed:true}, () => this.props.editUser(params));
    }
  };

  render() {
    // #622 Show a full page spinner on form submission.
    if (this.props.user.isUserUpdating) {
      return (
        <IndicatorPage />
      );
    }

    let loginForm =
      (<View style={Styles.pageScrollViewContainer}>
        <CustomTextInput
          editable={false}
          label="Benutzername"
          nonEditableStyle={{ backgroundColor: Colors.border }}
          onChangeText={(label) => {
            this.setState({label, errorMessage: null, savePressed: false});
          }}
          value={this.state.label}
        />
        <CustomTextInput
          keyboardType='email-address'
          label='E-Mail-Adresse'
          onChangeText={(email) => {
            this.setState({email, errorMessage: null, savePressed: false});
          }}
          value={this.state.email}
        />
        <CustomTextInput
          secureTextEntry={true}
          value={this.state.password}
          onChangeText={(text) => {this.setState({password: text})}}
          label="Passwort"
          clearButtonMode="always"
          selectTextOnFocus={true}
          autoCorrect={false}
          controlled={true}
        />
        <CustomTextInput
          secureTextEntry={true}
          value={this.state.rePassword}
          onChangeText={(text) => {this.setState({rePassword: text})}}
          label="Passwort bestätigen"
          clearButtonMode="always"
          selectTextOnFocus={true}
          autoCorrect={false}
          controlled={true}
        />
      </View>);
    let profileForm =
      (<View style={Styles.pageScrollViewContainer}>
        <TouchableOpacity onPress={() => chooseFileMethod('user', this.props.fileUpload) } style={{
          marginVertical: Config.STANDARD_MARGIN,
          alignSelf: 'center'
        }}>
          {this.props.uploading ?
            <View style={{ height: 100 }}>
              <CustomIndicator />
            </View>
            :
            <Thumbnail
              size="large"
              source={{uri: this.props.upload.image_id ? this.props.upload.image_uri : this.props.user.pictureUri}}
            />
          }
        </TouchableOpacity>
        <CustomTextArea
          value={this.state.description && this.state.description != null ? this.state.description.replace(/<br ?\/?>/g, "\n"): ""}
          label="Profilbeschreibung"
          onChangeText={(description) => {
            this.setState({description, errorMessage: null, savePressed: false});
          }}
          helpText='Hier kannst du dich kurz vorstellen und den anderen Mitgliedern mitteilen, wer du bist.'
        />
        <CustomTextInput
          value={this.state.birthday}
          label='Geburtstag'
          onChangeText={(birthday) => {
            this.setState({birthday, errorMessage: null, savePressed: false});
          }}
          secureTextEntry={false}
        />
        <CustomTextInput
          value={this.state.blog}
          secureTextEntry={false}
          label='Link zu deinem Blog'
          onChangeText={(blog) => {
            this.setState({blog, errorMessage: null, savePressed: false});
          }}
          autoCorrect={false}
        />
        <CustomTextInput
          value={this.state.facebook}
          secureTextEntry={false}
          label='Link zu deinem Facebook Profil'
          onChangeText={(facebook) => {
            this.setState({facebook, errorMessage: null, savePressed: false});
          }}
          autoCorrect={false}
        />
        <CustomTextInput
          value={this.state.instagram}
          secureTextEntry={false}
          label='Link zu deinem Instagram Profil'
          onChangeText={(instagram) => {
            this.setState({instagram, errorMessage: null, savePressed: false});
          }}
          autoCorrect={false}
        />
        <CustomTextInput
          value={this.state.pinterest}
          label='Link zu deinem Pinterest-Profil ein'
          onChangeText={(pinterest) => {
            this.setState({pinterest, errorMessage: null, savePressed: false});
          }}
          autoCorrect={false}
        />
        <CustomTextInput
          value={this.state.etsy}
          label='Link zu deinem Etsy-Shop'
          onChangeText={(etsy) => {
            this.setState({etsy, errorMessage: null, savePressed: false});
          }}
          autoCorrect={false}
        />
        <CustomCheckbox
          checked={this.state.agreeSocial}
          title="Ich möchte nicht, dass SoLebIch meine Inhalte auf Social Media Platformen (Facebook, Pinterest, Instagram etc.) zeigt."
          onPress={() => {
            this.setState({agreeSocial: !this.state.agreeSocial, errorMessage: null, savePressed: false});
          }}
        />
      </View>);
    let emailForm =
      (<View style={Styles.pageScrollViewContainer}>
        <CustomCheckbox
          checked={this.state.newsletter}
          title="Ja, ich möchte Homestorys und die beliebtesten Bilder im wöchentlichen Newsletter erhalten."
          onPress={() => {
            this.setState({newsletter: !this.state.newsletter, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.weeklyReminder}
          title="Ja, ich möchte jede Woche eine Zusammenfassung der Herzen, Kommentare und Ansichten für meine Bilder erhalten."
          onPress={() => {
            this.setState({weeklyReminder: !this.state.weeklyReminder, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.notifyMention}
          title="Ich möchte eine E-Mail erhalten, wenn mich ein anderes Mitglied erwähnt."
          onPress={() => {
            this.setState({notifyMention: !this.state.notifyMention, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.notifyPm}
          title="Ich möchte eine E-Mail erhalten, wenn mir andere Mitglieder eine private Nachricht senden."
          onPress={() => {
            this.setState({notifyPm: !this.state.notifyPm, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.notifyNode}
          title="Ich möchte eine E-Mail erhalten, wenn meine hochgeladenen Bilder kommentiert werden."
          onPress={() => {
            this.setState({notifyNode: !this.state.notifyNode, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.notifyComment}
          title="Ich möchte eine E-Mail erhalten, wenn andere Mitglieder einen Inhalt kommentieren, den ich ebenfalls kommentiert habe."
          onPress={() => {
            this.setState({notifyComment: !this.state.notifyComment, errorMessage: null, savePressed: false});
          }}
        />
        <CustomCheckbox
          checked={this.state.notifyReminder}
          title="Ich möchte eine E-Mail erhalten, wenn ich mich länger als einen Monat nicht eingeloggt habe."
          onPress={() => {
            this.setState({notifyReminder: !this.state.notifyReminder, errorMessage: null, savePressed: false});
          }}
        />
      </View>);
    const menus = [
      {title: 'Login', name: 'login'},
      {title: 'Profil', name: 'profile'},
      {title: 'E-Mail', name: 'email'},
    ];
    return (
      <ScrollView
        contentContainerStyle={{
          width: Layout.half.width,
          maxWidth: Layout.half.width,
          minWidth: Layout.half.width,
          backgroundColor: Colors.background,
        }}
        style={{backgroundColor: Colors.background, flex:1}}
        keyboardShouldPersistTaps={"handled"}
      >
        <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{flexDirection: 'row', alignSelf: 'center'}}>
          {menus.map((menuItem, index )=> (
            <TouchableOpacity
              key={index}
              onPress={() => { this.setState({activeMenu: menuItem.name, errorMessage: null, savePressed: false}); }}
              style={(menuItem.name !== this.state.activeMenu) ? Styles.greyButtonContainer: Styles.greyButtonContainerActive}
            >
              <Text style={Styles.pinkButtonText}>{menuItem.title}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
          {this.state.activeMenu === 'login'?
            loginForm:
            this.state.activeMenu === 'profile'?
              profileForm
              :
              this.state.activeMenu === 'email'?
                emailForm:
                null
          }
        <CustomButton
          title="Speichern"
          onPress={this.userEditSubmit}
          loading={this.props.user.isUserUpdating}
          disabled={this.props.user.isUserUpdating}
        />
        <View style={{alignItems: 'center'}}>
          <Text style={{color: Colors.tintColor, paddingHorizontal: 5, textAlign: 'center'}}>
            {this.state.errorMessage}
          </Text>
        </View>
        <View style={{alignItems: 'center'}}>
          <Text style={{paddingHorizontal: 5, textAlign: 'center'}}>
            {
              (this.props.user.userUpdateSucceeded && !this.props.user.isUserUpdating && this.state.savePressed) ?
              'Updated successfully' :
              this.props.user.userUpdateFailureMessage
            }
          </Text>
        </View>
      </ScrollView>
    );
  }
}

export default UserEditComponent;

