import React from 'react';
import { Text, View } from 'react-native';
import { openURL } from 'expo-linking';

import MenuDynamic from '../components/MenuDynamic';
import MenuItem from '../components/MenuItem';
import Colors from '../constants/Colors';
import { goToUrl } from '../helpers/urls';

class MenuScreenComponent extends React.Component {
  render()  {
    return (
      <MenuDynamic
        chosenItem={0}
        menuGet={this.props.menuGet}
        menuName={'menu-new-mmenu'}
        menus={this.props.menus}
        setChosenCategoryAndClose={this.setChosenCategoryAndClose}
      />
    );
  }
  setChosenCategoryAndClose = ({ path }) => {
    // Handle the special case of the logout menu item.
    if (path == 'user/logout') {
      this.props.logout();
    }
    // Handle the special case for the absolute community url
    else if (path === 'https://www.solebich.de/community') {
      goToUrl(['community'], {}, this.props.navigation, this.props.nodes);
    }
    // Handle external URLs.
    else if (path.indexOf('http') != -1 && path.indexOf('solebich.de') == -1) {
      openURL(path);
    }
    // Handle internal URLs.
    else {
      const urls = path.split('/');
      goToUrl(urls, {}, this.props.navigation, this.props.nodes);
    }
  }
}

export default MenuScreenComponent;
