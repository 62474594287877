import { createReducer } from './helpers/createReducer';
import * as ActionTypes from "../actions/actionTypes";
import connectionTypes from '../constants/connectionTypes';

const initialState = {
  connectivityType: connectionTypes.UNKNOWN,
  isConnected: undefined,
  isInternetReachable: undefined,
};

function storeConnectivityInfo(state, action) {
  const newType = action.payload.type.toLowerCase();
  return {
    ...state,
    connectivityType: newType,
    isConnected: action.payload.isConnected,
    isInternetReachable: action.payload.isInternetReachable,
    isOffline: newType === connectionTypes.NONE || newType === connectionTypes.UNKNOWN,
  }
}

const device = createReducer(initialState, {
  [ActionTypes.STORE_CONNECTIVITY_INFO]: storeConnectivityInfo,
});

export default device;
