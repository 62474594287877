import React, { PureComponent } from 'react';
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { EventRegister } from 'react-native-event-listeners';
import { SliderBox } from 'react-native-image-slider-box';
import VectorIcon from 'react-native-vector-icons/FontAwesome';
import ImageViewer from 'react-native-image-zoom-viewer';
import { debounce } from "lodash";

import * as Config from '../Config';
import Colors from "../constants/Colors";
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import CustomButton from './general/CustomButton';
import IndicatorPage from './general/IndicatorPage';

import { Icon } from "./Icon";
import CommerceShopInfo from "../components/CommerceShopInfo";
import CommerceShopContactInfo from "../components/CommerceShopContactInfo";
import CommerceVariantSelector from "../components/CommerceVariantSelector";
import CustomDropDownComponent from '../components/CustomDropDownComponent';
import ImageModal from "./general/ImageModal";
import ImagesList from '../components/ImagesList';

export default class ProductNodeCommerce extends PureComponent {

  constructor(props) {
    super(props);
    this.delayLike = debounce(this.onPressLike, 50);
  }

  state = {
    currentImage: 0,
    modalVisible: false,
    // When you land in the PDP screen load the item that is shown in the PLP screen,
    selectedProduct: this.props.navigation.getParam('productId'),
  };

  componentDidMount() {
    const { commerceProducts, commerceProductsById } = this.props;
    const node = this.currentNode();
    const parentId = node.dbsParent ?? node.id;
    if (!this.props.nodes[parentId]) {
      this.props.nodesGet({nid: parentId});
    }
  }

  currentNode = () => {
    // If the parent of the selected product is available and different than the one in the state,
    // update the state.
    const { selectedProduct } = this.state;
    const { commerceProducts, nodes } = this.props;
    if (commerceProducts[selectedProduct] && commerceProducts[selectedProduct].parentSLI) {
      if (nodes[commerceProducts[selectedProduct].parentSLI]) {
        return nodes[commerceProducts[selectedProduct].parentSLI];
      }
    }
    return this.props.node;
  }

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      const node = this.currentNode();
      if (node.liked) {
        this.props.likeNode({node: node, op: 'unflag'});
      }
      else {
        EventRegister.emit('analyticEvent', {
          category: 'Flag',
          action: 'Herzen',
          label: 'Product'
        });
        this.props.likeNode({node: node, op: 'flag'});
      }
    }
  };

  selectedProductSet = (id) => {
    this.setState({ selectedProduct: id }, () => {this.props.nodesGet({nid: this.props.commerceProducts[id].parentSLI})});
  };

  /**
   * Act when an item is add to cart.
   */
  addToCart = (item) => {
    // Send a GA event.
    EventRegister.emit('analyticEvent', {category: 'CartApp', action: 'addToCart', label: item.sku});
    // Dispatch the corresponding action.
    this.props.addToCart(item);
  }



  render() {
    const { commerceProducts, commerceProductsById } = this.props;
    const node = this.currentNode();
    const parentId = node.dbsParent ?? node.id;
    const parentNode = this.props.nodes[parentId];

    // Item is the product variation.
    let item = commerceProducts[this.state.selectedProduct];
    if (!item) {
      if (commerceProductsById && commerceProductsById[parentId]) {
        item = commerceProducts[commerceProductsById[parentId][0]];
      }
    }

    // Both product node and product variation (item) should be loaded before moving on.
    if (!node || !item) {
      return (
        <IndicatorPage />
      );
    }

    const color = item.delivery_time_id == 262701 ? '#54a76b' : '#000000';
    // Create array with images for SliderBox
    const images = item.images.map(image => image.styles["900x490-conditional"]);
    // Create array with images for ImageViewer
    const images2 = item.images.map(image => { return { url: image.styles["900x490-conditional"]}})
    return (
      <ScrollView style={[Styles.pageScrollViewContainer, {paddingHorizontal: 0}]} >
        <View style={{marginBottom: Config.STANDARD_MARGIN_BIG, marginTop: Config.STANDARD_MARGIN_SMALL}}>
          <ImageModal
            images={images}
          />
          <View style={[Styles.padInputWidth, { marginTop: 10, paddingHorizontal: 10 }]}>

            <Text style={{textAlign: "center", fontWeight: "bold", fontSize: 16}}>{item.details_manufacturer_label}</Text>

            <Text style={[Styles.pageTitle, {
              marginTop: Config.STANDARD_MARGIN_SMALL,
              marginBottom: Config.STANDARD_MARGIN_SMALL
            }]}>{node.label}</Text>

            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              {item.on_sale && <Text style={{fontSize: 20, fontWeight: 'bold', paddingRight: 10, textDecorationLine: 'line-through'}}>{item.original_price} €</Text>}
              <Text style={{color: item.on_sale ? Colors.onSaleRed : Colors.darkText, fontSize: 20, fontWeight: 'bold'}}>{item.current_price} €</Text>
            </View>

            <Text style={{textAlign: 'center', paddingBottom: 10, paddingTop: 10}}>inkl 19% MwSt. <Text style={{textDecorationLine: 'underline'}}>zzgl. Versand</Text></Text>

            <CommerceVariantSelector
              commerceProducts={commerceProducts}
              commerceProductsById={commerceProductsById}
              node={node}
              selectedProduct={this.state.selectedProduct}
              selectedProductSet={this.selectedProductSet}
            />

            <CustomButton
              title="In den Warenkorb"
              onPress={() => this.addToCart(item)}
              buttonStyle={[Styles.shopButton, {marginBottom: Config.STANDARD_MARGIN, width: '100%'}]}
              fontFamily={"OpenSansRegular"}
            />

            <View style={{display: 'flex', flexDirection: 'row', paddingBottom: 10, justifyContent: 'center', alignItems: 'center'}}>
              <Icon
                name='truck'
                size={Config.SIZE_ICON}
                color='black'
                style={{paddingRight: 5, paddingLeft: 5}}
              />
              <Text style={{color: color ,fontWeight: 'bold'}}>{item.delivery_time_label}</Text>
            </View>

            <CommerceShopInfo navigation={this.props.navigation} />

            <CommerceShopContactInfo />

            <CustomDropDownComponent type={'fields'} content={
              <View>
                {/*TODO create a json file with fields and iterate through this*/}
                {!!item.sku && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Artikelnummer:</Text>
                    <Text>{item.sku}</Text>
                  </View>
                )}
                {!!item.details_material_descript_value && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Material:</Text>
                    <Text>{item.details_material_descript_value}</Text>
                  </View>
                )}
                {!!item.details_size_description_value && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Maße:</Text>
                    <Text>{item.details_size_description_value}</Text>
                  </View>
                )}
                {!!item.details_manufacturer_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Hersteller:</Text>
                    <Text>{item.details_manufacturer_label}</Text>
                  </View>
                )}
                {!!item.vt_table_frame && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Rahmen:</Text>
                    <Text>{item.vt_table_frame}</Text>
                </View>
                )}
                {!!item.details_designer_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Designer:</Text>
                    <Text>{item.details_designer_label}</Text>
                  </View>
                )}
                {!!item.details_shape && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Formen:</Text>
                    <Text>{item.details_shape}</Text>
                  </View>
                )}
                {!!item.details_materials && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Material:</Text>
                    <Text>{item.details_materials}</Text>
                  </View>
                )}
                  {!!item.light_control && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Regelung:</Text>
                    <Text>{item.light_control}</Text>
                  </View>
                )}
                {!!item.details_type_of_chairs.length && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Stuhlart:</Text>
                    {item.details_type_of_chairs.map( arrayItem => {
                      return (
                        <Text>{arrayItem.label}</Text>
                      )
                    })}
                  </View>
                )}
                {!!item.details_feature.length && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Merkmal:</Text>
                    {item.details_feature.map( arrayItem => {
                      return (
                        <Text>{arrayItem.label}</Text>
                      )
                    })}
                  </View>
                )}
                {!!item.details_color_ranges.length && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Farbe:</Text>
                    {item.details_color_ranges.map( arrayItem => {
                      return (
                        <Text>{arrayItem.label}</Text>
                      )
                    })}
                  </View>
                )}
                {!!item.details_type_of_wood_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Holzart:</Text>
                    <Text>{item.details_type_of_wood_label}</Text>
                  </View>
                )}
                {!!item.details_size_margin_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Größe:</Text>
                    <Text>{item.details_size_margin_label}</Text>
                  </View>
                )}
                {!!item.details_shape && (
                <View style={{marginBottom: 10}}>
                  <Text style={{fontWeight: "bold"}}>Farbe:</Text>
                  <Text>{item.vt_color_manufact_label}</Text>
                </View>
                )}
                {!!item.details_energy_label_type && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Energy Label:</Text>
                    <Text>{item.details_energy_label_type}</Text>
                  </View>
                )}
                {!!item.details_family_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Family label:</Text>
                    <Text>{item.details_family_label}</Text>
                  </View>
                )}
                {!!item.vt_size_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Größe:</Text>
                    <Text>{item.vt_size_label}</Text>
                  </View>
                )}
                {!!item.vt_material_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Material:</Text>
                    <Text>{item.vt_material_label}</Text>
                  </View>
                )}
                {!!item.vt_setting_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Ausstattung:</Text>
                    <Text>{item.vt_setting_label}</Text>
                  </View>
                )}
                {!!item.vt_frame_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Gestell:</Text>
                    <Text>{item.vt_frame_label}</Text>
                  </View>
                )}
                {!!item.vt_chair_cover_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Bezug:</Text>
                    <Text>{item.vt_chair_cover_label}</Text>
                  </View>
                )}
                {!!item.vt_color_cable_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Kabelfarbe:</Text>
                    <Text>{item.vt_color_cable_label}</Text>
                  </View>
                )}
                {!!item.vt_chair_seat_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Stühle / Sitz:</Text>
                    <Text>{item.vt_chair_seat_label}</Text>
                  </View>
                )}
                {!!item.vt_city_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Stadt:</Text>
                    <Text>{item.vt_city_label}</Text>
                  </View>
                )}
                {!!item.vt_motive_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Motiv:</Text>
                    <Text>{item.vt_motive_label}</Text>
                  </View>
                )}
                {!!item.vt_color_hook_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Haken Farbe:</Text>
                    <Text>{item.vt_color_hook_label}</Text>
                  </View>
                )}
                {!!item.vt_usm_row_1_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>USM-Individuell / 1. Reihe:</Text>
                    <Text>{item.vt_usm_row_1_label}</Text>
                  </View>
                )}
                {!!item.vt_usm_row_2_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>USM-Individuell / 2. Reihe:</Text>
                    <Text>{item.vt_usm_row_2_label}</Text>
                  </View>
                )}
                {!!item.vt_usm_row_3_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>USM-Individuell / 3. Reihe:</Text>
                    <Text>{item.vt_usm_row_3_label}</Text>
                  </View>
                )}
                {!!item.vt_usm_row_4_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>USM-Individuell / 4. Reihe:</Text>
                    <Text>{item.vt_usm_row_4_label}</Text>
                  </View>
                )}
                {!!item.vt_chair_trim_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Stühle / Keder:</Text>
                    <Text>{item.vt_chair_trim_label}</Text>
                  </View>
                )}
                {!!item.light_detail_pdf_filename && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Datenblatt:</Text>
                    <TouchableOpacity
                    onPress={() => this.props.navigation.navigate('Webpage', {data: {uri: item.light_detail_pdf_uri}, pdf: true})}
                    >
                    <Text><VectorIcon name="file-pdf-o" size={20} color={Styles.iconDefault}/> {item.light_detail_pdf_filename}</Text>
                    </TouchableOpacity>
                  </View>
                )}
                {!!item.light_scattering && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Lichverteilung:</Text>
                    <Text>{item.light_scattering}</Text>
                  </View>
                )}
                {!!item.light_class && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Schutzklasse:</Text>
                    <Text>{item.light_class}</Text>
                  </View>
                )}
                {!!item.light_dimmable_label && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Dimmbar:</Text>
                    <Text>{item.light_dimmable_label}</Text>
                  </View>
                )}
                {!!item.light_energy_efficiency && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Energieeffizienzklasse:</Text>
                    <Text>{item.light_energy_efficiency}</Text>
                  </View>
                )}
                {!!item.light_power_supply && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Energieversorgung:</Text>
                    <Text>{item.light_power_supply}</Text>
                  </View>
                )}
                {!!item.light_energy_image_filename && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Energy Image:</Text>
                    <TouchableOpacity
                      onPress={() => this.props.navigation.navigate('Webpage', {data: {uri: item.light_detail_pdf_uri}, pdf: true})}>
                      <Text><VectorIcon name="file-pdf-o" size={20} color={Styles.iconDefault}/>{item.light_energy_image_filename}</Text>
                    </TouchableOpacity>
                  </View>
                )}
                {!!item.light_color_tempe && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Farbtemperatur:</Text>
                    <Text>{item.light_color_tempe}</Text>
                  </View>
                )}
                {!!item.light_lightsource && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Leuchtmittel:</Text>
                    <Text>{item.light_lightsource}</Text>
                  </View>
                )}
                {!!item.light_lamp && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Leuchtmittel:</Text>
                    <Text>{item.light_lamp}</Text>
                  </View>
                )}
                {!!item.light_power && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Leistung in Watt:</Text>
                    <Text>{item.light_power}</Text>
                  </View>
                )}
                {!!item.light_checkmark && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Prüfzeichen:</Text>
                    <Text>{item.light_checkmark}</Text>
                  </View>
                )}
                {!!item.light_color_repro && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Farbwiedergabe:</Text>
                    <Text>{item.light_color_repro}</Text>
                  </View>
                )}
                {!!item.light_fixture && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Fassung:</Text>
                    <Text>{item.light_fixture}</Text>
                  </View>
                )}
                {!!item.light_luminous_in && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>Lichtstrom in Lumen:</Text>
                    <Text>{item.light_luminous_in}</Text>
                  </View>
                )}
                {!!item.light_protection && (
                  <View style={{marginBottom: 10}}>
                    <Text style={{fontWeight: "bold"}}>IP Kennzahl:</Text>
                    <Text>{item.light_protection}</Text>
                  </View>
                )}
              </View>}
              label={'Eigenschaften'}
            />

            <CustomDropDownComponent
              content={
                <View>
                  {!!node.description && (
                  <Text>{node.description}</Text>
                  )}
                </View>
              }
              label={'Beschreibung'}
            />

            { (parentNode && parentNode.images >= 1) &&
              <CustomDropDownComponent content={
                  <ImagesList
                    containerWidth={Styles.button.width}
                    data={this.props.nodes}
                    isLikingNode={this.props.isLikingNode}
                    isLoading={this.props.isLoading}
                    likeComment={this.props.likeComment}
                    likeNode={this.props.likeNode}
                    list={this.props.list}
                    menus={[{title: '', path: 'node/' + parentId, view: 'frontpage_views_v3', display: 'block_1'}]}
                    navigation={this.props.navigation}
                    nodesById={this.props.nodesById}
                    onRefresh={this.props.onRefresh}
                    onlyLoadFirstPage={true}
                    pagination={this.props.pagination}
                    viewGet={this.props.viewGet}
                  />
                } label={'Das Product in echten Wohnungen'}
              />
            }


          </View>
        </View>
      </ScrollView>
    );
  }
}
