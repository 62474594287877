import  React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import UserScreenComponent from '../components/UserScreenComponent';
import {
  followUser,
  likeNode,
  logout,
  usersGet,
  usersGetTamperedByUsername,
  viewGet,
  likeComment
} from "../actions/index";
import IndicatorPage from '../components/general/IndicatorPage';

class UserScreen extends Component {

  componentDidMount() {
    const { id } = this.props.navigation.state.params;
    this.props.usersGet({ ids: [id] });
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.navigation.state.params;
    // if user ID is passed check for a user.
    if (id) {
      if (!this.props.users[id] || !this.props.users[id].following) {
        // fetch the user.
        this.props.usersGet({ ids: [id] });
      }
    }
  }

  onRefresh = () => {
    const { id } = this.props.navigation.state.params;
    this.props.usersGet({ ids: [id] });
  }

  render() {
    const { id } = this.props.navigation.state.params;
    const user = this.props.users[(id) ?? null];
    // if user does not exist in the DB yet, then wait.
    if (!user || !user.following) {
      return (
        <IndicatorPage />
      );
    }

    return (
      <View style={{flex:1}}>
        <UserScreenComponent
          followUser={this.props.followUser}
          data={this.props.data}
          isGettingUsersContent={this.props.isGettingUsersContent}
          isGettingUsersComments={this.props.isGettingUsersComments}
          list={this.props.list}
          logInState={this.props.logInState}
          logout={this.props.logout}
          likeNode={this.props.likeNode}
          navigation={this.props.navigation}
          nodesById={this.props.nodesById}
          user={user}
          viewGet={this.props.viewGet}
          usersContent={this.props.usersContent}
          usersComments={this.props.usersComments}
          pagination={this.props.pagination}
          isLikingNode={this.props.isLikingNode}
          onRefresh={this.onRefresh}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.images.nodes,
    isGettingUsersContent: state.usersContent.isLoading,
    isGettingUsersComments: state.usersComments.isLoading,
    isLikingNode: state.images.isLikingNode,
    isLoadingMentioning: state.users.isLoadingMentioning,
    nodesById: state.images.nodesById,
    usersContent: state.usersContent,
    usersComments: state.usersComments,
    list: state.images,
    logInState: state.user,
    users: state.users.users,
    usernames: state.users.usernames,
    pagination: state.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    followUser: (payload) => {
      dispatch(followUser(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    logout: (payload) => {
      dispatch(logout(payload))
    },
    usersGet: (payload) => {
      dispatch(usersGet(payload))
    },
    usersGetTamperedUseranme: (payload) => {
      dispatch(usersGetTamperedByUsername(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    likeComment: (payload) => {
      dispatch(likeComment(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
