import Intl from 'intl';
import 'intl/locale-data/jsonp/de-DE';
import flattenNode from './flattenNode';

const processNodes = (stateNodes, stateNodesById, data, blockedNodes, blockedUsers) => {
  return  data.reduce((object, item) => {
    // If node comes with missing data bail out.
    if (!item || !item.type || !item[item.type]) {
      return;
    }
    if (!object.nodesById.includes(item.id)) {
      object.nodesById.push(item.id);
    }
    object.newNodes[item.id] = flattenNode(item[item.type], stateNodes[item.id]);
    const uid = object.newNodes[item.id].userId;
    // A node can be blocked if returned as such from th API or if is blocked locally.
    object.newNodes[item.id].blocked = object.newNodes[item.id].blocked ?? (blockedNodes && blockedNodes[item.id] || blockedUsers && blockedUsers[uid]);
    return object;
  },
  // Initial object.
  {
    newNodes: {},
    nodesById: stateNodesById.slice(),
  });
};

export default processNodes;
