import React, { Component } from 'react';
import {View, Text, TouchableOpacity, ScrollView} from "react-native";
import * as Config from '../../Config';
import CustomButton from "../general/CustomButton";
import {Icon} from "../Icon";
import Styles from "../../constants/Styles";
import CustomIndicator from "../CustomIndicator";
import SelectedPaymentMethod from "./SelectedPaymentMethod";
import {isEmpty} from 'lodash';

class CheckoutScreenComponent extends Component {

  state = {
    selectedShipping: this.props.cart.shipping_service ?? Object.keys(this.props.shippingOptions)[0].toString() //TODO Remove this since shipping option in cart should be available at this point
  }

  componentDidMount() {
    // Fetch shipping and billing available addresses
    this.props.getShippingAddress();
    this.props.getBillingAddress();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if ( this.props.isLoadingShippingAddress !== nextProps.isLoadingShippingAddress
      || this.props.isLoadingBillingAddress !== nextProps.isLoadingBillingAddress
      || this.props.cart.payment_method !== nextProps.cart.payment_method
      || this.props.cart.profile_shipping_id !== nextProps.cart.profile_shipping_id
      || this.props.cart.profile_billing_id !== nextProps.cart.profile_billing_id
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {cart, shippingOptions, shippingAddresses, billingAddresses, isLoadingShippingAddress, isLoadingBillingAddress, navigation} = this.props;
    if (isLoadingShippingAddress || isLoadingBillingAddress) {
      return <CustomIndicator/>
    }
    else {
      const selectedShippingAddress = shippingAddresses.filter(obj => cart.profile_shipping_id == obj.id).pop();
      const selectedBillingAddress = billingAddresses.filter(obj => cart.profile_billing_id == obj.id).pop();
      return (
        <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
          <ScrollView
            contentContainerStyle={Styles.pageScrollViewContainer}
            keyboardShouldPersistTaps={"handled"}
            style={{marginBottom: 60}}
          >
            <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Lieferung</Text>
            <View style={{borderBottomWidth: 1, borderBottomColor: '#d1d1d1', paddingBottom: 15}}>
              <View style={[Styles.checkoutBlocks, {borderBottomWidth: 0, paddingBottom: 0}]}>
                <View style={Styles.textNextToIcon}>
                  <Text>{shippingOptions[this.state.selectedShipping].title}: {shippingOptions[this.state.selectedShipping].price} €</Text>
                </View>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('Delivery')} style={Styles.iconNextToText}>
                  <Icon
                    name='pencil'
                    size={20}
                    color='#818181'
                  />
                </TouchableOpacity>
              </View>
                {!isEmpty(selectedShippingAddress) ?
                  <View>
                    <Text>{selectedShippingAddress.first_name} {selectedShippingAddress.last_name}, {selectedShippingAddress.street}</Text>
                    <Text>{selectedShippingAddress.postal_code} {selectedShippingAddress.locality}</Text>
                  </View>
                  :
                  <View style={{marginTop: 10}}>
                    <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0}]} onPress={() => navigation.navigate('Delivery')}>
                      <Text>Lieferadresse auswählen</Text>
                    </TouchableOpacity>
                  </View>
                  }
                <Text style={{marginTop: 15}}>Rechnungsadresse:</Text>
                {!isEmpty(selectedBillingAddress) ?
                  <View>
                    <Text>{selectedBillingAddress.first_name} {selectedBillingAddress.last_name}, {selectedBillingAddress.street}</Text>
                    <Text>{selectedBillingAddress.postal_code} {selectedBillingAddress.locality}</Text>
                  </View> :
                  <View style={{marginTop: 10}}>
                    <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0}]} onPress={() => navigation.navigate('Delivery')}>
                      <Text>Rechnungsadresse auswählen</Text>
                    </TouchableOpacity>
                  </View> }
                <Text style={{marginTop: 15, flexWrap: 'wrap'}}>{shippingOptions[this.state.selectedShipping].description}</Text>
            </View>
            <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Zahlungsart</Text>
            <SelectedPaymentMethod
              paymentMethod={cart.payment_method}
              navigation={navigation}
            />
            <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Bestätigen</Text>
            <View style={Styles.checkoutBlocks}>
              <Text style={[Styles.textNextToIcon, {fontWeight: 'bold'}]}>{cart.total} €</Text>
              <TouchableOpacity onPress={() => navigation.navigate({routeName: 'Order', params: {cart: true}})} style={Styles.iconNextToText}>
                <Icon
                  name='eye'
                  size={20}
                  color='#818181'
                />
              </TouchableOpacity>
            </View>
          </ScrollView>
          <View style={Styles.shopButtonWrap}>
            <CustomButton
              title="jetzt kaufen"
              onPress={() => {}}
              buttonStyle={Styles.shopButton}
              fontFamily={"OpenSansRegular"}
            />
          </View>
        </View>
      )
    }
  }
}

export default CheckoutScreenComponent;
