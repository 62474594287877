import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import processOrders from './helpers/processOrders';
import processShipping from "./helpers/processShipping";

const initialState = {
  isLoadingOrders: false,
  ordersById: {},
  shippingOptions: {}
};

function ordersGet(state, action) {
  return {
    ...state,
    isLoadingOrders: true
  };
}

function ordersGetSuccess(state, action) {
  const processedOrders = processOrders(action.payload);
  return {
    ...state,
    isLoadingOrders: false,
    ordersById: processedOrders
  };
}

function ordersGetFail(state, action) {
  return {
    isLoadingOrders: false,
    ...state,
  };
}

function shippingCollect(state, action) {
  return {
    ...state,
  };
}

function shippingCollectSuccess(state, action) {
  const processedShipping = processShipping(action.payload)
  return {
    ...state,
    shippingOptions: processedShipping.shipping
  };
}

function shippingCollectFail(state, action) {
  return {
    ...state,
  };
}

const orders = createReducer(initialState, {
  [ActionTypes.ORDERS_GET]: ordersGet,
  [ActionTypes.ORDERS_GET_SUCCESS]: ordersGetSuccess,
  [ActionTypes.ORDERS_GET_FAIL]: ordersGetFail,
  [ActionTypes.SHIPPING_COLLECT]: shippingCollect,
  [ActionTypes.SHIPPING_COLLECT_SUCCESS]: shippingCollectSuccess,
  [ActionTypes.SHIPPING_COLLECT_FAIL]: shippingCollectFail,
});

export default orders;
