import Styles from "../constants/Styles";
import React from "react";
import * as AppleAuthentication from 'expo-apple-authentication';

const AppleAuthenticationComponent = (props) => {
  const register = props.register || false
  async function connect () {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      props.appleConnect({
        newsletter: props.agreeNewsletter,
        token: credential.identityToken,
        register: register
      });
    } catch (e) {
      if (e.code === 'ERR_CANCELED') {
        alert('Der Benutzer hat die Erlaubnis nicht erteilt');
      } else {
        alert('Etwas ist schiefgelaufen');
      }
    }
  }
  return (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={register ? AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP : AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN }
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
      style={Styles.button}
      cornerRadius={0}
      onPress={connect}
    />
  )
}

export default AppleAuthenticationComponent;
