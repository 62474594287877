import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import sampleCombine from 'xstream/extra/sampleCombine';
import * as actions from '../actions/index';
import {
  postSimpleHeaderWithAuthorization, simpleHeaderWithXcsrf,
} from './headers';
import * as Helpers from '../helpers/Drupal';
import { EventRegister } from 'react-native-event-listeners';

export function whoAmI(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.WHO_AM_I)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => ({
      url: Config.BASE_URL + 'api/users/me',
      category: 'whoami',
    }));

  const response$ = sources.HTTP
    .select('whoami')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data[0].id) {

          return actions.whoAmISuccess(data);
        }
        else {
          return actions.whoAmIFail(data);
        }
      }
      catch(e){
        return actions.whoAmIFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

//TODO: i think this one is redundant
export function whoAmISuccessOrFail(sources) {
  const action$ = sources.ACTION
    .filter(action => (action.type === ActionTypes.WHO_AM_I_SUCCESS || action.type === ActionTypes.WHO_AM_I_FAIL))
    .map(action => action.payload)
    .map(arr => actions.getTokenInit());

  return {
    ACTION: action$,
  }
}

//TODO: i think this one is redundant
export function loginSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LOGIN_SUCCESS || action.type === ActionTypes.LOGIN_FAIL)
    .map(action => action.payload)
    .map(arr => actions.whoAmI());

  return {
    ACTION: action$,
  }
}

export function getTokenInit(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TOKEN_INIT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => ({
      url: Config.BASE_URL + 'api/session/token',
      category: 'token',
    }));

  const response$ = sources.HTTP
    .select('token')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data['X-CSRF-Token'])
          return actions.getTokenSuccess(data);
        else
          return actions.getTokenFail();
      }catch (e) {
        return actions.getTokenFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function logout(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LOGOUT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'user/logout';
        return ({
          url: Config.BASE_URL + path,
          category: 'logout',
        });
      }
    );

  const response$ = sources.HTTP
    .select('logout')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      return actions.logoutSuccess();
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function loginInit(sources) {

  const token$ = sources.STATE.map(state => state.user.token);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LOGIN_INIT)
    .map(action => action.payload)
    .compose(sampleCombine(token$))
    .map(([payload, token])=>{
      return {
        ...payload,
        token
      }
    });

  if (!global.btoa) {
    global.btoa = require('base-64').encode;
  }

  const request$ = payload$
    .map(payload => {
        const userpass = unescape(encodeURIComponent(payload.username)) + ":" + unescape(encodeURIComponent(payload.password));
        return ({
          url: Config.BASE_URL + 'api/login',
          category: 'login',
          method: 'POST',
          headers: postSimpleHeaderWithAuthorization(global.btoa(userpass), payload.token),
          username: payload.username,
        });
      }
    );

  const response$ = sources.HTTP
    .select('login')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    // when user is already logged in a 404 answered is returned.
    // we considered this to be a successful login.
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.data)
          return actions.loginSuccess(data);
        else
          return actions.loginFail(data);
      }catch (e) {
        return actions.loginFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function loginInitStuck(sources) {
  const response$ = sources.HTTP
    .select('login')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status >= 400);

  const action$ = xs.combine(response$)
    .map(arr => {
      EventRegister.emit('analyticEvent', {
        category: 'error',
        action: 'username_password_try_again',
      });
      return actions.whoAmI();
    });
  return {
    ACTION: action$,
  };
}

export function loginInitFail(sources) {
  const response$ = sources.HTTP
    .select('login')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status >= 500);

  const action$ = xs.combine(response$)
    .map(arr => {
      EventRegister.emit('analyticEvent', {
        category: 'error',
        action: 'username_password_not_correct',
        label: arr[0].response.request.username ?? '',
        value: arr[0].response.status,
      });
      return actions.loginFail(arr)
    });
  return {
    ACTION: action$,
  };
}

export function pushToken(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PUSH_TOKEN)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/device-token/' + payload.token;
        return ({
          url: Config.BASE_URL + path,
          method: 'POST',
          category: 'device',
        });
      }
    );

  const response$ = sources.HTTP
    .select('device')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.ok == 'YES') {
          return actions.pushTokenSuccess(data);
        }
        else
          return actions.pushTokenFail(data);
      }catch (e) {
        return actions.pushTokenFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function userRegister(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USER_REGISTER)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/user-registration';

      return ({
        url: Config.BASE_URL + path,
        category: 'users_register',
        method: 'POST',
        send: payload,
      });
    });

  const response$ = sources.HTTP
    .select('users_register')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.uid) {
          return actions.userRegisterSuccess(data.data);
        } else
          return actions.userRegisterFail(data);
      }
      catch(e) {
        return actions.userRegisterFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function userUpdate(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.EDIT_USER)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const user_fields = {
        agree_social: payload.agree_social,
        birthday: payload.birthday,
        blog: payload.blog,
        description: payload.description,
        etsy: payload.etsy,
        facebook: payload.facebook,
        instagram: payload.instagram,
        label: payload.label,
        mail: payload.mail,
        newsletter: payload.newsletter,
        notify_comment: payload.notify_comment ? 1 : 0,
        notify_mention: payload.notify_mention,
        notify_node: payload.notify_node ? 1 : 0,
        notify_pm: payload.notify_pm ? 1 : 0,
        notify_reminder: payload.notify_reminder,
        pass: payload.password,
        pinterest: payload.pinterest,
        self: payload.self,
        weekly_reminder: payload.weeklyReminder,
      };

      if (payload.activeMenu === 'profile' && payload.picture && Helpers.isNumeric(payload.picture)) {
        user_fields.picture = payload.picture;
      }

      return ({
        url: payload.self,
        method: 'PATCH',
        send: user_fields,
        headers: simpleHeaderWithXcsrf(payload.token),
        category: 'userUpdate',
      });
    });

  const response$ = sources.HTTP
    .select('userUpdate')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data) {
          return actions.editUserSuccess(data.data);
        }
        else {
          return actions.editUserFail(data);
        }
      }
      catch (e) {
        return actions.editUserFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function facebookConnect(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.FACEBOOK_CONNECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/fb_connect',
        category: 'facebook',
        method: 'POST',
        send: payload,
      });
    });

  const response$ = sources.HTTP
    .select('facebook')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.ok == 'yes') {
          return actions.facebookConnectSuccess();
        }
        else {
          return actions.facebookConnectFail(data);
        }
      }
      catch (e) {
        return actions.facebookConnectFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function facebookConnectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.FACEBOOK_CONNECT_SUCCESS)
    .map(action => action.payload)
    .map(arr => actions.whoAmI());

  return {
    ACTION: action$,
  }
}

export function appleConnect(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.APPLE_CONNECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/apple_connect',
        category: 'apple',
        method: 'POST',
        send: payload,
      });
    });

  const response$ = sources.HTTP
    .select('apple')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.ok == 'yes') {
          return actions.appleConnectSuccess(data);
        }
        else {
          return actions.appleConnectFail(data);
        }
      }
      catch (e) {
        return actions.appleConnectFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function appleConnectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.APPLE_CONNECT_SUCCESS)
    .map(action => action.payload)
    .map(arr => actions.whoAmI());

  return {
    ACTION: action$,
  }
}

export function googleConnect(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GOOGLE_CONNECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/google_connect',
        category: 'google',
        method: 'POST',
        send: payload,
      });
    });

  const response$ = sources.HTTP
    .select('google')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.ok == 'yes') {
          return actions.googleConnectSuccess(data);
        }
        else {
          return actions.googleConnectFail(data);
        }
      }
      catch (e) {
        return actions.googleConnectFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function googleConnectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GOOGLE_CONNECT_SUCCESS)
    .map(action => action.payload)
    .map(arr => actions.whoAmI());

  return {
    ACTION: action$,
  }
}

export function forgotPassword(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.FORGOT_PASSWORD)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/ask_reset_pass',
        category: 'reset',
        method: 'POST',
        send: payload,
      });
    });

  const response$ = sources.HTTP
    .select('reset')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0];
        if (data && data.ok === true) {
          return actions.forgotPasswordSuccess();
        }
        else {
          return actions.forgotPasswordFail(data);
        }
      }
      catch (e) {
        return actions.forgotPasswordFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function usernameValidation(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USERNAME_VALIDATION)
    .map(action => action.payload);

  const request$ = payload$
   .map(payload => ({
      url: Config.BASE_URL + 'username_check/isunique?username=' + payload.username,
      category: 'usernameValidation',
    }));

  const response$ = sources.HTTP
    .select('usernameValidation')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.allowed === true) {
          return actions.usernameValidationSuccess(arr);
        }
        else
          return actions.usernameValidationFail(arr);
      }catch(e){
        return actions.usernameValidationFail(arr);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function emailValidation(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.EMAIL_VALIDATION)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => ({
      url: Config.BASE_URL + 'username_check/isuniquemail?mail=' + payload.email,
      category: 'emailValidation',
    }));

  const response$ = sources.HTTP
    .select('emailValidation')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.allowed === true) {
          return actions.emailValidationSuccess(data);
        }
        else
          return actions.emailValidationFail(data);
      }catch(e){
        return actions.emailValidationFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function forgotPasswordFail(sources) {
  const response$ = sources.HTTP
    .select('reset')
    .map(response =>
      response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      return actions.forgotPasswordFail(arr)
    });
  return {
    ACTION: action$,
  };
}

