import React, { Component } from 'react';
import {
  Keyboard,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import * as Config from '../Config';
import Styles from '../constants/Styles';
import CollapsilbleText from "../components/CollapsilbleText";
import CommentsScreenComponent from "../components/CommentsScreenComponent";
import NodeActionButtons from './general/NodeActionButtons';
import Colors from '../constants/Colors';
import { Thumbnail } from "../components/Thumbnail";
import IndicatorPage from './general/IndicatorPage';
import TagItemInNode from './TagItemInNode';
import {isEmpty} from 'lodash';

class ArticleNode extends Component {

  state = {
    commentSent: false,
    firstRender: true,
    liked: this.props.node.liked,
    focusComment: this.props.navigation.state.params.focusInputField,
    scroll: {
      contentHeight: 0,
      frameHeight: 0,
      offset: 0,
      percent: 0,
      range: 0,
    }
  };

  componentDidMount () {
    const { node } = this.props;
    // #580 [performance] Do not refetch product suggestions if they are present.
    if (!node.products || !node.products.length) {
      this.props.getProductSuggestions({ nid: node.id });
    }
    if (!this.keyboardDidHideListener) {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this.keyboardDidHide,
      );
    }
    // trigger a second render
    setTimeout(() => {
      this.setState({firstRender: false });
    }, 10);
  }

  keyboardDidHide = () => {
    if(this.state.commentSent) {
      this.toggleCommentSent(false);
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.node !== nextProps.node
      || this.props.isLoadingComments !== nextProps.isLoadingComments
      || this.props.isDeletingCommentId !== nextProps.isDeletingCommentId
      || this.props.isEditingComment !== nextProps.isEditingComment
      || this.props.isSendingComment !== nextProps.isSendingComment
      || this.props.commentsById.length !== nextProps.commentsById.length
      || this.state.focusComment !== nextState.focusComment
      || this.props.usersMentioning !== nextProps.usersMentioning
      || ((!isEmpty(nextProps.upload)) && this.props.upload !== nextProps.upload)
      || this.state.firstRender !== nextState.firstRender
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener.remove();
    }
  }

  toggleCommentSent = (value) => {
    this.setState({ commentSent: value});
  };

  goToGeherzt = () => this.props.navigation.navigate({
    routeName: 'Geherzt',
    params: {
      id: this.props.node.id
    },
    key: `geherzt${this.props.node.id}`
  });

  onPressComment = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      this.setState({
        focusComment: this.props.node.id,
      });
    }
  };

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      const node = this.props.node;
      if (node.liked) {
        this.props.likeNode({node, op: 'unflag'});
        this.setState({liked: 0});
      }
      else {
        EventRegister.emit('analyticEvent', {
          category: 'Flag',
          action: 'Herzen',
          label: 'Article'
        });
        this.props.likeNode({node, op: 'flag'});
        this.setState({liked: 1});
      }
    }
  };

  // Calculates the percent a user has scrolled
  // and sends events to GA when certain thresholds are reached.
  calculateScrollPrecent = () => {
    const { contentHeight, frameHeight, offset } = this.state.scroll;
    if (contentHeight && frameHeight && offset) {
      const totalScrollDistance = contentHeight - frameHeight;
      const percent = Math.round(100 * offset / totalScrollDistance);
      // Assign a range like 10, 20, 30, 40, 50, 60, 70, 80, 90.
      // A range of 10 means that the value is between 10 and 20.
        const range = Math.floor(percent/10)*10;
      // If a new higher range has been reached, which  is above 50.
      if (range >= 50) {
        if (range > this.state.scroll.range) {
          this.setState({
            scroll: {
              ...this.state.scroll,
              range: range,
            }
          }, () => {
            EventRegister.emit('analyticEvent', {
              category: 'ArticleRead',
              action: this.props.node.label,
              label: range,
            });
          });
        }
      }
    }
  }

  // Is triggered when ScrollView finishes (re)rendering.
  // - provides the ScrollView's outter height
  onLayout = (event) => {
    const frameHeight = event.nativeEvent.layout.height;
    if (!this.state.frameHeight) {
      this.setState({
        scroll: {
          ...this.state.scroll,
          frameHeight: frameHeight,
        }
      });
    }
    this.calculateScrollPrecent();
  }

  // Is triggered when content size changes.
  // - provides the ScrollView's content inner height
  onContentSizeChange = (contentWidth, contentHeight) => {
     if (!this.state.contentHeight) {
      this.setState({
        scroll: {
          ...this.state.scroll,
          contentHeight: contentHeight,
        }
      });
    }
    this.calculateScrollPrecent();
  }

  // Triggers when user scrolls.
  // - provide the scroll position (aka offset).
  onScroll = (event) => {
     if (!this.state.offset) {
      this.setState({
        scroll: {
          ...this.state.scroll,
          offset: event.nativeEvent.contentOffset.y,
        }
      });
    }
    this.calculateScrollPrecent();
  }

  render() {
    // if we do not have a node bail out
    if (!this.props.node || this.state.firstRender) {
      return (
        <IndicatorPage />
      );
    }
    const {node, navigation} = this.props;

    let tags = node.tags && node.tags.length ? node.tags : [];
    //TODO: remove this from render
    // build products array
    let products = [];
    if (node.products && node.products.length) {
      node.products.map((product_nid, index) => {
        if (this.props.products && this.props.nodes[product_nid] && this.props.nodes[product_nid].id) {
          products.push(this.props.nodes[product_nid]);
        }
      });
    }

    const picture_uri = node.userPictureUri ? node.userPictureUri : Config.DEFAULT_AVATAR;
    const file_url_prefix = 'src="' + Config.BASE_URL + 'sites/' + Config.SUBDOMAIN + '.' + Config.DOMAIN;
    const body = node.body ? node.body.replace(/(src="\/sites\/.*?solebich.?de)+/g, file_url_prefix) : '';
    return (
      <ScrollView
        keyboardShouldPersistTaps={"handled"}
        style={{backgroundColor: Colors.background, flex:1}}
        onLayout={this.onLayout}
        onContentSizeChange={this.onContentSizeChange}
        onScroll={this.onScroll}
        scrollEventThrottle={160}
      >
        <View style={{flex: 1, alignItems: 'center', margin: Config.STANDARD_MARGIN}}>
          <Text style={Styles.pageTitle}>{node.label}</Text>
          <Text style={{marginBottom:Config.STANDARD_MARGIN, marginTop: Config.STANDARD_MARGIN}}> von {node.userLabel}</Text>
          <View style={Styles.narrowCol}>
            <CollapsilbleText page='ArticleInline' text={body} collapse={false} align={'left'} navigation={navigation} />
          </View>
          <View style={Styles.narrowCol}>
            <View style={{marginTop:Config.STANDARD_MARGIN_BIG, marginBottom: Config.STANDARD_MARGIN_BIG}}>
              <NodeActionButtons
                navigateable={true}
                navigation={navigation}
                onPress={this.goToGeherzt}
                onPressLike={this.onPressLike}
                item={node}
                onPressComment={this.onPressComment}
                commentable={true}
              />

            </View>
            <View
              style={{justifyContent: 'center', alignItems: 'center', flex: 1, marginBottom: Config.STANDARD_MARGIN}}
            >
              <TouchableOpacity onPress={() => {
                navigation.navigate({routeName: 'User', key: `${node.userId}`, params:  { user: node.user, id: node.userId }});
              }}>
                <Thumbnail
                  source={{uri: picture_uri}}
                />
              </TouchableOpacity>
              <Text style={Styles.username}>{node.userLabel}</Text>
            </View>
            <View style={Styles.centerHorizontalItems}>
              {tags.map((t, l) => (
                <TagItemInNode
                  key={l}
                  tags={node.tags}
                  t={t}
                  l={l}
                  navigation={this.props.navigation}
                />
              ))}
            </View>
            <View style={Styles.centerVerticalItems}>
              <Text>{node.time}</Text>
            </View>
          </View>
        </View>
        <CommentsScreenComponent
          toggleCommentSent={this.toggleCommentSent}
          focusComment={this.state.focusComment}
          isLoading={this.props.isLoadingComments}
          isDeletingCommentId={this.props.isDeletingCommentId}
          isEditingComment={this.props.isEditingComment}
          isSendingComment={this.props.isSendingComment}
          logInState={this.props.logInState}
          comments={this.props.comments}
          commentsById={this.props.commentsById}
          commentSet={this.props.commentSet}
          commentsGet={this.props.commentsGet}
          entityDel={this.props.entityDel}
          navigation={this.props.navigation}
          node={node}
          onRefresh={this.onRefresh}
          onEndReached={this.onEndReached}
          usersMentioning={this.props.usersMentioning}
          usersGetMentioning={this.props.usersGetMentioning}
          upload={this.props.upload}
          fileUpload={this.props.fileUpload}
          clearUploadedImage={this.props.clearUploadedImage}
          commentDelete={this.props.commentDelete}
          likeComment={this.props.likeComment}
        />
      </ScrollView>
    );
  }
}

export default ArticleNode;
