import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  inboxGet,
  postPM,
} from "../actions/index";
import UserDiscussionScreenComponent from "../components/UserDiscussionScreenComponent";
import { AppState } from 'react-native';

class UserDiscussionScreen extends Component {
  state = {
    appState: 'active',
  };

  resetAndRefresh = () => {
    this.props.inboxGet({ uid: this.props.navigation.state.params.user.id });
  };

  componentDidMount() {
    this.resetAndRefresh();
    AppState.addEventListener('change', this.handleAppStateChange);
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.handleAppStateChange);
  }

  handleAppStateChange = (nextAppState) => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      this.resetAndRefresh();
    }
    this.setState({appState: nextAppState});
  };

  render() {
    return(
      <UserDiscussionScreenComponent
        logInState={this.props.logInState}
        inbox={this.props.inbox}
        navigation={this.props.navigation}
        postPM={this.props.postPM}
        recipient_id={this.props.navigation.state.params.user.id}
      />
    )
  }
}

const mapStateToProps = (state) => {
    return {
    logInState: state.user,
    inbox: state.inbox,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    inboxGet: (payload) => {
      dispatch(inboxGet(payload))
    },
    postPM: (payload) => {
      dispatch(postPM(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDiscussionScreen);
