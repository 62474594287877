import * as ActionTypes from './actionTypes';

export function commerceProductGet(payload) {
  return {
    type: ActionTypes.COMMERCE_PRODUCT_GET,
    payload
  }
}

export function commerceProductGetSuccess(payload) {
  return {
    type: ActionTypes.COMMERCE_PRODUCT_GET_SUCCESS,
    payload
  }
}

export function commerceProductGetFail(payload) {
  return {
    type: ActionTypes.COMMERCE_PRODUCT_GET_FAIL,
    payload
  }
}
