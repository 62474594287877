import React, {Component} from 'react';
import {FlatList, Text, View} from "react-native";
import Colors from "../../constants/Colors";
import LineItem from "./LineItem";

class LineItems extends Component {

  renderItem = ({ item, index }) => (
    <LineItem alterQuantity={this.props.alterQuantity}
              cartGet={this.props.cartGet}
              item={item}
              index={index}
              isCart={this.props.isCart}
              isLoadingQuantity={this.props.isLoadingQuantity}
              navigation={this.props.navigation}
              removeFromCart={this.props.removeFromCart}
              orderNumber={this.props.cart.order_number}
    />
  )

  render() {
    return (
      <FlatList
        backgroundColor={Colors.background}
        contentContainerStyle={{margin: 4}}
        data={this.props.cart.items}
        renderItem={this.renderItem}
        keyExtractor={item => item.lineItemId}
        ListFooterComponent={this.props.renderFooter}
        ListHeaderComponent={this.props.renderHeader}
        style={{paddingHorizontal: 10, marginBottom: this.props.isCart ? 60 : 0}}
      />
    );
  }
}

export default LineItems;
