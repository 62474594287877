export const sortValues = [
  {
    label: 'Beliebt',
    machine_name: 'best',
    url_parameter: '-top,-sli,-best',
    weight: 1,
  },
  {
    label: 'Preis aufsteigend',
    machine_name: 'price_asc',
    url_parameter: 'price',
    weight: 2,
  },
  {
    label: 'Preis absteigend',
    machine_name: 'price_desc',
    url_parameter: '-price',
    weight: 3,
  },
  {
    label: 'Reduziert',
    machine_name: 'discount',
    url_parameter: '-discount',
    weight: 4,
  },
];

export const sortsKeyed = sortValues.reduce((object, sort) => {
  object[sort.machine_name] = sort;
  return object;
}, {});
