import React from 'react';
import { StyleSheet, Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Config from '../Config';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

const tabletLayout = Layout.window.width/2-2*Config.STANDARD_MARGIN;
const mobileLayout = Layout.window.width-2*Config.STANDARD_MARGIN;

export default StyleSheet.create({
  borderBox: {
    flex: 1,
    margin: 2,
    padding: 5,
    paddingBottom: Config.STANDARD_MARGIN,
    borderWidth: 1,
    borderColor: Colors.border,
  },
  pageTitle: {
    fontFamily: 'MerriweatherBold',
    fontSize: Config.FONT_SIZE_BIG,
    lineHeight: 1.3 * Config.FONT_SIZE_BIG,
    marginTop: Config.STANDARD_MARGIN,
    marginBottom: Config.STANDARD_MARGIN,
    textAlign: 'center',
  },
  pageTitleH2: {
    fontFamily: 'OpenSansBold',
    fontSize: 18,
    lineHeight: 18 * 1.3,
    marginTop: Config.STANDARD_MARGIN,
    textAlign: 'center',
    alignSelf: 'center',
  },
  pageTitleH3456: {
    fontFamily: 'OpenSansBold',
    marginTop: Config.STANDARD_MARGIN,
    marginBottom: Config.STANDARD_MARGIN,
    textAlign: 'center',
    alignSelf: 'center',
  },
  normalText: {
    fontFamily: 'OpenSansRegular' , //normal text should not be bold
    fontSize: Config.FONT_SIZE_NORMAL,
    lineHeight: Config.FONT_SIZE_NORMAL * 1.42857,
  },
  username: {
    fontSize: Config.FONT_SIZE_NORMAL,
    fontFamily: 'OpenSansBold',
    lineHeight: 21,
    marginBottom: Config.STANDARD_MARGIN_SMALL,
  },
  price: {
    fontSize: Config.FONT_SIZE_NORMAL,
    lineHeight: 21,
    marginBottom: Config.STANDARD_MARGIN_SMALL,
  },
  gridTitle: {
    fontFamily: 'MerriweatherBold',
    fontSize: Config.FONT_SIZE_MEDIUM,
    lineHeight: 1.3*Config.FONT_SIZE_MEDIUM,
    textAlign: 'center',
  },
  gridTitleProduct: {
    fontFamily: 'MerriweatherBold',
    fontSize: Config.FONT_SIZE_MEDIUM,
    lineHeight: 1.3*Config.FONT_SIZE_MEDIUM,
    textAlign: 'left',
  },
  gridUsername: {
    fontFamily: 'OpenSansBold',
    fontSize: Config.FONT_SIZE_NORMAL,
    marginTop: Config.STANDARD_MARGIN,
  },
  subtitle: {
    fontSize: Config.FONT_SIZE_NORMAL,
  },
  textCenter: {
    textAlign: 'center',
  },
  centerItems: {
    flex: 1,
    marginTop: Config.STANDARD_MARGIN,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iconContainer: {
    flexDirection: 'row',
    minWidth: 50,
    // maxWidth: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconCounter: {
    fontFamily: 'OpenSansLight',
    fontSize: Config.FONT_SIZE_BIG,
    alignSelf: 'flex-end',
    paddingLeft: 5,
    paddingRight: 5,
  },
  iconsContainer: {
    flexDirection: 'row',
    marginTop: Config.STANDARD_MARGIN,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridImage: {
    // borderRadius: 20,
  },
  buttonTopLeftContainer: {
    paddingLeft: 10,
    position: 'absolute',
    paddingTop: 10,
    flexDirection: 'row',
    minWidth: 80,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerTopRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 5,
  },
  headerTopRightButton: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  messageContainer: {
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
  },
  inputLongText: {
    fontSize: Config.FONT_SIZE_NORMAL,
    maxHeight: 120,
    minHeight: 70,
    // height: 50,
    paddingLeft: 15,
    borderColor: Colors.border,
    borderWidth: 1,
    zIndex: 2,
    paddingTop: 15,
    paddingBottom: 15,
    // textAlignVertical: 'top',
  },
  inputOneLineText: {
    fontSize: Config.FONT_SIZE_NORMAL,
    fontWeight: 'normal',
    height: 50,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    borderColor: Colors.border,
    borderWidth: 1,
  },
  helpText: {
    fontSize: Config.FONT_SIZE_NORMAL,
    marginLeft: 2,
    color: Colors.helpText,
    marginTop: 5,
    fontFamily: 'OpenSansRegular'
  },
  inputItemOneLineText: {
    borderBottomWidth: 0,
    paddingVertical: 0,
    margin: 0,
    marginTop: 10,
  },
  simpleTextAreaItem:{
    maxHeight: 120,
    minHeight: 50,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  greyButtonContainer: {
    alignItems: 'center',
    borderColor: Colors.border,
    borderWidth: 1,
    flex: 0,
    margin: 10,
    marginRight: 0,
    padding: 10,
  },
  greyButtonContainerActive: {
    alignItems: 'center',
    borderColor: Colors.tintColor,
    borderWidth: 1,
    flex: 0,
    margin: 10,
    marginRight: 0,
    padding: 10,
  },
  pinkButtonContainer: {
    alignItems: 'center',
    backgroundColor: Colors.tintColorFill,
    flex: 0,
    margin: 10,
    padding: 10,
  },
  pinkButtonText: {
    color: 'black',
  },
  simpleLink: {
    paddingHorizontal: 5,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'black',
  },
  boldLink: {
    textAlign: 'center',
    fontFamily: 'OpenSansBold',
  },
  centerHorizontalItems: {
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap:'wrap',
  },
  centerVerticalItems: {
    alignItems: 'center',
  },
  narrowCol: {
    alignSelf: 'center',
    width: Layout.isSmallDevice ? (Layout.window.width - 20) : Layout.half.width,
  },
  IconBadgeStyle: {
    backgroundColor: Colors.tintColorFill,
    height:18,
    marginTop: -5,
    marginRight: -5,
    width:18,
  },
  productSuggestion: {
    borderWidth: 1,
    borderColor: Colors.borderProduct,
    margin: 2.5,
    height: Layout.productWidth - 2.5,
    width: Layout.productWidth - 2.5,
  },
  arrow: {
    height: 60,
    margin: 5,
  },
  arrowContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    width: 64,
  },
  pageScrollViewContainer: {
    width: Layout.half.width,
    maxWidth: Layout.half.width,
    minWidth: Layout.half.width,
    paddingHorizontal: 10,
    backgroundColor: Colors.background,
  },
  pageViewContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.background,
  },
  userIconBox: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  suggestionsRowContainer: {
    flexDirection: 'row',
    height: 40
  },
  mentionsUserDetailsBox: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 10,
    paddingRight: 15,
    minHeight: 40
  },
  mentionsUserNameText : {
    fontSize: 12,
  },
  buttonBottomCenterContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 5,
    borderRadius: 50,
    backgroundColor: Colors.transparentBackground,
  },
  regularButton: {
    backgroundColor: Colors.background,
    borderWidth: 1,
    borderColor: Colors.tintColor,
    alignSelf: 'center',
    padding: 0,
    borderRadius: 0,
    elevation: 0,
  },
  regularButtonGreyBorder: {
    backgroundColor: Colors.background,
    borderWidth: 1,
    borderColor: Colors.iconLightGrey,
    alignSelf: 'center',
    marginTop: 20,
    height: 50,
    padding: 0,
    borderRadius: 0,
    elevation: 0,
  },
  regularButtonTitle: {
    color: Colors.darkText,
    fontFamily: 'OpenSansBold',
    fontSize: Config.FONT_SIZE_NORMAL,
  },
  fbButton: {
    backgroundColor: Colors.facebook,
    borderWidth: 1,
    borderColor: Colors.facebook,
    alignSelf: 'center',
    padding: 0,
    borderRadius: 0,
    elevation: 0,
  },
  userDetailsBox: {
    // flex: 1,
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 15,
    minHeight: 40
  },
  displayNameText: {
    fontSize: 13,
    fontWeight: '500'
  },
  usernameText: {
    fontSize: 12,
  },
  autocompleteContainer: {
    flex: 1,
    left: 0,
    right: 0,
    marginTop: 0,
    marginBottom: -10,
    zIndex: 1,
    minWidth: Layout.half.width-30,
    alignItems: 'center'
  },
  padInputWidth: {
    width: Config.IS_TABLET ? tabletLayout : 'auto',
    alignSelf: Config.IS_TABLET ? 'center' : 'auto'
  },
  button: {
    width: Config.IS_TABLET ? tabletLayout : mobileLayout,
    height: 50,
    marginTop: 20,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 16 / 2,
    backgroundColor: "#4CB050",
    marginRight: 5
  },
  info: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#1068bf',
    fontSize: 12,
    marginRight: 5,
    color: '#1068bf',
    fontWeight: 'bold',
    width: 16,
    height: 16,
    textAlign: 'center'
  },
  itemContainer: {
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
    paddingVertical: 10
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 2
  },
  shopFooterText: {
    color: '#818181',
    paddingBottom: 5
  },
  shopFooterTitle: {
    fontWeight: 'bold',
    paddingTop: 15,
    paddingBottom: 10
  },
  shopFooterLine: {
    display: 'flex',
    flexDirection: 'row'
  },
  modalContentLink: {
    paddingBottom: 5,
    color: '#1068bf'
  },
  centerView: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  bottomPositioning: {
    flex: 1,
    marginBottom: 10,
    justifyContent: 'flex-end',
  },
  checkoutBlocks: {
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    display: 'flex',
    paddingBottom: 15,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  checkoutBlocksBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#d1d1d1',
    paddingBottom: 15,
  },
  link: {
    color: '#818181',
    textDecorationLine: 'underline'
  },
  terms: {
    color: '#818181'
  },
  couponItem: {
    borderColor: '#d1d1d1',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10
  },
  radioButtonLabel: {
    fontSize: 15,
    color: '#000000',
    flex: 0.95
  },
  textNextToIcon: {
    flex: 0.93
  },
  iconNextToText: {
    flex: 0.06,
    alignItems: 'flex-end'
  },
  shopButtonWrap: {
    borderTopColor: Colors.border,
    borderTopWidth: 1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#ffffff'
  },
  shopButton: {
    marginTop: 10,
    backgroundColor: Colors.tintColor,
    color: Colors.darkText
  },
  imageBox: {
    flexGrow:1,
    height: 80,
    width: Layout.width,
    alignItems: 'center',
    justifyContent:'center',
  },
  textBox: {
    backgroundColor: Colors.transparentBackgroundDark,
    color: '#ffffff',
    fontFamily: 'MerriweatherBold',
    fontSize: Config.FONT_SIZE_BIG,
    padding: 5,
    textAlign: 'center',
  },
  indicatorContainer: {
    position: 'absolute',
    alignSelf: 'center',
    top: 40,
  },
  indicatorText: {
    color: Colors.darkText,
    fontSize: Config.FONT_SIZE_NORMAL,
  },
  modalClose: {
    position: 'absolute',
    top: 35,
    right: 10,
    zIndex: 1
  },
  adBlockClose: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 44,
    backgroundColor: Colors.transparentBackground
  },
  colorBox: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    alignSelf: 'center',
    margin: 10,
    borderWidth: 1,
    borderColor: '#b2b0b0'
  },
  indicatorPageContainer: {
    alignItems: 'center',
    backgroundColor: Colors.background,
    flex: 1,
    justifyContent: 'center',
    margin: 10,
    width: Layout.containedWidth
  },
  restartText: {
    fontFamily: 'OpenSansRegular',
    fontSize: Config.FONT_SIZE_NORMAL,
    textAlign: 'center',
  },
  restartButton: {
    borderColor: '#000',
    width: 100,
  },
  googleButton: {
    backgroundColor: Colors.google,
    borderWidth: 1,
    borderColor: Colors.google,
    alignSelf: 'center',
    padding: 0,
    borderRadius: 0,
    elevation: 0,
  },
  googleButtonText: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: Config.FONT_SIZE_NORMAL
  },
  googleButtonLogo: {
    backgroundColor: '#ffffff',
    resizeMode: 'contain'
  },
  googleLogoWrapper: {
    backgroundColor: '#ffffff',
    padding: 13,
    marginRight: 5,
  },
  googleLogo: {
    height: 20,
    width: 20,
    resizeMode: "stretch"
  },
  flatListBottomWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    alignItems: 'center'
  }
});
