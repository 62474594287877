import React from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform
} from 'react-native';
import { connect } from 'react-redux';
import { Button } from 'react-native-elements';
import * as Config from '../Config';
import { userRegister } from '../actions/index';
import Styles from "../constants/Styles";
import CustomButton from '../components/general/CustomButton';
import {Icon} from "../components/Icon";
import * as AppleAuthentication from 'expo-apple-authentication';
import {GoogleButton} from "../components/general/GoogleButton";
import ContactText from "../components/general/ContactText";

class RegisterScreen extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( (this.props.logInState.switchRegisterLogin !== prevProps.logInState.switchRegisterLogin) && this.props.logInState.switchRegisterLogin) {
      this.setState({register: !this.props.logInState.switchRegisterLogin});
      Alert.alert(
        '',
        'Danke für deine Registrierung. Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos zugeschickt. Bitte klicke auf den Link in der E-Mail, um die Registrierung abzuschließen und ein Passwort festzulegen. Öffne danach die App erneut, um dich einzuloggen.',
        [
          {
            text: 'Okay',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
  }

  render() {
    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}>
          <Text style={{marginTop: 20}}>Melde dich jetzt kostenlos an, sammle die schönsten Einrichtungsideen und inspiriere andere!</Text>
          <CustomButton
            title="Mit E-Mail registrieren"
            onPress={() => this.props.navigation.navigate('RegisterEmail')}
          />
          <Button
            icon={<Icon name='small_fb' color='white' size={Config.FONT_SIZE_NORMAL}/>}
            title="Mit Facebook registrieren"
            onPress={() => this.props.navigation.navigate('RegisterFacebook')}
            buttonStyle={[Styles.fbButton, Styles.button]}
            titleStyle={{ fontWeight: 'bold' }}
            fontWeight='bold'
          />
          { Platform.OS === "ios" ? <AppleAuthentication.AppleAuthenticationButton
            buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP}
            buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
            style={Styles.button}
            cornerRadius={0}
            onPress={() => this.props.navigation.navigate('RegisterApple')}
          /> : null }
          {/*Hide GoogleButton until the OAuth app is published an working in standalone apps*/}
          {/*<GoogleButton*/}
          {/*  title={'Mit Google registrieren'}*/}
          {/*  onPress={() => this.props.navigation.navigate('RegisterGoogle')}*/}
          {/*/>*/}
          <View style={{marginTop: 20, display: 'flex', flexDirection: 'row', alignSelf: 'center'}}>
            <Text>Ich bin bereits Mitglied. </Text>
            <TouchableOpacity onPress={() => this.props.navigation.navigate('Login')}>
              <Text style={{fontWeight: 'bold'}}>Jetzt anmelden.</Text>
            </TouchableOpacity>
          </View>
          <ContactText navigation={this.props.navigation}/>
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    userRegister: (payload) => {
      dispatch(userRegister(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
