import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';

const initialState = {
  // number of unread notifications
  badgeCount: 0,
};

function badgeSet(state, action) {
  return {
    ...state,
    badgeCount: action.payload,
  };
}

function badgeReset(state) {
  return {
    ...state,
    badgeCount: 0,
  };
}

const badges = createReducer(initialState, {
  [ActionTypes.BADGE_SET]: badgeSet,
  [ActionTypes.BADGE_RESET]: badgeReset,
});

export default badges;

