import * as ActionTypes from './actionTypes';

export function shopGet(payload){
  return {
    type: ActionTypes.SHOP_GET,
    payload
  }
}

export function shopGetSuccess(payload){
  return {
    type: ActionTypes.SHOP_GET_SUCCESS,
    payload
  }
}

export function shopGetFail(payload){
  return {
    type: ActionTypes.SHOP_GET_FAIL,
    payload
  }
}
