import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';

import flattenSequentially from "xstream/extra/flattenSequentially";
import sampleCombine from 'xstream/extra/sampleCombine';

export function productSearchGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PRODUCT_SEARCH_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      // Add page param if not the first one.
      const { page, path, sort, tid } = payload;
      const pageParam = (page > 0) ? '&page=' + page : '';
      return ({
        category: 'product_search',
        page: page,
        path: path,
        sort: sort,
        tid: tid,
        url: Config.BASE_URL + payload.path + pageParam,
      });
    });

  const response$ = sources.HTTP
    .select('product_search')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        const data = arr[0].body;
        if (data) {
          data.page = arr[0].request.page;
          data.path = arr[0].request.path;
          data.sort = arr[0].request.sort;
          return actions.productSearchGetSuccess(data);
        }
        else {
          return actions.productSearchGetFail(data);
        }
      }
      catch (e) {
        return actions.productSearchGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function productSearchGetSuccess(sources) {
  const nodes$ = sources.STATE.map(state => state.images.nodes);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PRODUCT_SEARCH_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      const missing = arr.data[0]
        .filter(({ id }) => !nodes[id])
        .map(({ id }) => parseInt(id));

      if (missing.length) {
        return actions.nodesGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}
export function productSearchGetFacets(sources) {
  const tagsdb$ = sources.STATE.map(state => state.tags.tagsdb);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PRODUCT_SEARCH_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(tagsdb$))
    .map(([arr, tagsdb]) => {
      const missing = (arr && arr.facets) ? Object.keys(arr.facets).reduce((array, facetId) => {
        // For each facet check if we have the corresponding term.
        array = [
          ...array,
          ...arr.facets[facetId]
            .map(({ filter }) => filter.replace(/\"/g, ''))
            .filter(( id ) => !tagsdb[id]),
        ];

        return array;
      }, []) : [];

      if (missing.length) {
        return actions.termsGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}
