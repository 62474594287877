import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import screenNames from '../constants/screenNames';
import processNodes from './helpers/processNodes';
import flattenNode from './helpers/flattenNode';
import processProductSuggestions from "./helpers/processProductSuggestions";

// TODO: Check if it's needed to sort nodes by recently liked first
const initialState = {
  blockedNodes: {},
  // main data stores for entities
  nodes: {},
  nodesById: [],
  // store for files during upload
  upload: {
    1: {},
    user: {
      image_id: null,
    },
    uploading: false,
  },
  // TODO: refacor isDoingSomething control variables in a way that
  // - they will be unique
  // - they will be keyed under a common group/parent
  isLoading: false,
  isDeletingEntity: false,
  isUpdatingEntity: false,
  isLoadingSearch: false,
  // helper store during posting and editing of comments/users/nodes
  newUploadedImage: {},
  updatingNode: false,
  [screenNames.neu + 'ById']: [],
  [screenNames.folgeIch + 'ById']: [],
  [screenNames.neuKommentiert + 'ById']: [],
  [screenNames.beliebtLetzteWoche + 'ById']: [],
  [screenNames.dieBeliebtestenSolebichBilder + 'ById']: [],
  [screenNames.taxonomy + 'ById']: [],
  [screenNames.recent + 'ById']: [],
  [screenNames.wohnmagazin + 'ById']: [],
  isLikingNode: false,
  isGettingArticle: false,
};

function likeNode(state, action) {
  const { node, op } = action.payload;
  const nodeLikes = parseInt(node.likes);

  return {
    ...state,
    nodes: {
      ...state.nodes,
      [node.id]: {
        ...state.nodes[node.id],
        likes: op === 'flag' ? nodeLikes + 1 : nodeLikes - 1,
        liked: op === 'flag'
      }
    },
    isLikingNode: true,
  };
}

function likeNodeSuccess(state) {
  return {
    ...state,
    isLikingNode: false
  };
}

function likeNodeFail(state) {
  return {
    ...state,
    isLikingNode: false
  };
}

function blockNode(state, action) {
  const id = action.payload;
  return {
    ...state,
    nodes: {
      ...state.nodes,
      [id]: {
        ...state.nodes[id],
        blocked: true,
      }
    },
    isBlockingNode: true,
    blockedNodes: {
      ...state.blockedNodes,
      [id]: id,
    },
  };
}

function blockNodeSuccess(state) {
  return {
    ...state,
    isBlockingNode: false,
  };
}

function blockNodeFail(state) {
  return {
    ...state,
    isBlockingNode: false,
  };
}

// When a user is blocked discover all her content and block it too.
function blockUser(state, action) {
  const userNodes = Object.keys(state.nodes)
    .filter(id => state.nodes[id].userId == action.payload)
    .reduce((userNodes, id, index) => {
      userNodes[id] = {
        ...state.nodes[id],
        blocked: true,
      }
      return userNodes;
    }, {});
  return {
    ...state,
    nodes: {
      ...state.nodes,
      ...userNodes,
    },
    blockedUsers: {
      ...state.blockedUsers,
      [action.payload]: action.payload,
    }
  }
}

function blockUserSuccess(state, action) {
  return state;
}

function blockUserFail(state) {
  return state;
}

function nodesGet(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function nodesGetSuccess(state, action) {
  const processedNodes = processNodes(state.nodes, state.nodesById, action.payload, state.blockedNodes, state.blockedUsers);

  return {
    ...state,
    isLoading: false,
    nodes: {
      ...state.nodes,
      ...processedNodes.newNodes,
    },
    nodesById: processedNodes.nodesById,
  };
}
function getProductSuggestionsSuccess(state, action) {
  const processedNodes = processProductSuggestions(state.nodes, state.nodesById, action.payload.data, true);

  return {
    ...state,
    isLoading: false,
    nodes: {
      ...state.nodes,
      ...processedNodes.newNodes,
      [action.payload.nid]: {
        ...state.nodes[action.payload.nid],
        productsLastFetch: new Date().getDate(),
        products: action.payload.data.map(pr => pr.id),
      }
    },
    nodesById: processedNodes.nodesById,
  };
}

function nodesGetFail(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function commentSetSuccess(state, action) {
  const comment_set_nid = parseInt(action.payload[0].nid);
  return {
    ...state,
    upload: {
      ...state.upload,
      [comment_set_nid]: undefined,
      image_id: null,
      image_uri: null,
      uploading: false,
    },
    newUploadedImage: {},
    // increment comments counter on node
    nodes: {
      ...state.nodes,
      [comment_set_nid]: {
        ...state.nodes[comment_set_nid],
      },
    },
  };
}

function entityUpdate(state, action) {
  if (action.payload.self.includes('comments')) {
    return state;
  }
  return {
    ...state,
    isUpdatingEntity: true,
  };
}

function entityUpdateSuccess(state, action) {
  if (action.payload[0].self.includes('comments')) {
    return {
      ...state,
      upload: {
        ...state.upload,
        [action.payload[0].nid]: undefined,
      },
    };
  }
  return {
    ...state,
    isUpdatingEntity: false,
  };
}

function entityUpdateFail(state, action) {
  return {
    ...state,
    isUpdatingEntity: false,
    upload: {
      ...state.upload,
      [action.payload[0].nids]: undefined,
    },
  };
}

function entityDel(state, action) {
  if (action.payload.self.includes('comments')) return state;
  return {
    ...state,
    isDeletingEntity: true,
  };
}

function entityDelSuccess(state, action) {
  if (action.payload.request.url.includes('comments')) {
    return state;
  } else if (action.payload.request.url.includes('image')) {
    let nodeId = parseInt(action.payload.request.send.id);
    let { [nodeId]: omit, ...newNodes } = state.nodes;
    const newItemsById = state[screenNames.neu + 'ById'];
    return {
      ...state,
      isDeletingEntity: false,
      nodes: newNodes,
      [screenNames.neu + 'ById']: newItemsById.filter(theNodeId => String(theNodeId) !== String(nodeId)),
    };
  }
  return {
    ...state,
    isDeletingEntity: false,
  };
}

function entityDelFail(state) {
  return {
    ...state,
    isDeletingEntity: false,
  };
}

function fileUpload(state, action) {
  return {
    ...state,
    upload: {
      ...state.upload,
      [action.payload.id || 1]: {
        image_uri: action.payload.uri,
        uploading: true,
        retryUpload: action.payload.retryUpload
      },
      uploading: true,
    },
  };
}

function fileUploadSuccess(state, action) {
  return {
    ...state,
    upload: {
      ...state.upload,
      [action.payload.id || 1]: {
        ...state.upload[action.payload.id || 1],
        image_id: action.payload.data[0][0].id,
        uploading: false,
        retryUpload: action.payload.counter
      },
      uploading: false,
    },
  };
}

function fileUploadFail(state, action) {
  return {
    ...state,
    upload: {
      ...state.upload,
      [action.payload.id || 1]: {
        image_id: null,
        image_uri: null,
        uploading: false,
        showFailMessage: true
      },
      uploading: false,
    },
  };
}

function clearUploadedImage(state, action) {
  return {
    ...state,
    upload: {
      ...state.upload,
      [action.payload && action.payload.id || 1]: {
        image_id: null,
        image_uri: null,
        uploading: false,
      },
    },
    newUploadedImage: {},
  };
}

function editUserSuccess(state) {
  return {
    ...state,
    upload: {
      ...state.upload,
      user: {
        image_id: null,
        uploading: false,
      },
    },
  };
}

function nodeUpload(state, action) {
  return {
    ...state,
    upload: {
      ...state.upload,
      1: {
        uploading: true,
        retryNodeSave: action.payload.retryNodeSave
      },
    },
  };
}

function nodeUploadSuccess(state, action) {
  const newNodeId = Number(action.payload.data[0].id);
  const neuById = state[screenNames.neu + 'ById'].slice();
  const nodesById = state.nodesById.slice();
  neuById.unshift(newNodeId);
  nodesById.push(newNodeId);

  return {
    ...state,
    // save new node in store
    nodes: {
      ...state.nodes,
      [newNodeId]: flattenNode(action.payload.data[0], state.nodes[newNodeId]),
    },
    upload: {
      ...state.upload,
      1: {
        uploading: false,
        image_id: null,
        retryNodeSave: action.payload.counter,
        showFailMessage: false
      },
    },
    newUploadedImage: action.payload.data[0],
    [screenNames.neu + 'ById']: neuById,
  };
}

function nodeUploadFail(state) {
  return {
    ...state,
    upload: {
      ...state.upload,
      1: {
        uploading: false,
        showFailMessage: true
      },
    },
  };
}

function viewGet(state, action) {
  return {
    ...state,
    isLoading: true,
    [action.payload.path + "ById"]: action.payload.refreshing ? [] : state[action.payload.path + "ById"],
  };
}

function viewGetSuccess(state, action) {
  const processedNodes = processNodes(state.nodes, state.nodesById, action.payload.data, state.blockedNodes, state.blockedUsers);
  let screenById = state[action.payload.path + "ById"] ?
    state[action.payload.path + "ById"].slice() : [];
  action.payload.data.forEach(node => {
    if (!screenById.includes(node.id)) {
      screenById.push(node.id);
    }
  });
   
  return {
    ...state,
    isLoading: false,
    nodes: {
      ...state.nodes,
      ...processedNodes.newNodes,
    },
    nodesById: processedNodes.nodesById,
    [action.payload.path + "ById"]: screenById,
  };
}

function viewGetFail(state) {
  return {
    ...state,
    isLoading: false,
    isLoadingSearch: false,
  };
}

function logout() {
  return {
    ...initialState
  };
}

function nodeEdit(state) {
  return {
    ...state,
    updatingNode: true,
  };
}

function nodeEditSuccess(state, action) {
  return {
    ...state,
    updatingNode: false,
    nodes: {
      ...state.nodes,
      [action.payload.data[0].id]: flattenNode(action.payload.data[0], state.nodes[action.payload.data[0].id]),
    },
  };
}

function nodeEditFail(state) {
  return {
    ...state,
    updatingNode: false,
  };
}

/**
 * If an error was received cancel any interactive action that could be in progress.
 * Otherwise the user might find herself with an endless spinner.
 */
function errorReceived(state) {
  return {
    ...state,
    upload: {
      ...state.upload,
      uploading: false,
    },
    isLoading: false,
    isDeletingEntity: false,
    isUpdatingEntity: false,
    isLoadingSearch: false,
    updatingNode: false,
    isLikingNode: false,
    isGettingArticle: false,
  };
}

function handleReceivedNotification(state, action) {
  return {
    ...state,
    nodes: {
      ...state.nodes,
      [action.payload.nodeId]: {
        ...state.nodes[action.payload.nodeId],
        [action.payload.type]: (state.nodes[action.payload.nodeId] && state.nodes[action.payload.nodeId][action.payload.type]) ?
          Number(state.nodes[action.payload.nodeId][action.payload.type]) + 1 : 1,
      }
    }
  };
}

function commentsGet(state, action) {
  const nodeId = action.payload.nid;
  return {
    ...state,
    nodes: {
      ...state.nodes,
      [nodeId]: {
        ...state.nodes[nodeId],
        gettingComments: true,
      }
    }}
}

function commentsGetSuccess(state, action) {
    const nodeId = parseInt(action.payload.data[0].nid);
    let commentsIds =
      state.nodes[nodeId]
      && state.nodes[nodeId].commentsIds
      && state.nodes[nodeId].commentsIds.length ?
        state.nodes[nodeId].commentsIds.slice() : [];

    action.payload.data
      .forEach(comment => {
        // If comment ID is not in array add it.
        if (!commentsIds.includes(comment.id)) {
          // If comment does not have a parent push it to the end.
          commentsIds.push(comment.id);
        }
      });

    return {
      ...state,
      nodes: {
        ...state.nodes,
        [nodeId]: {
          ...state.nodes[nodeId],
          commentsIds: commentsIds,
          gettingComments: false,
      }
    }}
}

function commentsGetFail(state, action) {
  try {
    if (!action.payload.body.data.length) {
      const nodeId = action.payload.request.nodeId;
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [nodeId]: {
            ...state.nodes[nodeId],
            gettingComments: false,
          }
        }}
    }
  }
 catch (e) {
   return state;
 }
}

function deleteCommentFromNode(state, action) {
  const commentId = parseInt(action.payload[0].request.send.id);
  const nodeId = parseInt(action.payload[0].request.send.nid);

  const nodeComments = state.nodes[nodeId].commentsIds
    .filter(stateCommentId => String(stateCommentId) !== String(commentId));

  return {
    ...state,
    nodes: {
      ...state.nodes,
      [nodeId]: {
        ...state.nodes[nodeId],
        commentsIds: nodeComments
      },
    }
  }
}

const images = createReducer(initialState, {
  [ActionTypes.LIKE_NODE]: likeNode,
  [ActionTypes.LIKE_NODE_SUCCESS]: likeNodeSuccess,
  [ActionTypes.LIKE_NODE_FAIL]: likeNodeFail,
  [ActionTypes.BLOCK_NODE]: blockNode,
  [ActionTypes.BLOCK_NODE_SUCCESS]: blockNodeSuccess,
  [ActionTypes.BLOCK_NODE_FAIL]: blockNodeFail,
  [ActionTypes.BLOCK_USER]: blockUser,
  [ActionTypes.BLOCK_USER_SUCCESS]: blockUserSuccess,
  [ActionTypes.BLOCK_USER_FAIL]: blockUserFail,
  [ActionTypes.NODES_GET]: nodesGet,
  [ActionTypes.NODES_GET_SUCCESS]: nodesGetSuccess,
  [ActionTypes.NODES_GET_FAIL]: nodesGetFail,
  [ActionTypes.ENTITY_UPDATE]: entityUpdate,
  [ActionTypes.ENTITY_UPDATE_SUCCESS]: entityUpdateSuccess,
  [ActionTypes.ENTITY_UPDATE_FAIL]: entityUpdateFail,
  [ActionTypes.ENTITY_DEL]: entityDel,
  [ActionTypes.ENTITY_DEL_SUCCESS]: entityDelSuccess,
  [ActionTypes.ENTITY_DEL_FAIL]: entityDelFail,
  [ActionTypes.FILE_UPLOAD]: fileUpload,
  [ActionTypes.FILE_UPLOAD_SUCCESS]: fileUploadSuccess,
  [ActionTypes.FILE_UPLOAD_FAIL]: fileUploadFail,
  [ActionTypes.CLEAR_UPLOADED_IMAGE]: clearUploadedImage,
  [ActionTypes.EDIT_USER_SUCCESS]: editUserSuccess,
  [ActionTypes.NODE_UPLOAD]: nodeUpload,
  [ActionTypes.NODE_UPLOAD_SUCCESS]: nodeUploadSuccess,
  [ActionTypes.NODE_UPDATE_FAIL]: nodeUploadFail,
  [ActionTypes.VIEW_GET]: viewGet,
  [ActionTypes.VIEW_GET_SUCCESS]: viewGetSuccess,
  [ActionTypes.VIEW_GET_FAIL]: viewGetFail,
  [ActionTypes.LOGOUT]: logout,
  [ActionTypes.NODE_EDIT]: nodeEdit,
  [ActionTypes.NODE_EDIT_SUCCESS]: nodeEditSuccess,
  [ActionTypes.NODE_EDIT_FAIL]: nodeEditFail,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
  [ActionTypes.HANDLE_RECEIVED_NOTIFICATION]: handleReceivedNotification,
  [ActionTypes.COMMENT_SET_SUCCESS]: commentSetSuccess,
  [ActionTypes.COMMENTS_GET]: commentsGet,
  [ActionTypes.COMMENTS_GET_SUCCESS]: commentsGetSuccess,
  [ActionTypes.COMMENTS_GET_FAIL]: commentsGetFail,
  [ActionTypes.COMMENT_DELETE_SUCCESS]: deleteCommentFromNode,
  [ActionTypes.GET_PRODUCT_SUGGESTIONS_SUCCESS]: getProductSuggestionsSuccess,
});

export default images;
