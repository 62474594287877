import React, { PureComponent } from 'react';
import {
  Image, ScrollView,
  Text, TouchableOpacity,
  View,
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import * as Config from '../Config';
import Styles from '../constants/Styles';
import Layout from '../constants/Layout';
import CustomButton from './general/CustomButton';
import { ArrowLeft, ArrowRight } from './Arrows';
import IndicatorPage from './general/IndicatorPage';
import ImageModal from "./general/ImageModal";

export default class ProductNode extends PureComponent {

  render() {
    const { navigate } = this.props.navigation;
    const { node } = this.props;
    if (!this.props.node) {
      return (
        <IndicatorPage />
      );
    }
    let node_id = node.id;
    let node_uid = node.userId ?? 0;
    let node_author = node.userLabel ? ' bei ' + node.userLabel : '';

    // if there are subproducts connected to this product aka user label is 'adminshop'
    if (node.subProducts) {
      let keys = Object.keys(node.subProducts);
      node_uid = keys[Math.floor(Math.random()*keys.length)];
      node_id = node.subProducts[node_uid];
      node_author = ' bei ' + this.props.shops[node_uid].label;
    }

    return (
      <ScrollView style={Styles.pageScrollViewContainer} >
        <View style={{marginBottom: Config.STANDARD_MARGIN_BIG, marginTop: Config.STANDARD_MARGIN_SMALL}}>
          <ImageModal
            images={node.imageStyles1200x900Conditional ? [node.imageStyles1200x900Conditional]: [node.imageSelf]}
          />
          {this.props.showButtons &&
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              position: 'absolute',
              top: (3*Layout.imageHeight/4) / 2 - 60,
            }}
          >
            <TouchableOpacity onPress={this.props.scollToPrev}>
              <ArrowLeft/>
            </TouchableOpacity>
            <TouchableOpacity onPress={this.props.scollToNext}>
              <ArrowRight/>
            </TouchableOpacity>
          </View>
          }
          <View style={{alignItems: 'center' }}>
            <Text style={[Styles.pageTitle,{marginTop: Config.STANDARD_MARGIN_SMALL, marginBottom: Config.STANDARD_MARGIN_SMALL}]} > {node.label} </Text>
            <Text style={Styles.price} > {node.price + ' €'} </Text>
            <CustomButton
              title={"Mehr Infos" + node_author }
              onPress={() => {
                EventRegister.emit('analyticEvent', {
                  category: 'ShopClick',
                  action: 'ProductDetail'
                });
                navigate('Webpage', { data: {uri: Config.BASE_URL + 'url/' + node_id + '/1'}}); }
              }
            />
          </View>
        </View>
      </ScrollView>
    );
  }
}
