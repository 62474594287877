import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shopGet, commerceProductGet, likeNode, addToCart, nodesGet, viewGet } from "../actions/index";
import ProductScreenComponent from "../components/ProductScreenComponent";

class ProductScreen extends Component {
  render() {
    return(
      <ProductScreenComponent
        addToCart={this.props.addToCart}
        commerceProductGet={this.props.commerceProductGet}
        commerceProducts={this.props.commerceProducts}
        commerceProductsById={this.props.commerceProductsById}
        isLoading={this.props.isLoading}
        likeNode={this.props.likeNode}
        list={this.props.list}
        logInState={this.props.logInState}
        navigation={this.props.navigation}
        nodes={this.props.nodes}
        nodesById={this.props.nodesById}
        nodesGet={this.props.nodesGet}
        pagination={this.props.pagination}
        shops={this.props.shops}
        viewGet={this.props.viewGet}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shops.shops,
    commerceProducts: state.commerceProducts.commerceProducts,
    commerceProductsById: state.commerceProducts.commerceProductsById,
    isLikingNode: state.images.isLikingNode,
    isLoading: state.commerceProducts.isLoading,
    list: state.images,
    logInState: state.user,
    nodes: state.images.nodes,
    nodesById: state.images.nodesById,
    pagination: state.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    shopGet: (payload) => {
      dispatch(shopGet(payload))
    },
    commerceProductGet: (payload) => {
      dispatch(commerceProductGet(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    addToCart: (payload) => {
      dispatch(addToCart(payload))
    },
    nodesGet: (payload) => {
      dispatch(nodesGet(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
  }
};

export default connect(mapStateToProps , mapDispatchToProps, null, { pure: false })(ProductScreen);
