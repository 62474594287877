import React from 'react';
import { View, Image, Text} from "react-native";

const PaymentMethods = () => {
  return (
    <View style={{borderBottomWidth: 1, borderTopWidth: 1, borderColor: '#d1d1d1', paddingVertical: 20}}>
      <Text style={{fontWeight: 'bold', textAlign: 'center', paddingBottom: 15}}>Bezahlmethoden</Text>
      <Image source={require('../../assets/images/payments.png')}
             style={{ height: 40, alignSelf: 'center' }}
             resizeMode="contain" />
    </View>
  );
};

export default PaymentMethods;
