import React, {Component} from 'react';
import {FlatList, RefreshControl, View} from 'react-native';

import Colors from '../constants/Colors';
import MenuHorizontal from '../components/MenuHorizontal';
import NotificationItem from '../components/NotificationItem';
import CustomIndicator from '../components/CustomIndicator';
import BackToTopButton from '../components/BackToTopButton';

export default class NotificationScreenComponent extends Component {

  state = {
    backToTopVisible: false
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          backgroundColor={Colors.background}
          contentContainerStyle={{ margin:4 }}
          data={this.props.messages.filter(message => {
            // Filter out messages that do not have an author.
            if (!message.user || !this.props.users[message.user]) {
              return false;
            }
            switch (this.props.messageType(message.type)) {
              // Node based messages need to have a valid node_ref.
              case 'node':
                return (message.node_ref && this.props.nodes[message.node_ref]);
              // Comment messages need a node_ref and a comment_ref.
              case 'comment':
                return (message.node_ref && this.props.nodes[message.node_ref]);
              // User based messages need a user_ref.
              case 'user':
                return (message.user_ref && this.props.users[message.user_ref]);
              // The rest of the messages do not need any ref to be valid.
              default:
                return true;
            }
          })}
          horizontal={false}
          keyExtractor={data => `message${data.id}`}
          ListFooterComponent={this.props.isGettingMessages ? <CustomIndicator /> : null}
          ListHeaderComponent={(!this.props.menus) ? null :
            <MenuHorizontal
              active_menu={this.props.active_menu}
              menus={this.props.menus}
              onMenuPress={this.props.onMenuPress}
            />
          }
          onEndReached={this.props.onEndReached}
          onScroll={this.handleScroll}
          ref={this.handleFlatlistRef}
          renderItem={this.renderItem}
          refreshControl={
            <RefreshControl
              refreshing={false}
              onRefresh={this.props.onRefresh}
            />
          }
        />
        {this.state.backToTopVisible ?
          <BackToTopButton listRef={this.listRef}/>
          : null
        }
      </View>
    );
  }

  handleFlatlistRef = ref => this.listRef = ref;

  handleScroll = (event) => {
    // Update back to top button visibility if it has changed.
    const backToTopVisible = event.nativeEvent.contentOffset.y >1500;
    if (this.state.backToTopVisible !== backToTopVisible) {
      this.setState({ backToTopVisible });
    }
  };

  renderItem = (data) => {
    const message = data.item;
    return (
      <NotificationItem
        navigation={this.props.navigation}
        message={message}
        node_ref={(message.node_ref && this.props.nodes[message.node_ref]) ? this.props.nodes[message.node_ref] : null}
        user_ref={(message.user_ref && this.props.users[message.user_ref]) ? this.props.users[message.user_ref] : null}
        user={this.props.users[message.user]}
      />
    );
  };
}
