import React, { Component } from 'react';
import { connect} from 'react-redux';
import {
  commentsGet,
  commentSet,
  entityDel,
  usersGetMentioning,
  fileUpload,
  clearUploadedImage,
  likeNode,
  nodesGet,
  viewGet,
  commentDelete,
  likeComment,
  addToCart
} from "../actions/index";
import ArticleNode from "../components/ArticleNode";
import ImageNode from "../components/ImageNode";
import ProductNode from '../components/ProductNode';
import ProductNodeCommerce from "../components/ProductNodeCommerce";
import PageScreenComponent from '../components/PageScreenComponent';
import { commerceProductGet, getProductSuggestions } from '../actions';
import IndicatorPage from "../components/general/IndicatorPage";

class NodeSingleScreen extends Component {
  componentDidMount() {
    const node = this.props.navigation.getParam('node');
    const id = this.props.navigation.getParam('id') ?? false;
    // When navigating here the full node may be passed or just an id
    // If it is just an id then we need to request the node.
    if (!node && id) {
      this.props.nodesGet({ ids: [id] });
      this.props.commerceProductGet({nid: id});
    }
    // Set nodeID in navigation params for edit gear visibility.
    this.props.navigation.setParams({ nodeId: id ?? node.id });
  };

  currentNodeId = () => {
    return this.props.navigation.getParam('id');
  }

  render() {
    const id = this.props.navigation.getParam('id');
    const node = this.props.nodes[id];
    // return the corresponding component depending on the type of the node
    // possible types are:
    // - article
    // - image
    // - page
    // - product
    // - product_commerce
    if (!node || (!node.tags && node.type != 'page' && node.type != 'product_commerce')) {
      return (
        <IndicatorPage />
      );
    }

    switch (node.type) {
      case 'article':
        return (
          <ArticleNode
            key={node.id}
            likeNode={this.props.likeNode}
            navigation={this.props.navigation}
            node={node}
            nodes={this.props.nodes}
            style={{ flex:1, paddingHorizontal: 10 }}
            uid={this.props.uid}
            scollToNext={this.scollToNext}
            scollToPrev={this.scollToPrev}
            isLoadingComments={this.props.isLoadingComments}
            isDeletingCommentId={this.props.isDeletingCommentId}
            isEditingComment={this.props.isEditingComment}
            isSendingComment={this.props.isSendingComment}
            logInState={this.props.logInState}
            comments={this.props.comments}
            commentsById={this.props.commentsById}
            commentsGet={this.props.commentsGet}
            commentSet={this.props.commentSet}
            entityDel={this.props.entityDel}
            usersMentioning={this.props.usersMentioning}
            usersGetMentioning={this.props.usersGetMentioning}
            upload={this.props.upload[node.id] || {}}
            fileUpload={this.props.fileUpload}
            clearUploadedImage={this.props.clearUploadedImage}
            commentDelete={this.props.commentDelete}
            isGettingArticle={this.props.isGettingArticle}
            products={this.props.nodes[node.id] && this.props.nodes[node.id].products ?
              this.props.nodes[node.id].products.map(id=>this.props.nodes[id]) : []
            }
            getProductSuggestions={this.props.getProductSuggestions}
            nodesGet={this.props.nodesGet}
            likeComment={this.props.likeComment}
          />
        );
        break;
      case 'image':
        return (
          <ImageNode
            key={node.id}
            currentNodeId={this.currentNodeId}
            likeNode={this.props.likeNode}
            navigation={this.props.navigation}
            node={node}
            nodes={this.props.nodes}
            style={{ flex:1, paddingHorizontal: 10 }}
            uid={this.props.uid}
            scollToNext={this.scollToNext}
            scollToPrev={this.scollToPrev}
            isLoadingComments={this.props.isLoadingComments}
            isDeletingCommentId={this.props.isDeletingCommentId}
            isEditingComment={this.props.isEditingComment}
            isSendingComment={this.props.isSendingComment}
            logInState={this.props.logInState}
            comments={this.props.comments}
            commentsById={this.props.commentsById}
            commentsGet={this.props.commentsGet}
            commentSet={this.props.commentSet}
            entityDel={this.props.entityDel}
            usersMentioning={this.props.usersMentioning}
            usersGetMentioning={this.props.usersGetMentioning}
            upload={this.props.upload[node.id] || {}}
            fileUpload={this.props.fileUpload}
            clearUploadedImage={this.props.clearUploadedImage}
            commentDelete={this.props.commentDelete}
            products={this.props.nodes[node.id] && this.props.nodes[node.id].products ?
              this.props.nodes[node.id].products.map(id=>this.props.nodes[id]) : []
            }
            getProductSuggestions={this.props.getProductSuggestions}
            likeComment={this.props.likeComment}
          />
        );
        break;
      case 'page':
        return (
          <PageScreenComponent
            isLoading={this.props.isLoading}
            navigation={this.props.navigation}
            text={node.body}
          />
        );
        break;
      case 'product':
        if (node.userId == 3317959) {
          return (
             <ProductNodeCommerce
               addToCart={this.props.addToCart}
               commerceProducts={this.props.commerceProducts}
               commerceProductsById={this.props.commerceProductsById}
               isLikingNode={this.props.isLikingNode}
               isLoading={this.props.isLoading}
               key={node.id}
               likeComment={this.props.likeComment}
               likeNode={this.props.likeNode}
               list={this.props.list}
               logInState={this.props.logInState}
               navigation={this.props.navigation}
               node={node}
               nodes={this.props.nodes}
               nodesById={this.props.nodesById}
               nodesGet={this.props.nodesGet}
               pagination={this.props.pagination}
               viewGet={this.props.viewGet}
             />
          );
        }
        return (
          <ProductNode
            key={node.id}
            likeNode={this.props.likeNode}
            navigation={this.props.navigation}
            node={node}
            nodes={this.props.nodes}
            style={{ flex:1, paddingHorizontal: 10 }}
            uid={this.props.uid}
            scollToNext={this.scollToNext}
            scollToPrev={this.scollToPrev}
          />
        );
        break;
      case 'product_commerce':
        return (
           <ProductNodeCommerce
             addToCart={this.props.addToCart}
             commerceProducts={this.props.commerceProducts}
             commerceProductsById={this.props.commerceProductsById}
             isLikingNode={this.props.isLikingNode}
             isLoading={this.props.isLoading}
             key={node.id}
             likeNode={this.props.likeNode}
             list={this.props.list}
             logInState={this.props.logInState}
             navigation={this.props.navigation}
             node={node}
             nodes={this.props.nodes}
             nodesById={this.props.nodesById}
             nodesGet={this.props.nodesGet}
             pagination={this.props.pagination}
             viewGet={this.props.viewGet}
           />
        );
        break;
      default:
        return <IndicatorPage />;

    }
  }
}

const mapStateToProps = (state) => {
  return {
    comments: state.comments.comments,
    commentsById: state.comments.commentsById,
    commerceProducts: state.commerceProducts.commerceProducts,
    commerceProductsById: state.commerceProducts.commerceProductsById,
    isDeletingCommentId: state.comments.isDeletingCommentId,
    isEditingComment: state.comments.isEditingComment,
    isGettingArticle: state.images.isGettingArticle,
    isLikingNode: state.images.isLikingNode,
    isLoading: state.images.isLoading,
    isLoadingComments: state.comments.isLoadingComments,
    isSendingComment: state.comments.isSendingComment,
    list: state.images,
    logInState: state.user,
    nodes: state.images.nodes,
    nodesById: state.images.nodesById,
    pagination: state.pagination,
    uid: state.user.id,
    upload: state.images.upload,
    usersMentioning: state.users.usersMentioning,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    clearUploadedImage: (payload) => {
      dispatch(clearUploadedImage(payload))
    },
    commentsGet: (payload) => {
      dispatch(commentsGet(payload))
    },
    commentSet: (payload) => {
      dispatch(commentSet(payload))
    },
    commentDelete: (payload) => {
      dispatch(commentDelete(payload))
    },
    entityDel: (payload) => {
      dispatch(entityDel(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    nodesGet: (payload) => {
      dispatch(nodesGet(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    getProductSuggestions: (payload) => {
      dispatch(getProductSuggestions(payload))
    },
    commerceProductGet: (payload) => {
      dispatch(commerceProductGet(payload))
    },
    likeComment: (payload) => {
      dispatch(likeComment(payload))
    },
    addToCart: (payload) => {
      dispatch(addToCart(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeSingleScreen);
