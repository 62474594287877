import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import { createCycleMiddleware } from 'redux-cycles';
import { run } from '@cycle/run';
import { makeHTTPDriver } from '@cycle/http';
import Reactotron from './reactotron';
import { reactotronRedux } from 'reactotron-redux';
import main from '../api/cycle';
import reducers from '../reducers/reducers';
import {Platform} from 'react-native';

if (__DEV__) {
  if (Platform.OS !== 'web') {
    Reactotron
      .configure({host: '192.168.90.70'}) //Marianna's local IP - in order to debug iphone devices
      .useReactNative()
      .use(reactotronRedux())
      .connect()
    }
  else {
    Reactotron
      .configure({host: '192.168.90.70'}) //Marianna's local IP - in order to debug iphone devices
      .use(reactotronRedux())
      .connect()
  }
}

const cycleMiddleware = createCycleMiddleware();
const { makeActionDriver, makeStateDriver } = cycleMiddleware;
const combinedReducer = combineReducers(reducers);

const store = __DEV__ ? Reactotron.createStore(
  combinedReducer,
  compose(
    applyMiddleware(
      cycleMiddleware,
      // logger
    )
  )
) :
  createStore(
    combinedReducer,
    compose(
      applyMiddleware(
        cycleMiddleware,
      )
    )
  );

run(main, {
  ACTION: makeActionDriver(),
  STATE: makeStateDriver(),
  HTTP: makeHTTPDriver(),
});

export default store;
