import React, {PureComponent} from 'react';
import {ScrollView, View} from 'react-native';
import MenuHorizontalItem from '../components/MenuHorizontalItem';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

export default class MenuHorizontal extends PureComponent {
  render() {
    const menu_items = this.props.menus.map((menu, index) => {
      return(
        <MenuHorizontalItem
          active={this.props.active_menu == index}
          index={index}
          key={index}
          menu={menu}
          onMenuPress={this.props.onMenuPress}
        />
      );
    });

    if (this.props.menus.length > 3) {
      return(
        <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{flexDirection: 'row'}}>
          {menu_items}
        </ScrollView>
      );
    }
    else {
      return(
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
          {menu_items}
        </View>
      );
    }
  }
}
