import  React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFollowedUsers } from "../actions/index";
import UsersListComponent from '../components/UsersListComponent';

class FollowingScreen extends Component {
  componentDidMount() {
      const { state } = this.props.navigation;
      // if user exists in the DB
      if (state.params) {
        // if user ID is passed fetch user now
        if (state.params.id && !this.props.following[state.params.id]) {
          this.props.getFollowedUsers({ id: state.params.id });
        }
      }
  }
  render() {
    const { state } = this.props.navigation;
    const userName = (state.params && state.params.id)
      ? this.props.users[state.params.id] ? this.props.users[state.params.id].label : this.props.user.label
      : '';
    const following = this.props.following[state.params.id] ?? [];
    const data = following.map(id => this.props.users[id]).filter(user => user && !user.blocked);
    return (
      <UsersListComponent
        title={`${userName} folgt:`}
        data={data}
        navigation={this.props.navigation}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    following: state.users.following,
    user: state.user,
    usernames: state.users.usernames,
    users: state.users.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    getFollowedUsers: (payload) => {
      dispatch(getFollowedUsers(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowingScreen);
