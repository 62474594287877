import  React, {Component} from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';

import * as Config from "../Config";
import Colors from '../constants/Colors';
import Styles from "../constants/Styles";
import Layout from '../constants/Layout';

import CustomIndicator from "../components/CustomIndicator";
import CustomTextArea from "./CustomTextArea";
import CustomButton from './general/CustomButton';
import IndicatorPage from '../components/general/IndicatorPage';

class ReportFormComponent extends Component {

  state = {
    description: '',
  };

  componentDidUpdate(prevProps) {
    this.submitted = this.submitted || false;
    if (this.props.submittedReportForm && !prevProps.submittedReportForm && !this.submitted) {
      this.submitted = true;
      Alert.alert(
        '',
        'Vielen Dank für deine Meldung. Die Redaktion wurde informiert und wird sich den Fall in Kürze ansehen.',
        [
          {
            text: 'Okay',
            onPress: () => this.props.navigation.goBack(),
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }

  }

  render() {
    if (this.props.isSubmittingReportForm) {
      return (
        <IndicatorPage />
      );
    }
    return ( 
      <ScrollView
        contentContainerStyle={{width: Layout.half.width,
          maxWidth: Layout.half.width,
          minWidth: Layout.half.width,
          backgroundColor: Colors.background,}}
        style={{backgroundColor: Colors.background, flex:1}}
        keyboardShouldPersistTaps={"handled"}
      >
        <CustomTextArea
          value={this.state.description && this.state.description != null ? this.state.description.replace(/<br ?\/?>/g, "\n"): ""}
            onChangeText={(description) => this.setState({ description })}
            style={{ padding: 10 }}
        />
        <CustomButton
          title="Jetzt melden"
          onPress={this.onPressSubmit}
        />
        <CustomButton
          title="Abbrechen"
          onPress={this.onPressCancel}
        />
      </ScrollView>
    );
  }

  onPressSubmit = () => {
    const { type, id } = this.props.navigation.state.params;
    EventRegister.emit('analyticEvent', {
      category: 'ReportForm',
      action: 'Report ' + type,
      label: 'Submit Report'
    });
    this.props.submitReportForm({
      id,
      type,
      description: this.state.description,
      uid: this.props.user.id,
    });
  };

  onPressCancel = () => {
    this.props.navigation.goBack();
  };

}

export default ReportFormComponent;

