import React from 'react';
import { StyleSheet, View } from 'react-native';
import Collapsible from "react-native-collapsible";

import Colors from "../../constants/Colors";
import {Icon} from "../Icon";
import CustomCheckbox from "../CustomCheckbox";
import CollapsibleCustom from "../CollapsibleCustom";

class Sorts extends React.Component {

  renderFacetOption = (data) => {
    const { checked, label, id } = data.item;
    return (
      <View>
        <CustomCheckbox
          checked={checked}
          title={label}
          onPress={() => this.props.setChosenSort(id)}
          iconRight={true}
          paddingRight={5}
          right={true}
        />
      </View>
    )
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (nextProps.sortSelected != this.props.sortSelected);
  }

  render() {
    const data = this.props.sortValues
      // Filter and keep only terms/facets for which we have labels.
      .map(sortItem => {
        return {
          checked: sortItem.machine_name == this.props.sortSelected,
          id: sortItem.machine_name,
          label: sortItem.label,
        };
      })
      .filter(sortItem => sortItem.label);

    return (
      <CollapsibleCustom
        data={data}
        mainContent={
          <View style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: Colors.border }}>
            { data.map((item) => this.renderFacetOption({ item: item })) }
          </View>
        }
        mainTitle={'Sortieren Nach'}
        subTitle={''}
      />
    );
  }
}

export default Sorts;
