import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import processMessages from "./helpers/processMessages";

const initialState = {
  isGettingMessages: {
    messages: false,
    messages_user: false,
    messages_follow: false,
  },
  messagesPage: {
    messages: 0,
    messages_user: 0,
    messages_follow: 0,
  },
  //all messages
  messages: {},
  // general messages ("Bei allen") by id
  messagesById: [],
  messages_userById: [],
  messages_followById: [],
};

function messagesGet(state, action) {
  if (action.payload.refreshingNotifications) {
    return {
      ...state,
      isGettingMessages: {
        ...state.isGettingMessages,
        [action.payload.subcategory]: true,
      },
      messages_userById: [],
    };
  }
  if (action.payload.refreshing) {
    return {
      ...state,
      isGettingMessages: {
        ...state.isGettingMessages,
        [action.payload.subcategory]: true,
      },
      [action.payload.subcategory + 'ById']: [],
    };
  }
  return {
    ...state,
    isGettingMessages: {
      ...state.isGettingMessages,
      [action.payload.subcategory]: true,
    }
  };
}

function messagesGetSuccess(state, action) {
  const processedMessages = processMessages(state.messages, state[action.payload.subcategory + 'ById'],action.payload.data);
  return {
    ...state,
    isGettingMessages: {
      ...state.isGettingMessages,
      [action.payload.subcategory]: false,
    },
    messagesPage: {
      ...state.messagesPage,
      [action.payload.subcategory]: action.payload.page
    },
    messages: {
      ...state.messages,
      ...processedMessages.newMessages,
    },
    [[action.payload.subcategory] + 'ById']: processedMessages.messagesById,
  };
}

function messagesGetFail(state, action) {
  return {
    ...state,
    isGettingMessages: {
      ...state.isGettingMessages,
      [action.payload.subcategory]: false,
    }
  };
}

function clearMessages (state, action) {
  return {
    ...state,
    ...initialState
  };
}

const messages = createReducer(initialState, {
  [ActionTypes.MESSAGES_GET]: messagesGet,
  [ActionTypes.MESSAGES_GET_SUCCESS]: messagesGetSuccess,
  [ActionTypes.MESSAGES_GET_FAIL]: messagesGetFail,
  [ActionTypes.LOGOUT_SUCCESS]: clearMessages,
});

export default messages;
