import React, { Component } from 'react';
import {connect} from "react-redux";
import CartScreenComponent from "../components/shopping/CartScreenComponent";
import { removeFromCart, emptyCart, cartGet, addCoupon, removeCoupon, alterQuantity, cartShippingSelect, shoppingSlideGet, cartUpdateStatus, shippingCollect } from "../actions";

class CartScreen extends Component {

  render() {
    return (
      <CartScreenComponent
        addCoupon={this.props.addCoupon}
        alterQuantity={this.props.alterQuantity}
        cartGet={this.props.cartGet}
        cart={this.props.cart}
        cartCount={this.props.cartCount}
        cartShippingOptions={this.props.cartShippingOptions}
        cartShippingSelect={this.props.cartShippingSelect}
        cartUpdateStatus={this.props.cartUpdateStatus}
        emptyCart={this.props.emptyCart}
        isLoadingCart={this.props.isLoadingCart}
        isLoadingShipping={this.props.isLoadingShipping}
        isLoadingQuantity={this.props.isLoadingQuantity}
        isLoadingCoupon={this.props.isLoadingCoupon}
        isRemovingCoupon={this.props.isRemovingCoupon}
        navigation={this.props.navigation}
        removeCoupon={this.props.removeCoupon}
        removeFromCart={this.props.removeFromCart}
        shippingCollect={this.props.shippingCollect}
        shippingOptions={this.props.shippingOptions}
        shoppingSlideGet={this.props.shoppingSlideGet}
        shoppingSlides={this.props.shoppingSlides}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    cart: state.checkout.cart,
    cartCount: state.checkout.cartCount,
    cartShippingOptions: state.checkout.cartShippingOptions,
    isLoadingShipping: state.checkout.isLoadingShipping,
    isLoadingCart: state.checkout.isLoadingCart,
    isLoadingQuantity: state.checkout.isLoadingQuantity,
    isLoadingCoupon: state.checkout.isLoadingCoupon,
    isRemovingCoupon: state.checkout.isRemovingCoupon,
    shoppingSlides: state.slides.cart,
    shippingOptions: state.orders.shippingOptions
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCoupon: (payload) => {
      dispatch(addCoupon(payload))
    },
    alterQuantity: (payload) => {
      dispatch(alterQuantity(payload))
    },
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    },
    cartShippingSelect: (payload) => {
      dispatch(cartShippingSelect(payload))
    },
    cartUpdateStatus: (payload) => {
      dispatch(cartUpdateStatus(payload))
    },
    emptyCart: (payload) => {
      dispatch(emptyCart(payload))
    },
    removeCoupon: (payload) => {
      dispatch(removeCoupon(payload))
    },
    removeFromCart: (payload) => {
      dispatch(removeFromCart(payload))
    },
    shoppingSlideGet: (payload) => {
      dispatch(shoppingSlideGet(payload))
    },
    shippingCollect: (payload) => {
      dispatch(shippingCollect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
