import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import * as Config from '../Config';

// TODO: Examine followers and following structures
const initialState = {
  isGettingFollowers: false,
  isGettingFollowing: false,
  isGettingGeherzt: false,
  followers: {},
  following: {},
  geherzt: {},
  users: {},
  usersById: [],
  usernames: {},
  gettingUsers: false,
  isLoadingMentioning: false,
  usersMentioning: [],
};


function followUser(state) {
  return state;
}

function followUserSuccess(state, action) {
  const urlParts = action.payload.request.url.split('/');
  let userId = parseInt(urlParts[urlParts.length - 1]);
  let followed = urlParts[urlParts.length - 2] === 'flag';
  return {
    ...state,
    users: {
      ...state.users,
      [userId]: {
        ...state.users[userId],
        followed: followed,
      }
    }
  };
}

function followUserFail(state) {
  return state;
}

function blockUser(state, action) {
  return {
    ...state,
    blockedUsers: {
      ...state.blockedUsers,
      [action.payload]: action.payload,
    },
    users: {
      ...state.users,
      [action.payload]: {
        ...state.users[action.payload],
        blocked: true,
      }
    }
  }
}

function blockUserSuccess(state, action) {
  return state;
}

function blockUserFail(state) {
  return state;
}

function usersGet(state) {
  return {
    ...state,
    gettingUsers: true,
  };
}

function updateUsers(state, data) {
  let users = state.users;
  let usernames = Object.assign({}, state.usernames); // This is not a mutation cause state.usernames goes one level deep
  let usersById = state.usersById.slice();
  try {
    data.forEach((user) => {
      const { id, label, self, mail, username, description, created, blocked, blog, facebook, instagram, pinterest, etsy, followers, following, followed } = user;
      users = {
        ...users,
        [user.id]: {
          ...users[user.id],
          blocked,
          blog,
          created,
          description,
          etsy,
          facebook,
          followed: followed !== null ? followed : 0,
          followers,
          following,
          id,
          instagram,
          label,
          mail,
          pictureUri: user.picture && user.picture.uri ? user.picture.uri : Config.DEFAULT_AVATAR,
          pinterest,
          self,
          textFormat: user.text ? user.text.format : null,
          textSafeValue: user.text ? user.text.safe_value : null,
          textValue: user.text ? user.text.value : null,
          username,
        }
      };
      usernames = {
        ...usernames,
        [user.label]: String(user.id)
      };
      if (!usersById.includes(String(user.id))) {
        usersById.push(String(user.id));
      }
    });
  }catch (e) {
    //TODO: return some message?
  }
  return {
    users,
    usernames,
    usersById,
  }
}

function usersGetSuccess(state, action) {
  let result = updateUsers(state, action.payload);

  return {
    ...state,
    gettingUsers: false,
    ...result
  };
}

function usersGetFail(state) {
  return {
    ...state,
    gettingUsers: false,
  };
}

function getFollowedUsers(state) {
  return {
    ...state,
    isGettingFollowing: true,
  };
}

function getFollowedUsersSuccess(state, action) {
  let result = updateUsers(state, action.payload.data);
  return {
    ...state,
    isGettingFollowing: false,
    following: {
      ...state.following,
      [action.payload.id]:
        state.following[action.payload.id] ?
          [
            ...state.following[action.payload.id],
            ...action.payload.data.map((user) => user.id)
          ]
          :
          action.payload.data.map((user) => user.id)
    },
    ...result,
  };
}

function getFollowedUsersFail(state) {
  return {
    ...state,
    isGettingFollowing: false,
  };
}

function usersGetByTamperedUsername(state) {
  return {
    ...state,
    isLoadingMentioning: true,
  };
}

function usersGetByTamperedUsernameSuccess(state, action) {
  let result = updateUsers(state, action.payload.data);
  return {
    ...state,
    isLoadingMentioning: false,
    usersMentioning: action.payload.data.length > 5 ? action.payload.data.slice(0,4) : action.payload.data,
    ...result,
  };
}

function usersGetByTamperedUsernameFail(state) {
  return {
    ...state,
    isLoadingMentioning: false,
    usersMentioning: [],
  };
}

function usersGetMentioning(state) {
  return {
    ...state,
    isLoadingMentioning: true,
  };
}

function getFollowers(state) {
  return {
    ...state,
    isGettingFollowers: true,
  };
}

function getFollowersSuccess(state, action) {
  let result = updateUsers(state, action.payload.data);

  return {
    ...state,
    isGettingFollowers: false,
    followers: {
      ...state.followers,
      [action.payload.id]:
        state.followers[action.payload.id] ?
          [
            ...state.followers[action.payload.id],
            ...action.payload.data.map((user) => user.id)
          ]
          :
          action.payload.data.map((user) => user.id)
    },
    ...result,
  };
}

function getFollowersFail(state) {
  return {
    ...state,
    isGettingFollowers: false,
  };
}

function getGeherztUsers(state) {
  return {
    ...state,
    isGettingGeherzt: true,
  };
}

function getGeherztUsersSuccess(state, action) {
  let result = updateUsers(state, action.payload.data);

  return {
    ...state,
    isGettingGeherzt: false,
    ...result,
    geherzt: {
      ...state.geherzt,
      [action.payload.id]:
        state.geherzt[action.payload.id] ?
          [
            ...state.geherzt[action.payload.id],
            ...action.payload.data.map((user) => user.id)
          ]
          :
          action.payload.data.map((user) => user.id)
    },
  };
}

function getGeherztUsersFail(state) {
  return {
    ...state,
    isGettingGeherzt: false,
  };
}

/**
 * If an error was received cancel any interactive action that could be in progress.
 * Otherwise the user might find herself with an endless spinner.
 */
function errorReceived(state) {
  return{
    ...state,
    isGettingFollowers: false,
    isGettingFollowing: false,
    isGettingGeherzt: false,
    isLoadingMentioning: false,
  };
}

const users = createReducer(initialState, {
  [ActionTypes.FOLLOW_USER]: followUser,
  [ActionTypes.FOLLOW_USER_SUCCESS]: followUserSuccess,
  [ActionTypes.FOLLOW_USER_FAIL]: followUserFail,
  [ActionTypes.BLOCK_USER]: blockUser,
  [ActionTypes.BLOCK_USER_SUCCESS]: blockUserSuccess,
  [ActionTypes.BLOCK_USER_FAIL]: blockUserFail,
  [ActionTypes.USERS_GET]: usersGet,
  [ActionTypes.USERS_GET_SUCCESS]: usersGetSuccess,
  [ActionTypes.USERS_GET_FAIL]: usersGetFail,
  [ActionTypes.GET_FOLLOWED_USERS]: getFollowedUsers,
  [ActionTypes.GET_FOLLOWED_USERS_SUCCESS]: getFollowedUsersSuccess,
  [ActionTypes.GET_FOLLOWED_USERS_FAIL]: getFollowedUsersFail,
  [ActionTypes.USERS_GET_BY_TAMPERED_USERNAME]: usersGetByTamperedUsername,
  [ActionTypes.USERS_GET_BY_TAMPERED_USERNAME_SUCCESS]: usersGetByTamperedUsernameSuccess,
  [ActionTypes.USERS_GET_BY_TAMPERED_USERNAME_FAIL]: usersGetByTamperedUsernameFail,
  [ActionTypes.USERS_GET_MENTIONING]: usersGetMentioning,
  [ActionTypes.USERS_GET_MENTIONING_SUCCESS]: usersGetByTamperedUsernameSuccess,
  [ActionTypes.USERS_GET_MENTIONING_FAIL]: usersGetByTamperedUsernameFail,
  [ActionTypes.GET_FOLLOWERS]: getFollowers,
  [ActionTypes.GET_FOLLOWERS_SUCCESS]: getFollowersSuccess,
  [ActionTypes.GET_FOLLOWERS_FAIL]: getFollowersFail,
  [ActionTypes.GET_GEHERZT_USERS]: getGeherztUsers,
  [ActionTypes.GET_GEHERZT_USERS_SUCCESS]: getGeherztUsersSuccess,
  [ActionTypes.GET_GEHERZT_USERS_FAIL]: getGeherztUsersFail,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
});

export default users;
