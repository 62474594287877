import React from 'react';
import {Platform, ScrollView, Text, View} from 'react-native';
import { connect } from 'react-redux';
import { Button } from 'react-native-elements';
import { appleConnect } from '../actions/index';
import Styles from "../constants/Styles";
import TermsOfService from '../components/TermsOfService';
import AppleAuthenticationComponent  from '../components/AppleAuthenticationComponent'
import ContactText from "../components/general/ContactText";

class RegisterAppleScreen extends React.Component {

  state = {
    agreeNewsletter: false,
    agreeTerms: false,
  };

  toggleCheckBox = (name) => {
    this.setState({
      [name]: !this.state[name],
      errorMessage: null,
      savePressed: false,
    });
  }

  render() {
    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}> 
          <TermsOfService {...this.state} toggleCheckBox={this.toggleCheckBox} navigation={this.props.navigation} />
          <View style={{ alignItems: 'center' }}>
            {
              this.props.logInState.appleConnectMessage && this.props.logInState.appleConnectMessage !== null &&
              <Text style={{color: 'red', marginTop: 10, textAlign: 'left'}}>{this.props.logInState.appleConnectMessage}</Text>
            }
            { Platform.OS === "ios"  ? !this.state.agreeTerms ?
              <Button
                disabled
                title="Mit Apple anmelden"
                buttonStyle={Styles.button}
                titleStyle={{fontWeight: 'bold'}}
                fontWeight='bold'
              /> :
              <AppleAuthenticationComponent
                register={true}
                agreeNewsletter={this.state.agreeNewsletter}
                appleConnect={this.props.appleConnect}
              /> : null
              }
          </View>
        </View>
        <ContactText navigation={this.props.navigation}/>
      </ScrollView>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    appleConnect: (payload) => {
      dispatch(appleConnect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAppleScreen);
