import React from 'react';
import {connect} from 'react-redux';
import SearchScreenComponent from "../components/SearchScreenComponent";
import {likeNode, viewGet} from "../actions/index";

class SearchScreen extends React.Component {
  render() {
    return (
      <SearchScreenComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.images.nodes,
    nodesById: state.images.nodesById,
    isLikingNode: state.images.isLikingNode,
    list: state.images,
    isLoading: state.images.isLoading,
    pagination: state.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchScreen);
