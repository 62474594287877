import React from 'react';
import ShoppingScreenComponent from '../components/ShoppingScreenComponent';
import {
  likeNode,
  menuGet,
  shopGet,
  viewGet,
  productSearchGet,
  termsGet,
  getVocabulary
} from '../actions/index';
import connect from 'react-redux/es/connect/connect';

const ShoppingScreen = (props) => {
  return (
    <ShoppingScreenComponent
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.productSearch.data,
    facets: state.productSearch.facets,
    isLikingNode: state.images.isLikingNode,
    isLoading: state.images.isLoading,
    menus: state.general.menus,
    nodes: state.images.nodes,
    tagsdb: state.tags.tagsdb,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    menuGet: (payload) => {
      dispatch(menuGet(payload))
    },
    shopGet: (payload) => {
      dispatch(shopGet(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    productSearchGet: (payload) => {
      dispatch(productSearchGet(payload))
    },
    termsGet: (payload) => {
      dispatch(termsGet(payload))
    },
    getVocabulary: (payload) => {
      dispatch(getVocabulary(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingScreen);
