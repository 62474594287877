import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import { AdForbiddenScreens, ScreensBeforeResettingCounter } from "../constants/AdConfig";
import { currentDay } from '../helpers/formatNumbers';

const initialState = {
  categoryNames: {},
  creatingTag: false,
  gettingTag: false,
  isLoading: false,
  mainCategories: {},
  mainCategoriesLastUpdate: 0,
  newTags: [],
  tagListById: [],
  tagnames: {},
  // holds tags suggestions when uploading an image.
  tags: [],
  // DB of tags known by the app. keyed by id.
  tagsdb: {},
  ads: {
    [currentDay()]: {
      screenChanges: 0,
      adCounter: 0
    }
  }
};

function tagsGet(state) {
  return {
    ...state,
    gettingTag: true
  };
}

function tagsGetSuccess(state, action) {
  return {
    ...state,
    tags: action.payload,
    gettingTag: false,
  };
}

function tagsGetFail(state) {
  return {
    ...state,
    //Commented this out in order to leave the image submit button disabled until the tag is created
    // gettingTag: false
  };
}

function termsGet(state) {
  return {
    ...state,
  };
}

function termsGetSuccess(state, action) {
  const tagsdb = {...state.tagsdb};
  action.payload.data.map((term) => {
    tagsdb[term.id] = term;
  });
  return {
    ...state,
    tagsdb: tagsdb,
  };
}

function termsGetFail(state) {
  return {
    ...state,
  };
}

function createTag(state) {
  return {
    ...state,
    creatingTag: true,
  };
}

function createTagSuccess(state, action) {
  return {
    ...state,
    creatingTag: false,
    gettingTag: false,
    newTags: [...state.newTags, action.payload],
  };
}

function createTagFail(state) {
  return {
    ...state,
    creatingTag: false,
  };
}

function getTagByName(state) {
  return {
    ...state,
    gettingTag: true,
  };
}

function getTagByNameSuccess(state, action) {
  const tagsdb = {...state.tagsdb};
  const tagnames = {...state.tagname};
  const categoryNames = {};
  action.payload.data.map((tag) => {
    tagsdb[tag.id] = tag[tag.vocabulary];
    // Store terms from different vocabularies separately.
    if (tag.vocabulary == 'categories') {
      categoryNames[tag.label.toLowerCase()] = tag.id;
    }
    else if (tag.vocabulary == 'tags') {
      tagnames[tag.label.toLowerCase()] = tag.id;
    }
  });
  const tag1 = action.payload.data[0];
  return {
    ...state,
    gettingTag: false,
    newTags: [...state.newTags, tag1[tag1.vocabulary]],
    tagsdb: {...state.tagsdb, ...tagsdb},
    tagnames: {...state.tagnames, ...tagnames},
    categoryNames: {...state.categoryNames, ...categoryNames},
  };
}

function getTagByNameFail(state) {
  return {
    ...state,
    creatingTag: true,
  };
}

function nodeUploadSuccess(state) {
  return {
    ...state,
    newTags: [],
  };
}

export function handleNavigation(state, action) {
  // Show only 2 impressions per day
  // Reset screenCounter after 30 screen changes
  // Exclude specific screens from the ad functionality
  const todayAds = state.ads[currentDay()] ?? { screenChanges: 0, adCounter: 0 };
  return {
    ...state,
    tags: [],
    ads: {
      ...state.ads,
      [currentDay()]: {
        screenChanges: (todayAds.screenChanges < ScreensBeforeResettingCounter ) || action.payload.adShown ?
          ((AdForbiddenScreens.includes(action.payload.currentScreen)) ? todayAds.screenChanges : todayAds.screenChanges + 1) : 0,
        adCounter: action.payload.adShown ? (todayAds.adCounter ?? 0) + 1 : todayAds.adCounter
      }
    }
  };
}

/**
 * If an error was received cancel any interactive action that could be in progress.
 * Otherwise the user might find herself with an endless spinner.
 */
function errorReceived(state) {
  return {
    ...state,
    creatingTag: false,
    gettingTag: false,
    isLoading: false,
  };
}

function getTagName(state) {
  return {
    ...state,
  };
}

function getTagNameSuccess(state, action) {
  return {
    ...state,
    tagListById:  [...state.tagListById, action.payload[0]],
  };
}

function getTagNameFail(state) {
  return {
    ...state,
  };
}

function getVocabulary(state) {
  return {
    ...state,
    isLoading: true
  };
}

function getVocabularySuccess(state, action) {
  const categories = action.payload.data.reduce((categories, category) => {
    // If this is not Wohnen (55190) and does not have a parent,
    // then assign it under Wohnen.
    if (!category.parent || !category.parent.length) {
      category.parent = [55190];
      if (category.id == 55190) {
        category.weight = parseInt(category.weight) - 500;
      }
      else {
        category.weight = parseInt(category.weight) + 500;
      }
    }
    categories[category.id] = category;
    return categories;
  }, {});

  return {
    ...state,
    isLoading: false,
    mainCategories: {
      ...state.mainCategories,
      ...categories,
    },
    mainCategoriesLastUpdate: Date.now(),
    tagsdb: {
      ...state.tagsdb,
      ...categories,
    }
  };
}

function getVocabularyFail(state) {
  return {
    ...state,
    isLoading: false
  };
}

const tags = createReducer(initialState, {
  [ActionTypes.TAGS_GET]: tagsGet,
  [ActionTypes.TAGS_GET_SUCCESS]: tagsGetSuccess,
  [ActionTypes.TAGS_GET_FAIL]: tagsGetFail,
  [ActionTypes.TERMS_GET]: termsGet,
  [ActionTypes.TERMS_GET_SUCCESS]: termsGetSuccess,
  [ActionTypes.TERMS_GET_FAIL]: termsGetFail,
  [ActionTypes.CREATE_TAG]: createTag,
  [ActionTypes.CREATE_TAG_SUCCESS]: createTagSuccess,
  [ActionTypes.CREATE_TAG_FAIL]: createTagFail,
  [ActionTypes.GET_TAG_BY_NAME]: getTagByName,
  [ActionTypes.GET_TAG_BY_NAME_SUCCESS]: getTagByNameSuccess,
  [ActionTypes.GET_TAG_BY_NAME_FAIL]: getTagByNameFail,
  [ActionTypes.GET_TAG_NAME]: getTagName,
  [ActionTypes.GET_TAG_NAME_SUCCESS]: getTagNameSuccess,
  [ActionTypes.GET_TAG_NAME_FAIL]: getTagNameFail,
  [ActionTypes.GET_VOCABULARY]: getVocabulary,
  [ActionTypes.GET_VOCABULARY_SUCCESS]: getVocabularySuccess,
  [ActionTypes.GET_VOCABULARY_FAIL]: getVocabularyFail,
  [ActionTypes.NODE_UPLOAD_SUCCESS]: nodeUploadSuccess,
  [ActionTypes.HANDLE_NAVIGATION]: handleNavigation,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
});

export default tags;
