import  React, { Component } from 'react';
import { View } from 'react-native';
import UserInfoComponent from "../components/UserInfoComponent";
import ImagesList from '../components/ImagesList';

class UserScreenComponent extends Component {
  render() {
    const menus = [
      {title: 'Bilder', path: 'user/' + this.props.user.id, view: 'user_fluid_layouts', display: 'page_3'},
      {title: 'Kommentiert & Geherzt', path: 'user/' + this.props.user.id + '/geherztkommentiert', view: 'user_fluid_layouts', display: 'page_1'},
    ];
    return (
      <View style={{flex: 1}}>
        <ImagesList
          data={this.props.data}
          nodesById={this.props.nodesById}
          isLoading={this.props.isLoading}
          likeNode={this.props.likeNode}
          list={this.props.list}
          menus={menus}
          navigation={this.props.navigation}
          viewGet={this.props.viewGet}
          header={
            <UserInfoComponent
              followUser={this.props.followUser}
              navigation={this.props.navigation}
              style={{ bottomMargin: 20 }}
              logInState={this.props.logInState}
              user={this.props.user}
            />
          }
          pagination={this.props.pagination}
          isLikingNode={this.props.isLikingNode}
          likeComment={this.props.likeComment}
          onRefresh={this.props.onRefresh}
        />
      </View>
    );
  }
}

export default UserScreenComponent;
