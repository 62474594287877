import React, {Component} from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList} from "react-native";
import Styles from "../../constants/Styles";


class CouponForm extends Component {

  state = {
    text: ''
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.isLoadingCoupon !== prevProps.isLoadingCoupon) && !this.props.isLoadingCoupon) {
      setTimeout(() => this.props.cartGet(), 2000)
    }
    if ((this.props.isRemovingCoupon !== prevProps.isRemovingCoupon) && !this.props.isRemovingCoupon) {
      setTimeout(() => this.props.cartGet(), 2000)
    }
  }

  renderItem = ({ item }) => {
    return (
      <View style={[Styles.couponItem, {borderBottomWidth: 1}]}>
        <View style={{alignSelf:"center"}}>
          <Text style={{fontWeight: 'bold'}}><Text>Code: </Text><Text>{item.code}</Text></Text>
          <Text>{item.discount.map(discount => discount + ' ')}</Text>
        </View>
        <TouchableOpacity
          onPress={() => {this.props.removeCoupon(item.code)}}
          style={{fontSize: 14, borderWidth: 1, borderColor: '#d1d1d1', justifyContent: 'center', padding: 10}}
        >
          <Text style={{color: '#000000'}}>Entfernen</Text>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    return (
      <View>
        <View style={[Styles.couponItem, { borderBottomWidth: this.props.coupons ? 0 : 1}]}>
          <TextInput placeholder={"Du hast einen Gutschein?"}
                     onChangeText={(text) => this.setState({text})}
                     value={this.state.text}
                     style={{borderWidth: 1, borderColor: '#d1d1d1', padding: 10 , flex: 1, marginRight: 10}}/>
          <TouchableOpacity
            onPress={() => this.props.addCoupon(this.state.text)}
            style={{fontSize: 14, backgroundColor: '#707070', justifyContent: 'center', padding: 10}}
          >
            <Text style={{color: '#ffffff'}}>Gutschein hinzufügen</Text>
          </TouchableOpacity>
        </View>
        {this.props.coupons.length ?
          <View>
            <Text style={{fontWeight: 'bold'}}>Gutscheine</Text>
            <FlatList
              data={this.props.coupons}
              renderItem={this.renderItem}
              keyExtractor={item => item.id}
            />
          </View> : null
        }
      </View>
    );
  }
}

export default CouponForm;
