import React from 'react';
import { connect } from 'react-redux';
import { submitFeedbackForm } from "../actions/index";
import FeedbackScreenComponent from "../components/FeedbackScreenComponent";

const FeedbackScreen = (props) => {
  return (
    <FeedbackScreenComponent
      isSubmittingFeedbackForm={props.isSubmittingFeedbackForm}
      navigation={props.navigation}
      submittedFeedbackForm={props.submittedFeedbackForm}
      submitFeedbackForm={props.submitFeedbackForm}
      submittedFeedbackFormErrorMessage={props.submittedFeedbackFormErrorMessage}
      user={props.user}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isSubmittingFeedbackForm: state.general.isSubmittingFeedbackForm,
    submittedFeedbackForm: state.general.submittedFeedbackForm,
    submittedFeedbackFormErrorMessage: state.general.submittedFeedbackFormErrorMessage
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitFeedbackForm: (payload) => {
      dispatch(submitFeedbackForm(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackScreen);

