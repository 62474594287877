import React, { Component } from 'react';
import {
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  Image,
} from 'react-native';
import FitImage from 'react-native-fit-image';
import { EventRegister } from 'react-native-event-listeners';
import { connect } from 'react-redux';
import Styles from '../constants/Styles';
import NodeActionButtons from './general/NodeActionButtons';
import * as Config from "../Config";

class ArticleNodeThumb extends Component {

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.item.liked === this.props.item.liked && nextProps.images && this.props.images &&
      nextProps.images[this.props.item.id].comments ===  this.props.images[this.props.item.id].comments &&
      nextProps.images[this.props.item.id].likes ===  this.props.images[this.props.item.id].likes )
      return false;
    return true;
  }

  show_series = (item) => {
    if (item && item.series) {
      return (
        <Text style={[Styles.subtitle, Styles.textCenter,  { marginTop: 10 }]}>{item.series.label}</Text>
      );
    }
  };

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.props.item.liked) {
        this.props.likeNode({node: this.props.item, op: 'unflag'});
      }
      else {
        EventRegister.emit('analyticEvent', {category: 'Flag', action: 'Herzen', label: 'Overview'});
        this.props.likeNode({node: this.props.item, op: 'flag'});
      }
    }
  };

  onPressComment = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.props.item) {
        this.props.navigation.navigate({
          routeName: 'NodeSingle',
          key: `node${this.props.item.id}`,
          params: {
            id: this.props.item.id,
            type: this.props.item.type,
            focusInputField: this.props.item.id,
          }
        });
      }
    }
  };

  render() {
    const { item, likeNode, navigation } = this.props;
    return (
        <View>
          { this.show_series(item) }
          <Text style={[Styles.gridTitle, Styles.textCenter]}>{item.label}</Text>
          <NodeActionButtons
            navigateable={false}
            navigation={navigation}
            onPressLike={this.onPressLike}
            item={item}
            onPressComment={this.onPressComment}
            commentable={true}
          />
         </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logInState: state.user,
    images: state.images.image,
  }
};

export default connect(mapStateToProps)(ArticleNodeThumb)
