import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import ImageUploadScreen from '../containers/ImageUploadScreen';
import CommonStack from '../navigation/CommonStack';

export default createStackNavigator(
  {
    Camera: { screen: ImageUploadScreen, navigationOptions: CommonStack.navigationOptions },
  },
  {
    ...CommonStack.Config,
  }
);
