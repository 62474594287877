import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';

const initialState = {
  menu: {
    'menu-new-mmenu': {
      items: {
        1: {
          path: 'node/5262321',
          title: 'Community-Richtlinien',
          id: 1,
          hasChildren: false,
          parentId: 0,
          weight: 1001,
        },
        2: {
          path: 'user/orders',
          title: 'Bestellungen',
          id: 2,
          hasChildren: false,
          parentId: 0,
          weight: 1002,
        },
        3: {
          path: 'node/4427982',
          title: 'Impressum & Datenschutz',
          id: 3,
          hasChildren: false,
          parentId: 0,
          weight: 1003,
        },
        4: {
          path: 'help',
          title: 'Hilfe und Feedback',
          id: 4,
          hasChildren: false,
          parentId: 0,
          weight: 1004,
        },
        5: {
          path: 'user/logout',
          title: 'Ausloggen',
          id: 5,
          hasChildren: false,
          parentId: 0,
          weight: 1005,
        },
        6: {
          path: 'user/addressbook',
          title: 'Adressbuch',
          id: 6,
          hasChildren: false,
          parentId: 0,
          weight: 1000,
        },
        7: {
          path: 'suche',
          title: 'Suche',
          id: 7,
          hasChildre: false,
          parentId: 0,
          weight: 999,
        },
      },
    },
  },
  tos: {},
  isSubmittingFeedbackForm: false,
  submittedFeedbackForm: false,
  submittedFeedbackFormErrorMessage: null
};

function menuGet(state, action) {
  return {
    ...state,
    menu: {
      ...state.menu,
      [action.payload.menuName]: {
        isLoading: true,
        items: {},
      }
    }
  };
}

function menuGetSuccess(state, action) {
  const { data, menuName } = action.payload;
  return {
    ...state,
    menus: {
      ...state.menus,
      [menuName]: {
        isLoading: false,
        items: {
          ...((initialState.menu[menuName]) ? initialState.menu[menuName].items : {}),
          ...data.reduce((total, item) => {
            total[item.id] = {
              id: parseInt(item.id),
              hasChildren: parseInt(item.has_children) === 1,
              parentId: parseInt(item.parent_id),
              path: item.path,
              title: item.title,
              weight: parseInt(item.weight),
            };
            return total;
          }, {})
        }
      }
    }
  };
}

function menuGetFail(state, action) {
  return {
    ...state,
    menu: {
      ...state.menu,
      [action.payload]: {
        isLoading: false,
      }
    }
  };
}

function tosGet(state, action) {
  return {
    ...state,
    tos: {
      ...state.tos,
      [action.payload]: {
        isLoading: true,
      }
    }
  };
}

function tosGetSuccess(state, action) {
  return {
    ...state,
    tos: {
      ...state.tos,
      [action.payload.pageId]: {
        isLoading: false,
        text: action.payload.data.body
      }
    }
  };
}

function tosGetFail(state, action) {
  return {
    ...state,
    tos: {
      ...state.tos,
      [action.payload]: {
        isLoading: false,
      }
    }
  };
}

function submitReportForm(state) {
  return {
    ...state,
    isSubmittingReportForm: true,
    submittedReportForm: false,
  };
}

function submitReportFormSuccess(state, action) {
  return {
    ...state,
    isSubmittingReportForm: false,
    submittedReportForm: true,
  };
}

function submitReportFormFail(state) {
  return {
    ...state,
    isSubmittingReportForm: false,
    submittedReportForm: false,
  };
}

function submitFeedbackForm(state) {
  return {
    ...state,
    isSubmittingFeedbackForm: true,
    submittedFeedbackForm: false,
    submittedFeedbackFormErrorMessage: null
  };
}

function submitFeedbackFormSuccess(state, action) {
  return {
    ...state,
    isSubmittingFeedbackForm: false,
    submittedFeedbackForm: true,
    submittedFeedbackFormErrorMessage: null
  };
}

function submitFeedbackFormFail(state) {
  return {
    ...state,
    isSubmittingFeedbackForm: false,
    submittedFeedbackForm: false,
    submittedFeedbackFormErrorMessage: 'Der Versand der Nachricht ist fehlgeschlagen. Bitte probiere es später erneut.',
  };
}

const general = createReducer(initialState, {
  [ActionTypes.MENU_GET]: menuGet,
  [ActionTypes.MENU_GET_SUCCESS]: menuGetSuccess,
  [ActionTypes.MENU_GET_FAIL]: menuGetFail,
  [ActionTypes.TOS_GET]: tosGet,
  [ActionTypes.TOS_GET_SUCCESS]: tosGetSuccess,
  [ActionTypes.TOS_GET_FAIL]: tosGetFail,
  [ActionTypes.SUBMIT_REPORT_FORM]: submitReportForm,
  [ActionTypes.SUBMIT_REPORT_FORM_SUCCESS]: submitReportFormSuccess,
  [ActionTypes.SUBMIT_REPORT_FORM_FAIL]: submitReportFormFail,
  [ActionTypes.SUBMIT_FEEDBACK_FORM]: submitFeedbackForm,
  [ActionTypes.SUBMIT_FEEDBACK_FORM_SUCCESS]: submitFeedbackFormSuccess,
  [ActionTypes.SUBMIT_FEEDBACK_FORM_FAIL]: submitFeedbackFormFail,
});

export default general;
