import React from 'react';
import {Image, Text, TouchableOpacity, View} from "react-native";
import {emojify} from 'react-emojione';
import * as Config from '../Config';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import * as Drupal from '../helpers/Drupal';
import ProductSuggestionsInline from '../components/ProductSuggestionsInline';
import VideoComponent from "./VideoComponent";
import ImageModal from "./general/ImageModal";
import HTML from 'react-native-render-html';

class CollapsilbleText extends React.Component{
  state = {
    descriptionLong: this.props.text ? this.props.text.split(' ').length > Config.SHOW_MORE_WORDS : false,
    descriptionLength: this.props.text ? this.props.text.split(' ').slice(0,Config.SHOW_MORE_WORDS).join(' ').length : 0,
    descriptionOpen: false,
  };

  onLinkPress = (e, url_string, obj) => {
    const navigation = this.props.navigation;
    // first of all decode the url_string
    url_string = decodeURIComponent(url_string);
    // if a FULL url to an external domain
    if (url_string.indexOf('http') > -1 && url_string.indexOf('solebich.de') == -1) {
      // open inline URLs in Webview if external
      navigation.navigate('Webpage', {data: {uri: url_string}});
    }
    // try to map the URL to an entity
    var url = Drupal.url_to_id(url_string);
    if (url && url.entity_type) {
      switch(url.entity_type) {
        // in case it is a node
        case 'node':
          if (url.type !== 'product') {
            navigation.navigate({routeName: 'NodeSingle', key: `node${url.id}`, params:  {id: url.id, type: url.type}})
          }
          else {
            navigation.navigate({routeName: 'Product', key: `node${url.id}`, params:  {id: url.id}});
          }
          break;
        case 'term':
          navigation.navigate({routeName: 'Tag', key: `tag${url.label}`, params:  {name: url.label}});
          break;
        case 'user':
          let userId = url.label;
          const dataInternalPath = obj['data-internal-path'];
          if (dataInternalPath) {
            const parts = dataInternalPath.split('/');
            userId = parts[1];
          }
          navigation.navigate({routeName: 'User', key: `user${userId}`, params: { id: userId }});
          break;
      }
    }
    else {
      // Open URls that cannot be mapped in webview except if containing an anchor.
      if (url_string.indexOf('#') === -1) {
        navigation.navigate('Webpage', {data: {uri: url_string}});
      }
    }
  };
  productsRenderer = (htmlAttribs, children, convertedCSSStyles, passProps) => {
    let products = children[0][0].props.children[0].props.children.split('-').pop().replace(']', '').trim().split('+');
    return(
      <View style={{flexDirection: "row", flexWrap: 'wrap'}} key={children[0][0].props.children[0].props.children}>
        <View style={{width: Layout.window.width - 20}}>
          <ProductSuggestionsInline page={this.props.page ? this.props.page : ''} navigation={this.props.navigation} products={products}/>
        </View>
      </View>
    )
  };
  videoRenderer = (htmlAttribs, children, convertedCSSStyles, passProps) => {
    return <View style={{height:0}} />;
    return(
      <VideoComponent node={htmlAttribs} full_width={Layout.window.width - 2 *Config.STANDARD_MARGIN} />
    )
  };
  imgRenderer = (htmlAttribs) => {
    return (
      <ImageModal
        images={[htmlAttribs.src.indexOf('http') > -1 ? htmlAttribs.src : Config.BASE_URL + htmlAttribs.src]}
        full_width={Layout.window.width - 20}
        siblings={htmlAttribs.siblings ? htmlAttribs.siblings : 1}
        isArticle={true}
      />
    )
  };
  alterChildren = (node) => {
    let {name, children} = node;
    if(name === 'products' && children && children.filter(c => c.data && c.data.indexOf('[products-') > -1).length > 0){
      return children.filter(c => c.data && c.data.indexOf('[products-') > -1);
    }
    if(name === 'div' && children && children.filter(c => c.name && c.name === 'img').length > 1){
      let siblings = children.filter(c => c.name && c.name === 'img').length;
      return children.map(c => {if(c.name ==='img' ){
        c.attribs = {
          ...c.attribs,
          siblings
        }
      } return c;})
    }
  };

  alterNode = (node) => {
    if(node.name === 'p' && node.children && node.children.filter(c => c.data && c.data.indexOf('[products-') > -1).length > 0){
      node.name = 'products';
      return node;
    }
    if(node.name === 'div' && node.children && node.children.filter(c => c.name && c.name === 'img').length > 1){
      node.attribs.style = 'flex-direction: row;';
      return node;
    }
    //TODO: find a proper solution for videos
    if(node.name === 'div' && node.children && node.children.filter(c => c.name && c.name === 'iframe').length > 0){
      node.attribs.style = 'height: 0';
      return node;
    }
    if (node.name === 'a' && node.attribs && node.attribs.href && node.attribs.href.indexOf('#') !== -1) {
      node.attribs.style = 'textDecorationLine: none';
      return node;
    }
  };

  render() {
    const align = (this.props.align === 'left' ) ? 'flex-start' : 'center';
    const textAlign = this.props.align || 'center';
    // convert all emojis to unicode
    let textToUse = this.props.text? this.props.text.replace(/&nbsp;/gm, ' ').replace(/&amp;/gm, '&'): '';
    let text = textToUse !== null ? (
      this.props.collapse ?
        emojify(`<p style="text-align: ${textAlign};">` + textToUse+ '</p>', {output: 'unicode'})
        :
      emojify(textToUse, {output: 'unicode'})
    ): null;
    const desc = textToUse !== null && textToUse.length > 0 ? emojify(`<p style="text-align: ${textAlign};">` + (textToUse.substr(0,this.state.descriptionLength) + ' ...')+ '</p>', {output: 'unicode'}) : null;
    if (text && text !== null) {
      const extraProps = {
        renderers: {
          products: this.productsRenderer,
          iframe : this.videoRenderer,
          img: this.imgRenderer,
        },
        containerStyle: {
          marginBottom: 0,
          paddingBottom: 0,
          alignSelf: 'center',
          maxWidth: Layout.window.width - 2*Config.STANDARD_MARGIN
        },
        tagsStyles: {
          p: {
            marginTop: 5,
            marginBottom: 5,
            paddingBottom: 0,
            textAlign,
            fontSize: Config.FONT_SIZE_NORMAL,
            lineHeight: 1.5 * Config.FONT_SIZE_NORMAL,
          },
          div: {
            marginTop: 5,
            marginBottom: 1,
            paddingBottom: 0,
          },
          strong: {
            fontFamily: 'OpenSansBold',
          },
          a: {
            paddingHorizontal: 5,
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
            textDecorationColor: 'black',
            color: 'black',
            fontSize: Config.FONT_SIZE_NORMAL,
          },
          h1: {
            fontFamily: 'MerriweatherBold',
            fontSize: Config.FONT_SIZE_BIG,
            lineHeight: 1.3 * Config.FONT_SIZE_BIG,
            marginTop: Config.STANDARD_MARGIN,
            marginBottom: Config.STANDARD_MARGIN,
            textAlign: 'center',
          },
          h2: {
            fontFamily: 'OpenSansBold',
            fontSize: 18,
            lineHeight: 18 * 1.3,
            marginTop: Config.STANDARD_MARGIN,
            textAlign: 'center',
            alignSelf: 'center',
          },
          h3: {
            fontFamily: 'OpenSansBold',
            fontSize: Config.FONT_SIZE_MEDIUM,
            marginTop: Config.STANDARD_MARGIN,
            marginBottom: Config.STANDARD_MARGIN,
            textAlign: 'center',
            alignSelf: 'center',
          },
          h4: {
            fontFamily: 'OpenSansBold',
            fontSize: Config.FONT_SIZE_MEDIUM,
            marginTop: Config.STANDARD_MARGIN,
            marginBottom: Config.STANDARD_MARGIN,
            textAlign: 'center',
            alignSelf: 'center',
          },
          h5: {
            fontFamily: 'OpenSansBold',
            fontSize: Config.FONT_SIZE_NORMAL,
            marginTop: Config.STANDARD_MARGIN,
          },
          h6: {
            fontFamily: 'OpenSansBold',
            fontSize: Config.FONT_SIZE_NORMAL,
            marginTop: Config.STANDARD_MARGIN,
            marginBottom: Config.STANDARD_MARGIN,
            textAlign: 'center',
            alignSelf: 'center',
          },
          blockquote: {
            fontFamily: 'OpenSansBold',
            fontSize: 18,
            lineHeight: 18 * 1.3,
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center',
            alignSelf: 'center',
          },
          ul: {
            marginTop: 5
          },
          li: {
            textAlign,
            fontSize: Config.FONT_SIZE_NORMAL,
            lineHeight: 1.5 * Config.FONT_SIZE_NORMAL,
          },
        },
        classesStyles: {
          'btn-primary': {
            backgroundColor: '#fba9b5',
            borderColor: '#fa91a0',
            borderWidth: 1,
            paddingHorizontal: 6,
            paddingVertical: 10,
            textDecorationLine: 'none'
          },
          'hide-on-app': {
            display: 'none'
          }
      }};
      return (
        <View style={[{ justifyContent: align,
          alignItems: align,
          alignSelf: align }, this.props.style]}>
          {this.props.collapse && !this.state.descriptionOpen && this.state.descriptionLong ?
            <View>
              <HTML
                html={desc}
                onLinkPress={this.onLinkPress}
                {...extraProps}
              />
              <TouchableOpacity onPress={() => {
                this.setState({ descriptionOpen: true });
              }}>
                <Text
                  style={Styles.boldLink}>{desc ? "Ausklappen »" : ""}</Text>
              </TouchableOpacity>
            </View> : <View >
              <HTML
                html={text}
                onLinkPress={this.onLinkPress}
                alterChildren = {this.alterChildren}
                alterNode={this.alterNode}
               {...extraProps}
              />
              {this.props.collapse && this.state.descriptionLong ?
                <TouchableOpacity
                 onPress={() => {
                  this.setState({ descriptionOpen: false });
                }}>
                  <Text
                    style={Styles.boldLink}>{desc ? "« Einklappen" : ""}</Text>
                </TouchableOpacity> : null}
            </View>
          }

        </View>
      );
    }
    else return(
      <View style={[{justifyContent: align}, this.props.style]}>
      </View>
    );
  }
}



export default CollapsilbleText;
