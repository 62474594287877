import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import flattenAddress from "./helpers/flattenAddress";

const initialState = {
  addresses: {},
  isLoadingShippingAddress: false,
  isLoadingBillingAddress: false,
  shippingAddresses: [],
  billingAddresses: [],
  addressSave: false
};

function getShippingAddress(state) {
  return {
    ...state,
    isLoadingShippingAddress: false,
  };
}

function getShippingAddressSuccess(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      ...action.payload.data.reduce((object, item) => {
        object[item.id] = flattenAddress(item);
        object[item.id].self = action.payload.self.href + '/' + item.id;
        return object;
      }, {}),
    },
    isLoadingShippingAddress: false,
    shippingAddresses: action.payload.data.map(item => item.id),
    shippingAddressCount: action.payload.count
  };
}

function getShippingAddressFail(state, action) {
  return {
    ...state,
    isLoadingShippingAddress: false
  };
}

function getBillingAddress(state) {
  return {
    ...state,
    isLoadingBillingAddress: true,
  };
}

function getBillingAddressSuccess(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      ...action.payload.data.reduce((object, item) => {
        object[item.id] = flattenAddress(item);
        object[item.id].self = action.payload.self.href + '/' + item.id;
        return object;
      }, {}),
    },
    billingAddresses: action.payload.data.map(item => item.id),
    isLoadingBillingAddress: false,
    billingAddressCount: action.payload.count
  };
}

function getBillingAddressFail(state) {
  return {
    ...state,
    isLoadingBillingAddress: false
  };
}

function addressCreate(state, action) {
  return {
    ...state,
    addressSave: false
  };
}

function addressCreateSuccess(state, action) {
  return {
    ...state,
    addressSave: true,
    lastAddressId: action.payload.id
  };
}

function addressCreateFail(state, action) {
  return {
    ...state,
  };
}

function addressDelete(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.id]: {
        ...state.addresses[action.payload.id],
        isDeleted: true,
      },
    },
  };
}

function addressDeleteSuccess(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.id]: null,
    },
    billingAddresses: state.billingAddresses.filter(item => item.id != action.payload.id),
    shippingAddresses: state.shippingAddresses.filter(item => item.id != action.payload.id),
  };
}

function addressDeleteFail(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.id]: {
        ...state.addresses[action.payload.id],
        isDeleted: null,
      },
    },
  };
}

function addressUpdate(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.id]: {
        ...state.addresses[action.payload.id],
        isUpdated: true,
      },
    },
    addressSave: false
  };
}

function addressUpdateSuccess(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.data[0].id]: {
        ...flattenAddress(action.payload.data[0]),
        self: action.payload.self.href,
      },
    },
    addressSave: true
  };
}

function addressUpdateFail(state, action) {
  return {
    ...state,
    addresses: {
      ...state.addresses,
      [action.payload.id]: {
        ...state.addresses[action.payload.id],
        isUpdated: null,
      },
    },
  };
}

function profileSelect(state, action) {
  return {
    ...state,
  };
}

function profileSelectSuccess(state, action) {
  return {
    ...state,
  };
}

function profileSelectFail(state, action) {
  return {
    ...state,
  };
}

function profileSetDefault(state, action) {
  return {
    ...state,
  };
}

function profileSetDefaultSuccess(state, action) {
  return {
    ...state,
  };
}

function profileSetDefaultFail(state, action) {
  return {
    ...state,
  };
}

const address = createReducer(initialState, {
  [ActionTypes.GET_SHIPPING_ADDRESS]: getShippingAddress,
  [ActionTypes.GET_SHIPPING_ADDRESS_SUCCESS]: getShippingAddressSuccess,
  [ActionTypes.GET_SHIPPING_ADDRESS_FAIL]: getShippingAddressFail,
  [ActionTypes.GET_BILLING_ADDRESS]: getBillingAddress,
  [ActionTypes.GET_BILLING_ADDRESS_SUCCESS]: getBillingAddressSuccess,
  [ActionTypes.GET_BILLING_ADDRESS_FAIL]: getBillingAddressFail,
  [ActionTypes.ADDRESS_CREATE]: addressCreate,
  [ActionTypes.ADDRESS_CREATE_SUCCESS]: addressCreateSuccess,
  [ActionTypes.ADDRESS_CREATE_FAIL]: addressCreateFail,
  [ActionTypes.ADDRESS_DELETE]: addressDelete,
  [ActionTypes.ADDRESS_DELETE_SUCCESS]: addressDeleteSuccess,
  [ActionTypes.ADDRESS_DELETE_FAIL]: addressDeleteFail,
  [ActionTypes.ADDRESS_UPDATE]: addressUpdate,
  [ActionTypes.ADDRESS_UPDATE_SUCCESS]: addressUpdateSuccess,
  [ActionTypes.ADDRESS_UPDATE_FAIL]: addressUpdateFail,
  [ActionTypes.PROFILE_SELECT]: profileSelect,
  [ActionTypes.PROFILE_SELECT_SUCCESS]: profileSelectSuccess,
  [ActionTypes.PROFILE_SELECT_FAIL]: profileSelectFail,
  [ActionTypes.PROFILE_SET_DEFAULT]: profileSetDefault,
  [ActionTypes.PROFILE_SET_DEFAULT_SUCCESS]: profileSetDefaultSuccess,
  [ActionTypes.PROFILE_SET_DEFAULT_FAIL]: profileSetDefaultFail,
});

export default address;

