import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import * as Config from '../Config';

const initialState = {
  isLoading: false,
  userInfo: null,
  username: null,
  password: null,
  token: null,
  uid: null,
  logout: null,
  user: {},
  isUserUpdating: false,
  userUpdateSucceeded: false,
  loginErrorMessage: null,
  userUpdateFailureMessage: null,
  registerErrorMessage: null,
  registerSuccessMessage: null,
  switchRegisterLogin: false,
  switchForgotPasswordLogin: false,
  usernameValidationMessage:'',
  emailValidationMessage:'',
  forgotPasswordErrorMessage: null,
  appleConnectType: '',
  appleConnectMessage: null,
  googleConnectType: '',
  googleConnectMessage: null,
};

function whoAmI(state, action) {
  return{
    ...state,
    isLoading: typeof action.payload == 'boolean' ? action.payload : true,
    initial: typeof action.payload != 'boolean' ? action.payload : null,
  };
}

function getUserAttributes(user) {
  return {
    agreeSocial: user.agree_social,
    birthday: user.birthday,
    blog: user.blog,
    created: user.created,
    description: user.description,
    etsy: user.etsy,
    facebook: user.facebook,
    followed: user.followed,
    followers: user.followers,
    following: user.following,
    id: user.id,
    instagram: user.instagram,
    label: user.label,
    mail: user.mail,
    newsletter: user.newsletter,
    notifyComment: user.notify_comment,
    notifyMention: user.notify_mention,
    notifyNode: user.notify_node,
    notifyPm: user.notify_pm,
    notifyReminder: user.notify_reminder,
    pictureUri: user.picture && user.picture.uri ? user.picture.uri : Config.DEFAULT_AVATAR,
    pinterest: user.pinterest,
    self: user.self,
    textFormat: user.text ? user.text.format : null,
    textSafeValue: user.text ? user.text.safe_value : null,
    textValue: user.text ? user.text.value : null,
    uid: user.id,
    uuid: user.uuid,
    username: user.username,
    weeklyReminder: user.weekly_reminder,
  }
}

function whoAmISuccess(state, action) {
  let user = getUserAttributes(action.payload.data[0]);
  return{
    ...state,
    isLoading: false,
   ...user,
  };
}

function whoAmIFail(state) {
  return{
    ...state,
    isLoading: false,
    uid: 0,
  };
}

function getTokenInit(state) {
  return{
    ...state,
  };
}

function getTokenInitSuccess(state, action) {
  return{
    ...state,
    token: action.payload['X-CSRF-Token'],
    isLoading: false,
  };
}

function getTokenFail(state) {
  return{
    ...state,
    token: false,
    isLoading: false,
  };
}

function loginInit(state) {
  return{
    ...state,
    isLoading: true,
    loginErrorMessage: null,
  };
}

function loginSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    token: action.payload.data[0]['X-CSRF-Token'],
    uid: action.payload.data[0].id,
    id: action.payload.data[0].id,
    loginErrorMessage: null,
  };
}

function loginFail(state) {
  return {
    ...state,
    isLoading: false,
    uid: 0,
    loginErrorMessage: 'Der Benutzername oder das Passwort sind nicht korrekt.',
  };
}

function userRegister(state) {
  return{
    ...state,
    isLoading: true,
    registerErrorMessage: null,
    switchRegisterLogin: false,
  };
}

function userRegisterSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    registerErrorMessage: null,
    switchRegisterLogin: true,
    registerSuccessMessage: 'Danke für deine Registrierung. Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos zugeschickt. Bitte klicke auf den Link in der E-Mail, um die Registrierung abzuschließen.'
  };
}

function userRegisterFail(state, action) {
  return {
    ...state,
    isLoading: false,
    uid: 0,
    registerErrorMessage: action.payload.text,
    switchRegisterLogin: false
  };
}

function logout() {
  return{
    logout: true,
  };
}

function logoutSuccess(state) {
  return state;
}

function logoutFail(state) {
  return state;
}

function editUser(state) {
  return {
    ...state,
    isUserUpdating: true,
    userUpdateSucceeded: false,
    userUpdateFailureMessage: null,
  };
}

function editUserSuccess(state, action) {
  let user = getUserAttributes(action.payload[0]);
  return {
    ...state,
    ...user,
    isUserUpdating: false,
    userUpdateSucceeded: true,
    userUpdateFailureMessage: null,
  };
}

function editUserFail(state, action) {
  return {
    ...state,
    isUserUpdating: false,
    userUpdateSucceeded: false,
    userUpdateFailureMessage: action.payload.text,
  };
}

function errorReceived(state) {
  return{
    ...state,
    isUserUpdating: false,
    isLoading: false,
  };
}

function forgotPassword(state) {
  return{
    ...state,
    isLoading: true,
    forgotPasswordErrorMessage: null,
    switchForgotPasswordLogin: false,
  };
}

function forgotPasswordSuccess(state, action) {
  return {
    ...state,
    isLoading: false,
    forgotPasswordErrorMessage: null,
    switchForgotPasswordLogin: true,
    forgotPasswordSuccessMessage: 'Danke für deine Registrierung. Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos zugeschickt. Bitte klicke auf den Link in der E-Mail, um die Registrierung abzuschließen.'
  };
}

function forgotPasswordFail(state, action) {
  return {
    ...state,
    isLoading: false,
    forgotPasswordErrorMessage: 'Die E-Mail-Adresse ist unbekannt. Du kannst sie verwenden, um dich zu registrieren.',
    switchForgotPasswordLogin: false
  };
}

function usernameValidation(state) {
  return{
    ...state,
  };
}

function usernameValidationSuccess(state, action) {
  return {
    ...state,
    usernameValidationMessage: ''
  };
}

function usernameValidationFail(state, action) {
  return {
    ...state,
    usernameValidationMessage: action.payload[0].body.msg
  };
}

function emailValidation(state) {
  return{
    ...state,
  };
}

function emailValidationSuccess(state, action) {
  return {
    ...state,
    emailValidationMessage: ''
  };
}

function emailValidationFail(state, action) {
  return {
    ...state,
    emailValidationMessage: action.payload.msg
  };
}

function resetIsLoading(state, action) {
  return{
    ...state,
    isLoading: (state.isLoading && action.payload.isInternetReachable && !state.isInternetReachable) ? false : state.isLoading,
    isInternetReachable: action.payload.isInternetReachable,
  };
}

function urlToNav(state, action) {
  return{
    ...state,
    initial: typeof action.payload != 'boolean' ? action.payload : null,
  };
}

function appleConnect(state, action) {
  return{
    ...state,
    appleConnectMessage: null,
    appleConnectSuccess: null,
    appleConnectType: action.payload.register ? 'register' : 'login'
  };
}

function appleConnectSuccess(state, action) {
  return{
    ...state,
    appleConnectSuccess: true
  };
}

function appleConnectFail(state, action) {
  return {
    ...state,
    appleConnectSuccess: false,
    appleConnectMessage: action.payload.message ?? 'Etwas ist schiefgelaufen.'
  };
}

function appleConnectRedirect(state, action) {
  return{
    ...state,
    appleConnectSuccess: null
  };
}

function googleConnect(state, action) {
  return{
    ...state,
    googleConnectMessage: null,
    googleCConnectSuccess: null,
    googleConnectType: action.payload.register ? 'register' : 'login'
  };
}

function googleConnectSuccess(state, action) {
  return{
    ...state,
    googleConnectSuccess: true
  };
}

function googleConnectFail(state, action) {
  return {
    ...state,
    googleConnectSuccess: false,
    googleConnectMessage: action.payload.message ?? 'Etwas ist schiefgelaufen.'
  };
}

const user = createReducer(initialState, {
  [ActionTypes.WHO_AM_I]: whoAmI,
  [ActionTypes.WHO_AM_I_SUCCESS]: whoAmISuccess,
  [ActionTypes.WHO_AM_I_FAIL]: whoAmIFail,
  [ActionTypes.GET_TOKEN_INIT]: getTokenInit,
  [ActionTypes.GET_TOKEN_SUCCESS]: getTokenInitSuccess,
  [ActionTypes.GET_TOKEN_FAIL]: getTokenFail,
  [ActionTypes.LOGIN_INIT]: loginInit,
  [ActionTypes.LOGIN_SUCCESS]: loginSuccess,
  [ActionTypes.LOGIN_FAIL]: loginFail,
  [ActionTypes.LOGOUT]: logout,
  [ActionTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [ActionTypes.LOGOUT_FAIL]: logoutFail,
  [ActionTypes.EDIT_USER]: editUser,
  [ActionTypes.EDIT_USER_SUCCESS]: editUserSuccess,
  [ActionTypes.EDIT_USER_FAIL]: editUserFail,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
  [ActionTypes.USER_REGISTER]: userRegister,
  [ActionTypes.USER_REGISTER_SUCCESS]: userRegisterSuccess,
  [ActionTypes.USER_REGISTER_FAIL]: userRegisterFail,
  [ActionTypes.FORGOT_PASSWORD]: forgotPassword,
  [ActionTypes.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [ActionTypes.FORGOT_PASSWORD_FAIL]: forgotPasswordFail,
  [ActionTypes.USERNAME_VALIDATION]: usernameValidation,
  [ActionTypes.USERNAME_VALIDATION_SUCCESS]: usernameValidationSuccess,
  [ActionTypes.USERNAME_VALIDATION_FAIL]: usernameValidationFail,
  [ActionTypes.EMAIL_VALIDATION]: emailValidation,
  [ActionTypes.EMAIL_VALIDATION_SUCCESS]: emailValidationSuccess,
  [ActionTypes.EMAIL_VALIDATION_FAIL]: emailValidationFail,
  [ActionTypes.STORE_CONNECTIVITY_INFO]: resetIsLoading,
  [ActionTypes.URL_TO_NAV]: urlToNav,
  [ActionTypes.APPLE_CONNECT_SUCCESS]: appleConnectSuccess,
  [ActionTypes.APPLE_CONNECT_FAIL]: appleConnectFail,
  [ActionTypes.APPLE_CONNECT]: appleConnect,
  [ActionTypes.APPLE_CONNECT_REDIRECT]: appleConnectRedirect,
  [ActionTypes.GOOGLE_CONNECT_SUCCESS]: googleConnectSuccess,
  [ActionTypes.GOOGLE_CONNECT_FAIL]: googleConnectFail,
  [ActionTypes.GOOGLE_CONNECT]: googleConnect,
});

export default user;
