import React from 'react';
import { createAppContainer } from 'react-navigation';
import {CardStyleInterpolators, createStackNavigator} from 'react-navigation-stack';
import Colors from '../constants/Colors';
import MainTabNavigation from './MainTabNavigation';
import Webpage from '../containers/Webpage';
import CommonStack from "./CommonStack";

const RootStack = createStackNavigator(
  {
    MainTab: {
      screen: MainTabNavigation,
      navigationOptions: CommonStack.navigationOptions
    },
    Webpage: {
      screen: Webpage,
      navigationOptions: CommonStack.navigationOptions
    },
  },
  {
    headerMode: 'none'
  }
);

export default createAppContainer(RootStack);
