import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';
import ModalDropdown from 'react-native-modal-dropdown';
import { themes, themesKeyed } from "../constants/variationThemes";
import { values } from "lodash";

/**
 * Calculates and renders the variation selector for use on the commerce PDP.
 */
export default class CommerceVariantSelector extends PureComponent {

  render() {
    const { commerceProducts, commerceProductsById, node, selectedProduct, selectedProductSet } = this.props;

    if (!commerceProductsById || !commerceProductsById[node.dbsParent] || !selectedProduct) {
      return(
        <View />
      );
    }
    // Extract all variation theme field values in one big object of objects.
    // - keys are the theme keys
    // - values are the available options for the theme key. an object with:
    //   - keys the product id
    //   - values the theme value
    //
    // The following rule apply during the extraction:
    // - 1. Only one value per theme.
    // - 2. The themes are ordered.
    // - 3. Available values on each theme depend on the selected values in
    //   previous themes. For example:
    //   - First theme shows all values and has one selected value.
    //   - Second theme only show options that have the previous selected value.
    //   - Etc, Etc.
    const data = commerceProductsById[node.dbsParent].reduce((object, product_id) => {
      if (commerceProducts[product_id]) {
        themes
          .filter(theme => commerceProducts[product_id][theme.key])
          .map((theme, index, array) => {
            object[theme.key] = object[theme.key] || {};
            // 1. Only one value per theme.
            if (Object.values(object[theme.key]).indexOf(commerceProducts[product_id][theme.key]) == -1) {
              const selectedProductObj = commerceProducts[selectedProduct];
              const currentProductObj = commerceProducts[product_id];
              // 3. Available values on each theme depend on the selected values in
              //    previous theme. This means that the current product can only
              //    be used under this theme only if it has the same value in all
              //    previous (aka smaller weight) themes.
              const notMatched = array
                .filter((previousTheme) => 
                  themesKeyed[previousTheme.key].weight < themesKeyed[theme.key].weight
                  && selectedProductObj[previousTheme.key] != currentProductObj[previousTheme.key]
                );
              // If there were themes with other values we do not use the product.
              if (!notMatched.length) {
                object[theme.key][product_id] = commerceProducts[product_id][theme.key];
              }
            }
          });
      }
      return object;
    }, {});

    return (
      <View >
        { Object.keys(data)
          // Filter themes with only one option.
          .filter(theme => values(data[theme]).length > 1)
          // 2. The themes are ordered.
          .sort((a, b) => themesKeyed[a].weight -  themesKeyed[b].weight)
          .map((key) => (
            <View key={key} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Text style={{ paddingRight: 10, paddingTop: 11, paddingBottom: 9, fontSize: 18 }}>{themesKeyed[key].label}:</Text>
              <ModalDropdown
                textStyle={{ fontSize: 16, flex: 1 }}
                isFullWidth
                options={values(data[key]).sort((a, b) => a.localeCompare(b, 'de'))}
                defaultValue={data[key][this.props.selectedProduct]}
                dropdownStyle={{ marginTop: 10, left: 10, height: -1, maxHeight: 200, borderColor: '#5c5c5c' }}
                dropdownTextStyle={{ fontSize: 14, color: '#000000', padding: 10 }}
                style={{ flex: 1, height: 46, padding: 10, paddingRight: 0, textAlign: 'center', justifyContent: 'center', color: '#000000' }}
                onSelect={(idx, itemValue) => selectedProductSet(Object.keys(data[key]).find(id => data[key][id] === itemValue))}
                renderRightComponent={() => <Text style={{fontSize: 18}}>▼</Text>}
              />
            </View>
        ))}
      </View>
    );
  }
}
