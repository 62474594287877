import React, { Component } from 'react';
import { View, Text } from 'react-native';
import ImagesList from "./ImagesList";
import CustomTextInput from "./CustomTextInput";
import * as Config from "../Config";
import EmptySearchScreenComponent from './EmptySearchScreenComponent';
import Styles from "../constants/Styles";

class SearchScreenComponent extends Component {

  state = {
    path: 'suche',
    searchTerm: this.props.navigation.getParam('keyword', ''),
    submitted: false
  };

  onPressSearchTerm = (term) => {
    this.setState({
      path: 'suche&keywords=' + term,
      searchTerm: term,
    });
  };

  componentDidMount() {
    if (this.props.navigation.getParam('preFill', false)) {
      this.props.navigation.setParams({ preFill: false });
      setTimeout(this.fetchResults, 100);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.navigation.getParam('doubleClicked', false)) {
      this.setState({
        searchTerm: null,
        path: 'suche',
      });
    }
    if (this.props.navigation.getParam('preFill', false)) {
      this.props.navigation.setParams({ preFill: false });
      setTimeout(this.fetchResults, 100);
    }
  }

  fetchResults = () => {
    this.setState(
      {
        searchTerm: this.props.navigation.getParam('keyword', 0),
        path: 'suche&keywords=' + this.props.navigation.getParam('keyword', 0),
        submitted: true
      },
      this.props.viewGet({
        path: 'suche&keywords=' + this.props.navigation.getParam('keyword', 0),
        view: 'search_views_main_search_',
        display: 'panel_pane_1',
        page: 0,
        type: this.props.navigation.getParam('type', 'image')
      })
    );
  }

  render()  {
    const menus = [
      { title: 'Bilder', path: this.state.path, view:'search_views_main_search_', display: 'panel_pane_1', type: 'image' },
      { title: 'Artikel', path: this.state.path, view:'search_views_main_search_', display: 'panel_pane_1', type: 'article' },
      { title: 'Produkte', path: this.state.path, view:'search_views_main_search_', display: 'panel_pane_1', type: 'product' },
    ];
    return (
      <View style={{flex: 1}}>
        <ImagesList
          keyboardShouldPersistTaps={"handled"}
          data={this.props.data}
          nodesById={this.props.nodesById}
          isLoading={this.props.isLoading}
          likeNode={this.props.likeNode}
          list={this.props.list}
          menus={menus}
          menuPosition={'center'}
          navigation={this.props.navigation}
          viewGet={this.props.viewGet}
          header={
            <View style={{marginBottom: Config.STANDARD_MARGIN,  marginHorizontal: 2}}>
              <CustomTextInput
                label='Suchbegriff eingeben'
                onChangeText={(searchTerm) => this.setState({searchTerm: searchTerm, submitted: false })}
                value={this.state.searchTerm}
                returnKeyType='search'
                clearButtonMode="always"
                onSubmitEditing = {() => {
                  this.setState({ path: 'suche&keywords=' + this.state.searchTerm });
                  this.props.viewGet({
                    path: 'suche&keywords=' + this.state.searchTerm,
                    view: 'search_views_main_search_',
                    display: 'panel_pane_1',
                    page: 0,
                    type: this.props.navigation.getParam('type', 'image'),
                  });
                  this.setState({ submitted: true });
                }}
                style={{marginTop: 0}}
              />
              {this.state.submitted && this.state.searchTerm ?
                <Text style={[Styles.boldLink, {marginTop: 10}]}>"{this.state.searchTerm}"</Text> :
                null
              }
            </View>
          }
          emptyListComponent={(this.props.isLoading ) ? null :
            <EmptySearchScreenComponent onPressSearchTerm={this.onPressSearchTerm} />
          }
          pagination={this.props.pagination}
          isLikingNode={this.props.isLikingNode}
        />
      </View>
    );
  }
}

export default SearchScreenComponent;
