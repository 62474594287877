import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import RegisterScreen from '../containers/RegisterScreen';
import UserScreenComponent from '../components/UserScreenComponent';
import {
  followUser,
  likeNode,
  logout,
  viewGet,
  whoAmI,
} from "../actions/index";

class OwnUserScreen extends React.Component {
  render() {
    if (!this.props.logInState.uid) {
      return (
        <RegisterScreen navigation={this.props.navigation}/>
      );
    }
    return (
      <View style={{flex:1}}>
        <UserScreenComponent
          data={this.props.data}
          isGettingUsersContent={this.props.isGettingUsersContent}
          isGettingUsersComments={this.props.isGettingUsersComments}
          followUser={this.props.followUser}
          isLoading={this.props.isLoading}
          likeNode={this.props.likeNode}
          list={this.props.list}
          navigation={this.props.navigation}
          nodesById={this.props.nodesById}
          logInState={this.props.user}
          user={this.props.user}
          viewGet={this.props.viewGet}
          isSendingComment={this.props.isSendingComment}
          usersContent={this.props.usersContent}
          usersComments={this.props.usersComments}
          pagination={this.props.pagination}
          isLikingNode={this.props.isLikingNode}
          onRefresh={this.onRefresh}
        />
      </View>
    );
  }
  onRefresh = () => {
    this.props.whoAmI(false);
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.images.nodes,
    isLoading: state.images.isLoading,
    isLikingNode: state.images.isLikingNode,
    isSendingComment: state.comments.isSendingComment,
    list: state.images,
    logInState: state.user,
    nodesById: state.images.nodesById,
    pagination: state.pagination,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    followUser: (payload) => {
      dispatch(followUser(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    logout: (payload) => {
      dispatch(logout(payload))
    },
    whoAmI: (payload) => {
      dispatch(whoAmI(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnUserScreen);
