import React, {Component} from 'react';
import {
  FlatList,
  Keyboard,
  Platform,
  View
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import PrivateMessageLine from '../components/PrivateMessageLine';
import CustomTextArea from "./CustomTextArea";
import Layout from "../constants/Layout";
import * as Config from "../Config";
import CustomButton from './general/CustomButton';

class UserDiscussionScreenComponent extends Component {
  state = {
    text: '',
    inputFocused: false
  };

  onInputFocus = () => {
    //when the keyboard shows the extraHeight goes at the end of the list - but
    //our list is inverted so I am using this to manually add space
    this.setState({inputFocused: true});
  };

  onInputBlur = () => {
    this.setState({inputFocused: false})
  };

  submitPrivateMessage = () => {
    EventRegister.emit('analyticEvent', {
      category: 'UserDiscussion',
      action: 'Send private message',
      label: 'User sends a private message'
    });
    Keyboard.dismiss();
    this.props.postPM({
      body: this.state.text,
      uid: this.props.recipient_id,
      token: this.props.logInState.token,
    });
    this.setState({text: ''});
  };

  renderItem = (data) => (
    <PrivateMessageLine
      navigation={this.props.navigation}
      item={data.item}
      trimBody={false}
      logInStateId={this.props.logInState.id}
    />
  );

  handleContentSizeChange = () => {
    this.flatlist.scrollToEnd({ animated: false });
  };

  render() {
    let rows = [];
    const listOfElements = this.props.inbox.allMessagesPerUserById[this.props.recipient_id];
    if (listOfElements && listOfElements.length) {
      listOfElements.forEach(element => rows.push(this.props.inbox.allMessages[element]));
    }
    rows.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    return (
      <View style={{flex: 1, backgroundColor: Colors.background}}>
        <FlatList
          inverted
          keyboardShouldPersistTaps={"handled"}
          contentContainerStyle={Styles.pageScrollViewContainer}
          horizontal={false}
          backgroundColor={Colors.background}
          data={rows}
          keyExtractor={data => data.id}
          ListHeaderComponent={
            <View style={{flex: 1}}>
              <View style={{flex: 1, minWidth: Layout.half.width-2*Config.STANDARD_MARGIN}}>
                <CustomTextArea
                  onChangeText={(text) => this.setState({text})}
                  value={this.state.text}
                  label='Nachricht schreiben'
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                />
                <CustomButton
                  title="Senden"
                  onPress={this.submitPrivateMessage}
                  buttonStyle={{marginBottom: Config.STANDARD_MARGIN}}
                  loading={this.props.inbox.isPosting}
                  disabled={this.props.inbox.isPosting}
                />
              </View>
            </View>
          }
          renderItem={this.renderItem}
        />
      </View>
    )
  }
}

export default UserDiscussionScreenComponent;
