import React, {Component} from 'react';
import {Platform, View, Text} from 'react-native';
import remoteConfig from '@react-native-firebase/remote-config';

import CustomIndicator from "../components/CustomIndicator";
import CustomFlatlistItem from './CustomFlatlistItem';
import CustomFlatlistMenu from './CustomFlatlistMenu';
import PlainFlatlist from './generalFlatlist/plainFlatlist';
import screenNames from '../constants/screenNames';
import Layout from "../constants/Layout";
import { askForAdMobPermissions } from "../helpers/adMob"

class ImagesList extends Component {

  state = {
    backToTopVisible: false,
    commentWasSent: false,
    display: this.props.menus[this.props.navigation.getParam('showMenu', 0)].display,
    page: 0,
    path: this.props.menus[this.props.navigation.getParam('showMenu', 0)].path,
    refreshing: false,
    type: this.props.menus[this.props.navigation.getParam('showMenu', 0)].type,
    view: this.props.menus[this.props.navigation.getParam('showMenu', 0)].view,
    adBannerVisible: false, // Used to show the adBanner on scrolling
    showAdBanner: true, // Used to hide the adBanner when the close button is pressed.
    servePersonalizedAds: false,
    adCloseButton: false // Used to show the adCloseButton when an adBlock is received
  };

  handleFlatlistRef = ref => this.listRef = ref;

  shouldComponentUpdate (nextProps, nextState) {
    if ( this.state.path !== nextState.path
      || this.state.backToTopVisible !== nextState.backToTopVisible
      || this.state.adBannerVisible !== nextState.adBannerVisible
      || this.state.showAdBanner !== nextState.showAdBanner
      || this.state.refreshing !== nextState.refreshing
      || this.state.servePersonalizedAds !== nextState.servePersonalizedAds
      || this.state.adCloseButton !== nextState.adCloseButton
      || this.props.isLoading !== nextProps.isLoading
      || this.props.header !== nextProps.header
      || (!this.props.navigation.isFocused() && nextProps.navigation.isFocused())
      || this.props.nodesById.length !== nextProps.nodesById.length
      || this.props.isLikingNode !== nextProps.isLikingNode
      || ((this.props.navigation.state.params || nextProps.navigation.state.params)
         && this.props.navigation.state.params !== nextProps.navigation.state.params)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { path } = this.state;
    const menuNumber = this.props.navigation.getParam('showMenu', 0);
    //internal state is not being updated when keyword changes so page is wrong
    if (prevProps.menus[0].path !== this.props.menus[0].path) {
      this.setState({path: this.props.menus[0].path}, () => this.viewGet());
    }
    // refresh kommentiert & geherzt page after a comment
    if(prevProps.isSendingComment && !this.props.isSendingComment){
      this.setState({commentWasSent: true})
    }
    if(this.props.navigation.state.routeName == 'Me' && this.state.path.indexOf('geherztkommentiert' > -1) && this.state.commentWasSent) {
      this.viewGet({page: 0 });
      this.setState({commentWasSent: false})
    }

    if (this.props.navigation.getParam('doubleClicked', false) || this.props.navigation.getParam('redirectToNeu', false)) {
      // Reset doubleClicked parameter to fetch new content only once.
      this.props.navigation.setParams({ doubleClicked: false, redirectToNeu: false });
      // Scroll to top.
      if (this.listRef) {
        this.listRef.scrollToOffset({offset: 0, animated: true})
      }
      if (!this.props.navigation.getParam('redirectToNeu', false)) {
        // Reset masonry and refetch.
        this.viewGet({
          page: 0,
          path: (this.state.path !== screenNames.neu) ? screenNames.neu : this.state.path,
          refreshing: true
        });
      }
    }

    if (this.state.refreshing === true) {
      setTimeout(() => this.setState({refreshing: false}), 500);
    }

    // If fetching was just reset because of the network being just reconnected.
    if ( this.props.pagination.fetching[path] === undefined && prevProps.pagination.fetching[path] !== undefined) {
      this.onEndReached();
    }

    if (menuNumber && prevProps.navigation.getParam('showMenu') !== menuNumber) {
      this.setState({
        display: this.props.menus[menuNumber].display,
        path: this.props.menus[menuNumber].path,
        type: this.props.menus[menuNumber].type,
        view: this.props.menus[menuNumber].view,
      });
      this.onPressMenu();
    }
  }

  componentDidMount () {
    if (this.state.path !== 'suche') {
      this.viewGet();
    }
    askForAdMobPermissions().then(result => this.setState({servePersonalizedAds: result }))
  }

  onRefresh = () => {
    this.viewGet({ page: 0, refreshing: true });
    if (this.props.updateBigBoxSlide) {
      this.props.updateBigBoxSlide();
    }
    // If parent component is passing the same method call it.
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  };

  // Fetch more content from next pages if conditions are met.
  onEndReached = () => {
     // Avoid doing so if instructed from the parent component.
    if (this.props.onlyLoadFirstPage) {
      return;
    }
    const { page, path, view } = this.state;
    if (this.props.list[path + "ById"]
      // Check if current/last page has finished fetching.
      && (this.props.pagination.fetching[path] === undefined || this.props.pagination.fetching[path] === -1)
      // And that it has content.
      && this.props.pagination[path]
      && this.props.pagination[path][page]
      && this.props.pagination[path][page].length) {
        this.viewGet({ page: page + 1 });
    }
  };

  onPressMenu = (menu) => {
    this.viewGet({ ...menu, page: 0, refreshing: true });
    if (this.props.updateBigBoxSlide) {
      this.props.updateBigBoxSlide();
    }
  };

  onPressCloseAdBanner = () => {
    this.setState({showAdBanner: false, adCloseButton: false})
  }

  showAdCloseButton = () => {
    this.setState({adCloseButton: true})
  }

  viewGet = (params) => {
    // set stage and trigger action.
    this.setState(params, () => {
      this.props.viewGet({
        display: this.state.display,
        keywords: this.props.menus[0].keywords,
        page: this.state.page,
        path: this.state.path,
        type: this.state.type,
        view: this.state.view,
        ...params
      });
    });
  };

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - (2 * Layout.window.height);
  };

  handleScroll = ({nativeEvent}) => {
    if (nativeEvent.contentOffset.y > 1500) {
      if (!this.state.backToTopVisible) {
        this.setState({backToTopVisible: true});
      }
      if (!this.state.adBannerVisible && this.state.showAdBanner) {
        if (remoteConfig().getValue('ads').asString() == 'ON') {
          this.setState({adBannerVisible: true});
        }
      }
    }
    else {
      if (this.state.backToTopVisible) {
        this.setState({ backToTopVisible: false });
      }
    }
    if (this.isCloseToBottom(nativeEvent)) {
      this.onEndReached();
    }
  };

  renderItem = (data) =>
    <CustomFlatlistItem
      {...this.props}
      state={this.state}
      data={data}
      isLikingNode={this.props.isLikingNode}
    />;

  render() {
    const { path } = this.state;
    const { list, data } = this.props;
    const nodes = (list && list[path + 'ById'])
      ? list[path + 'ById'].filter(id => data[id] && !data[id].blocked).map(id => data[id])
      : [];

    return (
      <PlainFlatlist
        reference={this.handleFlatlistRef}
        onScroll={this.handleScroll}
        containerWidth={this.props.containerWidth}
        nodes={nodes}
        header={this.props.header}
        listHeaderComponent={
          <View style={{ width: Layout.containedWidth }}>
            {this.props.header}
            <CustomFlatlistMenu
              {...this.state}
              menus={this.props.menus}
              updateBigBoxSlide={this.props.updateBigBoxSlide}
              onPressMenu={this.onPressMenu}
              position={this.props.menuPosition}
              scrollToStart={this.props.navigation.state.params
              && (this.props.navigation.state.params.doubleClicked
              || this.props.navigation.state.params.redirectToNeu)}
            />
          </View>
        }
        onRefresh={this.onRefresh}
        renderItem={this.renderItem}
        listEmptyComponent={
          <View style={{ width: Layout.containedWidth }}>
            {this.props.emptyListComponent}
          </View>
        }
        backToTopVisible={this.state.backToTopVisible}
        listRef={this.listRef}
        isLoading={this.props.isLoading || this.state.refreshing}
        onPressCloseAdBanner={this.onPressCloseAdBanner}
        adBannerVisible={this.state.adBannerVisible}
        showAdBanner={this.state.showAdBanner}
        servePersonalizedAds={this.state.servePersonalizedAds}
        adCloseButton={this.state.adCloseButton}
        showAdCloseButton={this.showAdCloseButton}
      />
    );
  }
}


export default ImagesList
