import { Video } from 'expo-av';
import {View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";
import React,  {Component} from "react";


export default class VideoComponent extends Component{
  state={
    mute: false,
    fullScreen: false,
    shouldPlay: false,
  };

  handlePlayAndPause = () => {
    this.setState(prevState => ({
      shouldPlay: !prevState.shouldPlay
    }));
  };

  handleVolume = () => {
    this.setState(prevState => ({
      mute: !prevState.mute,
    }));
  };
  render(){
    return(
      <View style={{ width: this.props.full_width,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',marginBottom: 0,}} >
        <Video
          source={{ uri: this.props.node.src }}
          shouldPlay={this.state.shouldPlay}
          resizeMode="cover"
          style={{ alignSelf: 'center', height: 600, width: 400, }}
          isMuted={this.state.mute}
        />
        <View style={{
          zIndex:10,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 45,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: "rgba(0, 0, 0, 0.5)",}}>
          <MaterialIcons
            name={this.state.mute ? "volume-mute" : "volume-up"}
            size={45}
            color="white"
            onPress={this.handleVolume}
          />
          <MaterialIcons
            name={this.state.shouldPlay ? "pause" : "play-arrow"}
            size={45}
            color="white"
            onPress={this.handlePlayAndPause}
          />
        </View>
      </View>
    )
  }
}
