import React from 'react';
import { createMaterialTopTabNavigator } from 'react-navigation-tabs';
import Colors from '../constants/Colors';
import CustomTabBar from '../components/CustomTabBar';
import InboxScreen from '../containers/InboxScreen';
import NotificationScreen from '../containers/NotificationScreen';

export default createMaterialTopTabNavigator(
  {
    Notification: { screen: NotificationScreen },
    Inbox: { screen: InboxScreen },
  },
  {
    tabBarComponent: CustomTabBar,
    tabBarPosition: 'top',
    animationEnabled: true,
    swipeEnabled: true,
    tabBarOptions: {
      activeTintColor: Colors.iconSelected,
      inactiveTintColor: Colors.iconDefault,
      showLabel: true,
    },
    lazy: true,
  }
);
