export const Facets = [
  {
    hasParents: true,
    label: 'Farbe',
    machine_name: 'field_color_ranges',
    url_parameters: 'color_ranges',
    url_parameters_web: 'farbe',
    weight: 2,
  },
  {
    label: 'Hersteller',
    machine_name: 'field_brand_new',
    url_parameters: 'brand',
    url_parameters_web: 'marke',
    weight: 3,
  },
  {
    label: 'Shop',
    machine_name: 'author',
    url_parameters: 'author',
    url_parameters_web: 'shop',
    weight: 16,
  },
  {
    label: 'Preis',
    machine_name: 'field_price_range',
    url_parameters: 'price_range',
    url_parameters_web: 'preis2',
    weight: 1,
  },
  {
    label: 'typ',
    machine_name: 'type',
    url_parameters: 'type',
    url_parameters_web: 'typ',
    weight: 2,
    hide: true,
  },
  {
    label: 'Stuhlart',
    machine_name: 'field_product_reference:field_commerce_type_of_chairs',
    url_parameters: 'type_of_chairs',
    url_parameters_web: 'stuhlart',
    weight: 10,
  },
  {
    label: 'Material',
    machine_name: 'field_product_reference:field_commerce_material',
    url_parameters: 'material',
    url_parameters_web: '',
    weight: 6,
  },
  {
    label: 'Größe',
    machine_name: 'field_product_reference:field_commerce_size_margin',
    url_parameters: 'size_margin',
    url_parameters_web: 'grosse',
    weight: 8,
  },
  {
    label: 'Formen',
    machine_name: 'field_product_reference:field_commerce_shape',
    url_parameters: 'shape',
    url_parameters_web: 'formen',
    weight: 9,
  },
  {
    label: 'Sitzplätze',
    machine_name: 'field_product_reference:field_commerce_number_of_seat',
    url_parameters: 'number_of_seat',
    url_parameters_web: 'sitzplaetze',
    weight: 11,
  },
  {
    label: 'modulares System',
    machine_name: 'field_product_reference:field_commerce_modular_system',
    url_parameters: 'modular_system',
    url_parameters_web: 'modular',
    weight: 15,
  },
  {
    label: 'für wen?',
    machine_name: 'field_product_reference:field_commerce_for_whom',
    url_parameters: 'for_whom',
    url_parameters_web: 'fuer_wen',
    weight: 13,
  },
  {
    label: 'Merkmal',
    machine_name: 'field_product_reference:field_commerce_feature',
    url_parameters: 'feature',
    url_parameters_web: 'merkmal',
    weight: 12,
  },
  {
    label: 'Dimmbar',
    machine_name: 'field_product_reference:field_commerce_dimmable',
    url_parameters: 'dimmable',
    url_parameters_web: 'dimmbar',
    weight: 17,
  },
  {
    label: 'Designer',
    machine_name: 'field_product_reference:field_commerce_designer',
    url_parameters: 'designer',
    url_parameters_web: 'designer',
    weight: 5,
  },
  {
    label: 'Lieferzeit',
    machine_name: 'field_product_reference:field_commerce_delivery_time',
    url_parameters: 'delivery_time',
    url_parameters_web: 'leiferzeit',
    weight: 4,
  },
  {
    label: 'Kopfteil',
    machine_name: 'field_product_reference:field_commerce_bedhead',
    url_parameters: 'bedhead',
    url_parameters_web: 'kopfteil',
    weight: 14,
  },
  {
    label: 'Holzart',
    machine_name: 'field_product_reference:field_commerce_type_of_wood',
    url_parameters: 'type_of_wood',
    url_parameters_web: 'holzart',
    weight: 7,
  },
  {
    label: 'Marken- & nachhaltige Produkte',
    machine_name: 'author:field_shop_categories',
    oneline: true,
    url_parameters: 'shoptyp',
    url_parameters_web: 'shoptyp',
    weight: 18,
  },
  {
    label: 'Aktionen',
    machine_name: 'field_commerce_actions',
    oneline: true,
    url_parameters: 'actions',
    url_parameters_web: 'aktionen',
    weight: 19,
  }
];

export const facetIdToLabel = Facets.reduce((object, facet) => {
  object[facet.machine_name] = facet.label;
  return object;
}, {});

export const facetsKeyed = Facets.reduce((object, facet) => {
  object[facet.machine_name] = facet;
  return object;
}, {});

export const emptyFacets = Facets.reduce((object, facet) => {
  object[facet.machine_name] = [];
  return object;
}, {});
