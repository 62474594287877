import React, { PureComponent } from 'react';
import { TouchableOpacity } from 'react-native';
import * as Config from '../Config';
import { Icon } from '../components/Icon';
import Styles from '../constants/Styles';

export default class BackToTopButton extends PureComponent {
  render() {
    return (
      <TouchableOpacity
        onPress={() => { this.props.listRef.scrollToOffset({ offset: 0, animated: true }); }}
        style={Styles.buttonBottomCenterContainer}
      >
        <Icon
          name='up'
          size={Config.SIZE_ICON}
          color='black'
        />
      </TouchableOpacity>
    );
  }
}
