import React from 'react';
import MenuScreenComponent from "../components/MenuScreenComponent";
import { logout, menuGet } from '../actions/index';
import connect from 'react-redux/es/connect/connect';

class MenuScreen extends React.PureComponent {
  render() {
    return (
      <MenuScreenComponent
        logout={this.props.logout}
        menuGet={this.props.menuGet}
        menus={this.props.menus}
        navigation={this.props.navigation}
        uid={this.props.uid}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.user.uid,
    nodes: state.images.nodes,
    menus: state.general.menus,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    logout: (payload) => {
      dispatch(logout(payload))
    },
    menuGet: (payload) => {
      dispatch(menuGet(payload))
    },
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(MenuScreen);
