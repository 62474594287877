import React from 'react';
import {
  FlatList,
  RefreshControl, View,
} from 'react-native';
import { connect } from 'react-redux';
import Colors from '../constants/Colors';
import RegisterScreen from '../containers/RegisterScreen';
import PrivateMessageLine from '../components/PrivateMessageLine';
import { inboxGet } from "../actions/index";
import CustomIndicator from '../components/CustomIndicator';

class InboxScreen extends React.Component {

  componentDidMount () {
    this.props.inboxGet({});
  }

  onRefresh = () => {
    this.props.inboxGet({refreshing: true});
  };

  renderItem = (data) => {
    const { logInStateId, navigation } = this.props;
    if (data) return (
      <PrivateMessageLine
        navigation={navigation}
        item={data.item}
        trimBody={true}
        logInStateId={logInStateId}
      />
    );
  };

  render() {

    if (!this.props.logInStateId) {
      return (
        <RegisterScreen navigation={this.props.navigation}/>
      );
    }

    const { isLoading } = this.props;
    let inboxMessages = [];
    this.props.mostRecentPerUserMessagesIds.forEach(element =>
      inboxMessages.push(this.props.allMessages[element])
    );

    return (
      <FlatList
        contentContainerStyle={{margin:4}}
        horizontal={false}
        backgroundColor={Colors.background}
        keyExtractor={message => message.id}
        data={inboxMessages}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={this.onRefresh}
          />
        }
        renderItem={this.renderItem}
        ListEmptyComponent={
          <View>
            {(isLoading === undefined || isLoading) ? <CustomIndicator />:<View />}
          </View>
        }
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    logInStateId: state.user.id,
    isLoading: state.inbox.isLoading,
    allMessages: state.inbox.allMessages,
    mostRecentPerUserMessagesIds: state.inbox.mostRecentPerUserMessagesIds,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    inboxGet: (payload) => {
      dispatch(inboxGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxScreen);
