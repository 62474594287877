import React, { Component } from 'react';
import OrderHistoryScreenComponent from "../components/shopping/OrderHistoryScreenComponent";
import {connect} from "react-redux";
import {ordersGet} from "../actions";


class OrderHistoryScreen extends Component {

  render() {
    return (
      <OrderHistoryScreenComponent
        navigation={this.props.navigation}
        ordersById={this.props.ordersById}
        ordersGet={this.props.ordersGet}
        isLoadingOrders={this.props.isLoadingOrders}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    ordersById: state.orders.ordersById,
    isLoadingOrders: state.orders.isLoadingOrders,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ordersGet: (payload) => {
      dispatch(ordersGet(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryScreen);

