import * as Device from 'expo-device';
import { Platform } from 'react-native';

// Whether device is an iPhone version 13 and above.
export const is12or13 = (Platform.OS === "ios") &&
  [
    "iPhone13,1",
    "iPhone13,2",
    "iPhone13,3",
    "iPhone13,4",
    "iPhone14,2",
    "iPhone14,3",
    "iPhone14,4",
    "iPhone14,5",
  ].includes(Device.modelId);

// Whether device is an iPhone version 10 and above.
export const isX = (Platform.OS === "ios") && 
  [
    "iPhone10,3",
    "iPhone10,6",
    "iPhone11,2",
    "iPhone11,4",
    "iPhone11,6",
    "iPhone11,8",
    "iPhone12,1",
    "iPhone12,3",
    "iPhone12,5",
  ].includes(Device.modelId);

