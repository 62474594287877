import * as ActionTypes from './actionTypes';

export function getShippingAddress(payload){
  return {
    type: ActionTypes.GET_SHIPPING_ADDRESS,
    payload
  }
}

export function getShippingAddressSuccess(payload){
  return {
    type: ActionTypes.GET_SHIPPING_ADDRESS_SUCCESS,
    payload
  }
}

export function getShippingAddressFail(payload){
  return {
    type: ActionTypes.GET_SHIPPING_ADDRESS_FAIL,
    payload
  }
}

export function getBillingAddress(payload){
  return {
    type: ActionTypes.GET_BILLING_ADDRESS,
    payload
  }
}

export function getBillingAddressSuccess(payload){
  return {
    type: ActionTypes.GET_BILLING_ADDRESS_SUCCESS,
    payload
  }
}

export function getBillingAddressFail(payload){
  return {
    type: ActionTypes.GET_BILLING_ADDRESS_FAIL,
    payload
  }
}

export function addressCreate(payload){
  return {
    type: ActionTypes.ADDRESS_CREATE,
    payload
  }
}

export function addressCreateSuccess(payload){
  return {
    type: ActionTypes.ADDRESS_CREATE_SUCCESS,
    payload
  }
}

export function addressCreateFail(payload){
  return {
    type: ActionTypes.ADDRESS_CREATE_FAIL,
    payload
  }
}

export function addressDelete(payload){
  return {
    type: ActionTypes.ADDRESS_DELETE,
    payload
  }
}

export function addressDeleteSuccess(payload){
  return {
    type: ActionTypes.ADDRESS_DELETE_SUCCESS,
    payload
  }
}

export function addressDeleteFail(payload){
  return {
    type: ActionTypes.ADDRESS_DELETE_FAIL,
    payload
  }
}

export function addressUpdate(payload){
  return {
    type: ActionTypes.ADDRESS_UPDATE,
    payload
  }
}

export function addressUpdateSuccess(payload){
  return {
    type: ActionTypes.ADDRESS_UPDATE_SUCCESS,
    payload
  }
}

export function addressUpdateFail(payload){
  return {
    type: ActionTypes.ADDRESS_UPDATE_FAIL,
    payload
  }
}

export function profileSelect(payload){
  return {
    type: ActionTypes.PROFILE_SELECT,
    payload
  }
}

export function profileSelectSuccess(payload){
  return {
    type: ActionTypes.PROFILE_SELECT_SUCCESS,
    payload
  }
}

export function profileSelectFail(payload){
  return {
    type: ActionTypes.PROFILE_SELECT_FAIL,
    payload
  }
}

export function profileSetDefault(payload){
  return {
    type: ActionTypes.PROFILE_SET_DEFAULT,
    payload
  }
}

export function profileSetDefaultSuccess(payload){
  return {
    type: ActionTypes.PROFILE_SET_DEFAULT_SUCCESS,
    payload
  }
}

export function profileSetDefaultFail(payload){
  return {
    type: ActionTypes.PROFILE_SET_DEFAULT_FAIL,
    payload
  }
}
