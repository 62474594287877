import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';
import { simpleHeaderWithXcsrf } from './headers';
import sampleCombine from 'xstream/extra/sampleCombine';

export function postPM(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.POST_PM)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/pm',
        method: 'POST',
        send: {
          body: payload.body,
          uid: payload.uid,
        },
        headers: simpleHeaderWithXcsrf(payload.token),
        category: 'postPM',
      });
    });

  const response$ = sources.HTTP
    .select('postPM')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.length > 0)
          return actions.postPMSuccess(data.data[0]);
        else
          return actions.postPMFail(data);
      }catch (e) {
        return actions.postPMFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}


export function inboxGet(sources) {
  const uid$ = sources.STATE.map(state => state.user.uid);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.INBOX_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/private_msgs?range=' + Config.MESSAGES_PER_PAGE;
      if (payload.uid)
        path += '&user=' + payload.uid;
      if (payload.page)
        path += '&page=' + payload.page;

      return ({
        url: Config.BASE_URL + path,
        category: 'private_messages',
      });
    });

  const response$ = sources.HTTP
    .select('private_messages')
    .map((response) => response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .compose(sampleCombine(uid$));

  const action$ = xs.combine(response$)
    .map(([arr]) => {
      try {
        let data = arr[0].body;
        if (data && data.data)
          return actions.inboxGetSuccess({...data,uid: arr[1]});
        else
          return actions.inboxGetFail(arr);
      }catch (e) {
        return actions.inboxGetFail(arr);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function inboxGetSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.INBOX_GET_SUCCESS)
    .map(action => action.payload)
    .map((arr) => {
      // fetch next links until there is no more to fetch
      if (arr.next && arr.next.href) {
        let next_page = arr.next.href.match(/number%5D=([0-9]*)&/);
        if (next_page && next_page[1])
          return actions.inboxGet({page: next_page[1]});
        else
          return actions.doNothing();
      }
      else
        return actions.doNothing();
    });

  return {
    ACTION: action$,
  }
}
