import React from 'react';
import {
  Text,
  View,
} from 'react-native';

import * as Config from '../Config';
import Colors from '../constants/Colors';
import Styles from "../constants/Styles";
import CustomCheckbox from '../components/CustomCheckbox';
import Layout from "../constants/Layout";

export default class TermsOfService  extends React.Component {

  render() {
    const tablet = Layout.window.width/2-2*Config.STANDARD_MARGIN-35;
    const mobile = Layout.window.width-2*Config.STANDARD_MARGIN-35
    return (
      <View>
        <View style={{flexDirection: 'row', marginTop: 20}}>
          <CustomCheckbox
            checked={this.props.agreeNewsletter}
            onPress={() => this.props.toggleCheckBox('agreeNewsletter')}
            paddingRight={20}
            checkboxWidth={'auto'}
          />
          <Text style={{
            width: Config.IS_TABLET ? tablet : mobile,
            maxWidth: Config.IS_TABLET ? tablet : mobile,
            minWidth: Config.IS_TABLET ? tablet : mobile,
            paddingLeft: 0,
            paddingRight: 0,
            color: Colors.darkText,
            fontSize: Config.FONT_SIZE_NORMAL,
            fontWeight: 'normal',
            fontFamily: 'OpenSansRegular'
          }}>
            Ja, ich möchte Homestorys und die beliebtesten Bilder im wöchentlichen Newsletter erhalten.
          </Text>
        </View>
        <View style={{flexDirection: 'row',  marginTop: 20}}>
          <CustomCheckbox
            checked={this.props.agreeTerms}
            onPress={() => this.props.toggleCheckBox('agreeTerms')}
            checkboxWidth={'auto'}
            paddingRight={20}
          />
          <Text style={{
            width: Config.IS_TABLET ? tablet : mobile,
            maxWidth: Config.IS_TABLET ? tablet : mobile,
            minWidth: Config.IS_TABLET ? tablet : mobile,
            paddingLeft: 0,
            paddingRight: 0,
            color: Colors.darkText,
            fontSize: Config.FONT_SIZE_NORMAL,
            fontWeight: 'normal',
            fontFamily: 'OpenSansRegular'
          }}>
            <Text>Mit der Registrierung akzeptiere ich die </Text>
            <Text onPress={() => this.props.navigation.navigate('Webpage', {data: {uri: Config.URL_TOS}})} style={Styles.simpleLink}>Nutzungsbedingungen</Text>.<Text> Die </Text>
            <Text onPress={() => this.props.navigation.navigate('Webpage', {data: {uri: Config.URL_PRIVACY}})} style={Styles.simpleLink}>Datenschutzerklärung</Text> habe ich gelesen.
          </Text>
        </View>
      </View>
    );
  }
}
