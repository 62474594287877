import flattenCommerceProduct from './flattenCommerceProduct';
import flattenLineItem from './flattenLineItem';
import flattenCart from './flattenCart';
import flattenCartShippingItem from './flattenCartShippingItem';
import flattenCartCoupon from './flattenCartCoupon';
import flattenDiscount from "./flattenDiscount";

/**
 * Help functions that return 1 object with two elements .
 * - cart. This is an object that contains the cart and the lineItems flattened.
 * - count This is a variable that contains the sum of the quantities of the lineItems
 * I chose to flatten the product key of an item separately in order to be able to use the flattenCommerceProduct function
 * which was already implemented for the product
 */

const processCart = (data) => {
  let cart = flattenCart(data);
  // Loop through the items array of objects, flatten the product and the rest of the elements separately, and then add the
  // result in the object 'item' which is then pushed to the 'items' key of the cart object
  cart.items = data.items
    .filter(obj => obj !== null)
    .map(obj => Object.assign(flattenLineItem(obj), flattenCommerceProduct(obj.product), {discount: obj.discount.filter(item => item !== null).map(item => flattenDiscount(item))}))
  const count = data.items
    .filter(obj => obj !== null)
    .reduce((quantity, obj) => quantity + parseInt(obj.quantity), 0);
  cart.coupons = data.coupons
    .filter(obj => obj !== null)
    .map(obj => flattenCartCoupon(obj))
  cart.discount = data.discount
    .filter(obj => obj !== null)
    .map(obj => flattenDiscount(obj))
  // Loop through shipping array. Bypass empty elements and flatten the rest
  let shipping = data.shipping
    .filter(obj => obj !== null)
    .map(obj => flattenCartShippingItem(obj))
    .pop()
  return {
    cart: {...cart, ...shipping},
    count: count
  }
};

export default processCart;
