import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProductSuggestions from '../components/ProductSuggestions';
import { nodesGet } from "../actions/index";

class ProductSuggestionsInline extends PureComponent {
  componentDidMount() {
    // if there are products suggestions that are missing from the DB fetch them now
    const missing = this.props.products
      .filter((nid) => !this.props.nodes[nid]);

    if (missing && missing.length) {
      this.props.nodesGet({ids: missing});
    }
  }

  render() {
    // render individual products that exist in the DB at any given moment
    const products = this.props.products
      .filter((nid) => this.props.nodes[nid])
      .map((nid) => this.props.nodes[nid]);

    return (
      <ProductSuggestions page={this.props.page} navigation={this.props.navigation} products={products} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nodes: state.images.nodes,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    nodesGet: (payload) => {
      dispatch(nodesGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSuggestionsInline);
