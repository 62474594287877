import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';

const initialState = {
  data: {},
  facets: {},
  isLoading: false,
};

function productSearchGet(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function productSearchGetSuccess(state, action) {
  const { data, page, path, sort, tid } = action.payload;
  const newIds = data[0]
    .map((product, index) => {
      return { id: product.id, pos: index, price: product.price.und[0].value }
    })
    .sort((a, b) => {
      switch (sort) {
        case 'price_asc':
          return parseFloat(a.price) - parseFloat(b.price);
        case 'price_desc':
          return -(parseFloat(a.price) - parseFloat(b.price));
        default:
          return a.pos - b.pos;
      }
    })
    .map(product => product.id);

  const existing = (page == 0) ? {} : { ...state.data[path] };
  return {
    ...state,
    data: {
      ...state.data,
      [path]: {
        ...existing,
        [page]: newIds,
      }
    },
    isLoading: false,
    // Iterate over the facet data and massage them:
    // - remove double quote charaters
    // - parse everything to integer
    facets: (action.payload && action.payload.facets) ? Object.keys(action.payload.facets).reduce((object, key) => {
      object[key] = action.payload.facets[key]
        .filter(facetItem => facetItem.filter && facetItem.filter.length)
        .map(facetItem => {
          return {
            count: parseInt(facetItem.count),
            filter: parseInt(facetItem.filter.replace(/\"/g, '')),
          };
        });
      return object;
    }, {}) : {},
  };
}

function productSearchGetFail(state) {
  return {
    ...state,
    isLoading: false,
  };
}

const productSearch = createReducer(initialState, {
  [ActionTypes.PRODUCT_SEARCH_GET]: productSearchGet,
  [ActionTypes.PRODUCT_SEARCH_GET_SUCCESS]: productSearchGetSuccess,
  [ActionTypes.PRODUCT_SEARCH_GET_FAIL]: productSearchGetFail,
});

export default productSearch;
