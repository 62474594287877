import React, { Component } from 'react';
import {Text, View, TouchableOpacity, FlatList} from 'react-native';
import Styles from "../../constants/Styles";
import {Icon} from "../Icon";
import CustomButton from "../general/CustomButton";
import LineItems from "./LineItems";
import OrderTotal from "./OrderTotal";
import SelectedPaymentMethod from "./SelectedPaymentMethod";
import * as Config from "../../Config";
import CustomIndicator from "../CustomIndicator";
import {isEmpty} from 'lodash';
import {compareAddresses} from "../../helpers/compareAdresses";


class OrderScreenComponent extends Component {

  componentDidMount() {
    this.props.cartGet();
    if (Object.keys(this.props.shippingOptions).length === 0) {
      this.props.shippingCollect();
    }
    // If no shipping method is selected and you are on review cart, then select the first one of the available shipping methods
    if (!this.props.cart.shipping_service && this.props.navigation.getParam('cart', false)) {
      this.props.cartShippingSelect(Object.keys(this.props.cartShippingOptions)[0].toString());
    }
    // Fetch shipping and billing available addresses
    this.props.getShippingAddress();
    this.props.getBillingAddress();
  }

  /**
   * Move on to payment.
   *
   * - Open a WebView with the payment redirect step/page.
   */
  moveOnToPayment = () => {
    const { navigation } = this.props;
    const isPaypalExpress = this.props.navigation.getParam('paypal_express', false);
    if (isPaypalExpress) {
      const url = Config.BASE_URL + 'checkout/' + this.props.cart.order_id + '/paypal_ec';
      navigation.navigate('Webpage', { redirectCallback: this.redirectCallback, data: {uri: url}, payment: true, step: 'paypal_express_review' });
    }
    else {
      const url = Config.BASE_URL + 'api/cart-goto-payment';
      navigation.navigate('Webpage', { redirectCallback: this.redirectCallback, data: {uri: url}, payment: true });
    }
  }

  /**
   * This is called from the Webview if payment succeeds.
   */
  redirectCallback = () => {
    setTimeout(() => this.props.navigation.navigate("ThankYou", { order_number: this.props.cart.order_number }), 200);
  }

  currentOrder = () => {
    const { cart, ordersById } = this.props;
    return this.props.navigation.getParam('cart', false) ? cart : ordersById[this.props.navigation.getParam('id', 0)];
  }

  renderHeader = () => {
    const isCart = this.props.navigation.getParam('cart', false);
    const { navigation, shippingAddresses, billingAddresses, isLoadingShippingAddress, isLoadingBillingAddress, billingAddressCount, shippingAddressCount, shippingOptions } = this.props;
    const currentOrder = this.currentOrder();
    const selectedShippingAddress = shippingAddressCount ? shippingAddresses.filter(obj => currentOrder.profile_shipping_id == obj?.id).pop() : undefined;
    const selectedBillingAddress = billingAddressCount ? billingAddresses.filter(obj => currentOrder.profile_billing_id == obj?.id).pop() : undefined;
    let billingEqualShipping = false;

    if (isLoadingShippingAddress || isLoadingBillingAddress) {
      return <CustomIndicator/>
    } else {
      if (currentOrder.profile_shipping_id && currentOrder.profile_billing_id && !isEmpty(selectedShippingAddress) && !isEmpty(selectedBillingAddress)) {
        billingEqualShipping = compareAddresses(selectedShippingAddress, selectedBillingAddress)
      }
      return (
        <View style={{marginBottom: 10}}>
          {!isCart && <View style={[Styles.checkoutBlocksBorder, {marginTop: 15}]}>
            <Text>Bestellnummer - design-bestseller.de: {currentOrder.order_number_dbs}</Text>
            <Text style={{fontWeight: 'bold'}}>Bestellnummer - solebich.de:{currentOrder.order_number}</Text>
            <Text>Bestelldatum: {currentOrder.date}</Text>
            <Text>Status: {currentOrder.status}</Text>
          </View>}
          <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Versand</Text>
          <View style={Styles.checkoutBlocks}>
            {currentOrder.shipping_service && !isEmpty(shippingOptions) && !isEmpty(shippingOptions[currentOrder.shipping_service]) && <Text
              style={Styles.textNextToIcon}>{shippingOptions[currentOrder.shipping_service].title}: {currentOrder.shipping_price_total} €</Text>}
            {isCart && <TouchableOpacity onPress={() => this.props.navigation.navigate('Shipping')} style={Styles.iconNextToText}>
              <Icon
                name='pencil'
                size={20}
                color='#818181'
              />
            </TouchableOpacity>}
          </View>
          <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Lieferadresse</Text>
          <View style={Styles.checkoutBlocks}>
            { isEmpty(selectedShippingAddress) && isCart ?
              <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0}]}
                                onPress={() => navigation.navigate('Delivery')}>
                <Text>Adresse auswählen</Text>
              </TouchableOpacity> :
              <View style={Styles.textNextToIcon}>
                {!isEmpty(selectedShippingAddress) && <View>
                  <Text>{selectedShippingAddress.first_name} {selectedShippingAddress.last_name}</Text>
                  <Text>{selectedShippingAddress.street}</Text>
                  <Text>{selectedShippingAddress.postal_code} {selectedShippingAddress.locality}</Text>
                  <Text>{selectedShippingAddress.countryName}</Text>
                  <Text style={{marginTop: 15}}>{currentOrder.email}</Text>
                  <Text>{selectedShippingAddress.phone}</Text>
                </View>}
                <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Rechnungsadresse</Text>
                {billingEqualShipping && <Text style={Styles.textNextToIcon}>Meine Rechnungsadresse entspricht der Lieferadresse.</Text>}
                {!isEmpty(selectedBillingAddress) && !billingEqualShipping && <View style={Styles.textNextToIcon}>
                  <Text>{selectedBillingAddress.first_name} {selectedBillingAddress.last_name}</Text>
                  <Text>{selectedBillingAddress.street}</Text>
                  <Text>{selectedBillingAddress.postal_code} {selectedBillingAddress.locality}</Text>
                  <Text>{selectedBillingAddress.countryName}</Text>
                  <Text>{selectedBillingAddress.phone}</Text>
                </View> }
              </View> }
            {!isEmpty(selectedShippingAddress) && isCart && <TouchableOpacity onPress={() => navigation.navigate('Delivery')} styles={Styles.iconNextToText}>
              <Icon
                name='pencil'
                size={20}
                color='#818181'
              />
            </TouchableOpacity>}
          </View>
          <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Zahlungsart</Text>
          <SelectedPaymentMethod
            paymentMethod={currentOrder.payment_method}
            navigation={navigation}
            isCart={isCart}
          />
        </View>
      )
    }
  }

  renderFooter = () => {
    const { shippingOptions, navigation } = this.props;
    const currentOrder = this.currentOrder();
    return (
      <View style={{paddingVertical: 10}}>
        <OrderTotal cart={currentOrder} shippingOptions={shippingOptions} />
        <View style={{flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 5358784})} }>
            <Text style={[Styles.link]}>Hier findest du die Einzelheiten zum Widerrufsrecht.</Text>
          </TouchableOpacity>
          <Text style={[Styles.terms]}>Mit deiner Bestellung erklärst du dich mit</Text>
          <Text style={[Styles.terms]}>den Allgemeinen Geschäftsbedingungen der</Text>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 5358785})} }>
            <Text style={[Styles.link]}> SoLebIch GmbH</Text>
          </TouchableOpacity>
          <Text style={[Styles.terms]}> sowie der </Text>
          <TouchableOpacity
            onPress={() => {this.props.navigation.navigate('Webpage', {data: {uri: 'https://www.design-bestseller.de/agb'}})}}>
            <Text style={[Styles.link]}>Mathes Design GmbH</Text>
          </TouchableOpacity>
          <Text style={[Styles.terms]}> einverstanden und bestätigst, dass du die </Text>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 5358786})} }>
            <Text style={[Styles.link]}>Datenschutzerklärung</Text>
          </TouchableOpacity>
          <Text style={[Styles.terms]}> gelesen hast.</Text>
        </View>
      </View>
    )
  }

  render() {
    const {cart, addCoupon, navigation, removeFromCart} = this.props;
    const currentOrder = this.currentOrder();
    return (
      !isEmpty(currentOrder) ? <View style={{flex: 1, marginBottom: 10}}>
        <LineItems
          addCoupon={addCoupon}
          cart={currentOrder}
          isCart={this.props.navigation.getParam('cart', false)}
          navigation={navigation}
          removeFromCart={removeFromCart}
          renderHeader={this.renderHeader}
          renderFooter={this.renderFooter}
        />
        {this.props.navigation.getParam('cart', false) &&
          <View style={Styles.shopButtonWrap}>
            <CustomButton
              title="jetzt kaufen"
              onPress={this.moveOnToPayment}
              buttonStyle={Styles.shopButton}
              fontFamily={"OpenSansRegular"}
              disabled={!cart.profile_shipping_id || !cart.profile_billing_id || !cart.payment_method || !cart.shipping_service}
            />
          </View>
        }
      </View> : null
    )
  }
}

export default OrderScreenComponent;
