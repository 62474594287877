import * as ActionTypes from './actionTypes';

export function followUser(payload){
  return {
    type: ActionTypes.FOLLOW_USER,
    payload
  }
}

export function followUserSuccess(payload){
  return {
    type: ActionTypes.FOLLOW_USER_SUCCESS,
    payload
  }
}

export function followUserFail(payload){
  return {
    type: ActionTypes.FOLLOW_USER_FAIL,
    payload
  }
}

export function blockUser(payload){
  return {
    type: ActionTypes.BLOCK_USER,
    payload
  }
}

export function blockUserSuccess(payload){
  return {
    type: ActionTypes.BLOCK_USER_SUCCESS,
    payload
  }
}

export function blockUserFail(payload){
  return {
    type: ActionTypes.BLOCK_USER_FAIL,
    payload
  }
}

export function usersGet(payload){
  return {
    type: ActionTypes.USERS_GET,
    payload
  }
}

export function usersGetSuccess(payload){
  return {
    type: ActionTypes.USERS_GET_SUCCESS,
    payload
  }
}

export function usersGetFail(payload){
  return {
    type: ActionTypes.USERS_GET_FAIL,
    payload
  }
}

export function getFollowedUsers(payload){
  return {
    type: ActionTypes.GET_FOLLOWED_USERS,
    payload
  }
}

export function getFollowedUsersSuccess(payload){
  return {
    type: ActionTypes.GET_FOLLOWED_USERS_SUCCESS,
    payload
  }
}

export function getFollowedUsersFail(payload){
  return {
    type: ActionTypes.GET_FOLLOWED_USERS_FAIL,
    payload
  }
}


export function usersGetTamperedByUsername (payload){
  return {
    type: ActionTypes.USERS_GET_BY_TAMPERED_USERNAME,
    payload
  }
}

export function usersGetByTamperedUsernameSuccess(payload){
  return {
    type: ActionTypes.USERS_GET_BY_TAMPERED_USERNAME_SUCCESS,
    payload
  }
}

export function usersGetByTamperedUsernameFail(payload){
  return {
    type: ActionTypes.USERS_GET_BY_TAMPERED_USERNAME_FAIL,
    payload
  }
}

export function usersGetMentioning(payload){
  return {
    type: ActionTypes.USERS_GET_MENTIONING,
    payload
  }
}

export function usersGetMentioningSuccess(payload){
  return {
    type: ActionTypes.USERS_GET_MENTIONING_SUCCESS,
    payload
  }
}

export function usersGetMentioningFail(payload){
  return {
    type: ActionTypes.USERS_GET_MENTIONING_FAIL,
    payload
  }
}


export function getFollowers(payload){
  return {
    type: ActionTypes.GET_FOLLOWERS,
    payload
  }
}

export function getFollowersSuccess(payload){
  return {
    type: ActionTypes.GET_FOLLOWERS_SUCCESS,
    payload
  }
}

export function getFollowersFail(payload){
  return {
    type: ActionTypes.GET_FOLLOWERS_FAIL,
    payload
  }
}

export function getGeherztUsers(payload){
  return {
    type: ActionTypes.GET_GEHERZT_USERS,
    payload
  }
}

export function getGeherztUsersSuccess(payload){
  return {
    type: ActionTypes.GET_GEHERZT_USERS_SUCCESS,
    payload
  }
}

export function getGeherztUsersFail(payload){
  return {
    type: ActionTypes.GET_GEHERZT_USERS_FAIL,
    payload
  }
}
