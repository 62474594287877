import React, { Component } from 'react';
import {Text, TouchableOpacity, View} from "react-native";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from '../constants/Styles';
import MentionsTextInput from "../customModules/reactNativeMentions";
import CustomIndicator from "../components/CustomIndicator";

class CustomMentionsTextInput extends Component {
  state = {
    keyword: '',
    selection: {start: 0, end: 0},
  };

  onSuggestionTap = (username, hidePanel) =>{
    hidePanel();
    let firstPart = this.props.value.slice(0, this.state.selection.start - this.state.keyword.length );
    firstPart = firstPart[firstPart.length - 1] === " " ? firstPart : firstPart + " ";
    const secondPart = this.props.value.slice(this.state.selection.start);
    this.setState({ keyword: '' }, () => {
      this.props.onChangeText(
        firstPart+ '[@' + username +'] ' + secondPart
      );
    });
  };

  renderSuggestionsRow = ({ item }, hidePanel) => {
    if (this.state.keyword ==='') {
      return null;
    }
    return (
      <TouchableOpacity onPress={() => this.onSuggestionTap(item.name, hidePanel)}>
        <View style={Styles.suggestionsRowContainer}>
          <View style={Styles.mentionsUserDetailsBox}>
            <Text style={Styles.mentionsUserNameText}>@{item.name}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  };

  render() {
    return (
      <MentionsTextInput
        blurOnSubmit={false}
        horizontal={false} // defaut is true, change the orientation of the list
        keyExtractor={(item, index) => item.label}
        label={this.props.label}
        loadingComponent={
          <View style={{ flex: 1, width: Layout.width, justifyContent: 'center', alignItems: 'center' }}>
            <CustomIndicator/>
          </View>
        }
        MaxVisibleRowCount={4} // this is required if horizontal={false}
        onChangeText={this.props.onChangeText}
        onSuggestionTap={this.onSuggestionTap}
        onSelectionChange={(nativeEvent) => { this.setState({ selection:nativeEvent.selection!=null?nativeEvent.selection:{start:0,end:0} })}}
        onSubmitEditing={(event) => {
          this.setState({selection: {start: 0, end: 0}});
        }}
        {...this.props}
        ref={this.props.mentionsRef}
        renderSuggestionsRow={this.renderSuggestionsRow}
        selection={this.state.selection}
        suggestionsData={this.state.keyword ? this.props.usersMentioning : []} // array of objects
        suggestionsPanelStyle={{ backgroundColor: 'transparent', borderLeftWidth: 1, borderRightWidth: 1, borderColor: Colors.border }}
        suggestionRowHeight={40}
        trigger={'@'}
        triggerCallback={(keyword) => {if(keyword.slice(1)!==""){this.props.usersGetMentioning({keyword: keyword.slice(1)}); this.setState({keyword})}}}
        triggerLocation={'new-word-only'}
        value={this.props.value}
        autoCorrect={this.state.keyword ? false : true}
        style={Styles.padInputWidth}
      />
    );
  }
}

export default CustomMentionsTextInput;
