import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { googleConnect } from '../actions/index';
import Styles from "../constants/Styles";
import TermsOfService from '../components/TermsOfService';
import {GoogleButton} from "../components/general/GoogleButton";
import {withHooksHOC} from "../helpers/withHooksHOC";

interface IHooksHOCProps {
  promptAsync: any,
  disabled: boolean
}

class RegisterGoogleScreen extends React.Component<IHooksHOCProps> {

  state = {
    agreeNewsletter: false,
    agreeTerms: false,
  };

  toggleCheckBox = (name) => {
    this.setState({
      [name]: !this.state[name],
      errorMessage: null,
      savePressed: false,
    });
  }

  render() {
    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}> 
          <TermsOfService {...this.state} toggleCheckBox={this.toggleCheckBox} navigation={this.props.navigation} />
          <View style={{ alignItems: 'center' }}>
            {
              this.props.logInState.googleConnectMessage && this.props.logInState.googleConnectMessage !== null &&
              <Text style={{color: 'red', marginTop: 10, textAlign: 'left'}}>{this.props.logInState.googleConnectMessage}</Text>
            }
            <GoogleButton
              disabled={!this.state.agreeTerms}
              title={'Mit Google registrieren'}
              onPress={() => this.props.promptAsync().then(response => this.props.googleConnect({
                token: response.params.access_token,
                newsletter: this.state.agreeNewsletter,
                register: true
              }))}
            />
          </View>
        </View>
      </ScrollView>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    googleConnect: (payload) => {
      dispatch(googleConnect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooksHOC(RegisterGoogleScreen));
