import * as ActionTypes from './actionTypes';

export function messagesGet(payload) {
  return {
    type: ActionTypes.MESSAGES_GET,
    payload
  }
}

export function messagesGetSuccess(payload) {
  return {
    type: ActionTypes.MESSAGES_GET_SUCCESS,
    payload
  }
}

export function messagesGetFail(payload) {
  return {
    type: ActionTypes.MESSAGES_GET_FAIL,
    payload
  }
}
