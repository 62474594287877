import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';

export function slideGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SLIDE_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = `api/v1.0/slides?filter[custom_page]=1&filter[visibility][value]=1&filter[visibility][operator]="!="&range=${Config.SLIDES_FOR_BIGBOX}&sort=-visibility`;
      return ({
        url: Config.BASE_URL + path,
        category: 'slide',
      });
    });

  const response$ = sources.HTTP
    .select('slide')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        const data = arr[0].body;
        if (data && data.data && data.data.length) {
          const new_array = Object.keys(data.data)
            .reduce((res, key, index, array) => {
              if (data.data[key].visibility == 0 || data.data[key].visibility == 2) {
                res.push(data.data[key]);
              }
              return res;
            }, []);
          return actions.slideGetSuccess(new_array);
        }
        else {
          return actions.slideGetFail(data);
        }
      }
      catch (e) {
        return actions.slideGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function shoppingSlideGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SHOPPING_SLIDE_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const path = `api/v1.0/slides`;
      const params = (payload.where == 'cart')
        ? `?shoppingTid=55190`
        : '?filter[custom_page]=11';
      return ({
        url: Config.BASE_URL + path + params,
        category: 'shoppingSlide',
        where: payload.where,
      });
    });

  const response$ = sources.HTTP
    .select('shoppingSlide')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        const data = arr[0].body;
        if (data && data.data && data.data.length) {
          return actions.shoppingSlideGetSuccess({
            data: data.data,
            where: arr[0].request.where,
          });
        }
        else {
          return actions.shoppingSlideGetFail();
        }
      }
      catch(e) {
        return actions.shoppingSlideGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
