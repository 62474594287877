// Badges
export const BADGE_SET = 'BADGE_SET';
export const BADGE_RESET = 'BADGE_RESET';

// Products
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_FAIL = 'PRODUCT_GET_FAIL';
export const GET_PRODUCT_SUGGESTIONS = 'GET_PRODUCT_SUGGESTIONS';
export const GET_PRODUCT_SUGGESTIONS_SUCCESS = 'GET_PRODUCT_SUGGESTIONS_SUCCESS';
export const GET_PRODUCT_SUGGESTIONS_FAIL = 'GET_PRODUCT_SUGGESTIONS_FAIL';

// Commerce Product
export const COMMERCE_PRODUCT_GET = 'COMMERCE_PRODUCT_GET';
export const COMMERCE_PRODUCT_GET_SUCCESS = 'COMMERCE_PRODUCT_GET_SUCCESS';
export const COMMERCE_PRODUCT_GET_FAIL = 'COMMERCE_PRODUCT_GET_FAIL';

// Search Product
export const PRODUCT_SEARCH_GET = 'PRODUCT_SEARCH_GET';
export const PRODUCT_SEARCH_GET_SUCCESS = 'PRODUCT_SEARCH_GET_SUCCESS';
export const PRODUCT_SEARCH_GET_FAIL = 'PRODUCT_SEARCH_GET_FAIL';

export const DO_NOTHING = 'DO_NOTHING';
export const WHO_AM_I = 'WHO_AM_I';
export const WHO_AM_I_SUCCESS = 'WHO_AM_I_SUCCESS';
export const WHO_AM_I_FAIL = 'WHO_AM_I_FAIL';
export const GET_TOKEN_INIT = 'GET_TOKEN_INIT';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAIL = 'GET_TOKEN_FAIL';
export const LIKE_NODE = 'LIKE_NODE';
export const LIKE_NODE_SUCCESS = 'LIKE_NODE_SUCCESS';
export const LIKE_NODE_FAIL = 'LIKE_NODE_FAIL';
export const BLOCK_NODE = 'BLOCK_NODE';
export const BLOCK_NODE_SUCCESS = 'BLOCK_NODE_SUCCESS';
export const BLOCK_NODE_FAIL = 'BLOCK_NODE_FAIL';
export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL';
export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const NODES_GET = 'NODES_GET';
export const NODES_GET_SUCCESS = 'NODES_GET_SUCCESS';
export const NODES_GET_FAIL = 'NODES_GET_FAIL';
export const COMMENTS_GET = 'COMMENTS_GET';
export const COMMENTS_GET_SUCCESS = 'COMMENTS_GET_SUCCESS';
export const COMMENTS_GET_FAIL = 'COMMENTS_GET_FAIL';
export const COMMENT_SET = 'COMMENT_SET';
export const COMMENT_SET_SUCCESS = 'COMMENT_SET_SUCCESS';
export const COMMENT_SET_FAIL = 'COMMENT_SET_FAIL';
export const COMMENT_DELETE = 'COMMENT_DELETE';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL';
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAIL = 'LIKE_COMMENT_FAIL';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_UPDATE_SUCCESS = 'ENTITY_UPDATE_SUCCESS';
export const ENTITY_UPDATE_FAIL = 'ENTITY_UPDATE_FAIL';
export const ENTITY_DEL = 'ENTITY_DEL';
export const ENTITY_DEL_SUCCESS = 'ENTITY_DEL_SUCCESS';
export const ENTITY_DEL_FAIL = 'ENTITY_DEL_FAIL';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';
export const NODE_UPLOAD = 'NODE_UPLOAD';
export const NODE_UPLOAD_SUCCESS = 'NODE_UPLOAD_SUCCESS';
export const NODE_UPDATE_FAIL = 'NODE_UPLOAD_FAIL';
export const MESSAGES_GET = 'MESSAGES_GET';
export const MESSAGES_GET_SUCCESS = 'MESSAGES_GET_SUCCESS';
export const MESSAGES_GET_FAIL = 'MESSAGES_GET_FAIL';
export const TAGS_GET = 'TAGS_GET';
export const TAGS_GET_SUCCESS = 'TAGS_GET_SUCCESS';
export const TAGS_GET_FAIL = 'TAGS_GET_FAIL';
export const TERMS_GET = 'TERMS_GET';
export const TERMS_GET_SUCCESS = 'TERMS_GET_SUCCESS';
export const TERMS_GET_FAIL = 'TERMS_GET_FAIL';
export const USERS_GET = 'USERS_GET';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_FAIL = 'USERS_GET_FAIL';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USERNAME_VALIDATION = 'USERNAME_VALIDATION';
export const USERNAME_VALIDATION_SUCCESS = 'USERNAME_VALIDATION_SUCCESS';
export const USERNAME_VALIDATION_FAIL = 'USERNAME_VALIDATION_FAIL';
export const EMAIL_VALIDATION = 'EMAIL_VALIDATION';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EMAIL_VALIDATION_FAIL = 'EMAIL_VALIDATION_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const USER_SET = 'USER_SET';
export const INBOX_GET = 'INBOX_GET';
export const INBOX_GET_SUCCESS = 'INBOX_GET_SUCCESS';
export const INBOX_GET_FAIL = 'INBOX_GET_FAIL';
export const PUSH_TOKEN = 'PUSH_TOKEN';
export const PUSH_TOKEN_SUCCESS = 'PUSH_TOKEN_SUCCESS';
export const PUSH_TOKEN_FAIL = 'PUSH_TOKEN_FAIL';
export const SHOP_GET = 'SHOP_GET';
export const SHOP_GET_SUCCESS = 'SHOP_GET_SUCCESS';
export const SHOP_GET_FAIL = 'SHOP_GET_FAIL';
export const VIEW_GET = 'VIEW_GET';
export const VIEW_GET_SUCCESS = 'VIEW_GET_SUCCESS';
export const VIEW_GET_FAIL = 'VIEW_GET_FAIL';
export const POST_PM = 'POST_PM';
export const POST_PM_SUCCESS = 'POST_PM_SUCCESS';
export const POST_PM_FAIL = 'POST_PM_FAIL';
export const USERS_GET_MENTIONING = 'USERS_GET_MENTIONING';
export const USERS_GET_MENTIONING_SUCCESS = 'USERS_GET_MENTIONING_SUCCESS';
export const USERS_GET_MENTIONING_FAIL = 'USERS_GET_MENTIONING_FAIL';

export const USERS_GET_BY_TAMPERED_USERNAME = 'USERS_GET_BY_TAMPERED_USERNAME';
export const USERS_GET_BY_TAMPERED_USERNAME_SUCCESS = 'USERS_GET_BY_TAMPERED_USERNAME_SUCCESS';
export const USERS_GET_BY_TAMPERED_USERNAME_FAIL = 'USERS_GET_BY_TAMPERED_USERNAME_FAIL';

export const CLEAR_UPLOADED_IMAGE = 'CLEAR_UPLOADED_IMAGE';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const ERROR_RECEIVED = 'ERROR_RECEIVED';
export const NODE_EDIT = 'NODE_EDIT';
export const NODE_EDIT_SUCCESS = 'NODE_EDIT_SUCCESS';
export const NODE_EDIT_FAIL = 'NODE_EDIT_FAIL';
export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL';
export const GET_TAG = 'GET_TAG';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAIL = 'GET_TAG_FAIL';
export const GET_TAG_BY_NAME = 'GET_TAG_BY_NAME';
export const GET_TAG_BY_NAME_SUCCESS = 'GET_TAG_BY_NAME_SUCCESS';
export const GET_TAG_BY_NAME_FAIL = 'GET_TAG_BY_NAME_FAIL';
export const GET_TAG_NAME = 'GET_TAG_NAME';
export const GET_TAG_NAME_SUCCESS = 'GET_TAG_NAME_SUCCESS';
export const GET_TAG_NAME_FAIL = 'GET_TAG_NAME_FAIL';
export const GET_VOCABULARY = 'GET_VOCABULARY';
export const GET_VOCABULARY_SUCCESS = 'GET_VOCABULARY_SUCCESS';
export const GET_VOCABULARY_FAIL = 'GET_VOCABULARY_FAIL';
export const TOS_GET = 'TOS_GET';
export const TOS_GET_SUCCESS = 'TOS_GET_SUCCESS';
export const TOS_GET_FAIL = 'TOS_GET_FAIL';
export const SUBMIT_REPORT_FORM = 'SUBMIT_REPORT_FORM';
export const SUBMIT_REPORT_FORM_SUCCESS = 'SUBMIT_REPORT_FORM_SUCCESS';
export const SUBMIT_REPORT_FORM_FAIL = 'SUBMIT_REPORT_FORM_FAIL';
export const SUBMIT_FEEDBACK_FORM = 'SUBMIT_FEEDBACK_FORM';
export const SUBMIT_FEEDBACK_FORM_SUCCESS = 'SUBMIT_FEEDBACK_FORM_SUCCESS';
export const SUBMIT_FEEDBACK_FORM_FAIL = 'SUBMIT_FEEDBACK_FORM_FAIL';
export const SLIDE_GET = 'SLIDE_GET';
export const SLIDE_GET_SUCCESS = 'SLIDE_GET_SUCCESS';
export const SLIDE_GET_FAIL = 'SLIDE_GET_FAIL';
export const FACEBOOK_CONNECT = 'FACEBOOK_CONNECT';
export const FACEBOOK_CONNECT_SUCCESS = 'FACEBOOK_CONNECT_SUCCESS';
export const FACEBOOK_CONNECT_FAIL = 'FACEBOOK_CONNECT_FAIL';
export const APPLE_CONNECT = 'APPLE_CONNECT';
export const APPLE_CONNECT_SUCCESS = 'APPLE_CONNECT_SUCCESS';
export const APPLE_CONNECT_FAIL = 'APPLE_CONNECT_FAIL';
export const APPLE_CONNECT_REDIRECT = 'APPLE_CONNECT_REDIRECT';
export const GOOGLE_CONNECT = 'GOOGLE_CONNECT';
export const GOOGLE_CONNECT_SUCCESS = 'GOOGLE_CONNECT_SUCCESS';
export const GOOGLE_CONNECT_FAIL = 'GOOGLE_CONNECT_FAIL';
export const MENU_GET = 'MENU_GET';
export const MENU_GET_SUCCESS = 'MENU_GET_SUCCESS';
export const MENU_GET_FAIL = 'MENU_GET_FAIL';
// update likes and comments in the app after receiving a notification
export const HANDLE_RECEIVED_NOTIFICATION = 'HANDLE_RECEIVED_NOTIFICATION';

//listen to navigation actions without keeping the
// navigation state in redux
export const HANDLE_NAVIGATION = 'HANDLE_NAVIGATION';

// Get following, followers, geherzt
export const GET_FOLLOWED_USERS = 'GET_FOLLOWED_USERS';
export const GET_FOLLOWED_USERS_SUCCESS = 'GET_FOLLOWED_USERS_SUCCESS';
export const GET_FOLLOWED_USERS_FAIL = 'GET_FOLLOWED_USERS_FAIL';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
export const GET_FOLLOWERS_FAIL = 'GET_FOLLOWERS_FAIL';
export const GET_GEHERZT_USERS = 'GET_GEHERZT_USERS';
export const GET_GEHERZT_USERS_SUCCESS = 'GET_GEHERZT_USERS_SUCCESS';
export const GET_GEHERZT_USERS_FAIL = 'GET_GEHERZT_USERS_FAIL';

// Device
export const STORE_CONNECTIVITY_INFO = 'STORE_CONNECTIVITY_INFO';

// Pass initial url to navigation through redux
export const URL_TO_NAV = 'URL_TO_NAV';

// Commerce Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAIL = 'ADD_TO_CART_FAIL';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAIL = 'REMOVE_FROM_CART_FAIL';
export const EMPTY_CART = 'EMPTY_CART';
export const CART_GET = 'CART_GET';
export const CART_GET_SUCCESS = 'CART_GET_SUCCESS';
export const CART_GET_FAIL = 'CART_GET_FAIL';
export const CART_SHIPPING_COLLECT = 'CART_SHIPPING_COLLECT';
export const CART_SHIPPING_COLLECT_SUCCESS = 'CART_SHIPPING_COLLECT_SUCCESS';
export const CART_SHIPPING_COLLECT_FAIL = 'CART_SHIPPING_COLLECT_FAIL';
export const CART_PAYMENT_COLLECT = 'CART_PAYMENT_COLLECT';
export const CART_PAYMENT_COLLECT_SUCCESS = 'CART_PAYMENT_COLLECT_SUCCESS';
export const CART_PAYMENT_COLLECT_FAIL = 'CART_PAYMENT_COLLECT_FAIL';
export const ADD_COUPON = 'ADD_COUPON';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAIL = 'ADD_COUPON_FAIL';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const REMOVE_COUPON_SUCCESS = 'REMOVE_COUPON_SUCCESS';
export const REMOVE_COUPON_FAIL = 'REMOVE_COUPON_FAIL';
export const ALTER_QUANTITY = 'ALTER_QUANTITY';
export const ALTER_QUANTITY_SUCCESS = 'ALTER_QUANTITY_SUCCESS';
export const ALTER_QUANTITY_FAIL = 'ALTER_QUANTITY_FAIL';
export const CART_PAYMENT_SELECT = 'CART_PAYMENT_SELECT';
export const CART_PAYMENT_SELECT_SUCCESS = 'CART_PAYMENT_SELECT_SUCCESS';
export const CART_PAYMENT_SELECT_FAIL = 'CART_PAYMENT_SELECT_FAIL';
export const CART_SHIPPING_SELECT = 'CART_SHIPPING_SELECT';
export const CART_SHIPPING_SELECT_SUCCESS = 'CART_SHIPPING_SELECT_SUCCESS';
export const CART_SHIPPING_SELECT_FAIL = 'CART_SHIPPING_SELECT_FAIL';
export const CART_UPDATE_STATUS = 'CART_UPDATE_STATUS';
export const CART_UPDATE_STATUS_SUCCESS = 'CART_UPDATE_STATUS_SUCCESS';
export const CART_UPDATE_STATUS_FAIL = 'CART_UPDATE_STATUS_FAIL';
export const SHOPPING_SLIDE_GET = 'SHOPPING_SLIDE_GET';
export const SHOPPING_SLIDE_GET_SUCCESS = 'SHOPPING_SLIDE_GET_SUCCESS';
export const SHOPPING_SLIDE_GET_FAIL = 'SHOPPING_SLIDE_GET_FAIL';

// Commerce past orders
export const ORDERS_GET = 'ORDERS_GET';
export const ORDERS_GET_SUCCESS = 'ORDERS_GET_SUCCESS';
export const ORDERS_GET_FAIL = 'ORDERS_GET_FAIL';

// All shipping methods
export const SHIPPING_COLLECT = 'SHIPPING_COLLECT';
export const SHIPPING_COLLECT_SUCCESS = 'SHIPPING_COLLECT_SUCCESS';
export const SHIPPING_COLLECT_FAIL = 'SHIPPING_COLLECT_FAIL';

// Shipping and Billing Addresses
export const GET_SHIPPING_ADDRESS = 'GET_SHIPPING_ADDRESS';
export const GET_SHIPPING_ADDRESS_SUCCESS = 'GET_SHIPPING_ADDRESS_SUCCESS';
export const GET_SHIPPING_ADDRESS_FAIL = 'GET_SHIPPING_ADDRESS_FAIL';
export const GET_BILLING_ADDRESS = 'GET_BILLING_ADDRESS';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAIL = 'GET_BILLING_ADDRESS_FAIL';
export const ADDRESS_CREATE = 'ADDRESS_CREATE';
export const ADDRESS_CREATE_SUCCESS = 'ADDRESS_CREATE_SUCCESS';
export const ADDRESS_CREATE_FAIL = 'ADDRESS_CREATE_FAIL';
export const ADDRESS_DELETE = 'ADDRESS_DELETE';
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS';
export const ADDRESS_DELETE_FAIL = 'ADDRESS_DELETE_FAIL';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
export const ADDRESS_UPDATE_SUCCESS = 'ADDRESS_UPDATE_SUCCESS';
export const ADDRESS_UPDATE_FAIL = 'ADDRESS_UPDATE_FAIL';
export const PROFILE_SELECT = 'PROFILE_SELECT';
export const PROFILE_SELECT_SUCCESS = 'PROFILE_SELECT_SUCCESS';
export const PROFILE_SELECT_FAIL = 'PROFILE_SELECT_FAIL';
export const PROFILE_SET_DEFAULT = 'PROFILE_SET_DEFAULT';
export const PROFILE_SET_DEFAULT_SUCCESS = 'PROFILE_SET_DEFAULT_SUCCESS';
export const PROFILE_SET_DEFAULT_FAIL = 'PROFILE_SET_DEFAULT_FAIL';
