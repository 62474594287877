import React, { Component } from 'react';
import { View } from 'react-native';
import { BallIndicator } from 'react-native-indicators';
import Colors from '../constants/Colors';

export default class CustomIndicator extends Component {
  render() {
    return (
      <View style={{ flex:1, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', minHeight: 80, minWidth: 80 }}>
        <BallIndicator color={this.props.color || Colors.tintColor}/>
      </View>
    );
  }
}
