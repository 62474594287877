import React, {Component} from 'react';
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";
import {View} from 'react-native';
import {Text} from "react-native-elements";
import FloatingLabel from './CustomFloatingLabelInput';

class CustomTextInput extends Component{

  state = {
    fontFamily:(this.props.value && this.props.value.length > 0) ? 'OpenSansBold' : 'OpenSansRegular'
  };

  componentDidUpdate = (prevProps)=>{
    if (this.props.value && this.props.value !== prevProps.value) {
      this.setState({
        fontFamily:(this.props.value.length > 0) ? 'OpenSansBold' : 'OpenSansRegular'
      });
    }
  };

  render() {
    return (
      <View style={Styles.padInputWidth}>
        <FloatingLabel
          onFocus={() => {
            this.props.onFocus ? this.props.onFocus() : null;
            this.setState({
              fontFamily:'OpenSansBold'
            });
          }}
          onBlur={() => {
            this.props.onBlur ? this.props.onBlur() : null;
            this.setState({
              fontFamily:(this.props.value && this.props.value.length > 0) ? 'OpenSansBold' : 'OpenSansRegular'
            });
          }}
          labelStyle={{
            color: Colors.darkText, fontFamily: this.state.fontFamily
          }}
          inputStyle={[Styles.inputOneLineText, this.props.nonEditableStyle]}
          style={Styles.inputItemOneLineText}
          underlineColorAndroid='transparent'
          allowFontScaling={false}
          {...this.props}
        >
          {this.props.label}
        </FloatingLabel>
        {this.props.helpText ?
          <Text style={Styles.helpText}>{this.props.helpText}</Text> :
          null
        }
      </View>
    );
  };

};

export default CustomTextInput;
