import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import Webpage from '../containers/Webpage';
import AnonNavigator from "./AnonNavigator";
import CommonStack from '../navigation/CommonStack';

const RootStack = createStackNavigator(
  {
    MainTab: {
      screen: AnonNavigator, navigationOptions: CommonStack.navigationOptions
    },
    Webpage: {
      screen: Webpage, navigationOptions: CommonStack.navigationOptions
    },
  },
  {
    headerMode: 'none'
  }
);

export default createAppContainer(RootStack);
