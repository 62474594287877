import  React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getGeherztUsers,
} from "../actions/index";
import UsersListComponent from '../components/UsersListComponent';

class GeherztScreen extends Component {
  componentDidMount() {
    const { state } = this.props.navigation;
    // if user exists in the DB
    if (state.params) {
      // if user ID is passed fetch user now
      if (state.params.id && !this.props.geherzt[state.params.id]) {
        this.props.getGeherztUsers({ id: state.params.id });
      }
    }
  }
  render() {
    const { id } = this.props.navigation.state.params;
    return (
      <UsersListComponent
        title="Mitglieder, die das Bild geherzt haben:"
        data={(this.props.geherzt[id]) && this.props.geherzt[id]
            .map(id => this.props.users[id])
            .filter(user => user && !user.blocked)
        }
        navigation={this.props.navigation}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geherzt: state.users.geherzt,
    usernames: state.users.usernames,
    users: state.users.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    getGeherztUsers: (payload) => {
      dispatch(getGeherztUsers(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeherztScreen);
