import { paymentMethodsKeyed } from "../../constants/paymentMethods"

/**
 * Loop through Payment object and return methods object where we have filtered out methods that
 * do not exist in the paymentMethods constants file
 */

const processPaymentMethods = (data) => {
  return {
    methods: data.filter(method => method in paymentMethodsKeyed)
  }
};

export default processPaymentMethods;
