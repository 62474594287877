import React, { Component } from 'react';
import CheckoutScreenComponent from "../components/shopping/CheckoutScreenComponent";
import {connect} from "react-redux";
import {cartGet, getBillingAddress, getShippingAddress} from "../actions";

class CheckoutScreen extends Component {

  render() {
    return (
      <CheckoutScreenComponent
        cart={this.props.cart}
        navigation={this.props.navigation}
        shippingOptions={this.props.shippingOptions}
        shippingAddresses={this.props.shippingAddresses}
        billingAddresses={this.props.billingAddresses}
        getShippingAddress={this.props.getShippingAddress}
        getBillingAddress={this.props.getBillingAddress}
        isLoadingShippingAddress={this.props.isLoadingShippingAddress}
        isLoadingBillingAddress={this.props.isLoadingBillingAddress}
        cartGet={this.props.cartGet}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    billingAddresses: state.address.billingAddresses.map(id => state.address.addresses[id]),
    cart: state.checkout.cart,
    isLoadingBillingAddress: state.address.isLoadingBillingAddress,
    isLoadingShippingAddress: state.address.isLoadingShippingAddress,
    shippingAddresses: state.address.shippingAddresses.map(id => state.address.addresses[id]),
    shippingOptions: state.checkout.shippingOptions,
  }
};


const mapDispatchToProps = (dispatch) => {
  return {
    getShippingAddress: (payload) => {
      dispatch(getShippingAddress(payload))
    },
    getBillingAddress: (payload) => {
      dispatch(getBillingAddress(payload))
    },
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);
