import React from 'react';
import { NavigationActions } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import IconWithBadge from '../components/IconWithBadge';
import { isX } from '../helpers/devices.js';
import Colors from '../constants/Colors';
import { Icon } from '../components/Icon';
import NotificationNavigation from './NotificationNavigation';
import HomeNavigation from "./HomeNavigation";
import UserNavigation from "./UserNavigation";
import MenuNavigation from "./MenuNavigation";
import CameraNavigation from "./CameraNavigation";
import CartNavigation from "./CartNavigation";

const mapRouteToIcon = {
  Tab1: 'home',
  Node: 'home',
  Messages: 'bell',
  Camera: 'camera',
  Cart: 'shopping_cart',
  Me: 'person',
  Menu: 'menu',
};

export default createBottomTabNavigator(
  {
    Tab1: { screen: HomeNavigation },
    Messages: { screen: NotificationNavigation },
    Camera: { screen: CameraNavigation },
    Cart: { screen: CartNavigation },
    Me: { screen: UserNavigation },
    Menu: { screen: MenuNavigation },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarLabel: ({ focused }) => {
        const iconName = mapRouteToIcon[navigation.state.routeName];
        return (navigation.state.routeName == 'Messages' || navigation.state.routeName == 'Cart')
          ? <IconWithBadge focused={focused} style={{ alignSelf: 'center' }} icon={iconName} />
          : <Icon active={focused} name={iconName} style={{ alignSelf: 'center' }} />
      },
      tabBarOnPress: ({ navigation }) => {
        // If the tab is not focused simply navigate/switch to it.
        if (!navigation.isFocused()) {
          navigation.navigate(navigation.state.routeName);
        }
        else {
          const routeName = (navigation.state.routeName == 'Tab1') ? 'Home' : navigation.state.routeName;
          // If it is at the top screen (index equals to 0) just pass the param
          // doubleclicked as true via navigate (somehow setParams does not work)
          if (navigation.state.index == 0 && routeName != 'Search') {
            if (routeName == 'Messages') {
              navigation.navigate('Notification', {bellIconPressed: true});
            }
            else if (routeName == 'Cart') {
              navigation.navigate('Cart', {cartIconPressed: true});
            }
            else {
              navigation.navigate(routeName, {doubleClicked: true});
            }
          }
          // If user has navigated deep reset to the top of the stack.
          else {
            navigation.reset([ NavigationActions.navigate({ routeName, params: { isReset: true } }) ], 0);
          }
        }
      },
    }),
    tabBarOptions: {
      // We hide the icon and render it manaully as a label to avoid a bug that 
      // otherwise crops the camera icon on the right.
      showIcon: false,
      style: {
        borderTopWidth: 0.5,
        borderColor: Colors.border,
        height: (isX ? 60 : 50),
      },
      tabStyle: {
        opacity: 1,
        padding: 10,
      }
    },
    lazy: true,
  }
);
