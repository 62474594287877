import { Platform } from 'react-native';
import { priceFormat } from '../../helpers/formatNumbers'

const flattenNode = (node, oldNode) => {
  if (Array.isArray(node.image)) {
    node.image = node.image[0];
  }

  // Return slightly different data for specific types.
  switch (node.type) {
    case 'page':
    case 'product_commerce':
      return {
        body: node.body,
        description: node.body,
        id: node.id,
        images: node.images ?? 0,
        label: node.label,
        self: node.self,
        type: node.type,
      };
      break;
  }

  return {
    ...{
      id: node.id ?? undefined,
      label: node.label ?? undefined,
      self: node.self ?? undefined,
      type: node.type ?? undefined,
      timestamp: node.timestamp ?? undefined,
      time: node.time ?? undefined,
      likes: node.likes ?? undefined,
      liked: node.liked ?? undefined,
      blocked: node.blocked || node.user && node.user.blocked,
      comments: node.comments ?? undefined,
      userId: node.user ? node.user.id : undefined,
      userLabel: node.user ? node.user.label: undefined,
      userUserName: node.user ? node.user.username : undefined,
      userMail: node.user ? node.user.mail : undefined,
      onSale: node.price_original ? node.price_original > 0 : false,
      price: node.type == 'product' ? priceFormat(node.price) : undefined,
      priceOriginal: node.type == 'product' ? priceFormat(node.price_original) : undefined,
      userPictureUri: node.user && node.user.picture ? node.user.picture.uri : undefined,
      textValue: node.text && node.text.value ? node.text.value : undefined,
      textValueStripped: node.text && node.text.value ? node.text.value.replace(/(\r\n|\n|\r|<[^>]*>)/gm,"").substring(0, 50) : undefined,
      textSummary: node.text && node.text.summary ? node.text.summary : undefined,
      textFormat: node.text && node.text.format ? node.text.format : undefined,
      textSafeValue: node.text && node.text.safe_value ? node.text.safe_value : undefined,
      body: node.body ?? undefined,
      products: node.products,
      product_tags: node.product_tags,
      tags: node.tags.filter((tag) => tag && tag.label && tag.label.length),
      commentsIds: oldNode && oldNode.commentsIds ? oldNode.commentsIds : [],
      subProducts: node.subproducts,
      link: node.link ?? undefined,
      series: node.series ?? undefined,
      views: node.views ? new Intl.NumberFormat('de-DE').format(node.views) : undefined,
      product: node.product,
      dbsParent: node.dbsParent ?? undefined,
      description: node.description ? node.description.value : undefined,
      productReference: node.productReference ? node.productReference[0] : undefined,
    },
    ...(!node.image ? {} : {
      imageOriginalHeight: node.image.height ?? 100,
      imageOriginalWidth: node.image.width ?? 100,
      imageRatio: (node.image.width && node.image.height) ? (node.image.width / node.image.height) : 1,
      imageStylesFluidFixedWidth: (Platform.OS == 'android') ? (node.image.styles["fluid-fixed-width"] ?? undefined) : (node.image.styles["fluid-fixed-width-556"] ?? undefined),
      imageStylesFluidFixedWidthSProducts: node.image.styles["fluid-fixed-width"] ?? undefined,
      imageSelf: node.image.self ?? undefined,
      imageStylesScalecrop: node.image.styles["scalecrop_300x450"] ?? undefined,
      imageStyles1200x900Conditional: node.image.styles["1200x900-conditional"] ?? undefined,
      imageStylesS2SlideSm: node.image.styles["s2_slide_sm"] ?? undefined,
      imageStyles278x278: node.image.styles["278x278"] ?? undefined,
    })
  }
};

export default flattenNode;
