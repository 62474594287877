import * as ActionTypes from './actionTypes';

export function likeNode(payload) {
  return {
    type: ActionTypes.LIKE_NODE,
    payload
  }
}

export function likeNodeSuccess(payload) {
  return {
    type: ActionTypes.LIKE_NODE_SUCCESS,
    payload
  }
}

export function likeNodeFail(payload) {
  return {
    type: ActionTypes.LIKE_NODE_FAIL,
    payload
  }
}

export function blockNode(payload) {
  return {
    type: ActionTypes.BLOCK_NODE,
    payload
  }
}

export function blockNodeSuccess(payload) {
  return {
    type: ActionTypes.BLOCK_NODE_SUCCESS,
    payload
  }
}

export function blockNodeFail(payload) {
  return {
    type: ActionTypes.BLOCK_NODE_FAIL,
    payload
  }
}

export function nodesGet(payload) {
  return {
    type: ActionTypes.NODES_GET,
    payload
  }
}

export function nodesGetSuccess(payload) {
  return {
    type: ActionTypes.NODES_GET_SUCCESS,
    payload
  }
}

export function nodesGetFail(payload) {
  return {
    type: ActionTypes.NODES_GET_FAIL,
    payload
  }
}


export function fileUpload(payload) {
  return {
    type: ActionTypes.FILE_UPLOAD,
    payload
  }
}

export function fileUploadSuccess(payload) {
  return {
    type: ActionTypes.FILE_UPLOAD_SUCCESS,
    payload
  }
}

export function fileUploadFail(payload) {
  return {
    type: ActionTypes.FILE_UPLOAD_FAIL,
    payload
  }
}

export function clearUploadedImage(payload) {
  return {
    type: ActionTypes.CLEAR_UPLOADED_IMAGE,
    payload
  };
}

export function nodeSave(payload) {
  return {
    type: ActionTypes.NODE_UPLOAD,
    payload
  }
}

export function nodeSaveSuccess(payload) {
  return {
    type: ActionTypes.NODE_UPLOAD_SUCCESS,
    payload
  }
}

export function nodeSaveFail(payload) {
  return {
    type: ActionTypes.NODE_UPDATE_FAIL,
    payload
  }
}


export function viewGet(payload) {
  return {
    type: ActionTypes.VIEW_GET,
    payload
  }
}

export function viewGetSuccess(payload) {
  return {
    type: ActionTypes.VIEW_GET_SUCCESS,
    payload
  }
}

export function viewGetFail(payload) {
  return {
    type: ActionTypes.VIEW_GET_FAIL,
    payload
  }
}

export function nodeEdit(payload) {
  return {
    type: ActionTypes.NODE_EDIT,
    payload
  }
}

export function nodeEditSuccess(payload) {
  return {
    type: ActionTypes.NODE_EDIT_SUCCESS,
    payload
  }
}

export function nodeEditFail(payload) {
  return {
    type: ActionTypes.NODE_EDIT_FAIL,
    payload
  }
}
