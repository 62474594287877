import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import MenuScreen from "../containers/MenuScreen";
import CommonStack from "./CommonStack";

export default createStackNavigator(
  {
    Menu: { screen: MenuScreen, navigationOptions: CommonStack.navigationOptions },
  },
  {
    ...CommonStack.Config,
  },
);
