import React, { PureComponent } from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import { Thumbnail } from '../components/Thumbnail';
import moment from 'moment';
import Styles from '../constants/Styles';
import * as Config from '../Config';

export default class NotificationItem extends PureComponent {

  render() {
    const { message, node_ref, user, user_ref } = this.props;
    // left avatar is always shown
    const avatar_left =
      <TouchableOpacity onPress={this.onPressAvatar}>
        <Thumbnail
          size="medium"
          source={{uri: user ? user.pictureUri : Config.DEFAULT_AVATAR}}
        />
      </TouchableOpacity>;

    const avatar_right_source = (node_ref && node_ref.id) ? node_ref.imageStyles278x278 :
      (user_ref && user_ref.id && user_ref.picture) ? user_ref.picture.uri :
      false;

    const avatar_right = (!avatar_right_source) ? null :
      <Thumbnail
        size="medium"
        type="square"
        source={{uri: avatar_right_source}}
      />;

    return (
      <TouchableOpacity style={Styles.messageContainer} onPress={this.onPressItem}>
        <View>
          {avatar_left}
        </View>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text>{message.text}</Text>
          <Text>{moment.unix(message.timestamp).format("HH[.]mm [Uhr - ]DD[.]MM[.]YYYY")}</Text>
        </View>
        <View>
          {avatar_right}
        </View>
      </TouchableOpacity>
    );
  }

  // handle pressing the whole notification item
  onPressItem = () => {
    const { navigate } = this.props.navigation;
    const { message, node_ref, user, user_ref } = this.props;
    if (message.type === 'follow_message' && user && user.id) {
      navigate({
        routeName: 'User',
        key: `user${user.id}`,
        params:  { user: user, id: user.id }
      });
    }
    // if there is a node_ref passed navigate there
    else if (node_ref) {
        navigate({
          routeName: 'NodeSingle',
          key: `node${node_ref.id ? node_ref.id : node_ref}`,
          params: {id: node_ref.id ? node_ref.id : node_ref, focusInputField: message.type === "comment_group_message"}
        });
    }
    // if it has a user reference navigate there
    else if (user_ref) {
        navigate({
          routeName: 'User',
          key: `user${user_ref.id ? user_ref.id : user_ref}`,
          params: {id: user_ref.id ? user_ref.id : user_ref}
        });
    }
    // PM messages should navigate to the UserDiscussion screen
    else if (user && user.id) {
      navigate({
        routeName: 'UserDiscussion',
        key: `discussion${user.id}`,
        params: { user: {id: user.id} }
      });
    }
  };

  // when pressing an avatar always navigate to the user page
  onPressAvatar = () => {
    this.props.navigation.navigate({
      routeName: 'User',
      key: `user${this.props.user.id}`,
      params:  { id: this.props.user.id, user: this.props.user }
    });
  }

}
