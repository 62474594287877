import React from "react";
import { useLoginGoogle } from "./useLoginGoogle";

// In order to use hooks with class components I used HOC, an advanced React technique
// which gives the ability to use Hook logic inside an existing class component.
// The idea is to get a component as an input, and return that same component with some additional props.
// We pass the hook function as prop, then we wrap the existing class component (LoginScreen) with that HOC,
// passing the props (promptAsync, disabled) as any other prop.

export const withHooksHOC = (Component: any) => {
  return (props: any) => {
    const { promptAsync, disabled } = useLoginGoogle();
    return <Component disabled={disabled} promptAsync={promptAsync} {...props} />
  }
}
