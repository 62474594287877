import React, {PureComponent} from 'react';
import {Text, TouchableOpacity} from 'react-native';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

export default class MenuHorizontaltem extends PureComponent {
  render() {
    return(
      <TouchableOpacity
        key={this.props.index}
        onPress={this.onMenuPress}
        style={(!this.props.active) ? Styles.greyButtonContainer: Styles.greyButtonContainerActive}
      >
        <Text style={Styles.pinkButtonText}>{this.props.menu.title}</Text>
      </TouchableOpacity>
    );
  }
  onMenuPress = () => {
    this.props.onMenuPress(this.props.menu, this.props.index);
  }
}


