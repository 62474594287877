import React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { Button } from 'react-native-elements';
import { EventRegister } from 'react-native-event-listeners';
import Constants from 'expo-constants';
import * as AuthSession from 'expo-auth-session';
import * as Config from '../Config';
import { facebookConnect } from '../actions/index';
import Styles from "../constants/Styles";
import { Icon } from "../components/Icon";
import TermsOfService from '../components/TermsOfService';
import ContactText from "../components/general/ContactText";

class RegisterFacebookScreen extends React.Component {

  state = {
    agreeNewsletter: false,
    agreeTerms: false,
  };

  fbConnect = async () => {
    let redirectUrl = AuthSession.getRedirectUrl();
    let result = await AuthSession.startAsync({
      authUrl:
      `https://www.facebook.com/v2.8/dialog/oauth?response_type=token` +
      `&client_id=${Constants.manifest.facebookAppId}` +
      `&redirect_uri=${encodeURIComponent(redirectUrl)}`,
    });

    if (result.type !== 'success' || !result.params || !result.params.access_token) {
      alert('Etwas ist schiefgelaufen');
      return;
    }
    EventRegister.emit('analyticEvent', {
      category: 'Register',
      action: 'Facebook',
      label: 'User registered via facebook'
    });
    this.props.facebookConnect({
      newsletter: this.state.agreeNewsletter,
      token: result.params.access_token,
    });
  };

  toggleCheckBox = (name) => {
    this.setState({
      [name]: !this.state[name],
      errorMessage: null,
      savePressed: false,
    });
  }

  render() {
    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}> 
          <TermsOfService {...this.state} toggleCheckBox={this.toggleCheckBox} navigation={this.props.navigation} />
          <View style={{ alignItems: 'center' }}>
            {
              this.props.logInState.registerErrorMessage && this.props.logInState.registerErrorMessage !== null &&
              <Text style={{color: 'red'}}>{this.props.logInState.registerErrorMessage}</Text>
            }
            <Button
              disabled={!this.state.agreeTerms}
              icon={<Icon name='small_fb' color='white' size={Config.FONT_SIZE_NORMAL}/>}
              title="Mit Facebook registrieren"
              onPress={this.fbConnect}
              buttonStyle={[Styles.fbButton, Styles.button]}
              titleStyle={{fontWeight: 'bold'}}
              fontWeight='bold'
            />
          </View>
        </View>
        <ContactText navigation={this.props.navigation}/>
      </ScrollView>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    facebookConnect: (payload) => {
      dispatch(facebookConnect(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFacebookScreen);
