import React, { Component } from 'react';
import { View } from 'react-native';
import Swiper from 'react-native-swiper';
import ArticleNode from '../components/ArticleNode';
import ImageNode from '../components/ImageNode';
import Colors from '../constants/Colors';
import ProductNode from './ProductNode';
import screenNames from '../constants/screenNames';
import CustomIndicator from './CustomIndicator';
import * as Config from '../Config';

class NodeScreenComponent extends Component {

  state = {
    path: this.props.navigation.state.params.path,
    view: this.props.navigation.state.params.view,
    display: this.props.navigation.state.params.display,
    currentNodeId: Number(this.props.navigation.state.params.nodeId),
    skipRender: false,
  };

  handleSwiperRef = ref => this.swiper = ref;

  forceNodeUpdate = () => {
    this.forceUpdate();
  }

  componentDidMount() {
    // We are giving a timeout of 1 millisecond to the following actions
    // to ensure that they do not run in the duration of the first render
    setTimeout(() => {
      this.props.navigation.setParams({ forceNodeUpdate: this.forceNodeUpdate });
      this.determineSliceStartEnd();
    }, 1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.skipRender) {
      setTimeout(() => {
        this.setState({skipRender: false});
      }, 50);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      // if any of the nodes we are interested changes.
         this.state.nodesList !== nextState.nodesList
      || this.state.nodesList && this.state.nodesList.filter((nid) => nextProps.nodes[nid] !== this.props.nodes[nid]).length
      || this.props.usersMentioning !== nextProps.usersMentioning
      || this.props.isSendingComment !== nextProps.isSendingComment
      || this.props.upload !== nextProps.upload
      || this.state.skipRender !== nextState.skipRender
    ) {
      return true;
    }
    return false;
  }

  // #595 Filter our articles.
  getNodesList = () => {
    return this.props.list[this.state.path + 'ById'].filter((id) => {
      return (this.props.nodes[id] && this.props.nodes[id].type == 'image' && !this.props.nodes[id].blocked);
    });
  }

  // initially the slice point is -5 from initial node
  determineSliceStartEnd = () => {
    if (!this.state.sliceStart || !this.state.sliceEnd) {
      const nodesList = this.getNodesList();
      const initialNodePosition =  nodesList.indexOf(Number(this.state.currentNodeId));
      const listLength = nodesList.length;
      // start 5 items before initial item or from 0 if there are not enough items
      const sliceStart = (initialNodePosition > Config.SWIPER_ITEMS_BEFORE_CURRENT) ? initialNodePosition - Config.SWIPER_ITEMS_BEFORE_CURRENT : 0;
      // stop 20 items after initial item or at the end of the array
      const sliceEnd = (listLength > initialNodePosition + Config.SWIPER_ITEMS_AFTER_CURRENT) ? initialNodePosition + Config.SWIPER_ITEMS_AFTER_CURRENT : listLength;
      const sliceNodeId = nodesList[sliceStart];
      const initialIndex = initialNodePosition - sliceStart;
      let shortNodesList = nodesList.slice(sliceStart, sliceEnd);
      if (listLength > sliceEnd + 1) {
        shortNodesList.push(666);
      }
      this.setState({
        sliceNodeId,
        sliceStart,
        sliceEnd,
        nodesList: shortNodesList,
        initialIndex,
        realIndex: initialIndex,
        listLength
      });
    }
  }

  // on subsequent calculations it should be the position of the node
  // where we sliced the first time
  determineSliceStartEndAgain = (index) => {
    if (this.state.sliceNodeId) {
      const nodesList = this.getNodesList();
      const initialNodePosition =  nodesList.indexOf(Number(this.state.sliceNodeId));
      const listLength = nodesList.length;
      const sliceStart = nodesList.indexOf(Number(this.state.sliceNodeId));
      const sliceEnd = (listLength > initialNodePosition + index + Config.SWIPER_ITEMS_AFTER_CURRENT) ? initialNodePosition + index + Config.SWIPER_ITEMS_AFTER_CURRENT : listLength;
      const diff = Config.SWIPER_ITEMS_AFTER_CURRENT - 1;
      if (!this.state.sliceEnd || sliceEnd - this.state.sliceEnd > diff) {
        const newSliceStart = sliceStart + diff;
        const newIndex = index - diff - 1;
        const newSliceNodeId = nodesList[newSliceStart];
        let shortNodesList = nodesList.slice(newSliceStart, sliceEnd);
        if (listLength > sliceEnd + 1) {
          shortNodesList.push(666);
        }
        this.setState({
          sliceStart: newSliceStart,
          sliceEnd: sliceEnd,
          realIndex: newIndex + 1,
          skipRender: true,
          nodesList: shortNodesList,
          sliceNodeId: newSliceNodeId,
          listLength
        });
      }
    }
  }

  onIndexChanged = (index) => {
    const currentNodeId = this.state.nodesList[index];
    this.setState({index, currentNodeId}, () => {
      this.props.navigation.setParams({ nodeId: currentNodeId });
    });
    // Update state.
    this.determineSliceStartEndAgain(index);
    // Get nodesList and pagination.
    const nodesList = this.getNodesList();
    const pagination = this.props.pagination[this.state.path];
    // Do the math.
    const initialItemIndex = nodesList.indexOf(this.state.sliceNodeId);
    const realIndex = (initialItemIndex > Config.SWIPER_ITEMS_BEFORE_CURRENT) ? index - Config.SWIPER_ITEMS_BEFORE_CURRENT : index;
    const indexOfNodeInNodesList = initialItemIndex + realIndex;
    // When reaching to the end of the nodes loaded, load more nodes.
    if (nodesList.length - indexOfNodeInNodesList <= Config.SWIPER_ITEMS_BEFORE_PRELOADING) {
      const currentPage = Number(Object.keys(pagination).length - 1);
      const newPage = currentPage + 1;
      // Make sure that current page is not being fetched in order to bring the next one.
      if (this.props.pagination.fetching[this.state.path] === undefined || this.props.pagination.fetching[this.state.path] == -1) {
        const { display, path, view } = this.state;
        this.props.viewGet({
          view,
          path,
          page: newPage,
          display,
          keywords: (path === screenNames.suche) ? this.props.searchKeyword : null,
        });
      }
    }
  };

  scollToNext = () => {
    this.swiper.scrollBy(1);
  };

  scollToPrev = () => {
    this.swiper.scrollBy(-1);
  };

  currentNodeId = () => {
    return this.state.currentNodeId;
  }

  render() {
    if (this.state.initialIndex === undefined || this.state.skipRender) {
      return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
          <CustomIndicator />
        </View>
        );
    }
    return (
      <View style={{flex: 1}}>
        <Swiper
          ref={this.handleSwiperRef}
          index={this.state.realIndex}
          showsButtons={false}
          showsPagination={false}
          style={{ backgroundColor: Colors.background}}
          loadMinimal={true}
          loadMinimalSize={1}
          loadMinimalLoader={<CustomIndicator />}
          onIndexChanged={this.onIndexChanged}
          keyboardShouldPersistTaps={"always"}
          loop={false}
        >
          { this.state.nodesList.filter(nid => nid === 666 || this.props.nodes[nid]).map((nid, i, a) => {
              const node = this.props.nodes[nid];
              return (
                <ImageNode
                  key={nid}
                  currentNodeId={this.currentNodeId}
                  index={i}
                  likeNode={this.props.likeNode}
                  navigation={this.props.navigation}
                  node={(nid === 666) ? {} : this.props.nodes[nid]}
                  products={this.props.nodes[nid] && this.props.nodes[nid].products ?
                    this.props.nodes[nid].products
                      .filter(id => this.props.nodes[id] && (this.props.nodes[id].userId != 17621 || this.props.nodes[id].subProducts))
                      .map(id => this.props.nodes[id]) : []
                  }
                  style={{ flex:1, paddingHorizontal: 10 }}
                  uid={this.props.uid}
                  scollToNext={this.scollToNext}
                  scollToPrev={this.scollToPrev}
                  getProductSuggestions={this.props.getProductSuggestions}
                  showButtons={Config.SWIPER_SHOW_ARROWS && a.length > 1}
                  isLoadingComments={this.props.isLoadingComments}
                  isDeletingCommentId={this.props.isDeletingCommentId}
                  isEditingComment={this.props.isEditingComment}
                  isSendingComment={this.props.isSendingComment}
                  logInState={this.props.logInState}
                  comments={this.props.comments}
                  commentsById={this.props.commentsById}
                  commentsGet={this.props.commentsGet}
                  commentSet={this.props.commentSet}
                  entityDel={this.props.entityDel}
                  usersMentioning={this.props.usersMentioning}
                  usersGetMentioning={this.props.usersGetMentioning}
                  upload={this.props.upload[nid] || {}}
                  fileUpload={this.props.fileUpload}
                  clearUploadedImage={this.props.clearUploadedImage}
                  commentDelete={this.props.commentDelete}
                  isLast={(a.length - 1) === i}
                  likeComment={this.props.likeComment}
                />
              );
            })
          }
        </Swiper>
      </View>
    );
  }
};

export default NodeScreenComponent;
