import React from "react";
import { View, Image } from "react-native";
import {Button} from "react-native-elements";
import Styles from "../../constants/Styles";

export class GoogleButton extends React.Component {
  render() {
    return (
    <Button
      icon={
        <View style={Styles.googleLogoWrapper}>
          <Image
            source={require("../../assets/images/google.png")}
            style={Styles.googleLogo}
          />
        </View>
      }
      title= {this.props.title ? this.props.title : "Sign in with Google"}
      onPress={this.props.onPress}
      buttonStyle={[Styles.googleButton, Styles.button]}
      titleStyle={{fontWeight: 'bold'}}
      disabled={this.props.disabled}
    />
    );
  }
}
