import * as ActionTypes from './actionTypes';

export function whoAmI(payload) {
  return {
    type: ActionTypes.WHO_AM_I,
    payload
  }
}

export function whoAmISuccess(payload) {
  return {
    type: ActionTypes.WHO_AM_I_SUCCESS,
    payload
  }
}

export function whoAmIFail(payload) {
  return {
    type: ActionTypes.WHO_AM_I_FAIL,
    payload
  }
}

export function getTokenInit(payload) {
  return {
    type: ActionTypes.GET_TOKEN_INIT,
    payload
  }
}

export function getTokenSuccess(payload) {
  return {
    type: ActionTypes.GET_TOKEN_SUCCESS,
    payload
  }
}

export function getTokenFail(payload) {
  return {
    type: ActionTypes.GET_TOKEN_FAIL,
    payload
  }
}

export function loginInit(payload) {
  return {
    type: ActionTypes.LOGIN_INIT,
    payload
  }
}

export function loginSuccess(payload) {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload
  }
}

export function loginFail(payload) {
  return {
    type: ActionTypes.LOGIN_FAIL,
    payload
  }
}

export function logout(payload) {
  return {
    type: ActionTypes.LOGOUT,
    payload
  }
}

export function logoutSuccess(payload) {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    payload
  }
}

export function logoutFail(payload) {
  return {
    type: ActionTypes.LOGOUT_FAIL,
    payload
  }
}

export function editUser(payload) {
  return {
    type: ActionTypes.EDIT_USER,
    payload
  };
}

export function editUserSuccess(payload) {
  return {
    type: ActionTypes.EDIT_USER_SUCCESS,
    payload
  };
}

export function editUserFail(payload) {
  return {
    type: ActionTypes.EDIT_USER_FAIL,
    payload
  };
}

export function userRegister(payload){
  return {
    type: ActionTypes.USER_REGISTER,
    payload
  }
}

export function userRegisterSuccess(payload){
  return {
    type: ActionTypes.USER_REGISTER_SUCCESS,
    payload
  }
}

export function userRegisterFail(payload){
  return {
    type: ActionTypes.USER_REGISTER_FAIL,
    payload
  }
}

export function forgotPassword(payload){
  return {
    type: ActionTypes.FORGOT_PASSWORD,
    payload
  }
}

export function forgotPasswordSuccess(payload){
  return {
    type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
    payload
  }
}

export function forgotPasswordFail(payload){
  return {
    type: ActionTypes.FORGOT_PASSWORD_FAIL,
    payload
  }
}

export function usernameValidation(payload){
  return {
    type: ActionTypes.USERNAME_VALIDATION,
    payload
  }
}

export function usernameValidationSuccess(payload){
  return {
    type: ActionTypes.USERNAME_VALIDATION_SUCCESS,
    payload
  }
}

export function usernameValidationFail(payload){
  return {
    type: ActionTypes.USERNAME_VALIDATION_FAIL,
    payload
  }
}

export function emailValidation(payload){
  return {
    type: ActionTypes.EMAIL_VALIDATION,
    payload
  }
}

export function emailValidationSuccess(payload){
  return {
    type: ActionTypes.EMAIL_VALIDATION_SUCCESS,
    payload
  }
}

export function emailValidationFail(payload){
  return {
    type: ActionTypes.EMAIL_VALIDATION_FAIL,
    payload
  }
}

export function urlToNav(payload) {
  return {
    type: ActionTypes.URL_TO_NAV,
    payload
  }
}

export function appleConnect(payload){
  return {
    type: ActionTypes.APPLE_CONNECT,
    payload
  }
}

export function appleConnectSuccess(payload){
  return {
    type: ActionTypes.APPLE_CONNECT_SUCCESS,
    payload
  }
}

export function appleConnectFail(payload){
  return {
    type: ActionTypes.APPLE_CONNECT_FAIL,
    payload
  }
}

export function appleConnectRedirect(payload){
  return {
    type: ActionTypes.APPLE_CONNECT_REDIRECT,
    payload
  }
}

export function googleConnect(payload){
  return {
    type: ActionTypes.GOOGLE_CONNECT,
    payload
  }
}

export function googleConnectSuccess(payload){
  return {
    type: ActionTypes.GOOGLE_CONNECT_SUCCESS,
    payload
  }
}

export function googleConnectFail(payload){
  return {
    type: ActionTypes.GOOGLE_CONNECT_FAIL,
    payload
  }
}
