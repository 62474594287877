import { priceFormat } from '../../helpers/formatNumbers'

const flattenCommerceProduct = (node) => {
  return {
    id: node.id ? node.id : undefined,
    label: node.label ? node.label : undefined,
    self: node.self ? node.self : undefined,
    parentSLI: node.parent ? node.parent : undefined,
    parent: node.parentDBS ?? undefined,
    sku: node.sku ?  node.sku : undefined,
    current_price: node.current_price ? priceFormat(node.current_price) : 0,
    original_price: node.original_price ? priceFormat(node.original_price) : 0,
    on_sale: node.on_sale ?? undefined,
    delivery_time_id: node.delivery_time ? node.delivery_time.id : undefined,
    delivery_time_label: node.delivery_time ? node.delivery_time.label : undefined,
    images: node.images ? node.images : undefined,
    details_manufacturer_id: node.details_manufacturer ? node.details_manufacturer.id : undefined,
    details_manufacturer_label: node.details_manufacturer ? node.details_manufacturer.label : undefined,
    details_designer_id: node.details_designer ? node.details_designer.id : undefined,
    details_designer_label: node.details_designer ? node.details_designer.label : undefined,
    details_shape: node.details_shape ? node.details_shape.label : undefined,
    details_materials: node.details_materials ?? undefined,
    details_type_of_chairs: node.details_type_of_chairs ?? undefined,
    details_feature: node.details_feature ?? undefined,
    details_type_of_wood_label: node.details_type_of_wood ? node.details_type_of_wood.label : undefined,
    details_material_descript_value: node.details_material_descript ? node.details_material_descript.value : undefined,
    details_size_description_value: node.details_size_description ? node.details_size_description.value : undefined,
    details_family_label: node.details_family ? node.details_family.label : undefined,
    details_energy_label_type: node.details_energy_label_type ? node.details_energy_label_type.label : undefined,
    details_color_ranges: node.details_color_ranges ?? undefined,
    details_size_margin_label: node.details_size_margin ? node.details_size_margin.label : undefined,
    details_ean: node.details_ean ?? undefined,
    light_energy_efficiency: node.light_energy_efficiency ?? undefined,
    light_power: node.light_power ?? undefined,
    light_dimmable_label: node.light_dimmable ? node.light_dimmable.label : undefined,
    light_color_tempe: node.light_color_tempe ?? undefined,
    light_lamp: node.light_lamp ?? undefined,
    light_control: node.light_control ?? undefined,
    light_lightsource: node.light_lightsource ?? undefined,
    light_checkmark: node.light_checkmark ?? undefined,
    light_color_repro: node.light_color_repro ?? undefined,
    light_fixture: node.light_fixture ?? undefined,
    light_luminous_in: node.light_luminous_in ?? undefined,
    light_protection: node.light_protection ?? undefined,
    light_class: node.light_class ?? undefined,
    light_scattering: node.light_scattering ?? undefined,
    light_detail_pdf_filename: node.light_detail_pdf ? node.light_detail_pdf.filename : undefined,
    light_detail_pdf_uri: node.light_detail_pdf ? node.light_detail_pdf.self : undefined,
    light_power_supply: node.light_power_supply ?? undefined,
    light_energy_image_filename: node.light_energy_image ? node.light_energy_image.filename : undefined,
    light_energy_image_uri: node.light_energy_image ? node.light_energy_image.self : undefined,
    vt_size_label: node.vt_size ? node.vt_size.label : undefined,
    vt_setting_label: node.vt_setting ? node.vt_setting.label : undefined,
    vt_frame_label: node.vt_frame ? node.vt_frame.label : undefined,
    vt_chair_cover_label: node.vt_chair_cover ? node.vt_chair_cover.label : undefined,
    vt_color_cable_label: node.vt_color_cable ? node.vt_color_cable.label : undefined,
    vt_color_manufact_label: node.vt_color_manufact ? node.vt_color_manufact.label : undefined,
    vt_chair_seat_label: node.vt_chair_seat ? node.vt_chair_seat.label : undefined,
    vt_city_label: node.vt_city ? node.vt_city_label : undefined,
    vt_motive_label: node.vt_motive ? node.vt_motive.label : undefined,
    vt_material_label: node.vt_material ? node.vt_material.label : undefined,
    vt_color_hook_label: node.vt_color_hook ? node.vt_color_hook.label : undefined,
    vt_usm_row_1_label: node.vt_usm_row_1 ? node.vt_usm_row_1.label : undefined,
    vt_chair_trim_label: node.vt_chair_trim ? node.vt_chair_trim.label : undefined,
    vt_usm_row_2_label: node.vt_usm_row_2 ?  node.vt_usm_row_2.label : undefined,
    vt_table_frame_label: node.vt_table_frame ? node.vt_table_frame.label : undefined,
    vt_usm_row_3_label: node.vt_usm_row_3 ? node.vt_usm_row_3.label : undefined,
    vt_usm_row_4_label: node.vt_usm_row_4 ? node.vt_usm_row_4.label : undefined
  }
};

export default flattenCommerceProduct;
