import React, { Component } from 'react';
import { View, Text } from 'react-native';
import Styles from "../../constants/Styles";


class ThankYouScreenComponent extends Component {

  componentDidMount() {
    this.props.cartGet();
  }

  state = {
    order_number: this.props.navigation.getParam('order_number', '')
  }

  render() {
    return (
      <View style={[Styles.centerView]}>
        <Text style={[Styles.pageTitle]}>Wir haben deine Bestellung erhalten.</Text>
        <Text><Text>Deine Bestellnummer lautet: </Text><Text style={{fontWeight: 'bold'}}>{this.state.order_number}</Text></Text>
        <Text style={[Styles.textCenter]}>Du erhältst eine Bestellbestätigung per EMail mit weiteren Informationen.</Text>
      </View>
    )
  }
}

export default ThankYouScreenComponent;
