import * as ActionTypes from './actionTypes';

export function slideGet(payload) {
  return {
    type: ActionTypes.SLIDE_GET,
    payload
  }
}

export function slideGetSuccess(payload) {
  return {
    type: ActionTypes.SLIDE_GET_SUCCESS,
    payload
  }
}

export function slideGetFail(payload) {
  return {
    type: ActionTypes.SLIDE_GET_FAIL,
    payload
  }
}

export function shoppingSlideGet(payload) {
  return {
    type: ActionTypes.SHOPPING_SLIDE_GET,
    payload
  }
}

export function shoppingSlideGetSuccess(payload) {
  return {
    type: ActionTypes.SHOPPING_SLIDE_GET_SUCCESS,
    payload
  }
}

export function shoppingSlideGetFail(payload) {
  return {
    type: ActionTypes.SHOPPING_SLIDE_GET_FAIL,
    payload
  }
}
