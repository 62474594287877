import React, { Component } from "react";
import { Keyboard, Platform, Text, TextInput, View, LogBox } from 'react-native';
import HTML from 'react-native-render-html';
import { Provider } from 'react-redux';
import * as Config from './Config';
import RootScreen from "./containers/RootScreen";
import store from './store/store';

// Before rendering any navigation stack
import { enableScreens } from 'react-native-screens';
if (Platform.OS !== "ios" && !__DEV__) {
  enableScreens();
}

// Initialize error reporting.
import * as Sentry from 'sentry-expo';
Sentry.init({
  dsn: 'https://75f5c3aa700e4b368075883c21a38a8e:e6074dd1b81d4eb98b6c00e9746357a4@sentry.io/272589',
  enableInExpoDevelopment: Config.SENTRY_REPORT_DEV,
  debug: Config.SENTRY_REPORT_DEV,
});

// Disable Font Scaling on Text, TextInput and HTML components
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;
HTML.defaultProps = HTML.defaultProps || {};
HTML.defaultProps.allowFontScaling = false;

// Ignore specific harmless warnings but not all.
// Add one warning per line.
// Removing since it does not have web support
if (Platform.OS !== 'web') {
  LogBox.ignoreLogs([
    'Warning: `flexWrap: `wrap`` is not supported'
  ]);
}

export default class App extends Component {

  state = {
    marginBottom: 0
  };

  componentDidMount() {
    // List for keyboard changes
    if (!this.keyboardDidHideListener) {
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.keyboardDidHide);
    }
    if (!this.keyboardDidShowListener) {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.keyboardDidShow);
    }
  }

  // Remove all listeners on unMount.
  componentWillUnmount() {
    if (this.keyboardDidHideListener && this.keyboardDidHideListener.remove) {
      this.keyboardDidHideListener.remove();
    }
    if (this.keyboardDidShowListener && this.keyboardDidShowListener.remove) {
      this.keyboardDidShowListener.remove();
    }
  }

  render(){
    return(
      <Provider store={store}>
        <View style={{ flex: 1, marginBottom: this.state.marginBottom }}>
          <RootScreen />
        </View>
      </Provider>
    )
  }

  keyboardDidHide = (data) => {
    if (Platform.OS === 'ios') {
      this.setState({
        marginBottom: 0
      });
    }
  }

  keyboardDidShow = (data) => {
    if (Platform.OS === 'ios') {
      this.setState({
        marginBottom: data.endCoordinates.height,
      });
    }
  }

}
