import React, { Component } from 'react';
import PaymentScreenComponent from "../components/shopping/PaymentScreenComponent";
import {cartPaymentCollect, cartPaymentSelect } from "../actions";
import {connect} from "react-redux";


class PaymentScreen extends Component {

  render() {
    return (
      <PaymentScreenComponent
        navigation={this.props.navigation}
        cartPaymentCollect={this.props.cartPaymentCollect}
        paymentMethods={this.props.paymentMethods}
        isLoadingPayment={this.props.isLoadingPayment}
        selectedPaymentMethod={this.props.selectedPaymentMethod}
        cartPaymentSelect={this.props.cartPaymentSelect}
      />
    )
  }

}


const mapStateToProps = (state) => {
  return {
    isLoadingPayment: state.checkout.isLoadingPayment,
    paymentMethods: state.checkout.paymentMethods,
    selectedPaymentMethod: state.checkout.cart.payment_method
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cartPaymentCollect: (payload) => {
      dispatch(cartPaymentCollect(payload))
    },
    cartPaymentSelect: (payload) => {
      dispatch(cartPaymentSelect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
