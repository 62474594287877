const flattenSlide = (node) => {
  if (node.article.length) {
    if (Array.isArray(node.article[0].image)) {
      node.article[0].image = node.article[0].image[0];
    }
  }
  return {
    id: node.id ?? undefined,
    title: node.title ?? undefined,
    body: node.body ?? undefined,
    timestamp: node.timestamp ?? undefined,
    visibility: node.visibility ?? undefined,
    customPage: node.custom_page ?? undefined,
    weight: node.weight ?? undefined,
    onlyImage: node.only_image ?? undefined,
    link: node.link ? node.link.substring(node.link.lastIndexOf('/') + 1) : undefined,
    fullLink: node.link ?? undefined,
    ...(!node.article[0] ? {} : {
      articleId: node.article[0].id ?? undefined,
      articleLabel: node.article[0].label ?? undefined,
      articleSelf: node.article[0].self ?? undefined,
      articleType: node.article[0].type ?? undefined,
      artucleSeriesId: node.article[0].series ? node.article[0].series.id : undefined,
      artucleSeriesLabel: node.article[0].series ? node.article[0].series.label : undefined,
      artucleSeriesSelf: node.article[0].series ? node.article[0].series.self : undefined,
      articleTags: node.article[0].tags ?? undefined,
      articleTimestamp: node.article[0].timestamp ?? undefined,
      articleTime: node.article[0].time ?? undefined,
      articleImageId: node.article[0].image ? node.article[0].image.id : undefined,
      articleImageSelf: node.article[0].image ? node.article[0].image.self : undefined,
      articleImageFilemime: node.article[0].image ? node.article[0].image.filemime : undefined,
      articleImageFilesize: node.article[0].image ? node.article[0].image.filesize : undefined,
      articleImageWidth: node.article[0].image ? node.article[0].image.width : undefined,
      articleImageHeight: node.article[0].image ? node.article[0].image.height : undefined,
      articleImageStylesScalecrop: node.article[0].image ? node.article[0].image.styles["scalecrop_300x450"] : undefined,
      articleImageStyles1200x900Conditional: node.article[0].image ? node.article[0].image.styles["1200x900-conditional"] : undefined,
      articleUserId: node.article[0].user ? node.article[0].user.id : undefined,
      articleUserSelf: node.article[0].user ? node.article[0].user.self : undefined,
      articleUserLabel: node.article[0].user ? node.article[0].user.label : undefined,
      articleUserMail: node.article[0].user ? node.article[0].user.mail : undefined,
      articleUserUsername: node.article[0].user ? node.article[0].user.username : undefined,
      articleUserPictureUri: node.article[0].user && node.article[0].user.picture ? node.article[0].user.picture.uri : undefined,
      articleLikes: node.article[0].likes ?? undefined,
      articleLiked: node.article[0].liked ?? undefined,
      articleComments: node.article[0].comments ?? undefined,
    }),
    ...(!node.image ? {} : {
    imageId: node.image.id ?? undefined,
    imageSelf: node.image.self ?? undefined,
    imageFilemime: node.image.filemime ?? undefined,
    imageFilesize: node.image.filesize ?? undefined,
    imageWidth: node.image.imageWidth ?? undefined,
    imageHeight: node.image.imageHeight ?? undefined,
    imageStylesScalecrop1150x500: node.image.styles["scalecrop_1150x500"] ?? undefined,
    imageStyles585x330: node.image.styles["585x330"] ?? undefined,
  })
  }
};

export default flattenSlide;
