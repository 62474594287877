import React from 'react';
import { connect } from 'react-redux';
import {editUser, fileUpload, applePasswordAlertDismiss, appleConnectRedirect} from "../actions/index";
import UserEditComponent from "../components/UserEditComponent";

const UserEditScreen = (props) => {
  return (
    <UserEditComponent
      navigation={props.navigation}
      editUser={props.editUser}
      user={props.user}
      fileUpload={props.fileUpload}
      upload={props.upload}
      uploading={props.uploading}
      appleConnectRedirect={props.appleConnectRedirect}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    upload: state.images.upload.user,
    uploading: state.images.upload.user.uploading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (payload) => {
      dispatch(editUser(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    appleConnectRedirect: (payload) => {
      dispatch(appleConnectRedirect(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditScreen);
