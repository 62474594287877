import {AdMobInterstitial, setTestDeviceIDAsync, isAvailableAsync, requestPermissionsAsync, getPermissionsAsync} from "expo-ads-admob";
import {interstitialAdUnitID} from "../constants/AdConfig";

export async function adMob () {
  return isAvailableAsync()
    .then(() => setTestDeviceIDAsync('EMULATOR'))
    .then(() => AdMobInterstitial.setAdUnitID(interstitialAdUnitID))
    .then(() => askForAdMobPermissions())
    .then(adMobPermission => AdMobInterstitial.requestAdAsync({ servePersonalizedAds: adMobPermission}))
    .then(() => AdMobInterstitial.showAdAsync())
    .then(() => ({ adShown: true }))
    .catch(error => ({ adShown: false, error }));
}

// Ask for permission to use data for tracking the user or the device in order to serve personalized ads.
export async function askForAdMobPermissions () {
  let settings = await getPermissionsAsync();
  if (settings.canAskAgain && !settings.granted) {
    settings = await requestPermissionsAsync();
  }
  return settings.granted;
}
