import React, { PureComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';

import * as Config from '../Config';
import { Icon } from '../components/Icon';
import Colors from '../constants/Colors';

export default class UserSocialIcons extends PureComponent {
  render() {
    // properties on the User object that are valid social media profile links
    // each one is a string/url pointing to the user profile
    const social = ['facebook', 'blog', 'instagram', 'pinterest', 'etsy'];

    // render individual icons
    const iconsRendered = social.map((iconName, i) => {
      if (this.props.user[iconName]) return (
        <TouchableOpacity key={i} onPress={() => { this.props.navigate('Webpage', {data: {uri: this.props.user[iconName]}}) }} >
          <Icon
            color={Colors.iconLightGrey}
            name={( iconName === 'etsy')?'shopping_cart':(iconName === 'blog'? 'homepage' : iconName)}
            size={Config.SIZE_ICON}
            style={{marginHorizontal: 5}}
          />
        </TouchableOpacity>
      );
    });

    // return all icons in a row
    return (
      <View style={{flexDirection: 'row', marginBottom: Config.STANDARD_MARGIN}}>
        {iconsRendered}
      </View>
    );
  }
}
