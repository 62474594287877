import React, { PureComponent } from 'react';
import {
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import CollapsilbleText from "../components/CollapsilbleText";
import Styles from '../constants/Styles';
import { Thumbnail } from "../components/Thumbnail";
import moment from 'moment';

export default class PrivateMessageLine extends PureComponent {
  pressOnAvatar = () => {
    const { navigation: {navigate}, item, trimBody } = this.props;
    const targetUserId = item.userId === this.props.logInStateId ? item.recipientId: item.userId;
    navigate({
      routeName: 'User',
      key: `user${targetUserId}`,
      params: { id: targetUserId}
    });
  };

  pressOnText = () => {
    const { navigation: {navigate}, item, trimBody } = this.props;
    if (trimBody) {
      const targetUserId = item.userId === this.props.logInStateId ? item.recipientId : item.userId;
      navigate({
        routeName: 'UserDiscussion',
        key: `discussion${targetUserId}`,
        //TODO: make it just id
        params: { user: {id: targetUserId} }
      });
    }
  };

  render() {
    const { item, logInStateId, trimBody } = this.props;
    const prefix = (trimBody && item.userId === logInStateId ) ? "recipient": "user";
    let text = item.body.replace(/<br>/gm, '');
    text = trimBody ? text.substring(0,100) : text;
    if (item.isNew && item.isNew > 0) {
      text = '<strong>' + text + '</strong>';
    }
    return (
      <View style={Styles.messageContainer}>
        <TouchableOpacity onPress={this.pressOnAvatar}>
          <Thumbnail
            size="medium"
            source={{uri: item[prefix + "PictureUri"]}}
          />
        </TouchableOpacity>
        <TouchableOpacity style={{flex: 1, paddingLeft: 10 }} onPress={this.pressOnText}>
          <View style={{ flexDirection: 'row', flex: 1}}>
            <TouchableOpacity onPress={this.pressOnAvatar}>
              <Text onPress={this.pressOnAvatar} style={{fontFamily: 'OpenSansBold'}}>{item[prefix + "Label"]} </Text>
            </TouchableOpacity>
            <Text>{moment.unix(item.timestamp).format("HH[.]mm [Uhr - ]DD[.]MM[.]YYYY")}</Text>
          </View>
          <CollapsilbleText text={trimBody ? text.substring(0,100) : text} collapse={false} align={'left'} navigation={this.props.navigation} />
        </TouchableOpacity>
      </View>
    );
  }
}
