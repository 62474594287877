import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import processCommerceProducts from "./helpers/processCommerceProducts";

const initialState = {
  isLoading: false,
  // Object of objects.
  // - Keys are the commerce product_id.
  // - Values are the commerce product object itself.
  commerceProducts: {},
  // Object of arrays.
  // - Keys are the ids of the parent object.
  // - Values are arrays of the ids of the variations a parent has.
  commerceProductsById: {}
};

function commerceProductGet(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function commerceProductGetSuccess(state, action) {
  const processedCommerceProducts = processCommerceProducts(state.commerceProducts, state.commerceProductsById, action.payload.data);
  return {
    ...state,
    commerceProducts: {
      ...state.commerceProducts,
      ...processedCommerceProducts.new,
    },
    commerceProductsById: processedCommerceProducts.byId,
    isLoading: false
  };
}

function commerceProductGetFail(state) {
  return {
    ...state,
    isLoading: false,
  };
}

const commerceProducts = createReducer(initialState, {
  [ActionTypes.COMMERCE_PRODUCT_GET]: commerceProductGet,
  [ActionTypes.COMMERCE_PRODUCT_GET_SUCCESS]: commerceProductGetSuccess,
  [ActionTypes.COMMERCE_PRODUCT_GET_FAIL]: commerceProductGetFail,
});

export default commerceProducts;
