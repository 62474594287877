import React, {Component} from 'react';
import {
  AppState,
  FlatList,
  Image,
  Keyboard, KeyboardAvoidingView, Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import CustomButton from "./general/CustomButton";
import * as Config from '../Config';
import Colors from '../constants/Colors';
import Layout from "../constants/Layout";
import Styles from '../constants/Styles';
import CommentForm from '../components/CommentForm';
import CommentItem from '../components/CommentItem';
import CustomIndicator from '../components/CustomIndicator';
import CustomMentionsTextInput from "./CustomMentionsTextInput";
import * as Permissions from 'expo-permissions';
import { rotateImage } from '../helpers/images';

class CommentsScreenComponent extends Component {

  state = {
    appState: 'active',
    commentSent: false,
    focusCommentOnceAllowed: true,
    manipulating: false,
    page: 0,
    // By default 0/false or else the comment ID that the user is replying to.
    replyToComment: 0,
    // By default show just a few comments (3) collapse the rest.
    showAll: false,
  };

  componentDidMount() {
    const { node } = this.props;
    // 1. Get latest Comments.
    this.props.commentsGet({ nid: this.props.node.id });
    // 2. List for keyboard changes.
    if (!this.keyboardDidHideListener) {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this.keyboardDidHide,
      );
    }
    // 3. Listen to app state changes.
    AppState.addEventListener('change', this.handleAppStateChange);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.commentsById.length !== nextProps.commentsById.length
      || this.props.isLoading !== nextProps.isLoading
      || this.props.isDeletingCommentId !== nextProps.isDeletingCommentId
      || this.props.isSendingComment !== nextProps.isSendingComment
      || this.props.isEditingComment !== nextProps.isEditingComment
      || this.state.text !== nextState.text
      || this.state.showAll !== nextState.showAll
      || this.state.replyToComment !== nextState.replyToComment
      || this.props.usersMentioning !== nextProps.usersMentioning
      || this.props.upload !== nextProps.upload
      || this.state.focusCommentOnceAllowed !== nextState.focusCommentOnceAllowed
      || this.props.focusComment !== nextProps.focusComment
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    // If user finished commenting and it was a replyToComment, reset it,
    if (!this.props.isSendingComment && prevProps.isSendingComment) {
      if (this.state.replyToComment != 0){
        this.setState({ replyToComment: 0 });
      }
    }
  }
    
  render() {
    let comments = [];
    const commentsIds = this.props.node.commentsIds;

    if (commentsIds) {
      comments = commentsIds.map(commentId => this.props.comments[commentId])
        // In case of deleting, nodeCommentIds are deleted with some delay compared to comments state
        .filter((commentInArray) => {
          return commentInArray !== undefined
        })
        // Sort comments by the combination of id or parentId if any so that they are threaded.
        .sort((a,b) => {
          const sortA = parseInt((a.parent > 0 ? a.parent : a.id) + a.id.toString());
          const sortB = parseInt((b.parent > 0 ? b.parent : b.id) + b.id.toString());
          return (sortA - sortB);
        });

      // Slice comments if more than 3.
      if (!this.state.showAll) {
        comments = comments.slice(0, 3);
      }
    }

    return (
      <View style={Styles.pageViewContainer}>
        <FlatList
          backgroundColor={Colors.background}
          contentContainerStyle={Styles.pageScrollViewContainer}
          data={comments}
          horizontal={false}
          initialNumToRender={Config.COMMENTS_PER_PAGE}
          ListFooterComponent={this.renderFooter}
          keyboardShouldPersistTaps={'handled'}
          keyExtractor={data => 'comment-' + data.id}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={this.state.page > 1 ? Config.FLATLIST_ON_END_REACHED_THRESHOLD : 0.1}
          ref={this.listRefCallback}
          renderItem={this.renderItem}
          ItemSeparatorComponent={this.renderSeparator}
        />
      </View>
    )
  }

  componentWillUnmount() {
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener.remove();
    }
    AppState.removeEventListener('change', this.handleAppStateChange);
  }

  listRefCallback = ref => this.listRef = ref;

  onEndReached = () => {
    const { node } = this.props;
    let new_page = this.state.page + 1;
    if (this.state.showAll && new_page <= Math.ceil(node.comments/Config.COMMENTS_PER_PAGE)) {
      this.props.commentsGet({nid: node.id, page: new_page});
      this.setState({page: new_page});
    }
  };

  toggleCommentSent = (value) => {
    this.setState({ commentSent: value,  showAll: true});
  };

  keyboardDidHide = () => {
    if (this.state.commentSent) {
      this.listRef.scrollToEnd();
      this.toggleCommentSent(false);
    }
  };

  onRefresh = ()  => {
    const { node } = this.props;
    this.props.commentsGet({nid: node.id});
  };

  // #565 Refresh comments when appState changes to active but only if focus is here.
  handleAppStateChange = (nextAppState) => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      if (this.props.navigation.isFocused()) {
        this.onRefresh();
      }
    }
    this.setState({appState: nextAppState});
  };

  renderFooter = () => {
    const autoFocus = this.state.focusCommentOnceAllowed && this.props.focusComment == this.props.node.id;
    return (
      <View style={{flex: 1}}>
        {this.props.isLoading && <CustomIndicator /> }
        {!this.props.isLoading && !this.state.showAll && this.props.node.commentsIds && this.props.node.commentsIds.length > 3 && 
          <TouchableOpacity onPress={() => {this.setState({focusCommentOnceAllowed: false, showAll: true}) }}>
            <Text style={[Styles.simpleLink, {
              marginTop: 20,
              alignSelf: 'center'
            }]}>alle Kommentare anzeigen</Text>
          </TouchableOpacity>
        }
        {this.state.replyToComment === 0 && autoFocus &&
          <CommentForm {...this.props} autoFocus={true} toggleCommentSent={this.toggleCommentSent} />
        }
        {this.state.replyToComment === 0 && !autoFocus &&
          <CommentForm {...this.props} autoFocus={false} toggleCommentSent={this.toggleCommentSent} />
        }

      </View>
    )
  };

  renderItem = (data) => (
    <View style={Styles.padInputWidth}>
      <CommentItem
        comment={data.item}
        commentDelete={this.props.commentDelete}
        isDeletingCommentId={this.props.isDeletingCommentId}
        logInState={this.props.logInState}
        navigation={this.props.navigation}
        replyToComment={() => {
          this.setState({
            focusCommentOnceAllowed: false,
            replyToComment: (this.state.replyToComment == data.item.id) ? 0 : data.item.id,
            showAll: true,
          });
        }}
        usersMentioning={this.props.usersMentioning}
        usersGetMentioning={this.props.usersGetMentioning}
        likeComment={this.props.likeComment}
      />
      {this.state.replyToComment == data.item.id &&
        <CommentForm {...this.props} initialText={'[@' + data.item.userUserName + '] '} autoFocus={true} toggleCommentSent={this.toggleCommentSent} parent={data.item.parent > 0 ? data.item.parent : data.item.id} />
      }
    </View>
  );

  renderSeparator = () => {
    return (
      <View style={{ height: 1, width: Config.IS_TABLET ? '50%' : "100%", alignSelf: 'center', backgroundColor: "#CED0CE" }} />
    );
  }

}
export default CommentsScreenComponent
