import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Styles from '../constants/Styles';
import Layout from '../constants/Layout';
import { Thumbnail } from "../components/Thumbnail";
import * as Config from '../Config';

class UserListItem extends React.Component{
  onPress = () => { this.props.navigation.navigate({routeName: 'User', key: `user${this.props.item.id}`, params: { user: this.props.item, id: this.props.item.id }}); };
  render() {
  return (
      <TouchableOpacity
        onPress={this.onPress}
        style={{ flex: 1, minHeight: 60 }}
      >
        <View style={[Styles.suggestionsRowContainer, { marginLeft: 20, flex: 1, width: Layout.window.width / 2, alignSelf: 'center' }]}>
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Thumbnail
              size="small"
              source={{uri: this.props.item.pictureUri }}
            />
          </View>
          <View style={Styles.userDetailsBox}>
            <Text style={{ fontSize: Config.FONT_SIZE_NORMAL}}>{this.props.item.label}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default UserListItem;
