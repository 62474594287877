import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import flattenShop from "./helpers/flattenShop";

const initialState = {
  isGettingShop: false,
  shops: {},
  shopsById: [],
};

function shopGet(state) {
  return {
    ...state,
    isGettingShop: true,
  };
}

function shopGetSuccess(state, action) {
  let shops = Object.assign({}, state.shops);
  let shopsById = [];
  action.payload.map((shop) => {
    if (shop.user) {
      shops = {
        ...shops,
        [shop.user.id]: {
          ...shops[shop.user.id],
          ...flattenShop(shop)
        },
      };
      shopsById.push(shop.user.id);
    }
  });
  return {
    ...state,
    isGettingShop: false,
    shops,
    shopsById
  };
}

function shopGetFail(state) {
  return {
    ...state,
    isGettingShop: false,
  };
}

const shops = createReducer(initialState, {
  [ActionTypes.SHOP_GET]: shopGet,
  [ActionTypes.SHOP_GET_SUCCESS]: shopGetSuccess,
  [ActionTypes.SHOP_GET_FAIL]: shopGetFail,
});

export default shops;
