import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  commentsGet,
  commentSet,
  entityDel,
  usersGetMentioning,
  fileUpload,
  clearUploadedImage,
  likeNode,
  viewGet,
  commentDelete,
  getProductSuggestions,
  likeComment
} from "../actions/index";
import NodeScreenComponent from "../components/NodeScreenComponent";
import {nodesGet} from "../actions";

class NodeScreen extends Component {
  render() {
    return(
      <NodeScreenComponent
        isLoading={this.props.isLoading}
        likeNode={this.props.likeNode}
        list={this.props.list}
        navigation={this.props.navigation}
        nodes={this.props.nodes}
        pagination={this.props.pagination}
        uid={this.props.uid}
        viewGet={this.props.viewGet}
        isLoadingComments={this.props.isLoadingComments}
        isDeletingCommentId={this.props.isDeletingCommentId}
        isEditingComment={this.props.isEditingComment}
        isSendingComment={this.props.isSendingComment}
        logInState={this.props.logInState}
        comments={this.props.comments}
        commentsById={this.props.commentsById}
        commentsGet={this.props.commentsGet}
        commentSet={this.props.commentSet}
        entityDel={this.props.entityDel}
        onRefresh={this.onRefresh}
        onEndReached={this.onEndReached}
        usersMentioning={this.props.usersMentioning}
        usersGetMentioning={this.props.usersGetMentioning}
        upload={this.props.upload}
        fileUpload={this.props.fileUpload}
        clearUploadedImage={this.props.clearUploadedImage}
        commentDelete={this.props.commentDelete}
        isGettingUsersContent={this.props.isGettingUsersContent}
        isGettingUsersComments={this.props.isGettingUsersComments}
        usersContent={this.props.usersContent}
        usersComments={this.props.usersComments}
        searchKeyword={this.props.searchKeyword}
        getProductSuggestions={this.props.getProductSuggestions}
        isGettingArticle={this.props.isGettingArticle}
        nodesGet={this.props.nodesGet}
        likeComment={this.props.likeComment}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    comments: state.comments.comments,
    commentsById: state.comments.commentsById,
    isLoadingComments: state.comments.isLoadingComments,
    isLoading: state.images.isLoading,
    isDeletingCommentId: state.comments.isDeletingCommentId,
    isEditingComment: state.comments.isEditingComment,
    isSendingComment: state.comments.isSendingComment,
    list: state.images,
    logInState: state.user,
    nodes: state.images.nodes,
    uid: state.user.id,
    upload: state.images.upload,
    usersMentioning: state.users.usersMentioning,
    isGettingUsersContent: state.usersContent.isLoading,
    isGettingUsersComments: state.usersComments.isLoading,
    usersContent: state.usersContent,
    usersComments: state.usersComments,
    pagination: state.pagination,
    searchKeyword: state.images.searchKeyword,
    isGettingArticle: state.images.isGettingArticle,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUploadedImage: (payload) => {
      dispatch(clearUploadedImage(payload))
    },
    commentsGet: (payload) => {
      dispatch(commentsGet(payload))
    },
    commentSet: (payload) => {
      dispatch(commentSet(payload))
    },
    entityDel: (payload) => {
      dispatch(entityDel(payload))
    },
    commentDelete: (payload) => {
      dispatch(commentDelete(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    getProductSuggestions: (payload) => {
      dispatch(getProductSuggestions(payload))
    },
    nodesGet: (payload) => {
      dispatch(nodesGet(payload))
    },
    likeComment: (payload) => {
      dispatch(likeComment(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeScreen);
