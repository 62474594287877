import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';
import sampleCombine from 'xstream/extra/sampleCombine';

export function messagesGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.MESSAGES_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const subcategory = payload.subcategory || 'messages';
      const page = payload.page || 0;
      let path = (subcategory == 'messages_user') ? 'api/v1.1/' : 'api/v1.0/';
      path += subcategory + '?sort=-timestamp' + '&page=' + page + '&range=' + Config.MESSAGES_PER_PAGE;
      return ({
        url: Config.BASE_URL + path,
        category: 'messages',
        subcategory: subcategory,
        reset: payload.reset || false,
        page: page,
      });
    })
    .map(payload => {
      return payload;
    });

  const response$ = sources.HTTP
    .select('messages')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.length) {
          data.subcategory = arr[0].request.subcategory;
          data.reset = arr[0].request.reset;
          data.page = arr[0].request.page;
          return actions.messagesGetSuccess(data);
        }
        else {
          data.subcategory = arr[0].request.subcategory;
          return actions.messagesGetFail(data);
        }
      }
      catch(e){
        data.subcategory = arr[0].request.subcategory;
        return actions.messagesGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function messagesGetSuccess(sources) {
  const nodes$ = sources.STATE.map(state => state.images.nodes);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.MESSAGES_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      const missing = arr.data
        .filter((data) => !isNaN(parseFloat(data.node_ref)) && data.node_ref > 0 && !nodes[data.node_ref])
        .map((data, index) => parseInt(data.node_ref));

      if (missing.length) {
        return actions.nodesGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}

export function messagesGetSuccessCounter(sources) {
  const nodes$ = sources.STATE.map(state => state.images.nodes);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.MESSAGES_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      // Set the counter of unread messages unless a reset param was passed.
      const counter = arr.reset ? 0 : arr.data.filter((data) => data.is_read === false ).length;
      if (counter > 0) {
        return actions.badgeSet(counter);
      }
      else {
        return actions.badgeReset();
      }
    });

  return {
    ACTION: action$,
  }
}

export function messagesGetSuccessUsers(sources) {
  const nodes$ = sources.STATE.map(state => state.users.users);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.MESSAGES_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      const missing_authors = arr.data
        .filter((data) => !isNaN(parseFloat(data.user)) && data.user > 0 && !nodes[data.user])
        .map((data, index) => parseInt(data.user));

      const missing_targets = arr.data
        .filter((data) => !isNaN(parseFloat(data.user_ref)) && data.user_ref > 0 && !nodes[data.user_ref])
        .map((data, index) => parseInt(data.user_ref));

      const missing = [...missing_authors, ...missing_targets];
      if (missing.length) {
        return actions.usersGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}
