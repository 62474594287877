import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import CommonStack from '../navigation/CommonStack';
import HomeScreen from '../containers/HomeScreen';

export default createStackNavigator(
  {
    Home: { screen: HomeScreen, navigationOptions: CommonStack.navigationOptions },
    ...CommonStack.Screens,
  },
  {
    ...CommonStack.Config,
  },
);


