import React from "react";
import {Text, TouchableOpacity, View} from "react-native";
import { Card } from 'react-native-elements';
import {Icon} from "../Icon";
import Colors from "../../constants/Colors";

class OrderCard extends React.Component {
  render () {
    const order = this.props.order;
    return (
      <TouchableOpacity onPress={() => this.props.navigation.navigate({routeName: 'Order', params: {id: order.order_id}})}>
        <Card containerStyle={{ marginTop: 15 }}>
          <Card.Title style={{textAlign: 'left'}}>Bestell-Nr. {order.order_number}</Card.Title>
          <Card.Divider />
          <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <View>
              <Text><Text style={{fontWeight: 'bold'}}>Datum: </Text>{order.date}</Text>
              <Text><Text style={{fontWeight: 'bold'}}>Summe: </Text>{order.total} €</Text>
              <Text><Text style={{fontWeight: 'bold'}}>Bestellstatus: </Text>{order.status}</Text>
            </View>
            <View>
              <Icon color={Colors.tintColorFill} name='right_tall' size={50}/>
            </View>
          </View>
        </Card>
      </TouchableOpacity>
    )}
}

export default OrderCard
