import React from 'react';
import {
  FlatList,
  StyleSheet,
  Text, TouchableOpacity,
  View
} from 'react-native';
import Colors from "../constants/Colors";
import { Icon } from "./Icon";
import Collapsible from "react-native-collapsible";
import { Facets, facetIdToLabel } from '../constants/Facets.js';
import CustomCheckbox from "./CustomCheckbox";

export default class CollapsibleCustom extends React.Component {

  state = {
    collapsed: true,
    prerender: false,
  };

  componentDidMount() {
    const delay = 100 + 100 * Math.random();
    this.timeout = setTimeout(() => this.setState({ prerender: true }), delay);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  toggleExpanded = () => {
    const newState = !this.state.collapsed;
    this.setState({ collapsed: newState });
    // If a callback was passed from parent give it a call.
    if (this.props.toggleExpanded) {
      this.props.toggleExpanded(newState);
    }
  };

  render() {
    return (
      <View>
        <TouchableOpacity
          onPress={this.toggleExpanded}
          style={{
            padding: 10,
            borderBottomWidth: this.state.collapsed ? StyleSheet.hairlineWidth : 0,
            borderColor: Colors.border
          }}>
          <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Text style={{fontWeight: "bold"}}>{this.props.mainTitle}</Text>
            <Text>{this.props.subTitle}</Text>
            <Icon
              name='up_arrow'
              size={30}
              color='black'
              style={{transform: [{rotateX: this.state.collapsed ? "180deg" : "0deg"}]}}
            />
          </View>
        </TouchableOpacity>
        <Collapsible style={{padding: 10}} collapsed={this.state.collapsed} align="center">
          { this.state.prerender && this.props.mainContent }
        </Collapsible>
      </View>
    )
  }
}

