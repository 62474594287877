import * as Config from "../../Config";

const flattenInboxMessage = (message) => {
  return {
    id: message.mid,
    isNew: message.is_new,
    subject: message.subject,
    body: message.body,
    timestamp: message.timestamp,
    time: message.time,
    recipientId: message.recipient ? message.recipient.id: null,
    recipientLabel: message.recipient ? message.recipient.label : null,
    recipientSelf: message.recipient ? message.recipient.self : null,
    recipientUsername: message.recipient ? message.recipient.username : null,
    recipientPictureUri: message.recipient && message.recipient.picture && message.recipient.picture.uri ? message.recipient.picture.uri : Config.DEFAULT_AVATAR,
    userId: message.user ? message.user.id : null,
    userLabel: message.user ? message.user.label : null,
    userSelf: message.user ? message.user.self : null,
    userUserName: message.user ? message.user.username : null,
    userMail: message.user ? message.user.mail : null,
    userPictureUri: message.user && message.user.picture && message.user.picture.uri ? message.user.picture.uri : Config.DEFAULT_AVATAR,
  }
};

export default flattenInboxMessage;
