import React from 'react';
import {
  ScrollView,
  Text,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import { EventRegister } from 'react-native-event-listeners';
import * as Config from '../Config';
import { userRegister, usernameValidation, emailValidation } from '../actions/index';
import Colors from '../constants/Colors';
import Styles from "../constants/Styles";
import CustomButton from '../components/general/CustomButton';
import CustomTextInput from '../components/CustomTextInput';
import TermsOfService from '../components/TermsOfService';
import ContactText from "../components/general/ContactText";

class RegisterEmailScreen extends React.Component {

  state = {
    username: this.props.username ? this.props.username : '',
    email: this.props.email ? this.props.email : '',
    agreeNewsletter: false,
    agreeTerms: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( (this.props.logInState.switchRegisterLogin !== prevProps.logInState.switchRegisterLogin) && this.props.logInState.switchRegisterLogin) {
      this.setState({register: !this.props.logInState.switchRegisterLogin});
      Alert.alert(
        '',
        'Danke für deine Registrierung. Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos zugeschickt. Bitte klicke auf den Link in der E-Mail, um die Registrierung abzuschließen und ein Passwort festzulegen. Öffne danach die App erneut, um dich einzuloggen.',
        [
          {
            text: 'Okay',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
  }

  handleRegisterAsync = () => {
    if (this.state.username && this.state.email) {
      EventRegister.emit('analyticEvent', {
        category: 'Register',
        action: 'Email ',
        label: 'User registered via email'
      });
      this.props.userRegister({
        mail: this.state.email,
        name: this.state.username,
        newsletter: (this.state.agreeNewsletter) ? 1 : 0,
      });
    }
  }

  handleUsernameValidation = (text) => {
      this.props.usernameValidation({
        username: text,
      });
  };

  handleEmailValidation = (text) => {
      this.props.emailValidation({
        email: text,
      });
  };

  toggleCheckBox = (name) => {
    this.setState({
      [name]: !this.state[name],
      errorMessage: null,
      savePressed: false,
    });
  }

  render() {
    const regex = /(<([^>]+)>)/ig;
    const reactStringReplace = require('react-string-replace');
    let replacedText = '';
    let usernameValidationMessage = '';
    if (this.props.logInState.emailValidationMessage) {
      replacedText = this.props.logInState.emailValidationMessage.replace(regex, '');
      replacedText = reactStringReplace(replacedText, 'Klicke hier um dich einzuloggen.', (match, i) =>
        (<Text key={match + i} style={{color: Colors.darkText}}
          onPress={() => this.props.navigation.navigate('Login')}> {match} </Text>));
      replacedText = reactStringReplace(replacedText, 'Oder klicke hier, falls du dein Passwort vergessen hast.', (match, i) =>
        (<Text key={match + i} style={{color: Colors.darkText}}
               onPress={() => this.props.navigation.navigate('ForgotPassword')}> {match} </Text>));
    }
    if(this.props.logInState.usernameValidationMessage) {
      usernameValidationMessage = this.props.logInState.usernameValidationMessage.replace(regex, '');
    }

    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}> 
          <CustomTextInput
            value={this.state.username}
            onChangeText={(text) => {
              this.setState({username: text});
              this.handleUsernameValidation(text);
            }}
            label="Benutzername"
            autoCapitalize="none"
            clearButtonMode="always"
            autoCorrect={false}
          />
          { (usernameValidationMessage.length > 0) &&
          <Text style={{
            marginTop: 10,
            color: 'red',
            fontSize: Config.FONT_SIZE_NORMAL,
            fontWeight: 'normal',
            fontFamily: 'OpenSansRegular'
          }}
          >
            {usernameValidationMessage}
          </Text>
          }
          <CustomTextInput
            value={this.state.email}
            onChangeText={(text) => {
              this.setState({email: text});
              this.handleEmailValidation(text);
            }}
            label="Email"
            clearButtonMode="always"
            selectTextOnFocus={true}
            autoCorrect={false}
            controlled={true}
          />
          { (replacedText.length > 0) &&
            <Text style={{
              marginTop: 10,
              color: 'red',
              fontSize: Config.FONT_SIZE_NORMAL,
              fontWeight: 'normal',
              fontFamily: 'OpenSansRegular'
            }}>
              {replacedText}
            </Text>
          }
          <TermsOfService {...this.state} toggleCheckBox={this.toggleCheckBox} navigation={this.props.navigation} />
          <View style={{ alignItems: 'center' }}>
            <CustomButton
              title="Jetzt per E-Mail registrieren"
              onPress={this.handleRegisterAsync}
              disabled={!this.state.agreeTerms}
            />
          </View>
        </View>
        <ContactText navigation={this.props.navigation}/>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    userRegister: (payload) => {
      dispatch(userRegister(payload))
    },
    usernameValidation: (payload) => {
      dispatch(usernameValidation(payload))
    },
    emailValidation: (payload) => {
      dispatch(emailValidation(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmailScreen);
