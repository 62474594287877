import React from 'react';
import {
  View,
  TouchableOpacity, Text
} from 'react-native';
import { connect } from 'react-redux';

import Styles from '../../constants/Styles';
import { Icon } from '../Icon';
import * as Config from '../../Config';
import Colors from '../../constants/Colors';

import {debounce} from 'lodash';

class NodeActionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.delayLike = debounce(props.onPressLike, 50);
  }

  render (){
    return (
      <View style={Styles.iconsContainer}>
        <TouchableOpacity
          onPress={this.delayLike}
          style={Styles.iconContainer}
        >
          {this.props.navigateable ?
            <TouchableOpacity
              onPress={this.props.isOffline ? null : this.props.onPress}
            >
              <Text
                style={[Styles.iconCounter, Styles.simpleLink]}>{this.props.item.likes !== 0 && this.props.item.likes}</Text>
            </TouchableOpacity>
            :
            <View
            >
              <Text
                style={[Styles.iconCounter]}>{this.props.item.likes !== 0 && this.props.item.likes}</Text>
            </View>
          }
          <Icon
            active={this.props.item.liked}
            name='heart'
            size={Config.SIZE_ICON}
            color={this.props.item.liked ? Colors.iconSelected : Colors.iconDefault}
          />

        </TouchableOpacity>
        { this.props.commentable &&
        <TouchableOpacity
          onPress={this.props.onPressComment}
          style={[Styles.iconContainer, { marginLeft: 10 }]}
        >
          <View
          >
            <Text
              style={[Styles.iconCounter]}>{this.props.item.comments !== 0 && this.props.item.comments}</Text>
          </View>
          <Icon
            name='comment'
            size={Config.SIZE_ICON}
          />
        </TouchableOpacity>
        }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOffline: state.device.isOffline,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{}
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeActionButtons);
