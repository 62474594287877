import React from 'react';
import {
  Text,
  View
} from 'react-native';
import Styles from '../constants/Styles';
import ImagesList from './ImagesList';
import {
  likeNode,
  viewGet
} from '../actions/index';
import connect from 'react-redux/es/connect/connect';
import screenNames from '../constants/screenNames';

class MagazineScreenComponent extends React.Component {
  render()  {
    let menus = [
      {title: 'Neu', path: screenNames.wohnmagazin, view: 'frontpage_views_v3', display: 'panel_pane_8'}
    ];
    return (
      <ImagesList
        data={this.props.nodes}
        nodesById={this.props.nodesById}
        isLoading={this.props.isLoadingMagazine}
        likeNode={this.props.likeNode}
        list={this.props.list}
        menus={menus}
        navigation={this.props.navigation}
        viewGet={this.props.viewGet}
        header={
          <View>
            <Text style={Styles.pageTitle}>Das SoLebIch Wohnmagazin</Text>
          </View>
        }
        pagination={this.props.pagination}
        isLikingNode={this.props.isLikingNode}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingMagazine: state.images.isLoading,
    list: state.images,
    nodes: state.images.nodes,
    isLikingNode: state.images.isLikingNode,
    nodesById: state.images.nodesById,
    pagination: state.pagination,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MagazineScreenComponent);
