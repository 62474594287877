import React, { Component } from 'react';
import OrderScreenComponent from "../components/shopping/OrderScreenComponent";
import {connect} from "react-redux";
import {cartGet, cartShippingSelect, getBillingAddress, getShippingAddress, shippingCollect} from "../actions";

class OrderScreen extends Component {

  render() {
    return (
      <OrderScreenComponent
        billingAddresses={this.props.billingAddresses}
        billingAddressCount={this.props.billingAddressCount}
        cart={this.props.cart}
        cartShippingOptions={this.props.cartShippingOptions}
        cartGet={this.props.cartGet}
        cartShippingSelect={this.props.cartShippingSelect}
        getShippingAddress={this.props.getShippingAddress}
        getBillingAddress={this.props.getBillingAddress}
        isLoadingShippingAddress={this.props.isLoadingShippingAddress}
        isLoadingBillingAddress={this.props.isLoadingBillingAddress}
        navigation={this.props.navigation}
        ordersById={this.props.ordersById}
        shippingAddresses={this.props.shippingAddresses}
        shippingAddressCount={this.props.shippingAddressCount}
        shippingCollect={this.props.shippingCollect}
        shippingOptions={this.props.shippingOptions}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    billingAddresses: state.address.billingAddresses.map(id => state.address.addresses[id]),
    billingAddressCount: state.address.billingAddressCount,
    cart: state.checkout.cart,
    cartShippingOptions: state.checkout.cartShippingOptions,
    isLoadingBillingAddress: state.address.isLoadingBillingAddress,
    isLoadingShippingAddress: state.address.isLoadingShippingAddress,
    ordersById: state.orders.ordersById,
    shippingAddresses: state.address.shippingAddresses.map(id => state.address.addresses[id]),
    shippingAddressCount: state.address.shippingAddressCount,
    shippingOptions: state.orders.shippingOptions
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cartShippingSelect: (payload) => {
      dispatch(cartShippingSelect(payload))
    },
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    },
    getShippingAddress: (payload) => {
      dispatch(getShippingAddress(payload))
    },
    getBillingAddress: (payload) => {
      dispatch(getBillingAddress(payload))
    },
    shippingCollect: (payload) => {
      dispatch(shippingCollect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen);

