import flattenCommerceProduct from './flattenCommerceProduct';

/**
 * Help functions that return 2 objects.
 *
 * - new. This is an object of objects that contains all the flattened products.
 * - byId. This is an object of arrays. Each array contains all the product IDs.
 *     The byId arrays are keyed by the parent Id so that the parent to product relation
 *     can be easily retrieved.
 */
const processCommerceProducts = (commerceProducts, commerceProductsById, data) => {

  return  data.reduce((object, item) => {

    if (!item) {
      return;
    }

    // Initialize array keyed under the parent ID if none is found.
    if (!object.byId[item.parentDBS]) {
      object.byId[item.parentDBS] = [];
    }

    // Add product IDs in array, keyed under parent ID.
    if (!object.byId[item.parentDBS].includes(item.id)) {
      object.byId[item.parentDBS].push(item.id);
    }

    // Add product after flattening its properties.
    object.new[item.id] = flattenCommerceProduct(item);
    return object;
  },
  // Initial object.
  {
    // Contains an object of objects.
    new: {},
    // And an object of arrays.
    byId: {...commerceProductsById},
  });

};

export default processCommerceProducts;
