import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import processCart from "./helpers/processCart";
import processShipping from "./helpers/processShipping";
import processPaymentMethods from "./helpers/processPaymentMethods";

const initialState = {
  isLoadingCart: false,
  isLoadingQuantity: false,
  isLoadingShipping: false,
  isLoadingPayment: false,
  isLoadingShippingSelect: false,
  cartCount: 0,
  cart: {},
  cartShippingOptions: {},
  paymentMethods: [],
};

function cartGet(state) {
  return {
    ...state,
    isLoadingCart: true
  };
}

function cartGetSuccess(state, action) {
  const processedCart = action.payload.count ? processCart(action.payload.data[0]) : undefined;
  return {
    ...state,
    isLoadingCart: false,
    cart: action.payload.count ? processedCart.cart : {},
    cartCount: action.payload.count ? processedCart.count : 0,
  };
}

function cartGetFail(state) {
  return {
    ...state,
    isLoadingCart: false
  };
}

function cartShippingCollect(state) {
  return {
    ...state,
    isLoadingShipping: true
  };
}

function cartShippingCollectSuccess(state, action) {
  const processedShipping = processShipping(action.payload)
  return {
    ...state,
    isLoadingShipping: false,
    cartShippingOptions: processedShipping.shipping
  };
}

function cartShippingCollectFail(state) {
  return {
    ...state,
    isLoadingShipping: false
  };
}

function cartPaymentCollect(state, action) {
  return {
    isLoadingPayment: true,
    ...state,
  };
}

function cartPaymentCollectSuccess(state, action) {
  const processedPaymentMethods = processPaymentMethods(action.payload);
  return {
    ...state,
    isLoadingPayment: false,
    paymentMethods: processedPaymentMethods.methods
  };
}

function cartPaymentCollectFail(state, action) {
  return {
    isLoadingPayment: false,
    ...state,
  };
}

function addCoupon(state, action) {
  return {
    ...state,
    isLoadingCoupon: true
  };
}

function addCouponSuccess(state, action) {
  return {
    ...state,
    isLoadingCoupon: false
  };
}

function addCouponFail(state, action) {
  return {
    ...state,
    isLoadingCoupon: false
  };
}

function removeCoupon(state, action) {
  return {
    ...state,
    isRemovingCoupon: true
  };
}

function removeCouponSuccess(state, action) {
  return {
    ...state,
    isRemovingCoupon: false
  };
}

function removeCouponFail(state, action) {
  return {
    ...state,
    isRemovingCoupon: false
  };
}

function cartShippingSelect(state, action) {
  return {
    ...state,
  };
}

function cartShippingSelectSuccess(state, action) {
  return {
    ...state,
  };
}

function cartShippingSelectFail(state, action) {
  return {
    ...state,
  };
}

function cartPaymentSelect(state, action) {
  return {
    ...state,
  };
}

function cartPaymentSelectSuccess(state, action) {
  return {
    ...state,
  };
}

function cartPaymentSelectFail(state, action) {
  return {
    ...state,
  };
}

function alterQuantity(state) {
  return {
    ...state,
    isLoadingQuantity: true
  };
}

function alterQuantitySuccess(state) {
  return {
    ...state,
    isLoadingQuantity: false
  };
}

function alterQuantityFail(state) {
  return {
    ...state,
    isLoadingQuantity: false
  };
}

function cartUpdateStatus(state, action) {
  return {
    ...state,
  };
}

function cartUpdateStatusSuccess(state, action) {
  return {
    ...state,
  };
}

function cartUpdateStatusFail(state, action) {
  return {
    ...state,
  };
}

const checkout = createReducer(initialState, {
  [ActionTypes.CART_GET]: cartGet,
  [ActionTypes.CART_GET_SUCCESS]: cartGetSuccess,
  [ActionTypes.CART_GET_FAIL]: cartGetFail,
  [ActionTypes.CART_SHIPPING_COLLECT]: cartShippingCollect,
  [ActionTypes.CART_SHIPPING_COLLECT_SUCCESS]: cartShippingCollectSuccess,
  [ActionTypes.CART_SHIPPING_COLLECT_FAIL]: cartShippingCollectFail,
  [ActionTypes.CART_PAYMENT_COLLECT]: cartPaymentCollect,
  [ActionTypes.CART_PAYMENT_COLLECT_SUCCESS]: cartPaymentCollectSuccess,
  [ActionTypes.CART_PAYMENT_COLLECT_FAIL]: cartPaymentCollectFail,
  [ActionTypes.ADD_COUPON]: addCoupon,
  [ActionTypes.ADD_COUPON_SUCCESS]: addCouponSuccess,
  [ActionTypes.ADD_COUPON_FAIL]: addCouponFail,
  [ActionTypes.REMOVE_COUPON]: removeCoupon,
  [ActionTypes.REMOVE_COUPON_SUCCESS]: removeCouponSuccess,
  [ActionTypes.REMOVE_COUPON_FAIL]: removeCouponFail,
  [ActionTypes.CART_PAYMENT_SELECT]: cartPaymentSelect,
  [ActionTypes.CART_PAYMENT_SELECT_SUCCESS]: cartPaymentSelectSuccess,
  [ActionTypes.CART_PAYMENT_SELECT_FAIL]: cartPaymentSelectFail,
  [ActionTypes.CART_SHIPPING_SELECT]: cartShippingSelect,
  [ActionTypes.CART_SHIPPING_SELECT_SUCCESS]: cartShippingSelectSuccess,
  [ActionTypes.CART_SHIPPING_SELECT_FAIL]: cartShippingSelectFail,
  [ActionTypes.ALTER_QUANTITY]: alterQuantity,
  [ActionTypes.ALTER_QUANTITY_SUCCESS]: alterQuantitySuccess,
  [ActionTypes.ALTER_QUANTITY_FAIL]: alterQuantityFail,
  [ActionTypes.CART_UPDATE_STATUS]: cartUpdateStatus,
  [ActionTypes.CART_UPDATE_STATUS_SUCCESS]: cartUpdateStatusSuccess,
  [ActionTypes.CART_UPDATE_STATUS_FAIL]: cartUpdateStatusFail,
});

export default checkout;
