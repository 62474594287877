import React, { Component } from 'react';
import {connect} from "react-redux";

import AddressFormScreenComponent from "../components/shopping/AddressFormScreenComponent";
import { addressCreate, addressDelete, addressUpdate } from "../actions";

class AddressFormScreen extends Component {

  render() {
    return (
      <AddressFormScreenComponent
        addressCreate={this.props.addressCreate}
        addressDelete={this.props.addressDelete}
        addressUpdate={this.props.addressUpdate}
        navigation={this.props.navigation}
        shippingAddresses={this.props.shippingAddresses}
        billingAddresses={this.props.billingAddresses}
        email={this.props.email}
        logInState={this.props.logInState}
        addressSave={this.props.addressSave}
  />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    billingAddresses: state.address.billingAddresses.map(id => state.address.addresses[id]),
    email: state.checkout.cart.email,
    shippingAddresses: state.address.shippingAddresses.map(id => state.address.addresses[id]),
    logInState: state.user,
    addressSave: state.address.addressSave,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addressCreate: (payload) => {
      dispatch(addressCreate(payload))
    },
    addressDelete: (payload) => {
      dispatch(addressDelete(payload))
    },
    addressUpdate: (payload) => {
      dispatch(addressUpdate(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressFormScreen);
