export function compareAddresses(address1, address2 ) {
  return address1.country === address2.country
    && address1.first_name === address2.first_name
    && address1.last_name === address2.last_name
    && address1.locality === address2.locality
    && address1.phone === address2.phone
    && address1.postal_code === address2.postal_code
    && address1.premise === address2.premise
    && address1.street === address2.street;
}
