export const AddressFields = [
  {
    label: 'E-Mail-Adresse',
    value: 'email',
    required: true
  },
  {
    label: 'Nachname',
    value: 'last_name',
    required: true
  },
  {
    label: 'Name',
    value: 'first_name',
    required: true
  },
  {
    label: 'Straße und Hausnummer',
    value: 'street',
    required: true
  },
  {
    label: 'Zusatz',
    value: 'premise',
    required: false
  },
  {
    label: 'PLZ',
    value: 'postal_code',
    required: true
  },
  {
    label: 'Ort',
    value: 'locality',
    required: true
  },
  {
    label: 'Land',
    value: 'country',
    required: true
  },
  {
    label: 'Telefon',
    value: 'phone',
    required: true
  }
];
