import {priceFormat100} from "../../helpers/formatNumbers";

const flattenLineItem = (item) => {
  return !item.id ? false : {
    price_unit: item.price_unit ? priceFormat100(item.price_unit) : 0,
    price_total: item.price_total ? priceFormat100(item.price_total) : 0,
    quantity: item.quantity ? Number(item.quantity) : undefined,
    lineItemId: item.id ?? undefined,
  }
};

export default flattenLineItem;
