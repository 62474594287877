import * as ActionTypes from "../actions/actionTypes";
import * as Config from "../Config";
import xs from "xstream";
import * as actions from "../actions";

export function addToCart(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADD_TO_CART)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-add-item/' + payload.id;
        return ({
          url: Config.BASE_URL + path,
          category: 'addItem',
        });
      }
    );

  const response$ = sources.HTTP
    .select('addItem')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.addToCartSuccess();
        }
        else {
          return actions.addToCartFail(data);
        }
      }catch(e){
        return actions.addToCartFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addToCartSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADD_TO_CART_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function removeFromCart(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REMOVE_FROM_CART)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-remove-item/';
        if (payload.orderNumber && payload.lineItemId) {
          path += parseInt(payload.orderNumber)+ '/' + parseInt(payload.lineItemId)
        }
        return ({
          url: Config.BASE_URL + path,
          category: 'removeItem',
        });
      }
    );

  const response$ = sources.HTTP
    .select('removeItem')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.removeFromCartSuccess();
        }
        else {
          return actions.removeFromCartFail(data);
        }
      }catch(e){
        return actions.removeFromCartFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function removeFromCartSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REMOVE_FROM_CART_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function cartGet(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart';
        return ({
          url: Config.BASE_URL + path,
          category: 'cartGet',
        });
      }
    );

  const response$ = sources.HTTP
    .select('cartGet')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.cartGetSuccess(data);
        }
        else {
          return actions.cartGetFail(data);
        }
      }catch(e){
        return actions.cartGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function cartGetSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_GET_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartShippingCollect());

  return {
    ACTION: action$,
  }
}


export function cartShippingCollect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_SHIPPING_COLLECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-shipping-collect';
        return ({
          url: Config.BASE_URL + path,
          category: 'cartShippingCollect',
        });
      }
    );

  const response$ = sources.HTTP
    .select('cartShippingCollect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.cartShippingCollectSuccess(data);
        }
        else {
          return actions.cartShippingCollectFail(data);
        }
      }catch(e){
        return actions.cartShippingCollectFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function cartPaymentCollect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_PAYMENT_COLLECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-payment-collect';
        return ({
          url: Config.BASE_URL + path,
          category: 'cartPaymentCollect',
        });
      }
    );

  const response$ = sources.HTTP
    .select('cartPaymentCollect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.cartPaymentCollectSuccess(data);
        }
        else {
          return actions.cartPaymentCollectFail(data);
        }
      }catch(e){
        return actions.cartPaymentCollectFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addCoupon(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADD_COUPON)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-coupon-select/' + payload;
        return ({
          url: Config.BASE_URL + path,
          category: 'addCoupon',
        });
      }
    );

  const response$ = sources.HTTP
    .select('addCoupon')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.addCouponSuccess();
        }
        else {
          return actions.addCouponFail(data);
        }
      }catch(e){
        return actions.addCouponFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addCouponSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADD_COUPON_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function removeCoupon(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REMOVE_COUPON)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-coupon-remove/' + payload;
        return ({
          url: Config.BASE_URL + path,
          category: 'removeCoupon',
        });
      }
    );

  const response$ = sources.HTTP
    .select('removeCoupon')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.removeCouponSuccess();
        }
        else {
          return actions.removeCouponFail(data);
        }
      }catch(e){
        return actions.removeCouponFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function removeCouponSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.REMOVE_COUPON_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function alterQuantity(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ALTER_QUANTITY)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-alter-quantity/' + payload.lineItemId + '/' + payload.quantity;
        return ({
          url: Config.BASE_URL + path,
          category: 'alterQuantity',
        });
      }
    );

  const response$ = sources.HTTP
    .select('alterQuantity')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.alterQuantitySuccess();
        }
        else {
          return actions.alterQuantityFail(data);
        }
      }catch(e){
        return actions.alterQuantityFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function alterQuantitySuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ALTER_QUANTITY_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function cartShippingSelect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_SHIPPING_SELECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-shipping-select/' + payload;
        return ({
          url: Config.BASE_URL + path,
          category: 'cartShippingSelect',
        });
      }
    );

  const response$ = sources.HTTP
    .select('cartShippingSelect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data == 'ok') {
          return actions.cartShippingSelectSuccess();
        }
        else {
          return actions.cartShippingSelectFail();
        }
      }catch(e){
        return actions.cartShippingSelectFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function cartShippingSelectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_SHIPPING_SELECT_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function cartUpdateStatus(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_UPDATE_STATUS)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/cart-update-status/' + payload.orderId + '/' + payload.status;
      return ({
        url: Config.BASE_URL + path,
        category: 'cartUpdateStatus',
      });
    });

  const response$ = sources.HTTP
    .select('cartUpdateStatus')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data == 'ok') {
          return actions.cartUpdateStatusSuccess();
        }
        else {
          return actions.cartUpdateStatusFail();
        }
      }catch(e){
        return actions.cartUpdateStatusFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function cartUpdateStatusSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_UPDATE_STATUS_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}


export function cartPaymentSelect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_PAYMENT_SELECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-payment-select/' + payload;
        return ({
          url: Config.BASE_URL + path,
          category: 'cartPaymentSelect',
        });
      }
    );

  const response$ = sources.HTTP
    .select('cartPaymentSelect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.ok == 'YES') {
          return actions.cartPaymentSelectSuccess();
        }
        else {
          return actions.cartPaymentSelectFail();
        }
      }catch(e){
        return actions.cartPaymentSelectFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function cartPaymentSelectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.CART_PAYMENT_SELECT_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}







