import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';

export function menuGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.MENU_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const menuDepth = payload.menuDepth ?? 5;
      const menuItem = payload.menuItem ?? 0;
      return ({
        url: Config.BASE_URL + 'api/menu-get/' + payload.menuName + '/' + menuItem + '/' + menuDepth,
        category: 'menu',
        menuName: payload.menuName,
      });
    });

  const response$ = sources.HTTP
    .select('menu')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        if (arr[0].body && arr[0].body.length) {
          return actions.menuGetSuccess({ data: arr[0].body, menuName: arr[0].request.menuName});
        }
        else {
          return actions.menuGetFail(arr[0].request.menuName);
        }
      }
      catch (e) {
        return actions.menuGetFail(arr[0].request.menuName);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
export function tosGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.TOS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/v1.0/pages/' + payload,
        category: 'tos',
        pageId: payload,
      });
    });

  const response$ = sources.HTTP
    .select('tos')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.data && data.data.length) {
          return actions.tosGetSuccess({ data: data.data[0], pageId: arr[0].request.pageId });
        }
        else {
          return actions.tosGetFail(arr[0].request.pageId);
        }
      }
      catch (e) {
        return actions.tosGetFail(arr[0].request.pageId);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function submitReportForm(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SUBMIT_REPORT_FORM)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        category: 'submitReportForm',
        method: 'POST',
        send: payload,
        url: Config.BASE_URL + 'api/report-form',
      });
    });

  const response$ = sources.HTTP
    .select('submitReportForm')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        if (arr[0].body && arr[0].body.ok === 'YES') {
          return actions.submitReportFormSuccess();
        }
        else {
          return actions.submitReportFormFail();
        }
      }
      catch (e) {
        return actions.submitReportFormFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function submitFeedbackForm(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SUBMIT_FEEDBACK_FORM)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        category: 'submitFeedbackForm',
        method: 'POST',
        send: payload,
        url: Config.BASE_URL + 'api/report-form',
      });
    });

  const response$ = sources.HTTP
    .select('submitFeedbackForm')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        if (arr[0].body && arr[0].body.ok === 'YES') {
          return actions.submitFeedbackFormSuccess();
        }
        else {
          return actions.submitFeedbackFormFail();
        }
      }
      catch (e) {
        return actions.submitFeedbackFormFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
