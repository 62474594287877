import * as ActionTypes from './actionTypes';

export function tosGet(payload){
  return {
    type: ActionTypes.TOS_GET,
    payload
  }
}

export function tosGetSuccess(payload){
  return {
    type: ActionTypes.TOS_GET_SUCCESS,
    payload
  }
}

export function tosGetFail(payload){
  return {
    type: ActionTypes.TOS_GET_FAIL,
    payload
  }
}
