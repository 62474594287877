import React, {PureComponent} from 'react';
import {
  View
} from 'react-native';
import Swiper from 'react-native-swiper';
import Colors from '../constants/Colors';
import {ArrowLeft, ArrowRight} from './Arrows';
import ProductNode from "./ProductNode";
import ProductNodeCommerce from "./ProductNodeCommerce";
import IndicatorPage from "./general/IndicatorPage";

export default class ProductScreenComponent extends PureComponent {

  state = {
    newProduct: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if(((prevProps.isLoading !== this.props.isLoading) && !this.props.isLoading )) {
      this.setState({
        newProduct: true
      });
    }
  }

  handleSwiperRef = ref => this.swiper = ref;
  scollToNext = () => {
    this.swiper.scrollBy(1);
  };

  scollToPrev = () => {
    this.swiper.scrollBy(-1);
  };

  componentDidMount() {
    const { state } = this.props.navigation;
    const { nodes } = state.params;
    if (nodes.userId == 3317959) {
      this.props.commerceProductGet({nid: nodes.dbsParent});
    }
  }


  render() {
    const { state } = this.props.navigation;
    const { index, nodes, parent } = state.params;
    const arrow_left = <ArrowLeft />;
    const arrow_right = <ArrowRight />;
    if (parent === 'list') {
      if (nodes.userId == 3317959) {
        if (this.state.newProduct) {
          return (
             <ProductNodeCommerce
               addToCart={this.props.addToCart}
               commerceProducts={this.props.commerceProducts}
               commerceProductsById={this.props.commerceProductsById}
               isLikingNode={this.props.isLikingNode}
               isLoading={this.props.isLoading}
               key={index}
               likeComment={this.props.likeComment}
               likeNode={this.props.likeNode}
               list={this.props.list}
               logInState={this.props.logInState}
               navigation={this.props.navigation}
               node={nodes}
               nodes={this.props.nodes}
               nodesById={this.props.nodesById}
               nodesGet={this.props.nodesGet}
               pagination={this.props.pagination}
               viewGet={this.props.viewGet}
             />
          );
        } else {
          return (
            <IndicatorPage />
          )
        }
      } else {
        return (
          <ProductNode
            key={index}
            navigation={this.props.navigation}
            node={nodes}
            shops={this.props.shops}
            showButtons={false}
          />)
      }
    }
    else {
      return (
        <View style={{flex: 1}}>
          <Swiper
            ref={this.handleSwiperRef}
            index={index}
            nextButton={arrow_right}
            prevButton={arrow_left}
            showsButtons={false}
            showsPagination={false}
            style={{backgroundColor: Colors.background}}
          >
            {nodes
              .filter(node => node.userId != 17621 || node.subProducts)
              .map((node, i) => (
                <ProductNode
                  key={i}
                  navigation={this.props.navigation}
                  node={node}
                  shops={this.props.shops}
                  showButtons={true}
                  scollToNext={this.scollToNext}
                  scollToPrev={this.scollToPrev}
                  parent={parent}
                  commerceProductGet={this.props.commerceProductGet}
                  commerceProduct={this.props.commerceProduct}
                  logInState={this.props.logInState}
                  likeNode={this.props.likeNode}
                />
              ))}
          </Swiper>
        </View>
      )
    }
  }
}
