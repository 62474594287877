import React, { PureComponent } from 'react';
import { View } from 'react-native';
import * as Config from '../Config';
import Styles from '../constants/Styles';
import ProductSuggestion from '../components/ProductSuggestion';

export default class ProductSuggestions extends PureComponent {
  render() {
    let productsRendered;
    if (this.props.products.length) {
      // render individual products
      productsRendered = this.props.products
        .slice(0, Config.MAX_PRODUCTS)
        .map((p, j, a) => {
          return (
            <ProductSuggestion
              index={j}
              key={j}
              navigation={this.props.navigation}
              product={p}
              products={a}
              page={this.props.page}
            />
          );
        });
    } else {  // If products have not yet loaded, load 4 empty product suggestions
      productsRendered = [1,2,3,4]
        .map((p, j, a) => (<ProductSuggestion
          key={'temp'+j.toString()} />));
    }

    // return all products in a row
    return (
      <View style={Styles.centerHorizontalItems}>
        { productsRendered }
      </View>
    );
  }
}
