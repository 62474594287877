import React, { Component } from 'react';
import { FlatList, Platform, Text, View } from 'react-native';

import * as Config from "../Config";
import screenNames from '../constants/screenNames';
import Layout from "../constants/Layout";
import { facetsKeyed } from '../constants/Facets';
import { sortsKeyed } from '../constants/Sorts';

import CustomIndicator from "../components/CustomIndicator";
import CustomFlatlistItem from './CustomFlatlistItem';
import CustomFlatlistMenu from './CustomFlatlistMenu';
import PlainFlatlist from './generalFlatlist/plainFlatlist';

class ProductsList extends Component {

  constructor(props){
    super(props);
    this.state = {
      backToTopVisible: false,
      page: 0, 
      path: this.constructPath(),
    }
  }

  handleFlatlistRef = ref => this.listRef = ref;

  componentDidMount() {
    this.productSearch(0);
  }

  // Scroll to top and refetch the first page.
  onRefresh = () => {
    this.productSearch(0);
  };

  // Fetch more content from next pages if conditions are met.
  onEndReached = () => {
    const { data, isLoading } = this.props;
    const { page, path } = this.state;
    // Another page should not be already loading.
    if (!isLoading) {
      // Previous page should exist.
      if (data[path] && data[path][page]) {
        const next_page = page + 1;
        this.productSearch(next_page);
      }
    }
  };

  productSearch = (page) => {
    const path = this.constructPath();
    this.setState({ page: page, path: path }, () => this.props.productSearchGet({
      facets: this.props.facets,
      keyword: this.props.tagsdb[this.props.chosenCategory].label,
      page: page,
      path: path,
      sort: this.props.chosenSort,
      tid: this.props.chosenCategory,
    }));
  };

  constructPath = () => {
    const { chosenCategory, chosenSort, facets } = this.props;
    const keyword = this.props.tagsdb[this.props.chosenCategory].label;
    // Inititalize the request.
    let path = 'api/search_products/' + keyword + '?tid=' + chosenCategory;
    // Sort by top (sticky), sli (shop_priority), best (score).
    if (chosenSort) {
      path += '&sort=' + sortsKeyed[chosenSort].url_parameter;
    }
    else {
      path += '&sort=' + sortsKeyed.best.url_parameter;
    }
    // Filter for products of the solebich shop.
    path += '&filter[type]=product&filter[author_id]=3317959';
    if (facets) {
      // Multiple, multivalue facets.
      Object.keys(facets).map(facetId => {
        if (facets[facetId] && facets[facetId].length) {
          const param = facetsKeyed[facetId].url_parameters;
          // Facets with multiple selected values use the IN operator.
          if (facets[facetId].length > 1) {
            path += '&filter[' + param + '][operator]=IN';
            facets[facetId].map(v => {
              path += '&filter[' + param + '][value][]=' + v;
            });
          }
          else {
            path += '&filter[' + param + '][value]=' + facets[facetId];
          }
        }
      });
    }
    return path;
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - (2 * Layout.window.height);
  };

  handleScroll = ({nativeEvent}) =>{
    if (nativeEvent.contentOffset.y > 1500) {
      if (!this.state.backToTopVisible) {
        this.setState({backToTopVisible: true});
      }
    }
    else {
      if (this.state.backToTopVisible) {
        this.setState({ backToTopVisible: false });
      }
    }
    if (this.isCloseToBottom(nativeEvent)) {
      this.onEndReached();
    }
  };

  renderItem = (data) =>
    <CustomFlatlistItem
      {...this.props}
      state={this.state}
      data={{ data: { data: { ...data.item, width: 170, height: 170 }}}}
      isLikingNode={this.props.isLikingNode}
    />;

  render() {
    const { data, nodes } = this.props;
    const { path } = this.state;

    // Build the data array by combining the array of IDs with the actual node objects.
    const dataArray = (!data || !data[path]) ? [] : Object.keys(data[path])
      .sort((keyA, keyB) => keyA - keyB)
      .map(key => data[path][key])
      .reduce((data_glued, data_per_page) => {
        data_per_page.filter(id => nodes[id]).map(id => data_glued.push(id));
        return data_glued;
      }, [])
    .filter((id, index, array) => array.indexOf(id) === index)
    .map(id => nodes[id]);

    return (
      <FlatList
        ListHeaderComponent={<View style={{ width: Layout.containedWidth }}>{this.props.header}</View>}
        backToTopVisible={this.state.backToTopVisible}
        numColumns={Config.COLUMNS}
        data={dataArray}
        keyExtractor={item => item.id}
        listEmptyComponent={<View style={{ width: Layout.containedWidth }}>{this.props.emptyListComponent}</View>}
        onRefresh={this.onRefresh}
        onScroll={this.handleScroll}
        ref={this.handleFlatlistRef}
        refreshing={this.props.isLoading}
        renderItem={this.renderItem}
      />
    );
  }
}

export default ProductsList;
