import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import flattenSequentially from 'xstream/extra/flattenSequentially';
import flattenConcurrently from 'xstream/extra/flattenConcurrently';
import * as actions from '../actions/index';

export function commerceProductGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.COMMERCE_PRODUCT_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/v1/commerceproduct?dbsParent=' + payload.nid;
        let page = payload.page ?? 0;
        if (page > 0) {
          path += '&page=' + page;
        }
        return ({
          url: Config.BASE_URL + path,
          category: 'commerce_product',
          nid: payload.nid,
          page: page,
        });
      }
    );

  const response$ = sources.HTTP
    .select('commerce_product')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        data.nid = arr[0].request.nid;
        data.page = arr[0].request.page;
        if (data.data) {
          return actions.commerceProductGetSuccess(data);
        }
        else {
          return actions.commerceProductGetFail(data);
        }
      }
      catch (e) {
        return actions.commerceProductGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function commerceProductGetSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.COMMERCE_PRODUCT_GET_SUCCESS)
    .map(action => action.payload)
    .map((arr) => {
      // fetch next links until there is no more to fetch
      if (arr.next && arr.nid && arr.page >= 0) {
        const { nid, page } = arr;
        const next_page = parseInt(page) + 1;
        return actions.commerceProductGet({ nid: nid, page: next_page });
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}
