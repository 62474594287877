import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';

import * as Config from '../Config';
import { Icon } from './Icon';
import Styles from '../constants/Styles';

export default class BackButton extends PureComponent {
  render() {
    return (
        <View style={Styles.backButtonContainer}>
          <Icon
            name='back'
            size={Config.SIZE_ICON}
            color='black'
          />
          {this.props.text? <Text style={{marginLeft: Config.STANDARD_MARGIN_BIG}}>zurück zu SoLebIch</Text> : null}
        </View>
    );
  }
}
