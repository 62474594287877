import * as ActionTypes from './actionTypes';

export function productSearchGet(payload) {
  return {
    type: ActionTypes.PRODUCT_SEARCH_GET,
    payload
  }
}

export function productSearchGetSuccess(payload) {
  return {
    type: ActionTypes.PRODUCT_SEARCH_GET_SUCCESS,
    payload
  }
}

export function productSearchGetFail(payload) {
  return {
    type: ActionTypes.PRODUCT_SEARCH_GET_FAIL,
    payload
  }
}
