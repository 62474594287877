import React, { Component } from 'react';
import { ScrollView, Text, TouchableOpacity, View} from 'react-native';
import Styles from "../../constants/Styles";
import CustomButton from "../general/CustomButton";
import CustomIndicator from "../CustomIndicator";
import CustomRadioForm from "../general/CustomRadioForm"
import CustomCheckbox from "../CustomCheckbox";
import {compareAddresses} from "../../helpers/compareAdresses";
import {isEmpty} from 'lodash';


class DeliveryScreenComponent extends Component {

  state = {
    selectedShippingAddress: this.props.cart.profile_shipping_id ?? undefined,
    selectedBillingAddress: this.props.cart.profile_billing_id ?? undefined,
    selectedShipping: this.props.cart.selectedShipping ?? Object.keys(this.props.shippingOptions)[0].toString(), //TODO Remove this since shipping option in cart should be available at this point
    check: !this.props.cart.profile_billing_id,
    newBillingAddress: false
  }

  componentDidUpdate(prevProps, prevState) {
    let billingAddress = [];
    // If the shipping address selection has changed or if the checkbox status has changed, and if the checkbox is checked, then create a billing address that is the same as the shipping address
    if (((this.state.selectedShippingAddress !== prevState.selectedShippingAddress) ||
      (this.state.check !== prevState.check))
      && this.state.check) {
      //Do not create a billing address if there is one that is exactly the same as the selected shipping address. Ιn that case select this address as a billing address
      if (this.props.billingAddresses.length && this.props.shippingAddresses.length && this.state.selectedShippingAddress) {
        billingAddress = this.props.billingAddresses.filter(address => compareAddresses(address, this.props.shippingAddresses.filter(obj => obj.id == this.state.selectedShippingAddress).pop()));
      }
      if (!isEmpty(billingAddress[0])) {
        this.setState({newBillingAddress: false})
        this.setBillingAddress(billingAddress[0].id)
      } else {
        this.props.addressCreate({
          data: {
            ...this.props.shippingAddresses.filter(obj => obj.id == this.state.selectedShippingAddress).pop(),
            type: 'billing'
          }, token: this.props.logInState.token
        });
        this.setState({newBillingAddress: true})
      }
    }
    // When the new address is saved, select this address as a billing profile
    if ((this.props.addressSave !== prevProps.addressSave) && this.props.addressSave && this.state.check && this.state.newBillingAddress ) {
      this.setBillingAddress(this.props.lastAddressId)
    }
  }

  componentDidMount() {
    const { shippingAddresses, billingAddresses } = this.props;
    const shippingAddress = shippingAddresses.filter(obj => obj.id == this.state.selectedShippingAddress).pop()
    const billingAddress = billingAddresses.filter(obj => obj.id == this.state.selectedBillingAddress).pop()
    if(!isEmpty(shippingAddress) && !isEmpty(billingAddress)) {
      this.setState({check: compareAddresses(shippingAddress, billingAddress)})
    }
  }

  setShippingAddress (selection) {
    this.props.profileSelect({type: 'shipping', id: selection})
    this.setState({selectedShippingAddress: selection})
  }

  setBillingAddress (selection) {
    this.props.profileSelect({type: 'billing', id: selection})
    this.setState({selectedBillingAddress: selection})
  }

  render() {
    const { shippingAddresses, billingAddresses, shippingAddressCount, billingAddressCount } = this.props;
    const shipping_props = shippingAddressCount ? shippingAddresses.map( item => {
      return ({
        label: item.first_name + ' ' + item.last_name + ', ' + item.street + `\n` + item.locality,
        value: item.id
      })
    }) : undefined
    const billing_props = billingAddressCount ? billingAddresses.map( item => {
      return ({
        label: item.first_name + ' ' + item.last_name + ', ' + item.street + `\n` + item.locality,
        value: item.id
      })
    }) : undefined

    return (
      <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
        <ScrollView
          contentContainerStyle={Styles.pageScrollViewContainer}
          keyboardShouldPersistTaps={"handled"}
          style={{marginBottom: 60}}
        >
          <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Lieferadresse</Text>
          { this.props.isLoadingShippingAddress ? <CustomIndicator /> :
            shippingAddressCount ?
              <View>
                <CustomRadioForm
                  radio_props={shipping_props}
                  selectedProp={this.state.selectedShippingAddress}
                  setSelectedProp={(selection) => this.setShippingAddress(selection)}
                  navigation={this.props.navigation}
                  editButton={true}
                />
                <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0, marginTop: 20}]} onPress={() => this.props.navigation.navigate('AddressForm', {type: 'shipping'})}>
                  <Text>Neu Lieferaddresse hinzufügen.</Text>
                </TouchableOpacity>
              </View> :
                <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0, marginTop: 20}]} onPress={() => this.props.navigation.navigate('AddressForm', {type: 'shipping'})}>
                  <Text>Neu Lieferaddresse hinzufügen.</Text>
                </TouchableOpacity>
          }
          <Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Rechnungsadresse</Text>
          <CustomCheckbox
            checked={this.state.check}
            onPress={() => this.setState(prevState => ({check: !prevState.check}))}
            title={'Meine Rechnungsadresse entspricht der Lieferadresse.'}
          />
          {!this.state.check ? (this.props.isLoadingBillingAddress ? <CustomIndicator/> :
            billingAddressCount ? 
              <View>
                <CustomRadioForm
                  radio_props={billing_props}
                  selectedProp={this.state.selectedBillingAddress}
                  setSelectedProp={(selection) => this.setBillingAddress(selection)}
                  navigation={this.props.navigation}
                  editButton={true}
                />
                <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0, marginTop: 20}]} onPress={() => this.props.navigation.navigate('AddressForm', {type: 'billing'})}>
                  <Text>Neu Rechnungsaddresse hinzufügen.</Text>
                </TouchableOpacity>
              </View> :
              <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0, marginTop: 20}]} onPress={() => this.props.navigation.navigate('AddressForm', {type: 'billing'})}>
                <Text>Neu Rechnungsaddresse hinzufügen.</Text>
              </TouchableOpacity>
            ) : null
          }
          {/*<Text style={[Styles.pageTitleH3456, {textAlign: 'left', alignSelf: 'flex-start'}]}>Versand</Text>*/}
          {/*<View style={Styles.checkoutBlocks}>*/}
          {/*  <View style={Styles.textNextToIcon}>*/}
          {/*    <Text>{shippingOptions[this.state.selectedShipping].title}: {shippingOptions[this.state.selectedShipping].price} €</Text>*/}
          {/*    <Text style={{marginTop: 10}}>{shippingOptions[this.state.selectedShipping].description}</Text>*/}
          {/*  </View>*/}
          {/*  <TouchableOpacity onPress={() => this.props.navigation.navigate('Shipping')} style={Styles.iconNextToText}>*/}
          {/*    <Icon*/}
          {/*      name='pencil'*/}
          {/*      size={20}*/}
          {/*      color='#818181'*/}
          {/*    />*/}
          {/*  </TouchableOpacity>*/}
          {/*</View>*/}
        </ScrollView>
        <View style={Styles.shopButtonWrap}>
          <CustomButton
            title="Weiter"
            onPress={() => {this.props.navigation.goBack()}}
            buttonStyle={Styles.shopButton}
            fontFamily={"OpenSansRegular"}
          />
        </View>
      </View>
    )
  }
}

export default DeliveryScreenComponent;
