'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import {
  StyleSheet,
  TextInput,
  Animated,
  Easing,
  Text,
  View,
  Platform,
} from 'react-native';
import * as Config from "../Config";
import Colors from "../constants/Colors";

const textPropTypes = Text.propTypes;
const textInputPropTypes = TextInput.propTypes || textPropTypes;
const propTypes = {
  ...textInputPropTypes,
  inputStyle: PropTypes.shape({style: PropTypes.any}),
  labelStyle: PropTypes.shape({style: PropTypes.any}),
  disabled: PropTypes.bool,
  style: PropTypes.shape({style: PropTypes.any}),
};

var FloatingLabel  = createReactClass({
  propTypes: propTypes,

  getInitialState () {
    var state = {
      text: this.props.value,
      dirty: (this.props.value || this.props.placeholder)
    };
    let parameterizedCleanStyle = cleanStyle;
    parameterizedCleanStyle.top = this.props.multiline === true ? (Platform.OS === 'ios' ? 15 :20) : 15;
    let style = state.dirty ? (this.props.labelDoubleTop ? dirtyStyleDoubleTop : dirtyStyle) : parameterizedCleanStyle;
    state.labelStyle = {
      fontSize: new Animated.Value(style.fontSize),
      top: new Animated.Value(style.top),
      paddingLeft: new Animated.Value(style.paddingLeft),

    };

    return state
  },

  componentDidUpdate(prevProps) {
    if (typeof this.props.value !== 'undefined' && this.props.value !== this.state.text) {
      this.setState({ text: this.props.value, dirty: !!this.props.value });
      this._animate(!!this.props.value)
    }
  },

  _animate(dirty) {
    let parameterizedCleanStyle = cleanStyle;
    parameterizedCleanStyle.top = this.props.multiline === true ? (Platform.OS === 'ios' ? 15 :20) : 15;
    let nextStyle = dirty ? (this.props.labelDoubleTop ? dirtyStyleDoubleTop : dirtyStyle) : parameterizedCleanStyle;
    let labelStyle = this.state.labelStyle;
    let anims = Object.keys(nextStyle).map(prop => {
      return Animated.timing(
        labelStyle[prop],
        {
          toValue: nextStyle[prop],
          duration: 200,
          useNativeDriver: false
        },
        Easing.ease
      )
    });

    Animated.parallel(anims).start()
  },

  _onFocus () {
    this._animate(true);
    this.setState({dirty: true});
    if (this.props.onFocus) {
      this.props.onFocus(arguments);
    }
  },

  _onBlur () {
    if (!this.state.text) {
      this._animate(false);
      this.setState({dirty: false});
    }

    if (this.props.onBlur) {
      this.props.onBlur(arguments);
    }
  },

  _onChangeText(text) {
    this.setState({ text });
    if (this.props.onChangeText) {
      this.props.onChangeText(text)
    }
  },

  _updateText(event) {
    let text = event.nativeEvent.text;
    this.setState({ text });

    if (this.props.onEndEditing) {
      this.props.onEndEditing(event)
    }
  },

  _renderLabel () {
    return (
      <Animated.Text
        ref='label'
        style={[this.state.labelStyle, styles.label, this.props.labelStyle]}
      >
        {this.props.children}
      </Animated.Text>
    )
  },

  render() {
    var props = {
      inputRef: this.props.inputRef,
        autoCapitalize: this.props.autoCapitalize,
        autoCorrect: this.props.autoCorrect,
        autoFocus: this.props.autoFocus,
        bufferDelay: this.props.bufferDelay,
        clearButtonMode: this.props.clearButtonMode,
        clearTextOnFocus: this.props.clearTextOnFocus,
        controlled: this.props.controlled,
        editable: this.props.editable,
        enablesReturnKeyAutomatically: this.props.enablesReturnKeyAutomatically,
        keyboardType: this.props.keyboardType,
        multiline: this.props.multiline,
        numberOfLines: this.props.numberOfLines,
        onBlur: this._onBlur,
        onChange: this.props.onChange,
        onChangeText: this._onChangeText,
        onEndEditing: this._updateText,
        onFocus: this._onFocus,
        onSubmitEditing: this.props.onSubmitEditing,
        placeholder: this.props.placeholder,
        secureTextEntry: this.props.secureTextEntry,
        returnKeyType: this.props.returnKeyType,
        selectTextOnFocus: this.props.selectTextOnFocus,
        selectionState: this.props.selectionState,
        style: [styles.input],
        testID: this.props.testID,
        value: this.state.text !== null ? this.state.text : ' ',
        underlineColorAndroid: this.props.underlineColorAndroid, // android TextInput will show the default bottom border
        onKeyPress: this.props.onKeyPress,
        onSelectionChange: this.props.onSelectionChange,
        labelDoubleTop: this.props.labelDoubleTop
      },
      elementStyles = [styles.element];

    if (this.props.inputStyle) {
      props.style.push(this.props.inputStyle);
    }

    if (this.props.style) {
      elementStyles.push(this.props.style);
    }

    return (
      <View style={elementStyles}>
        {this._renderLabel()}
        <TextInput
          {...props}
          ref={props.inputRef}
        >
        </TextInput>
      </View>
    );
  },
});

const labelStyleObj = {
  marginTop: 21,
  color: Colors.darkText,
  position: 'absolute',
  fontFamily: 'OpenSansBold',
};

if (Platform.OS === 'web') {
  labelStyleObj.pointerEvents = 'none'
}

const styles = StyleSheet.create({
  element: {
    position: 'relative'
  },
  input: {
    // height: 40,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    borderWidth: 1,
    color: 'black',
    fontSize: 20,
    paddingLeft: 10,
    marginTop: 20,
    fontFamily: 'OpenSansRegular',
  },
  label: labelStyleObj,
});

const cleanStyle = {
  fontSize: Config.FONT_SIZE_NORMAL,
  top: 15,
  paddingLeft: 15,
};

const dirtyStyle = {
  fontSize: Config.FONT_SIZE_NORMAL,
  top:  -22,
  paddingLeft: 0,
};

const dirtyStyleDoubleTop = {
  fontSize: Config.FONT_SIZE_NORMAL,
  top:  -44,
  paddingLeft: 0,
};

export  default FloatingLabel;
