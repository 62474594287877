import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearUploadedImage, entityUpdate, entityUpdateFail, fileUpload, usersGetMentioning } from "../actions/index";
import CommentEditScreenComponent from "../components/CommentEditScreenComponent";

class CommentEditScreen extends Component {

  componentDidUpdate(prevProps){
    if (!this.props.isEditingComment && prevProps.isEditingComment) {
      this.props.navigation.goBack();
    }
  }

  render() {
    return(
      <CommentEditScreenComponent
        clearUploadedImage={this.props.clearUploadedImage}
        entityUpdate={this.props.entityUpdate}
        entityUpdateFail={this.props.entityUpdateFail}
        fileUpload={this.props.fileUpload}
        logInState={this.props.logInState}
        isEditingComment={this.props.isEditingComment}
        isSendingComment={this.props.isSendingComment}
        navigation={this.props.navigation}
        uid={this.props.uid}
        upload={this.props.upload}
        usersGetMentioning={this.props.usersGetMentioning}
        usersMentioning={this.props.usersMentioning}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isEditingComment: state.comments.isEditingComment,
    logInState: state.user,
    uid: state.user.id,
    usersMentioning: state.users.usersMentioning,
    upload: state.images.upload,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    entityUpdate: (payload) => {
      dispatch(entityUpdate(payload))
    },
    entityUpdateFail: (payload) => {
      dispatch(entityUpdateFail(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    clearUploadedImage: (payload) => {
      dispatch(clearUploadedImage(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditScreen);
