import React from 'react';
import {RefreshControl, TouchableOpacity, View} from 'react-native';
import Colors from '../../constants/Colors';
import * as Config from '../../Config';
import BackToTopButton from '../BackToTopButton';
import Masonry from 'react-native-masonry-list';
import Layout from '../../constants/Layout';
import IndicatorPage from "../general/IndicatorPage";
import { AdMobBanner } from 'expo-ads-admob';
import { blockAdUnitID } from "../../constants/AdConfig";
import { EventRegister } from 'react-native-event-listeners';
import {Icon} from "../Icon";
import Styles from "../../constants/Styles";

const plainFlatlist = (props) => {
  const {
    reference, onScroll, nodes, listHeaderComponent, onEndReached,
    onRefresh, renderItem, listEmptyComponent,
    backToTopVisible, listRef, navigation, likeNode, view, path,
    isLoading, onPressCloseAdBanner, adBannerVisible, showAdBanner, servePersonalizedAds,
    showAdCloseButton, adCloseButton
  } = props;

  const containerWidth= props.containerWidth ?? Layout.window.width;
  const colWidth = containerWidth / Config.COLUMNS - 10;
  return (
    <View style={{ flex: 1 }}>
      <Masonry
        columns={Config.COLUMNS}
        rerender={true}
        images={(!nodes || !nodes.length) ? [] : nodes.map(node => {
          const height = (1 / node.imageRatio) * (colWidth -10);
          return ({
            key: node.id,
            uri: node.imageStylesScalecrop,
            data: {...node, ...{width: colWidth}, height},
            width: colWidth,
            height
          }) 
        })}
        completeCustomComponent={renderItem}
        masonryFlatListColProps={{
          initialNumToRender: Config.FLATLIST_INITIAL_NUM_TO_RENDER,
          ListEmptyComponent: listEmptyComponent,
          ListFooterComponent: isLoading ? <IndicatorPage timeout="20000"/> : null,
          ListHeaderComponent: listHeaderComponent,
          maxToRenderPerBatch: Config.NODES_PER_PAGE,
          onEndReached: onEndReached,
          onScroll: onScroll,
          ref: (ref) => props.reference(ref),
          refreshControl:
            <RefreshControl
              refreshing={false}
              onRefresh={onRefresh}
            />,
        }}
        listContainerStyle={{ flexWrap: 'wrap', margin: 4 }}
        backgroundColor={Colors.background}
        containerWidth={containerWidth}
      />
      { backToTopVisible || adBannerVisible ?
        <View style={Styles.flatListBottomWrapper}>
          { backToTopVisible ? <BackToTopButton listRef={listRef}/> : null }
          { (adBannerVisible && showAdBanner) ?
            <View>
              { adCloseButton ? <TouchableOpacity style={Styles.adBlockClose} onPress={onPressCloseAdBanner}>
                <Icon name='cancel' size={20} color='black'/>
              </TouchableOpacity> : null}
              <AdMobBanner bannerSize="smartBannerPortrait"
                           adUnitID={blockAdUnitID}
                           servePersonalizedAds={servePersonalizedAds}
                           onAdViewDidReceiveAd={showAdCloseButton}
                           onDidFailToReceiveAdWithError={()=> {
                             onPressCloseAdBanner();
                             EventRegister.emit('analyticEvent', {
                               category: 'admob',
                               action: 'deliveryFail',
                               label: 'Fail to receive AD block',
                             });
                          }}
              />
            </View> : null }
        </View> : null}
    </View>
  );
};

export default plainFlatlist;
