import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';

import Colors from "../constants/Colors";
import { Icon } from "../components/Icon";

/**
 * Contains Contact info for the DBS shop.
 *
 */
export default class CommerceShopContactInfo extends PureComponent {
  render() {
    return (
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#f3f3f3',
        paddingHorizontal: 5,
        paddingVertical: 10,
        marginTop: 10,
        marginBottom: 5,
        alignItems: 'center'
      }}>
        <Icon
          name='mobile'
          size={50}
          color='black'
          style={{paddingHorizontal: 10, paddingLeft: 0, paddingRight: 5}}
        />
        <View style={{flex: 1}}>
          <Text style={{fontWeight: 'bold'}}>Beratung und Kundenservice</Text>
          <Text>+49 89 12085485 <Text>(Mo. - Fr. 8:30 - 18:00 Uhr)</Text></Text>
          <Text>shop@solebich.de</Text>
        </View>
      </View>
    );
  }
}
