import React from "react";
import ImageViewer from "react-native-image-zoom-viewer";
import {Image, Modal, Text, TouchableOpacity, View} from "react-native";
import Styles from "../../constants/Styles";
import {Icon} from "../Icon";
import * as Config from "../../Config";
import {SliderBox} from "react-native-image-slider-box";
import Layout from "../../constants/Layout";
import Colors from "../../constants/Colors";
import Pinchable from "react-native-pinchable";

class ImageModal extends React.Component {

  state = {
    currentImage: 0,
    ratio: 1
  };

  componentDidMount(){
    if (this.props.isArticle) {
      Image.getSize(this.props.images[0], (width, height) => {
        this.setState({ratio: height / width});
      });
    }
  }

  handleDoubleTap = () => {
    const time = new Date().getTime()
    const delta = time - this.lastPress
    const doublePressDelay = 400
    if (delta < doublePressDelay) {
      this.props.animateIcon()
    }
    this.lastPress = time
  }

  imageComponent = (currentImage, sliderBox) => {
    return (
    <Pinchable>
      <Image
        source={{uri: this.props.images[currentImage]}}
        resizeMethod={'resize'}
        resizeMode={Config.SWIPER_IMAGE_CONFIG}
        style={{
          alignSelf: 'center',
          height: sliderBox ? 3*Layout.imageHeight/4 : (this.props.isArticle ? ((this.props.full_width / this.props.siblings) - this.props.siblings * Config.STANDARD_MARGIN)*this.state.ratio : Layout.imageHeight),
          width: sliderBox ? '100%' : (this.props.isArticle ? (this.props.full_width / this.props.siblings) - this.props.siblings *Config.STANDARD_MARGIN : Layout.window.width),
          margin: this.props.isArticle ? Config.STANDARD_MARGIN : 0
        }}
      />
    </Pinchable>
    )
  }

  render () {
    // Create array with images for ImageViewer
    return (
      <View>
        {this.props.images?.length > 1 ? <SliderBox
          images={this.props.images}
          sliderBoxHeight={3*Layout.imageHeight/4}
          resizeMode={'contain'}
          dotColor={Colors.tintColorFill}
          inactiveDotColor={Colors.iconLightGrey}
          ImageComponent={() => this.imageComponent(this.state.currentImage, true)}
          disableOnPress
          LoaderComponent={() => { return null }}
          currentImageEmitter={(index) => this.setState({currentImage: index})}
          pagingEnabled
        /> :
          <TouchableOpacity
            activeOpacity={1}
            onPress={!this.props.isArticle ? this.handleDoubleTap : null}
          >
            {this.imageComponent(0)}
        </TouchableOpacity> }
      </View>
    )
  }
}

export default ImageModal;
