import * as Config from '../../Config'
const flattenComment = (node) => {
  return {
    id: node.id,
    label: node.label,
    self: node.self,
    nid: node.nid,
    body: node.body,
    parent: node.parent ? parseInt(node.parent) : null,
    timestamp: node.timestamp,
    time: node.time,
    textValue: node.text && node.text.value ? node.text.value : undefined,
    textFormat: node.text && node.text.format ? node.text.format : undefined,
    textSafeValue: node.text && node.text.safe_value ? node.text.safe_value : undefined,
    imageId: node.image ? node.image.id : undefined,
    imageSelf: node.image ? node.image.self : undefined,
    imageStylesFluidFixedWidth: node.image && node.image.styles ? node.image.styles["fluid-fixed-width"] : undefined,
    imageStyles1200x900Conditional: node.image && node.image.styles ? node.image.styles["1200x900-conditional"] : undefined,
    userId: node.user.id,
    userLabel: node.user.label,
    userUserName: node.user.username,
    userMail: node.user.mail,
    userPictureUri: node.user && node.user.picture ? node.user.picture.uri : Config.DEFAULT_AVATAR,
    likes: node.likes,
    liked: node.liked,
  }
};

export default flattenComment;
