import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import Styles from '../constants/Styles';

class TagItemInNode extends React.PureComponent {
  onPress = () => {
    this.props.navigation.navigate({
      routeName: 'Tag',
      key: `tag${this.props.t.id}`,
      params: { tag: this.props.t }
    })
  };
  render() {
    return (
      <TouchableOpacity
        style={{ padding: 2 }}
        onPress={this.onPress}
      >
        <Text
          style={[Styles.simpleLink, { paddingHorizontal: 0 }]}
        >
          {this.props.t.label}{this.props.l < this.props.tags.length - 1 && ','}
        </Text>
      </TouchableOpacity>
    );
  }
}

export default TagItemInNode;
