import React from 'react';
import {
  StyleSheet, TouchableOpacity,
  View
} from 'react-native';
import Colors from "../../constants/Colors";
import Layout from "../../constants/Layout";
import { Facets, facetIdToLabel, facetsKeyed } from '../../constants/Facets.js';
import CustomCheckbox from "../CustomCheckbox";
import CollapsibleCustom from "../CollapsibleCustom";

class Facet extends React.Component {

  state = {
    prerender: false,
  };

  componentDidMount() {
    const delay = 100 + 100 * Math.random();
    setTimeout(() => this.setState({ prerender: true }), delay);
  }

  toggleExpanded = (collapsed) => {
    // Scroll to the specific item only if the menu was just expanded. (not collapsed)
    if (!collapsed) {
      setTimeout(() => this.props.scrollToFacet(this.props.index), 200);
    }
  };

  renderFacetOption = (data, bold) => {
    const { facetId } = this.props;
    const { checked, count, label, id, parent } = data.item;
    const color = this.props.tagsdb[id].color;
    const colorBoxWidth = color ? 50 : 0;
    const parentWidth = parent ? 50 : 0;
    return (
      <View>
        <CustomCheckbox
          bold={bold}
          checked={checked}
          checkboxWidth={Layout.containedWidth - colorBoxWidth - parentWidth}
          titleWidth={Layout.containedWidth - 40 - colorBoxWidth - parentWidth}
          title={label + ' (' + count + ')'}
          onPress={() => this.props.setChosenFacet(facetId, id)}
          iconRight={true}
          paddingRight={5}
          right={true}
          color={color}
          parentWidth={parentWidth}
        />
      </View>
    )
  }

  sortByCount = (a, b) => -1 * (a.count - b.count);

  sortByName = (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

  /**
   * Returns the terms sorted by name, while respecting the parents and colors.
   *
   * Thera are some special cases that are treated first:
   * - 1. Same parent.
   * - 2. One is parent of the other.
   * - 3. No Color.
   *
   * At the end a default value is returned.
   */
  sortByNameWithParents = (a, b) => {
    const { tagsdb } = this.props;
    // - 1. There is a special case when A, B have the same parent. In that case
    // we compare their respective names.
    if (a.parent && b.parent && a.parent == b.parent) {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase(), 'de');
    }
    // - 2.1. If the parent of A is B, then place A after B.
    if (a.parent && tagsdb[a.parent]?.id == b.id) {
      return 1;
    }
    // - 2.2. If the parent of B is A, then place B after A.
    if (b.parent && a.id == tagsdb[b.parent]?.id) {
      return -1;
    }
    // - 3.1. Color is empty for A and B has a color or a parent.
    if (!a.parent && !tagsdb[a.id].color && (b.parent || tagsdb[b.id].color)) {
      return 1;
    }
    // - 3.2. Color is empty for B and A has a color or a parent.
    if (!b.parent && !tagsdb[b.id].color && (a.parent || tagsdb[a.id].color)) {
      return -1;
    }
    // - Otherwise we compare the names that were calculated above.
    const aName = a.parent
      ? tagsdb[a.parent]?.label.toLowerCase()
      : a.label.toLowerCase();
    const bName = b.parent
      ? tagsdb[b.parent]?.label.toLowerCase()
      : b.label.toLowerCase();
    return aName?.localeCompare(bName, 'de');
  }

  sortByWeight = (a, b) => (parseInt(this.props.tagsdb[a.id].weight) - parseInt(this.props.tagsdb[b.id].weight));

  sortFacetItem = (a, b) => {
    switch(this.props.facetId) {
      case 'field_color_ranges':
        return this.sortByNameWithParents(a, b);
      case 'field_price_range':
      case 'field_product_reference:field_commerce_delivery_time':
        return this.sortByWeight(a, b);
      default:
        return this.sortByName(a, b);
    }
  }

  render() {
    const { selectedFacets, tagsdb, facetId } = this.props;
    const data = this.props.facetValues
      .map(facetItem => {
        const facetTermId = facetItem.filter;
        return {
          checked: selectedFacets[facetId].includes(facetItem.filter),
          count: facetItem.count,
          id: facetTermId,
          label: tagsdb[facetTermId] && tagsdb[facetTermId].label ? tagsdb[facetTermId].label : '',
          parent: tagsdb[facetTermId] && tagsdb[facetTermId].parent ? tagsdb[facetTermId].parent[0] : undefined,
        };
      })
      // Filter out facets for which we do not have a label.
      .filter(facetItem => facetItem.label)
      // Filter out color facets which have a parent but is not the currently selected.
      .filter(item => !facetsKeyed[facetId].hasParents || !item.parent || selectedFacets[facetId].includes(Number(item.parent)))
      .sort(this.sortFacetItem);

    const selectedFacetsString = selectedFacets[facetId]
      .filter(id => tagsdb[id] && tagsdb[id].label)
      .map(id => tagsdb[id].label)
      .join(', ');

    // If there is only one option there is no need for a collapsible region.
    if (facetsKeyed[facetId].oneline) {
      return (
        <View style={{marginLeft:5}}>
          { data.map((item) => this.renderFacetOption({ item }, true)) }
        </View>
      );
    }

    return (
      <CollapsibleCustom
        data={data}
        mainContent={
          <View style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: Colors.border }}>
            { data.map((item) => this.renderFacetOption({ item }, false)) }
          </View>
        }
        mainTitle={facetIdToLabel[facetId]}
        subTitle={selectedFacetsString}
        toggleExpanded={this.toggleExpanded}
      />
    );
  }
}

export default Facet;
