import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import sampleCombine from 'xstream/extra/sampleCombine';
import flattenConcurrently from 'xstream/extra/flattenConcurrently';
import * as actions from '../actions/index';
import { simpleHeaderWithXcsrf } from './headers'

export function commentsGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.COMMENTS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/comments?sort=id&filter[nid]=' + payload.nid;
      path += '&range=' + Config.COMMENTS_PER_PAGE;
      if (payload.page) {
        path += '&page=' + payload.page;
      }
      return ({
        url: Config.BASE_URL + path,
        category: 'comments',
        nodeId: payload.nid,
      });
    });

  const response$ = sources.HTTP
    .select('comments')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenConcurrently)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.length) {
          data.page = 0;
          if (data.next) {
            let next_page = data.next.href.match(/number%5D=([0-9]*)&/);
            if (next_page[1] > 1) data.page = parseInt(next_page[1]) - 1;
          }
          if (data.previous) {
            let prev_page = data.previous.href.match(/number%5D=([0-9]*)&/);
            if (prev_page[1]) data.page = parseInt(prev_page[1]) + 1;
          }
          return actions.commentsGetSuccess(data);
        }
        else
          return actions.commentsGetFail(arr[0]);
      }catch(e){
        return actions.commentsGetFail(arr[0]);
      }

    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function commentSet(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.COMMENT_SET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let token = payload.token;
      delete payload['token'];
      return ({
        url: Config.BASE_URL + 'api/comments',
        method: 'POST',
        send: payload,
        headers: simpleHeaderWithXcsrf(token),
        category: 'comment',
      });
    })
    .map(payload => {
      return payload;
    });

  const response$ = sources.HTTP
    .select('comment')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map((response => {
      return response
    }));

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data)
          return actions.commentSetSuccess(data.data);
        else
          return actions.commentSetFail(data);
      }catch(e){
        return actions.commentSetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getUpdatedCommentsAfterSettingCommentSuccessfully(sources) {
  const response$ = sources.HTTP
    .select('comment')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map((response) => {
      try {
        return actions.commentsGet({ nid: response[0].body.data[0].nid })
      } catch (e) {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  };
}

export function commentDelete(sources) {
  const token$ = sources.STATE.map(state => state.user.token);

  const request$ = sources.ACTION
    .filter(action => action.type === ActionTypes.COMMENT_DELETE)
    .compose(sampleCombine(token$))
    .map(([action, token]) => ({
      url: `${Config.BASE_URL}api/comments/${action.payload.id}`,
      category: 'commentDelete',
      method: 'DELETE',
      headers: simpleHeaderWithXcsrf(token),
      send: {
        id: action.payload.id,
        nid: action.payload.nid,
      },
    }));

  const response$ = sources.HTTP
    .select('commentDelete')
    .map(response => response.replaceError(err => xs.of(err)))
    .flatten()
    .filter(response => response.status === 200)
    .map((response => {
      return response
    }));

  const action$ = xs.combine(response$)
    .map(response => actions.commentDeleteSuccess(response));

  return {
    ACTION: action$,
    HTTP: request$,
  };
}

export function commentDeleteFail(sources) {
  const response$ = sources.HTTP
    .select('commentDelete')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status !== 200);

  const action$ = xs.combine(response$)
    .map(response => actions.commentDeleteFail(response));

  return {
    ACTION: action$,
  };
}

export function likeComment(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LIKE_COMMENT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/flags/favorite_comment/' + payload.op + '/' + payload.comment.id;
        return ({
          url: Config.BASE_URL + path,
          category: 'likeComment',
        });
      }
    );

  const response$ = sources.HTTP
    .select('likeComment')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.ok === 'YES') {
          return actions.likeCommentSuccess(arr[0]);
        }
        else
          return actions.likeCommentFail(data);
      }catch(e){
        return actions.likeCommentFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
