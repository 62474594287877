import React, { PureComponent } from 'react';
import { Image } from 'react-native';

import * as Config from '../Config';
import Colors from '../constants/Colors';

const sizes = {
  'small': 32,
  'medium': 48,
  'large': 96
};

export class Thumbnail extends PureComponent {
  render() {

    // Dynamic style. We support 3 sizes [small|medium|big]
    // And we support 2 types [squared|avatar]
    const size = (this.props.size && sizes[this.props.size]) ? this.props.size : 'medium';
    const sizePx = sizes[size];
    const style = { alignSelf: 'center', height: sizePx, width: sizePx };
    if (this.props.type != 'squared') {
      style.borderRadius = Math.round(sizePx/2) + 1;
    }
    if (this.props.onPress instanceof Function) {
      return (
        <TouchableOpacity onPress={this.props.onPress}>
          <Image
            source={{ uri: this.props.source.uri }}
            style={ style }
          />
        </TouchableOpacity>
      );
    }
    else {
      return (
        <Image
          source={{ uri: this.props.source.uri }}
          style={ style }
        />
      );
    }
  }
}
