export const themes = [
  {
    label: 'Größe',
    key: 'vt_size_label',
    weight: 2,
  },
  {
    label: 'Ausstattung',
    key: 'vt_setting',
    weight: 4,
  },
  {
    label: 'Gestell',
    key: 'vt_frame',
    weight: 5,
  },
  {
    label: 'Bezug',
    key: 'vt_chair_cover',
    weight: 6,
  },
  {
    label: 'Größe',
    key: 'vt_color_cable',
    weight: 7,
  },
  {
    label: 'Farbe',
    key: 'vt_color_manufact_label',
    weight: 1,
  },
  {
    label: 'Stühle / Sitz',
    key: 'vt_chair_seat',
    weight: 8,
  },
  {
    label: 'Stadt',
    key: 'vt_city',
    weight: 9,
  },
  {
    label: 'Motiv',
    key: 'vt_motive',
    weight: 10,
  },
  {
    label: 'Material',
    key: 'vt_material_label',
    weight: 3,
  },
  {
    label: 'Haken Farbe',
    key: 'vt_color_hook',
    weight: 11,
  },
  {
    label: 'USM-Individuell / 1. Reihe',
    key: 'vt_usm_row_1',
    weight: 12,
  },
  {
    label: 'USM-Individuell / 2. Reihe',
    key: 'vt_usm_row_2',
    weight: 13,
  },
  {
    label: 'USM-Individuell / 3. Reihe',
    key: 'vt_usm_row_3',
    weight: 14,
  },
  {
    label: 'USM-Individuell / 4. Reihe',
    key: 'vt_usm_row_4',
    weight: 15,
  },
  {
    label: 'Stühle / Keder',
    key: 'vt_chair_trim',
    weight: 16,
  },
  {
    label: 'Rahmen',
    key: 'vt_table_frame',
    weight: 17,
  },
];

export const themesKeyed = themes
  .sort((themeA, themeB) => themeA.weight - themeB.weight)
  .reduce((data, theme) => {
    data[theme.key] = theme;
    return data;
  }, {});
