import React, { Component } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import * as Config from '../Config';
import Styles from '../constants/Styles';
import CollapsilbleText from "../components/CollapsilbleText";
import UserSocialIcons from '../components/UserSocialIcons';
import { Thumbnail } from "../components/Thumbnail";
import UserInteractionButtons from './UserInteractionButtons';

class UserInfoComponent extends Component {
  onPressFollowers = () => this.props.navigation.navigate({routeName: 'Followers', key: `followers${this.props.user.id}`, params: { id: this.props.user.id }});
  onPressFollowing = () => this.props.navigation.navigate({routeName: 'Following', key: `following${this.props.user.id}`, params: { id: this.props.user.id }});

  render () {
    const {user} = this.props;
    if (!user) return;
    return (
      <View style={Styles.centerItems}>
        <Thumbnail
          size="large"
          source={{uri: user.pictureUri}}
        />
        <Text style={{marginTop: Config.STANDARD_MARGIN}}>So lebt</Text>
        <Text style={Styles.pageTitle}>{user.label}</Text>
        <View style={{flexDirection: 'row', marginBottom: Config.STANDARD_MARGIN}}>
          <TouchableOpacity
            onPress={this.onPressFollowers}
          >
            <Text>{user.followers} Follower - </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.onPressFollowing}
          >
            <Text>Folgt {user.following} - </Text>
          </TouchableOpacity>
          <Text>Mitglied seit {user.created} </Text>
        </View>
        {this.props.logInState.uid !== user.id &&
          <UserInteractionButtons
            user={user}
            followUser={this.followUser}
            gotoUserDiscussion={this.gotoUserDiscussion}
          />
        }
        <View style={Styles.narrowCol}>
          <CollapsilbleText
            text={user.description ? user.description : ''}
            collapse={true}
            navigation={this.props.navigation}
          />
        </View>
        <View style={{marginTop: Config.STANDARD_MARGIN}} >
          <UserSocialIcons navigate={this.props.navigation.navigate} user={this.props.user} />
        </View>
      </View>
    )
  }

  followUser = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      const {user} = this.props;
      this.props.followUser({uid: user.id, op: user.followed ? 'unflag' : 'flag'});
    }
  };

  gotoUserDiscussion = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      const {user} = this.props;
      this.props.navigation.navigate({
        routeName: 'UserDiscussion',
        key: `discusion${user.id}`,
        params: {user: user}
      });
    }
  };

}

export default UserInfoComponent;
