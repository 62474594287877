import processCart from "./processCart";

/**
 * Use the same process function the is used for the cart object since the properties are the same.
 * Rename the cart property into order property.
 */

const processOrders = (data) => {
  return data.reduce((object, order) => {
      // If order comes with missing data bail out.
      if (!order || !order.id) {
        return;
      }
      const { cart } = processCart(order);
      object[order.id] = cart;
    return object;
    }, {}
  )

};

export default processOrders;
