import React from 'react';
import * as Config from '../Config';
import { Text, TouchableOpacity, View } from 'react-native';
import Styles from '../constants/Styles';

const UserInteractionButtons = (props) => {

    return (
      <View style={{marginBottom: Config.STANDARD_MARGIN, flexDirection: 'row'}}>
        <TouchableOpacity onPress={props.followUser} style={Styles.pinkButtonContainer}>
          <Text style={Styles.pinkButtonText}>{props.user.followed ? "Folge ich" : "Folgen"}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={props.gotoUserDiscussion} style={Styles.pinkButtonContainer}>
          <Text style={Styles.pinkButtonText}>Nachricht schreiben</Text>
        </TouchableOpacity>
      </View>
    );
}

export default UserInteractionButtons;