export const paymentMethods = [
  {
    method: 'bank_transfer',
    title: 'Vorkasse | Überweisung',
    subtitle: '(kostenlos)',
    description: 'Nach Bestelleingang erhältst du eine E-Mail mit allen Informationen für die Überweisung. Bitte beachte, dass Bestellungen mit Vorkasse-Zahlung erst nach Zahlungseingang bearbeitet werden.',
    image: require('../assets/images/bank_transfer.png')
  },
  {
    method: 'paypal_wps',
    title: 'PayPal',
    subtitle: '(kostenlos)',
    description: 'Du wirst nach Überprüfung der Bestelldetails an PayPal weitergeleitet um den Bezahlvorgang abzuschließen.',
    image: require('../assets/images/paypal_wps.png')
  },
  {
    method: 'emerchant_wpf',
    title: 'Kreditkarte',
    subtitle: '(kostenlos)',
    description: 'Du wirst nach Überprüfung der Bestelldetails zur Eingabe deiner Kartendaten weitergeleitet um den Bestellvorgang abzuschließen.',
    image: require('../assets/images/emerchant_wpf.png')
  },
  {
    method: 'mangopay_payin',
    title: 'Kreditkarte',
    subtitle: '(kostenlos)',
    description: 'Du wirst nach Überprüfung der Bestelldetails zur Eingabe deiner Kartendaten weitergeleitet um den Bestellvorgang abzuschließen.',
    image: require('../assets/images/emerchant_wpf.png')
  },
  {
    method: 'paypal_ec',
    title: 'PayPal Express Checkout',
    hidden: true,
  }
];

export const paymentMethodsKeyed = paymentMethods.reduce((object, paymentMethod) => {
  object[paymentMethod.method] = paymentMethod;
  return object;
}, {});
