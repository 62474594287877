import * as Linking from 'expo-linking';
import * as Config from '../Config';
import { isEmpty } from 'lodash';

export function handleUrlFromRoot(url_string, whoAmI, urlToNav) {
  const time = new Date().getTime();
  // Remove the base (scheme + domain) of the url and use the one from Config.
  // Handle the special case when it starts with 'exp://' and has an extra '/--/' at the end.
  if (url_string && url_string != '') {
    const base_url_to_replace = (url_string.indexOf('exp://') === 0)
      ? new RegExp(Linking.makeUrl('/'), "g")
      : new RegExp(Linking.makeUrl(''), "g");
    url_string = url_string.replace(base_url_to_replace, Config.BASE_URL);
    // If the url is an autologin one:
    // - hit the server with it
    // - keep the cookie and ignore the answer
    // - use the resulting/final url to navigate in app
    if (url_string.indexOf('autologin') > -1) {
      fetch(url_string, {method: 'GET', credentials: 'include'})
        .then(response => {
          //TODO remove initial url handling from whoAmI function
          whoAmI({url: response.url, time: time});
        });
    } else {
      urlToNav({url: url_string, time: time});
    }
  }
}

export function handleUrlFromHome (url, navigation, urlToNav, nodes) {
  const params = getParams(url);
  // remove anchors from url
  url = url.split('#')[0];
  // add an extra param (api=2)
  url += (url.indexOf('?') > -1) ? '&' : '?';
  url += 'api=2';
  fetch(url, {method: 'GET', credentials: 'include'})
    .then(response => (response.headers.map['content-type'] == 'application/json')
      ? response.json() : ''  )
    .then(data => {
      if (data && data.length) {
        // Pass URL to helper function below.
        handleUrlResponseRecursive (data, params, navigation, urlToNav, nodes, {});
      }
    });
}

export function handleUrlResponseRecursive (data, params, navigation, urlToNav, nodes, handled) {
  // Remove shop param since the app only show products from the solebich shop.
  if (params && params.shop) {
    delete params.shop;
  }
  // If marke (brand) or farbe (volor) facet is part of the URL,
  // it needs to be translated from labels to IDs.
  if (params && params.marke && !handled.marke) {
    const urlFacetConvert = 'https://www.solebich.de/facet-convert/' + params.marke + '/taxonomy/brands/reverse';
    fetch(urlFacetConvert, {method: 'GET', credentials: 'include'})
      .then(response => response.text())
      .then(tid => {
        // Replace label with ID before passing to navigation.
        params.marke = [tid];
        handled.marke = true;
        handleUrlResponseRecursive (data, params, navigation, urlToNav, nodes, handled);
      });
  }
  else if (params && params.farbe && !handled.farbe) {
    const urlFacetConvert = 'https://www.solebich.de/facet-convert/' + params.farbe + '/taxonomy/color_range/reverse';
    fetch(urlFacetConvert, {method: 'GET', credentials: 'include'})
      .then(response => response.text())
      .then(tid => {
        // Replace label with ID before passing to navigation.
        params.farbe = [tid];
        handled.farbe = true;
        handleUrlResponseRecursive (data, params, navigation, urlToNav, nodes, handled);
      });
  }
  else {
    goToUrl(data, params, navigation, nodes);
    urlToNav('');
  }
}

function getParams(url) {
  let keyValPairs = [];
  let params = {};
  url = url.replace(/.*?\?/,"");

  if (url.length) {
    keyValPairs = url.split('&');
    for (let pairNum in keyValPairs) {
      let key = keyValPairs[pairNum].split('=')[0];
      if (!key.length) continue;
      if (typeof params[key] === 'undefined') {
        params[key] = [];
      }
      params[key].push(keyValPairs[pairNum].split('=')[1]);
    }
  }
  return params;
}

export function goToUrl(url, params, navigation, nodes) {
  if (url && url.length) {
    switch (url[0]) {
      // This is a fake url we use for feedback in the app.
      case 'help':
        navigation.navigate('Feedback');
        break;
      // in case it is a node
      case 'node':
        if (url[1] == 5428563) {
          navigation.navigate('Webpage', { data: {uri: Config.QUESTIONNAIRE }});
        }
        else if (url[1] === 'add' && url[2] === 'image') {
          navigation.navigate('Camera');
        }
        else if (url[2] === 'edit') {
          navigation.navigate({routeName: 'NodeEdit', key: `nodeEdit${url[1]}`, params:  {node: nodes[url[1]], forceNodeUpdate: this.forceUpdate}});
        }
        else {
          navigation.navigate({routeName: 'NodeSingle', key: `node${url[1]}`, params: { id: url[1], productId: params.id }});
        }
        break;
      case 'shopping':
        navigation.navigate({routeName: 'Shopping', key: `shopping${url[2]}`, params: {params, categoryId: url[2]}});
        break;
      case 'taxonomy':
        navigation.navigate({routeName: 'Tag', key: `tag${url[2]}`, params: {id: url[2], showMenu: 0 }});
        break;
      case 'recent':
        navigation.navigate({routeName: 'Tag', key: `tag${url[2]}`, params: {id: url[2], showMenu: 1 }});
        break;
      case 'user':
        switch (url[1]) {
          case 'addressbook':
            navigation.navigate('AddressBook');
            break;
          case 'orders':
            navigation.navigate('OrderHistory');
            break;
          case 'reset':
            navigation.navigate('Reset');
            break;
          default:
            switch (url[2]) {
              case 'edit':
                navigation.navigate({routeName: 'UserEdit', params: {tab: 'email'}});
                break;
              case 'geherztkommentiert':
                navigation.navigate({routeName: 'User', key: `user${url[1]}`, params: {id: url[1], showMenu: 1}});
                break;
              default:
                navigation.navigate({routeName: 'User', key: `user${url[1]}`, params: {id: url[1]}});
            }
        }
        break;
      case 'community':
        switch (url[1]) {
          case 'follow':
            navigation.navigate({routeName: 'Community', params: {showMenu: 1}});
          break;
          case 'bei-mir':
            navigation.navigate({routeName: 'Community', params: {showMenu: 2}});
          break;
          default:
            navigation.navigate('Community');
        }
        break;
      case 'wohnmagazin':
        navigation.navigate('Magazine');
        break;
      case 'newsletter':
        navigation.navigate({routeName: 'UserEdit', params: {tab: 'email'}});
        break;
      case 'private':
        if (url[2]) {
          navigation.navigate({
            routeName: 'UserDiscussion',
            key: `discussion${url[2]}`,
            params: {user: {id: url[2]}}
          });
        }
        else {
          navigation.navigate('Inbox');
        }
        break;
      case 'suche':
        navigation.navigate({
          routeName: 'Search',
          params: { keyword: params['keywords'], preFill: true }
        });
        break;
      case 'neu':
        navigation.navigate({ routeName: 'Home', params: { showMenu: 0 }});
        break;
      case 'folge-ich':
        navigation.navigate({ routeName: 'Home', params: { showMenu: 1 }});
        break;
      case 'neu_kommentiert':
        navigation.navigate({ routeName: 'Home', params: { showMenu: 2 }});
        break;
      case 'beliebte_woche':
        navigation.navigate({ routeName: 'Home', params: { showMenu: 3 }});
        break;
      case 'beliebt':
        navigation.navigate({ routeName: 'Home', params: { showMenu: 4 }});
        break;
      default:
        // If no match do nothing. Better than resetting to home screen.
        // navigation.navigate('Home', { doubleClicked: true });
    }
  }
}

