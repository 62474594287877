import React, {Component} from 'react';
import { View, Text, ScrollView} from "react-native";
import CustomRadioForm from "../general/CustomRadioForm"
import CustomButton from "../general/CustomButton";
import Styles from "../../constants/Styles";


class ShippingMethods extends Component {

  constructor(props) {
    super(props);
    let selectedShipping = props.selectedShipping;
    if (!selectedShipping) {
      const options = Object.keys(props.cartShippingOptions);
      if (options && options[0]) {
        selectedShipping = options[0].toString();
      }
    }
    this.state = {
       selectedShipping: selectedShipping,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoadingShipping && !this.props.isLoadingShipping) {
      const { cartShippingOptions,  selectedShipping } = this.props;
      if (cartShippingOptions[selectedShipping] === undefined) {
        if (Object.keys(cartShippingOptions)[0]) {
          this.setShipping(Object.keys(cartShippingOptions)[0].toString());
        }
      }
    }
  }

  setShipping (selection) {
    this.setState({selectedShipping: selection})
    this.props.cartShippingSelect(selection)
  }

  customRadioForm () {
    const { cartShippingOptions } = this.props;
    const radio_props = Object.keys(cartShippingOptions).map( key => {
      return ({
        label: cartShippingOptions[key].title + ': ' + cartShippingOptions[key].price + ' €' + `\n\n`  +  cartShippingOptions[key].description,
        value: key
      })
    })
    return (
      <CustomRadioForm
        radio_props={radio_props}
        selectedProp={this.state.selectedShipping}
        setSelectedProp={(selection) => {this.setShipping(selection)}}
        navigation={this.props.navigation}
      />
    )
  }

  render() {
    return (
      this.props.isCart ?
        <View style={{paddingVertical: 20}}>
          <Text style={{marginBottom: 10, fontWeight: 'bold'}}>Versand</Text>
          {this.customRadioForm()}
        </View> :
        <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
          <ScrollView
            contentContainerStyle={Styles.pageScrollViewContainer}
            keyboardShouldPersistTaps={"handled"}
            style={{marginBottom: 60}}
          >
            {this.customRadioForm()}
          </ScrollView>
          <View style={Styles.shopButtonWrap}>
            <CustomButton
              title="Weiter"
              onPress={() => this.props.navigation.goBack()}
              buttonStyle={Styles.shopButton}
              fontFamily={"OpenSansRegular"}
            />
          </View>
        </View>
        )
  }
}

export default ShippingMethods;
