import { Alert, Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as Config from '../Config';
import * as Updates from 'expo-updates';

// Check for major updates and alert the user.
export function checkUpdateMajor() {
  const currentVersion = Constants.manifest.version.split('.');
  const latestVersion = Config.LATEST_VERSION.split('.');
  const storeUrl = (Platform.OS === 'ios')
    ? "itms-apps://itunes.apple.com/app/1340857642"
    : "market://details?id=de.solebich.app";

  if (latestVersion[1] > currentVersion[1]) {
    Alert.alert(
      'Update notwendig',
      'Eine neue Version der App ist verfügbar. Bitte klicke auf Okay und lade die neue Version im App Store herunter.',
      [
        {
          text: 'Okay',
          onPress: () => Linking.openURL(storeUrl),
          style: 'cancel',
        },
      ],
      {
        cancelable: true
      },
    );
  }
};

// Check for minor updates, wait and restart if any.
export async function checkUpdateMinor() {
  if (!__DEV__) {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      Alert.alert(
        '',
        'Die App wird aktualisiert und muss kurz neu starten.',
        [
          {
            text: 'Okay',
            onPress: () => Updates.reloadAsync(),
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
  }
};
