import xs from "xstream";
import sampleCombine from 'xstream/extra/sampleCombine';

import { simpleHeaderWithXcsrf, simpleHeaderWithXcsrfAndroidCompatible } from './headers';
import * as Config from "../Config";
import * as actions from "../actions";
import * as ActionTypes from "../actions/actionTypes";

export function getShippingAddress(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_SHIPPING_ADDRESS)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/v1.0/profile_shipping';
      return ({
        url: Config.BASE_URL + path,
        category: 'getShippingAddress',
      });
    });

  const response$ = sources.HTTP
    .select('getShippingAddress')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.getShippingAddressSuccess(data);
        }
        else {
          return actions.getShippingAddressFail(data);
        }
      }catch(e){
        return actions.getShippingAddressFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getBillingAddress(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_BILLING_ADDRESS)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/v1.0/profile_billing';
      return ({
        url: Config.BASE_URL + path,
        category: 'getBillingAddress',
      });
    });

  const response$ = sources.HTTP
    .select('getBillingAddress')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.getBillingAddressSuccess(data);
        }
        else {
          return actions.getBillingAddressFail(data);
        }
      }catch(e){
        return actions.getBillingAddressFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addressCreate(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADDRESS_CREATE)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const data = {
        address: {
          first_name: payload.data.first_name,
          last_name: payload.data.last_name,
          thoroughfare: payload.data.street,
          premise: payload.data.premise,
          postal_code: payload.data.postal_code,
          locality: payload.data.locality,
          country: payload.data.country,
          name_line: payload.data.first_name + ' ' + payload.data.last_name
        },
        phone: payload.data.phone,
      };
      let path = 'api/v1.0/' + 'profile_' + payload.data.type;
      return ({
        url: Config.BASE_URL + path,
        send: data,
        method: 'POST',
        category: 'addressCreate',
        headers: simpleHeaderWithXcsrf(payload.token)
      });
    });

  const response$ = sources.HTTP
    .select('addressCreate')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.addressCreateSuccess(data.data[0]);
        }
        else {
          return actions.addressCreateFail(data);
        }
      }
      catch(e) {
        return actions.addressCreateFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addressCreateSuccessShipping(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADDRESS_CREATE_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.getShippingAddress());

  return {
    ACTION: action$,
  }
}

export function addressCreateSuccessBilling(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADDRESS_CREATE_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.getBillingAddress());

  return {
    ACTION: action$,
  }
}

export function addressDelete(sources) {

  const token$ = sources.STATE.map(state => state.user.token);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADDRESS_DELETE)
    .map(action => action.payload)
    .compose(sampleCombine(token$))
    .map(([payload, token]) => {
      return {
        ...payload,
        token
      }
    });

  const request$ = payload$
    .map(payload => {
      return ({
        category: 'addressDelete',
        id: payload.id,
        headers: simpleHeaderWithXcsrf(payload.token),
        method: 'DELETE',
        url: payload.self,
      });
    });

  const response$ = sources.HTTP
    .select('addressDelete')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        if (arr[0].ok) {
          return actions.addressDeleteSuccess({ id: arr[0].request.id });
        }
        else {
          return actions.addressDeleteFail({ id: arr[0].request.id });
        }
      }
      catch(e) {
        return actions.addressDeleteFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function addressUpdate(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ADDRESS_UPDATE)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const data = {
        address: {
          first_name: payload.data.first_name,
          last_name: payload.data.last_name,
          thoroughfare: payload.data.street,
          premise: payload.data.premise,
          postal_code: payload.data.postal_code,
          locality: payload.data.locality,
          country: payload.data.country,
          name_line: payload.data.first_name + ' ' + payload.data.last_name
        },
        phone: payload.data.phone,
      };
      return ({
        url: payload.data.self,
        send: data,
        method: 'PATCH',
        category: 'addressUpdate',
        headers: simpleHeaderWithXcsrf(payload.token)
      });
    });

  const response$ = sources.HTTP
    .select('addressUpdate')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.addressUpdateSuccess(data);
        }
        else {
          return actions.addressUpdateFail(data);
        }
      }
      catch(e) {
        return actions.addressUpdateFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function profileSelect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PROFILE_SELECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/profile-select/' + payload.type + '/' + payload.id;
      return ({
        url: Config.BASE_URL + path,
        category: 'profileSelect',
      });
    });

  const response$ = sources.HTTP
    .select('profileSelect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.profileSelectSuccess(data);
        }
        else {
          return actions.profileSelectFail(data);
        }
      }catch(e){
        return actions.profileSelectFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function profileSelectSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PROFILE_SELECT_SUCCESS)
    .map(action => action.payload)
    .map((arr) => actions.cartGet());

  return {
    ACTION: action$,
  }
}

export function profileSetDefault(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.PROFILE_SET_DEFAULT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/profile-set-default/' + payload.id;
      return ({
        url: Config.BASE_URL + path,
        category: 'profileSetDefault',
      });
    });

  const response$ = sources.HTTP
    .select('profileSetDefault')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.profileSetDefaultSuccess(data);
        }
        else {
          return actions.profileSetDefaultFail(data);
        }
      }catch(e){
        return actions.profileSetDefaultFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
