import React from 'react';
import {Alert, Keyboard, Platform, Text, View, ScrollView} from 'react-native';
import Styles from "../constants/Styles";
import CustomTextArea from "./CustomTextArea";
import CustomButton from './general/CustomButton';
import IndicatorPage from '../components/general/IndicatorPage';
import CustomTextInput from "./CustomTextInput";

class FeedbackScreenComponent extends React.Component {
  state = {
    text: '',
    email: ''
  };


  componentDidUpdate(prevProps) {
    this.submitted = this.submitted || false;
    if (this.props.submittedFeedbackForm && !prevProps.submittedFeedbackForm && !this.submitted) {
      this.submitted = true;
      Alert.alert(
        '',
        'Vielen Dank für deine Nachricht.',
        [
          {
            text: 'Okay',
            onPress: () => this.props.navigation.goBack(),
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
    if (this.props.submittedFeedbackFormErrorMessage && this.props.submittedFeedbackFormErrorMessage !== null) {
      Alert.alert(
        '',
        this.props.submittedFeedbackFormErrorMessage,
        [
          {
            text: 'Okay',
            onPress: () =>  {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
  }

  onPressSubmit = () => {
    const body = (this.props.user.uid > 0) ? this.state.text + ' <br/> ' + this.props.user.username + ' <br/> ' + '<a href="https://www.solebich.de/user/' + this.props.user.id +'">User profile</a>' + ' <br/> ' + this.props.user.mail : this.state.text + ' <br/> ' + this.state.email;
    const subject = (this.props.user.uid > 0) ? this.props.user.username : this.state.email;
    if (!(this.props.user.uid > 0) && !this.state.email) {
      Alert.alert(
        '',
        "Eine E-Mail Adresse ist erforderlich.",
        [
          {
            text: 'Okay',
            onPress: () =>  {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
    else if (!this.state.text) {
      Alert.alert(
        '',
        "Die Nachricht darf nicht leer sein.",
        [
          {
            text: 'Okay',
            onPress: () =>  {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    } else {
      this.props.submitFeedbackForm({
        subject: '[App] - App Hilfe/Feedback von ' + subject,
        body: body,
      })
    }
  };


  render() {
    if (this.props.isSubmittingFeedbackForm) {
      return (
        <IndicatorPage />
      );
    }
    return (
      <View style={[Styles.pageScrollViewContainer, {flex: 1}]}>
        <ScrollView keyboardShouldPersistTaps={"handled"}>
          <Text style={Styles.pageTitle}>Hilfe und Feedback</Text>
          <Text style={Styles.textCenter}>Hinterlasse uns eine Nachricht und wir melden uns in Kürze bei Dir.</Text>
          { !(this.props.user.uid > 0) ? <CustomTextInput
            value={this.state.email}
            onChangeText={(text) => {this.setState({email: text});}}
            label="Email"
            clearButtonMode="always"
            selectTextOnFocus={true}
            autoCorrect={false}
            controlled={true}
          /> : null }
          <CustomTextArea
            label='Nachricht'
            value={this.state.text && this.state.text != null ? this.state.text.replace(/<br ?\/?>/g, "\n"): ""}
            onChangeText={(text) => this.setState({ text })}
          />
          <CustomButton
            title="Senden"
            onPress={this.onPressSubmit}
            loading={this.props.isSubmittingFeedbackForm}
            disabled={this.props.isSubmittingFeedbackForm}
          />
        </ScrollView>
      </View>

    );
  }
}

export default FeedbackScreenComponent;
