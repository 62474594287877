import React, { PureComponent } from 'react';
import {
  Image,
  TouchableOpacity,
  View
} from 'react-native';

import * as Config from '../Config';
import { Icon } from '../components/Icon';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';

export class ArrowLeft extends PureComponent {
  render() {
    return (
      <View style={Styles.arrowContainer}>
        <Icon name='left_tall' size={64} />
      </View>
    );
  }
}

export class ArrowRight extends PureComponent {
  render() {
    return (
      <View style={Styles.arrowContainer}>
        <Icon name='right_tall' size={64}/>
      </View>
    );
  }
}
