import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  commentsGet,
  commentSet,
  entityUpdate,
  entityDel,
  usersGetMentioning,
  fileUpload,
  clearUploadedImage,
  likeComment,
} from "../actions/index";
import * as Config from '../Config';
import CommentsScreenComponent from "../components/CommentsScreenComponent";
import CustomInteractionManager from '../components/CustomInteractionManager';

class CommentsScreen extends Component {
  state = {
    page: 0,
    text: '',
  };

  componentDidMount() {
    CustomInteractionManager.runAfterInteractions(() => {
      const { node } = this.props.navigation.state.params;
      this.props.commentsGet({ nid: node.id });
    })
  }

  _onRefresh = ()  => {
    const { node } = this.props.navigation.state.params;
    this.props.commentsGet({nid: node.id});
  };

  _onEndReached = () => {
    const { node } = this.props.navigation.state.params;
    if (!this.props.isLoading) {
      let new_page = this.state.page + 1;
      if (new_page <= Math.floor(node.comments/Config.COMMENTS_PER_PAGE)) {
        this.props.commentsGet({nid: node.id, page: new_page});
        this.setState({page: new_page});
      }
    }
  };

  render() {
    const { node } = this.props.navigation.state.params;
    return(
      <CommentsScreenComponent
        isLoading={this.props.isLoadingComments}
        isDeletingCommentId={this.props.isDeletingCommentId}
        isSendingComment={this.props.isSendingComment}
        logInState={this.props.logInState}
        comments={this.props.comments}
        commentSet={this.props.commentSet}
        entityDel={this.props.entityDel}
        navigation={this.props.navigation}
        node={node}
        _onRefresh={this._onRefresh}
        _onEndReached={this._onEndReached}
        usersMentioning={this.props.usersMentioning}
        usersGetMentioning={this.props.usersGetMentioning}
        commentEdit={this.props.commentEdit}
        upload={this.props.upload}
        fileUpload={this.props.fileUpload}
        clearUploadedImage={this.props.clearUploadedImage}
        likeComment={this.props.likeComment}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingComments: state.comments.isLoadingComments,
    isDeletingCommentId: state.comments.isDeletingCommentId,
    isSendingComment: state.comments.isSendingComment,
    logInState: state.user,
    comments: state.comments.comments,
    usersMentioning: state.users.usersMentioning,
    upload: state.images.upload,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    commentsGet: (payload) => {
      dispatch(commentsGet(payload))
    },
    commentSet: (payload) => {
      dispatch(commentSet(payload))
    },
    commentEdit: (payload) => {
      dispatch(entityUpdate(payload))
    },
    entityDel: (payload) => {
      dispatch(entityDel(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    clearUploadedImage: (payload) => {
      dispatch(clearUploadedImage(payload))
    },
    likeComment: (payload) => {
      dispatch(likeComment(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsScreen);
