import Styles from "../../constants/Styles";
import {Text, View} from "react-native";
import CustomButton from "./CustomButton";
import React from "react";
import {EventRegister} from "react-native-event-listeners";
import * as Updates from "expo-updates";

const onReload = (indicatorPlacement) => {
  // Send event. Wait a second before reloading.
  setTimeout(() => {
    EventRegister.emit('analyticEvent', {
      category: 'error',
      action: 'app_reloaded',
      label: indicatorPlacement ?? 'place_unknown'
    });
  }, 1000);
  // Reload the app.
  Updates.reloadAsync();
}

const RestartMessage = (props) => (
  <View style={Styles.indicatorPageContainer}>
    <Text style={Styles.restartText}>{'Die App hat keine Internet-Verbindung. Versuche eine bessere Verbindung zu bekommen und starte die App neu.'}</Text>
    <CustomButton title="Neustart" onPress={() => { onReload(props.indicatorPlacement) }} buttonStyle={Styles.restartButton}/>
  </View>
)

export default RestartMessage;
