import {Text, TouchableOpacity, View} from "react-native";
import Styles from "../../constants/Styles";
import {paymentMethodsKeyed} from "../../constants/paymentMethods";
import {Icon} from "../Icon";
import React from "react";

const SelectedPaymentMethod = ({paymentMethod, navigation, isCart}) => (
 paymentMethod ?
  <View style={Styles.checkoutBlocks}>
    <Text>{paymentMethodsKeyed[paymentMethod].title}</Text>
    { isCart && <TouchableOpacity onPress={() => navigation.navigate('Payment')}>
      <Icon
        name='pencil'
        size={20}
        color='#818181'
      />
    </TouchableOpacity> }
  </View> :
   (isCart && <View style={Styles.checkoutBlocks}>
    <TouchableOpacity style={[Styles.greyButtonContainer, {margin: 0}]} onPress={() => navigation.navigate('Payment')}>
      <Text>Select Payment Method</Text>
    </TouchableOpacity>
  </View>)
)

export default SelectedPaymentMethod;
