import React, { Component } from 'react';
import { Image, Keyboard, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Colors from "../constants/Colors";
import CustomIndicator from "../components/CustomIndicator";
import CustomMentionsTextInput from "./CustomMentionsTextInput";
import CustomButton from './general/CustomButton';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import { chooseFileMethod } from '../helpers/images';

export default class CommentEditScreenComponent extends Component {

  state={
    text: '',
    clearImage: false
  };

  // on mount get the plain version of the text
  componentDidMount() {
    this.setState({
      text: this.props.navigation.state.params.comment.textValue,
    })
  };

  undoEdit = () => {
    this.props.entityUpdateFail([{nids: this.props.navigation.state.params.comment.nid}]);
    this.props.navigation.goBack();
  };

  submitComment = () => {
    if (this.state.text !== null && this.state.text !== '') {
      this.props.entityUpdate({
        nid: this.props.navigation.state.params.comment.nid,
        self: this.props.navigation.state.params.comment.self,
        token: this.props.logInState.token,
        body: this.state.text,
        image: this.state.clearImage ? undefined : (this.props.upload[this.props.navigation.state.params.comment.nid] ? this.props.upload[this.props.navigation.state.params.comment.nid].image_id : this.props.navigation.state.params.comment.imageId)
      });
      Keyboard.dismiss();
    }
  };

  // when a temporary image is pressed clear it from store
  onRemoveImage = () => {
    this.setState({clearImage: true});
  };
  
  onChangeText = (text) => {
    this.setState({text});
  };

  render() {
    return (
      <ScrollView
        style={Styles.pageScrollViewContainer}
        keyboardShouldPersistTaps={"handled"}
      >
        <View style={{flex: 1}}>
          <CustomMentionsTextInput
            onChangeText={this.onChangeText}
            usersGetMentioning={this.props.usersGetMentioning}
            usersMentioning={this.props.usersMentioning}
            value={this.state.text}
          />
          {!this.state.clearImage && this.props.navigation.state.params.comment.imageId || this.props.upload[this.props.navigation.state.params.comment.nid] ?
            this.props.upload[this.props.navigation.state.params.comment.nid] && this.props.upload[this.props.navigation.state.params.comment.nid].uploading ?
              <CustomIndicator />
            :
              <View>
            <TouchableOpacity onPress={() => {
              this.setState({clearImage: false});
              chooseFileMethod(this.props.navigation.state.params.comment.nid, this.props.fileUpload);
            }}>
              <View
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  overflow: 'hidden',
                }}>
                <Image
                  source={{uri: this.props.upload[this.props.navigation.state.params.comment.nid] && this.props.upload[this.props.navigation.state.params.comment.nid].image_uri ? this.props.upload[this.props.navigation.state.params.comment.nid].image_uri : this.props.navigation.state.params.comment.imageStyles1200x900Conditional}}
                  style={{width: (Layout.half.width - 30), height: 120}}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
                { !this.state.clearImage ?
                  <TouchableOpacity onPress={this.onRemoveImage}>
                    <Text style={[Styles.simpleLink, {
                      marginTop: 20,
                      alignSelf: 'center'
                    }]}>Bild entfernen</Text>
                  </TouchableOpacity>
                  :
                  null
                }
              </View>
            :
            <TouchableOpacity onPress={() => {
              this.setState({clearImage: false});
              chooseFileMethod(this.props.navigation.state.params.comment.nid, this.props.fileUpload);
            }}>
              <Text style={[Styles.simpleLink, {
                marginTop: 20,
                alignSelf: 'center'
              }]}>Bild hinzufügen</Text>
            </TouchableOpacity>
          }
          <CustomButton
            title="Speichern"
            onPress={this.submitComment}
            disabled={this.props.isEditingComment || (this.props.upload[this.props.navigation.state.params.comment.nid] && this.props.upload[this.props.navigation.state.params.comment.nid].uploading)}
            loading={this.props.isEditingComment}
          />
          <CustomButton
            title="Cancel"
            onPress={this.undoEdit}
          />
        </View>
        <View>
          <Text style={{color: Colors.tintColor, paddingHorizontal: 5, textAlign: 'center'}}>
            {this.state.errorMessage}
          </Text>
        </View>
      </ScrollView>
    );
  }

};
