const flattenShop = (node) => {
  return {
    id: node.id,
    label: node.label,
    self: node.self,
    type: node.type,
    status: node.status,
    imageId: node.image ? node.image.id : undefined,
    imageSelf: node.image ? node.image.self : undefined,
    imageFilemime: node.image ? node.image.filemime : undefined,
    imageFileSize: node.image ? node.image.filesize : undefined,
    imageWidth: node.image ? node.image.width : undefined,
    imageHeight: node.image ? node.image.height : undefined,
    imageStylesS2SlideSm: node.image && node.image.styles ? node.image.styles["s2_slide_sm"] : undefined,
    userId: node.user ? node.user.id: undefined,
    userLabel: node.user ? node.user.label : undefined,
    userSelf: node.user ? node.user.self : undefined,
    userMail: node.user? node.user.mail : undefined,
  }
};

export default flattenShop;
