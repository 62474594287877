import * as ActionTypes from "./actionTypes";

export function ordersGet(payload){
  return {
    type: ActionTypes.ORDERS_GET,
    payload
  }
}

export function ordersGetSuccess(payload){
  return {
    type: ActionTypes.ORDERS_GET_SUCCESS,
    payload
  }
}

export function ordersGetFail(payload){
  return {
    type: ActionTypes.ORDERS_GET_FAIL,
    payload
  }
}

export function shippingCollect(payload){
  return {
    type: ActionTypes.SHIPPING_COLLECT,
    payload
  }
}

export function shippingCollectSuccess(payload){
  return {
    type: ActionTypes.SHIPPING_COLLECT_SUCCESS,
    payload
  }
}

export function shippingCollectFail(payload){
  return {
    type: ActionTypes.SHIPPING_COLLECT_FAIL,
    payload
  }
}
