import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  entityDel,
  fileUpload,
  getTagByName,
  nodeEdit,
  tagsGet,
  usersGetMentioning
} from "../actions/index";
import NodeEditScreenComponent from "../components/NodeEditScreenComponent";

class NodeEditScreen extends Component {

  componentDidUpdate(prevProps) {
    if (!this.props.isUpdating && prevProps.isUpdating) {
      // update parent component (NodeScreen)
      this.props.navigation.state.params.forceNodeUpdate();
      this.props.navigation.goBack();
    }
    else if (!this.props.isDeletingEntity && prevProps.isDeletingEntity) {
      this.props.navigation.pop(2);
    }
  }

  render() {
    return(
      <NodeEditScreenComponent
        nodeEdit={this.props.nodeEdit}
        nodes={this.props.nodes}
        isUpdating={this.props.isUpdating}
        navigation={this.props.navigation}
        uid={this.props.uid}
        logInState={this.props.logInState}
        tags={this.props.tags}
        tagsGet={this.props.tagsGet}
        usersMentioning={this.props.usersMentioning}
        usersGetMentioning={this.props.usersGetMentioning}
        newTags = {this.props.newTags}
        getTagByName = {this.props.getTagByName}
        fileUpload={this.props.fileUpload}
        upload={this.props.upload}
        isGettingTag={this.props.isGettingTag}
        creatingTag={this.props.creatingTag}
        entityDel={this.props.entityDel}
        isDeletingEntity={this.props.isDeletingEntity}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    nodes: state.images.nodes,
    isUpdating: state.images.updatingNode,
    isGettingTag: state.tags.gettingTag,
    isDeletingEntity: state.images.isDeletingEntity,
    creatingTag: state.tags.creatingTag,
    logInState: state.user,
    uid: state.user.id,
    usersMentioning: state.users.usersMentioning,
    tags: state.tags.tags,
    newUploadedImage: state.newUploadedImage,
    newTags: state.tags.newTags,
    upload: state.images.upload[1],
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    nodeEdit: (payload) => {
      dispatch(nodeEdit(payload))
    },
    tagsGet: (payload) => {
      dispatch(tagsGet(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    getTagByName: (payload) => {
      dispatch(getTagByName(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    entityDel: (payload) => {
      dispatch(entityDel(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeEditScreen);
