import React from 'react';
import {
  AppState,
  Platform,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import { MenuProvider } from 'react-native-popup-menu'
import * as Linking from 'expo-linking';
import { connect } from 'react-redux';
import Colors from '../constants/Colors';
import { alertAfterRegistering, alertAfterNewPasswordRequest } from '../helpers/alerts.js';
import { loadAssetsAsync } from '../helpers/assets.js';
import { is12or13 } from '../helpers/devices.js';
import { checkUpdateMajor, checkUpdateMinor } from '../helpers/updates.js';
import { handleUrlFromRoot } from '../helpers/urls.js';
import RootNavigation from '../navigation/RootNavigation';
import { whoAmI, urlToNav } from '../actions/index';
import IndicatorPage from '../components/general/IndicatorPage';
import NoConnectivity from "../components/noConnectivity";

class RootScreen extends React.Component {

  state = {
    assetsAreLoaded: false,
  };

  componentDidMount() {
    // 0. Check which is the current user.
    this.props.whoAmI();
    // 1. Load some heavy resources
    loadAssetsAsync(() => this.setState({ assetsAreLoaded: true }));
    // 2. Listen for incoming URLs.
    // 2.1. When app is already open.
    Linking.addEventListener('url', this.handleEvent);
    // 2.2. When app was closed and just opened.
    Linking.getInitialURL().then((url) => {
      handleUrlFromRoot(url, this.props.whoAmI, this.props.urlToNav);
    });
    if (Platform.OS !== 'web') {
      // 3. Listen to app state changes
      AppState.addEventListener('change', this.handleAppStateChange);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( (this.props.logInState.switchRegisterLogin !== prevProps.logInState.switchRegisterLogin) && this.props.logInState.switchRegisterLogin) {
      alertAfterRegistering();
    }
    if ( (this.props.logInState.switchForgotPasswordLogin !== prevProps.logInState.switchForgotPasswordLogin) && this.props.logInState.switchForgotPasswordLogin) {
      alertAfterNewPasswordRequest();
    }
  }

  handleEvent = (event) => {
    handleUrlFromRoot(event.url, this.props.whoAmI, this.props.urlToNav);
  }
 
  componentWillUnmount() {
    AppState.removeEventListener('change', this.handleAppStateChange);
    Linking.removeEventListener('url', this.handleEvent);
  }

  render() {

    if (!this.state.assetsAreLoaded || this.props.isLoading) {
      return (
        <IndicatorPage place={'RootScreen'} />
      );
    }

    return (
      <View style={styles.container}>
        <StatusBar
          backgroundColor="#FFFFFF"
          barStyle="dark-content"
        />
        { this.props.isOffline ? <NoConnectivity/> : null }
        <MenuProvider>
          <RootNavigation />
        </MenuProvider>
      </View>
    );
  }


  handleAppStateChange = async (nextAppState) => {
    // TODO: Check if this is needed. What's wrong to check for updates when
    // the app is about to go to the background.
    if (nextAppState === 'active') {
      checkUpdateMajor();
      checkUpdateMinor();
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.background,
    marginTop: Platform.OS === "ios" ? (is12or13 ? 40 : 20) : 0
  },
});

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    isOffline: state.device.isOffline,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    whoAmI: (payload) => {
      dispatch(whoAmI(payload))
    },
    urlToNav: (payload) => {
      dispatch(urlToNav(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
