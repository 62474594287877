import React from 'react';
import { Text, View } from 'react-native';
import { connect } from 'react-redux';
import IconBadge from 'react-native-icon-badge';

import * as Config from '../Config';
import { Icon } from '../components/Icon';
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';

class IconWithBadge extends React.Component {
  render() {
    const count = this.props.icon == 'bell' ? this.props.badgeCount : this.props.cartCount;
    return (
      <View style={{flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
        <IconBadge
          MainElement={
            <Icon
              active={this.props.focused}
              name={this.props.icon}
              size={Config.SIZE_ICON}
              color={this.props.focused ? Colors.iconSelected : Colors.iconDefault}
            />
          }
          BadgeElement={
            <Text style={{color:'#FFFFFF'}}>{count}</Text>
          }
          Hidden={count==0}
          IconBadgeStyle={Styles.IconBadgeStyle}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    badgeCount: state.badges.badgeCount,
    cartCount: state.checkout.cartCount
  }
};

export default connect(mapStateToProps)(IconWithBadge);
