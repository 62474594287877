import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity
} from 'react-native';
import { connect } from 'react-redux';
import { Button } from 'react-native-elements';
import * as AuthSession from 'expo-auth-session';
import Constants from 'expo-constants'
import * as SecureStore from 'expo-secure-store';
import * as Config from '../Config';
import Colors from '../constants/Colors';
import Styles from "../constants/Styles";
import {appleConnect, facebookConnect, loginInit, googleConnect} from '../actions/index';
import CustomTextInput from "../components/CustomTextInput";
import {Icon} from "../components/Icon";
import CustomButton from '../components/general/CustomButton';
import AppleAuthenticationComponent from "../components/AppleAuthenticationComponent";
import {withHooksHOC} from "../helpers/withHooksHOC";
import {GoogleButton} from "../components/general/GoogleButton";

interface IHooksHOCProps {
  promptAsync: any,
  disabled: boolean
}

class LoginScreen extends React.Component<IHooksHOCProps> {

  state = {
    assetsAreLoaded: false,
    username: '',
    password: '',
  };

  componentDidMount() {
    // load stored credentials if any
    if(Platform.OS !== 'web') {
      this.loadCredentials();
    }
  };

  // get the last used combination of username/password if any
  loadCredentials = async () => {
    let user = await SecureStore.getItemAsync('sli_user');
    let pass = await SecureStore.getItemAsync('sli_pass');
    if (user && pass)
      this.setState({
        isLoading: false,
        username: user,
        password: pass,
      });
    else
      this.setState({
        isLoading: false,
      });
  };

  handleLoginAsync = () => {
    if (!this.state.username || !this.state.password) return;
    if (Platform.OS !== 'web') {
      SecureStore.setItemAsync('sli_user', this.state.username);
      SecureStore.setItemAsync('sli_pass', this.state.password);
    }
    this.props.loginInit({username: this.state.username, password: this.state.password});
  };

  fbConnect = async () => {
    let redirectUrl = AuthSession.getRedirectUrl();
    let result = await AuthSession.startAsync({
      authUrl:
      `https://www.facebook.com/v2.8/dialog/oauth?response_type=token` +
      `&client_id=${Constants.manifest.facebookAppId}` +
      `&redirect_uri=${encodeURIComponent(redirectUrl)}`,
    });

    if (result.type !== 'success' || !result.params || !result.params.access_token) {
      alert('Something went wrong');
      return;
    }
    this.props.facebookConnect({token: result.params.access_token});
  };

  render() {
    return (
      <ScrollView
          style={Styles.pageScrollViewContainer}
          keyboardShouldPersistTaps='handled'
          contentContainerStyle={{flexGrow:1, justifyContent: 'center'}}
        >
        <View style={[Styles.padInputWidth, {marginTop: 100}]}>
          <CustomTextInput
            value={this.state.username}
            onChangeText={(text) => this.setState({username: text})}
            label="Benutzername / E-Mail"
            autoCapitalize="none"
            clearButtonMode="always"
            autoCorrect={false}
          />
          <CustomTextInput
            secureTextEntry={true}
            value={this.state.password}
            onChangeText={(text) => {this.setState({password: text})}}
            label="Passwort"
            clearButtonMode="always"
            selectTextOnFocus={true}
            autoCorrect={false}
            controlled={true}
          />
          <View style={{ alignItems: 'center' }}>
            { this.props.logInState.loginErrorMessage && <Text style={{color: 'red', marginTop: 10}}>{this.props.logInState.loginErrorMessage}</Text> }
            { this.props.logInState.appleConnectMessage && <Text style={{color: 'red', marginTop: 10, textAlign: 'left'}}>{this.props.logInState.appleConnectMessage}</Text> }
            <CustomButton
              title="Einloggen"
              onPress={this.handleLoginAsync}
            />
            <Button
              icon={
                <Icon
                  name='small_fb'
                  color='white'
                  size={Config.FONT_SIZE_NORMAL}
                />
              }
              title="Mit Facebook anmelden"
              onPress={this.fbConnect}
              buttonStyle={[Styles.fbButton, Styles.button]}
              titleStyle={{fontWeight: 'bold'}}
              fontWeight='bold'
            />
            { Platform.OS === "ios" ?
              <AppleAuthenticationComponent
                register={false}
                appleConnect={this.props.appleConnect}
              /> : null }
            {/*Hide GoogleButton until the OAuth app is published an working in standalone apps*/}
            {/*<GoogleButton*/}
            {/*  disabled={this.props.disabled}*/}
            {/*  title={'Mit Google anmelden'}*/}
            {/*  onPress={() => this.props.promptAsync().then(response => this.props.googleConnect({*/}
            {/*    token: response.params.access_token,*/}
            {/*    newsletter: false,*/}
            {/*    register: false*/}
            {/*  }))}*/}
            {/*/>*/}
          </View>
        </View>
        <View style={{alignItems: 'center', marginTop: 20, marginBottom: 30, width: '100%' }}>
          <View style={{marginTop: 20, display: 'flex', flexDirection: 'row'}}>
            <Text>Du bist noch kein Mitglied? </Text>
            <TouchableOpacity onPress={() => this.props.navigation.navigate('Register')}>
              <Text style={{fontWeight: 'bold'}}>Jetzt registrieren.</Text>
            </TouchableOpacity>
          </View>
          <View style={{marginTop: 20, width: '100%', maxWidth: 290}}>
            <Text style={{textAlign: 'center'}}>Probleme bei Login / Registrierung?</Text>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
              <TouchableOpacity onPress={() => this.props.navigation.navigate('Feedback')}>
                <Text style={{fontWeight: 'bold'}}>Kontakt</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.props.navigation.navigate('ForgotPassword')}>
                <Text style={{fontWeight: 'bold'}}>Passwort vergessen</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.background,
  },
});


const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    facebookConnect: (payload) => {
      dispatch(facebookConnect(payload))
    },
    loginInit: (payload) => {
      dispatch(loginInit(payload))
    },
    appleConnect: (payload) => {
      dispatch(appleConnect(payload))
    },
    googleConnect: (payload) => {
      dispatch(googleConnect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooksHOC(LoginScreen));
