import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import CommonStack from '../navigation/CommonStack';
import OwnUserScreen from '../containers/OwnUserScreen';

export default createStackNavigator(
  {
    Me: {
      screen: OwnUserScreen,
      navigationOptions: CommonStack.navigationOptions
    },
  },
  {
    ...CommonStack.Config,
  }
);


