import React from 'react';
import ArticleNodeThumb from './ArticleNodeThumb';
import ImageNodeThumb from './ImageNodeThumb';
import ProductNodeThumb from './ProductNodeThumb';
import {Image, TouchableWithoutFeedback, View} from "react-native";
import Styles from "../constants/Styles";
import Layout from "../constants/Layout";
import * as Config from "../Config";

class CustomFlatlistItem extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // Only update the liked node, not others
    if (nextProps.data.data.data.likes !== this.props.data.data.data.likes
      && this.props.isLikingNode !== nextProps.isLikingNode) {
      return true;
    }

    if (this.props.data.data.data.imageStylesScalecrop !== nextProps.data.data.data.imageStylesScalecrop) {
      return true;
    }

    if(nextProps.data.data.index !== this.props.data.data.index) return true;
    return false;
  }
  onPressItem = () => {
    const { index, data, navigation: { navigate } } = this.props;
    const { path, view, display } = this.props.state;
    if (data.data.data.type === 'product') {
      navigate({
        routeName: 'Product',
        key: `node${data.data.data.id}`,
        params: {
          index: index,
          nodes: data.data.data,
          parent: 'list',
          productId: data.data.data.productReference,
        }
      })
    }
    else {
      navigate({
        routeName: data.data.data.type === 'article' ? 'NodeSingle' : 'Node',
        key: `node${data.data.data.id}`,
        params: {
          display: display,
          index: index,
          path: path,
          view: view,
          nodeId: data.data.data.id,
          id: data.data.data.id,
          type: data.data.data.type,
        }
      });
    }
  }

  getCorrectVariation = () => {
    const { likeNode, navigation, data } = this.props;
    const { path, view, display } = this.props.state;
    switch (data.data.data.type) {
      case 'article':
        return (
          <ArticleNodeThumb
            index={data.data.index}
            item={data.data.data}
            navigation={navigation}
            likeNode={likeNode}
            style={{ flex: 1 }}
          />
        );
      case 'image':
        return (
          <ImageNodeThumb
            index={data.data.index}
            item={data.data.data}
            navigation={navigation}
            likeNode={likeNode}
            path={path}
            style={{ flex: 1 }}
            view={view}
            display={display}
          />
        );
      case 'product':
        return (
          <ProductNodeThumb
            index={data.data.index}
            item={data.data.data}
            navigation={navigation}
            likeNode={likeNode}
            path={path}
            style={{ flex: 1 }}
            view={view}
            display={display}
          />
        );
    }
  }


  render() {
    const { data } = this.props;
    const width = data.data.data.type == 'product' ? Layout.window.width / Config.COLUMNS - 16 : data.data.data.width - 11;
    if (data.data.data.imageSelf && data.data.data.imageStylesScalecrop) {
      const thumbVariations = this.getCorrectVariation();
      return(
        <TouchableWithoutFeedback
          onPress={this.onPressItem}
        >
          <View style={[Styles.borderBox, {display: 'flex',  alignItems: 'center' , width: data.data.data.width, minHeight: data.data.data.height}]}>
            <Image
              indicator={true} // disable loading indicator
              indicatorColor="white" // react native colors or color codes like #919191
              indicatorSize="large" // (small | large) or integer
              source={{ uri: data.data.data.type === 'product' ? data.data.data.imageStylesFluidFixedWidthSProducts: data.data.data.imageStylesFluidFixedWidth }}
              style={[Styles.gridImage,  {width: width, height: data.data.data.height}]}
            />
            {thumbVariations}
          </View>
        </TouchableWithoutFeedback>
      );


    }
    else {
      return null;
    }
  }
};

export default CustomFlatlistItem;
