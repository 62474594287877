import React from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Styles from '../constants/Styles';
import Colors from '../constants/Colors';

import * as Config from '../Config';
import Constants from 'expo-constants';
import { Icon } from './Icon';
import CustomIndicator from "./CustomIndicator";
import MenuDynamicItem from './MenuDynamicItem';

import { values } from "lodash";

class MenuDynamic extends React.Component {

  /**
   * We have an activeItem and a chosenItem.
   * - The 'activeItem' is the category that the menu is currently at. It is
   *   saved at the state and is used locally to navigate the different levels of the menu.
   * - The 'chosenItem' is the category that the whole page is at. It is coming in as a prop
   *   and it defines the content of the page.
   */
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.chosenItem,
    };
  }

  componentDidMount() {
    const { menuName, menus } = this.props;
    if (!menus || !menus[menuName]) {
      this.props.menuGet({ menuName: this.props.menuName });
    }
  }

  mainItem = () => {
    if (this.state.activeItem === 0) {
      return ({ id: 0, path: '' });
    }
    const { menuName, menus } = this.props;
    const mainItem = values(menus[menuName].items).filter(item => item.path == this.state.activeItem).pop();
    const parentItem = (mainItem && mainItem.parentId) ? menus[menuName].items[mainItem.parentId] : null;
    return (mainItem && mainItem.hasChildren) ? mainItem : parentItem ?? { id: 0, path: '' };
  }

  render()  {
    const { menuName, menus } = this.props;
    if (!menus || !menus[menuName] || menus[menuName].isLoading) {
      return (
        <CustomIndicator/>
      )
    }
    else {
      const mainItem = this.mainItem();
      const items = values(menus[menuName].items)
        .filter(item => item.parentId == mainItem.id)
        .sort((a, b) => a.weight - b.weight);
      return (
        <View style={Styles.pageViewContainer}>
          { this.props.header ?? <View/> }
          { this.state.activeItem != 0 && this.headerItem() }
          <FlatList
            backgroundColor={Colors.background}
            data={items}
            keyExtractor={data => `category${data.id}`}
            ListFooterComponent={Constants.nativeAppVersion ?
              <View style={{flex:1, justifyContent: 'flex-end', marginBottom: 10, marginTop: 10, alignItems: 'center'}}>
                <Text style={{fontStyle: 'italic'}}>Version {Constants.manifest.version}</Text>
              </View> : <View />
            }
            renderItem={this.renderItem}
          />
          {          }
        </View>
      );
    }
  }

  headerItem =() => {
    const { menuName, menus } = this.props;
    if (menus && menus[menuName] && menus[menuName].items) {
      const mainItem = this.mainItem();
      return (
        <View style={{ flexDirection: 'row'}}>
          <TouchableOpacity
            onPress={this.goToParent}
            style={{
              borderColor: Colors.border,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderRightWidth: StyleSheet.hairlineWidth,
              padding: 10
            }}
          >
            <Icon
              name='up_arrow'
              color='black'
              onPress={null}
              size={Config.SIZE_ICON}
              style={{ marginLeft: 30, transform: [{ rotate: '270deg'}] }}
            />
          </TouchableOpacity>
          <View>
            <MenuDynamicItem
              hasChildren={mainItem.hasChildren}
              item={mainItem}
              isCurrent={mainItem.path.split('/').pop() == this.props.chosenItem}
              setChosenCategory={this.props.setChosenCategory}
              setChosenCategoryAndClose={this.props.setChosenCategoryAndClose}
              setCurrentItem={this.setCurrentItem}
              closeCategoriesMenu={this.props.closeCategoriesMenu}
              shoppingCategories={[]}
            />
          </View>
        </View>
      );
    }
    else {
      return (<View/>);
    }
  }

  renderItem = (data) => {
    // An item is the current when its path matches the chosen item.
    // But we also have a few pseudocurrent items that are always bold.
    // Those are the top level menu items that have children.
    return (
      <MenuDynamicItem
        goToParent={this.goToParent}
        hasChildren={data.item.hasChildren}
        item={data.item}
        isCurrent={data.item.path == this.props.chosenItem || data.item.parentId == 0 && data.item.hasChildren}
        setChosenCategory={this.props.setChosenCategory}
        setChosenCategoryAndClose={this.props.setChosenCategoryAndClose}
        setCurrentItem={this.setCurrentItem}
        closeCategoriesMenu={this.props.closeCategoriesMenu}
        shoppingCategories={values(this.props.mainCategories)}
      />
    );
  }

  setCurrentItem = ({ path }) => this.setState({ activeItem: path });

  goToParent = () => {
    const { menuName, menus } = this.props;
    const mainItem = this.mainItem();
    const parentItem = menus[menuName].items[mainItem.parentId];
    this.setState({
      activeItem: parentItem ? parentItem.path : 0,
    });
  }

}

export default MenuDynamic;
