import React from 'react';
import { View, Text } from 'react-native';

const NoConnectivity = (props) => {
  const {
    styles
  } = props;
  return (
    <View
      style={[{
        backgroundColor: 'gray',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
      }, styles]}
    >
      <Text style={{ color: 'white', alignSelf: 'center' }}>
        Internetverbindung unterbrochen
      </Text>
    </View>
  );
};

NoConnectivity.defaultProps = {
  styles: {},
};
export default NoConnectivity;
