import React, { Component } from 'react';
import {
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  Image
} from 'react-native';
import FitImage from 'react-native-fit-image';
import { EventRegister } from 'react-native-event-listeners';
import { connect } from 'react-redux';
import Styles from '../constants/Styles';
import NodeActionButtons from './general/NodeActionButtons';
import Layout from '../constants/Layout';
import * as Config from "../Config";

class ImageNodeThumb extends Component {

  shouldComponentUpdate (nextProps, nextState) {
    if (nextProps.item.liked === this.props.item.liked && nextProps.images && this.props.images &&
      nextProps.images[this.props.item.id].comments ===  this.props.images[this.props.item.id].comments &&
      nextProps.images[this.props.item.id].likes ===  this.props.images[this.props.item.id].likes )
      return false;
    return true;
  }

  onPressAvatar = () => {
    const { navigation, item } = this.props;
    navigation.navigate({
      routeName: 'User',
      key: `user${item.userId}`,
      params: { user: item.user, id: item.userId }
    });
  };

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.props.item.liked) {
        this.props.likeNode({node: this.props.item, op: 'unflag'});
      }
      else {
        EventRegister.emit('analyticEvent', { category: 'Flag', action: 'Herzen', label: 'Overview' });
        this.props.likeNode({node: this.props.item, op: 'flag'});
      }
    }
  };

  onPressComment = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      const { display, index, item, e, navigation: { navigate }, path, view } = this.props;
      const newItem = this.props.images!== undefined && this.props.images[item.id]!== 1?this.props.images[item.id]:item;
      if (newItem)
        navigate({
          routeName: 'Node',
          key: `node${newItem.id}`,
          params:  {
            display: display,
            index: index,
            path: path,
            view: view,
            focusInputField: newItem.id,
            nodeId: newItem.id,
          }
        });
    }
  };

  render() {
    const { index, item, likeNode, navigation , path, view } = this.props;
    return (

        <View>
          <TouchableOpacity
            onPress={this.onPressAvatar}
          >
            <Text style={[Styles.gridUsername, Styles.textCenter]}>{item.userLabel || null}</Text>
            { (item.textValueStripped)
              ? <Text style={[Styles.subtitle, Styles.textCenter]} numberOfLines={1}>{item.textValueStripped}</Text>
              : null
            }
          </TouchableOpacity>
          <NodeActionButtons
            navigateable={false}
            navigation={navigation}
            onPressLike={this.onPressLike}
            item={item}
            onPressComment={this.onPressComment}
            commentable={true}
          />
        </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logInState: state.user,
    images: state.images.image,
  }
};

export default connect(mapStateToProps)(ImageNodeThumb)
