import React from 'react';
import {connect} from 'react-redux';
import NotificationScreenComponent from './NotificationScreenComponent';
import { messageType } from '../containers/NotificationScreen';
import {messagesGet, usersGet} from "../actions/index";
import screenNames from "../constants/screenNames";

class CommunityScreenComponent extends React.Component {
  state = {
    active_menu: this.props.navigation.getParam('showMenu', 0),
    menus: [
      {title: 'Bei allen', path: screenNames.messagesGeneral},
      {title: 'Folge ich', path: screenNames.messagesFollow},
      {title: 'Bei mir', path: screenNames.messagesUser}
    ],
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!this.props.navigation.isFocused() && !nextProps.navigation.isFocused()) {
      return false;
    }
    return true;
  }

  resetAndRefresh = () => {
    this.props.messagesGet({
      subcategory: this.state.menus[this.state.active_menu].path,
      page: 1,
      refreshing: true,
    });
  };

  componentDidMount() {
    this.resetAndRefresh();
  }

  onRefresh = () => {
    this.resetAndRefresh();
  };

  onEndReached = () => {
    const subcategory = this.state.menus[this.state.active_menu].path;
    const pagination = this.props.pagination;
    const currentPage = pagination[subcategory].currentPage;

    if (
        pagination[subcategory].pages[currentPage]
        && !pagination[subcategory].pages[currentPage].fetching
        && pagination[subcategory].pages[currentPage].ids.length
    ) {
      const nextPage = currentPage + 1;
      this.props.messagesGet({
        subcategory,
        page: nextPage
      });
    }
  };

  onMenuPress = (menu, index) => {
    // set active menu item
    this.setState({
      active_menu: index,
    });
    // call for new content if any
    this.props.messagesGet({subcategory: menu.path, page: 1});
  };

  render() {
    let messages = [];
    const subcategory = this.state.menus[this.state.active_menu].path;
    const listOfElements = this.props.messages[subcategory + 'ById'];
    listOfElements.forEach(element => messages.push(this.props.messages.messages[element]));
    const pagination = this.props.pagination;
    const currentPage = pagination[subcategory].currentPage;
    const isLoading =
        pagination[subcategory].pages[currentPage] &&
        pagination[subcategory].pages[currentPage].fetching;
    const refreshControlLoader = this.props.isGettingMessages[this.state.menus[this.state.active_menu].path];

    return (
        <NotificationScreenComponent
            active_menu={this.state.active_menu}
            isGettingMessages={isLoading}
            nodes={this.props.nodes}
            menus={this.state.menus}
            messages={messages}
            messageType={messageType}
            navigation={this.props.navigation}
            onEndReached={this.onEndReached}
            onMenuPress={this.onMenuPress}
            onRefresh={this.onRefresh}
            users={this.props.users}
            refreshControlLoader={refreshControlLoader}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    nodes: state.images.nodes,
    pagination: state.pagination,
    isGettingMessages: state.messages.isGettingMessages,
    messages: state.messages,
    users: state.users.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    messagesGet: (payload) => {
      dispatch(messagesGet(payload))
    },
    usersGet: (payload) => {
      dispatch(usersGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityScreenComponent);
