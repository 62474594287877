import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';

import AddressBookScreen from '../containers/AddressBookScreen';
import AddressFormScreen from '../containers/AddressFormScreen';
import CartScreen from "../containers/CartScreen";
import CheckoutScreen from '../containers/CheckoutScreen';
import CommonStack from '../navigation/CommonStack';
import DeliveryScreen from '../containers/DeliveryScreen';
import PaymentScreen from '../containers/PaymentScreen';
import ShippingScreen from '../containers/ShippingScreen';
import ThankYouScreen from '../containers/ThankYouScreen';

export default createStackNavigator(
  {
    AddressBook: { screen: AddressBookScreen, navigationOptions: CommonStack.navigationOptions },
    AddressForm: { screen: AddressFormScreen, navigationOptions: CommonStack.navigationOptions },
    Cart: { screen: CartScreen, navigationOptions: CommonStack.navigationOptions },
    Checkout: { screen: CheckoutScreen, navigationOptions: CommonStack.navigationOptions },
    Delivery: { screen: DeliveryScreen, navigationOptions: CommonStack.navigationOptions },
    Payment: { screen: PaymentScreen, navigationOptions: CommonStack.navigationOptions },
    Shipping: { screen: ShippingScreen, navigationOptions: CommonStack.navigationOptions },
    ThankYou: { screen: ThankYouScreen, navigationOptions: CommonStack.navigationOptions },
  },
  {
    ...CommonStack.Config,
  },
);


