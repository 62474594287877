import React from 'react';
import {Text, TouchableOpacity, View} from "react-native";

const ContactText = (props) => (
  <View style={{marginTop: 20, marginBottom: 30, display: 'flex', flexDirection: 'row', alignSelf: 'center'}}>
    <Text>Probleme bei Login / Registrierung? </Text>
    <TouchableOpacity onPress={() => props.navigation.navigate('Feedback')}>
      <Text style={{fontWeight: 'bold'}}>Kontakt.</Text>
    </TouchableOpacity>
  </View>

);

export default ContactText;
