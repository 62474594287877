import React from 'react';
import { Alert, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { editUser } from '../actions/index';
import Styles from "../constants/Styles";
import CustomButton from '../components/general/CustomButton';
import CustomTextInput from '../components/CustomTextInput';
import IndicatorPage from '../components/general/IndicatorPage';

class ResetScreen extends React.Component {

  state = {
    password: '',
    rePassword: '',
  };

  handleReset = () => {
    if (!this.state.password || !this.state.rePassword || this.state.password != this.state.rePassword) {
      // Passwords do not match."
      Alert.alert(
        '',
        'Die Passwörter stimmen nicht überein. Bitte trage in beide Felder das gleiche Passwort ein.',
        [{ text: 'Okay', onPress: () => {}, style: 'cancel', },],
        { cancelable: false },
      );
    }
    else {
      this.props.editUser({
        ...this.props.user,
        password: this.state.password,
      });
      this.props.navigation.goBack();
    }
  };

  render() {
    if (this.props.user.isUserUpdating) {
      return (
        <IndicatorPage />
      );
    }
    return (
      <View style={Styles.pageScrollViewContainer}>
        <View style={{paddingTop: 30}}>
          <Text style={Styles.pageTitle}>{'Lege dein neues Passwort fest:'}</Text>
        </View>
        <CustomTextInput
          secureTextEntry={true}
          value={this.state.password}
          onChangeText={(text) => {this.setState({password: text})}}
          label="Passwort"
          clearButtonMode="always"
          selectTextOnFocus={true}
          autoCorrect={false}
          controlled={true}
        />
        <CustomTextInput
          secureTextEntry={true}
          value={this.state.rePassword}
          onChangeText={(text) => {this.setState({rePassword: text})}}
          label="Passwort bestätigen"
          clearButtonMode="always"
          selectTextOnFocus={true}
          autoCorrect={false}
          controlled={true}
        />
        <View style={{ alignItems: 'center' }}>
          <CustomButton
            title="Speichern"
            onPress={this.handleReset}
          />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (payload) => {
      dispatch(editUser(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetScreen);
