import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import screenNames from '../constants/screenNames';

const initialState = {
  isLoading: false,
  byId: {},
};

function storeUsersComments(state, action) {
  if (action.payload.path.includes(screenNames.geherztkommentiert)) {
    const userId = action.payload.path.split("/")[1];

    let items = {};
    const itemsById = state.byId[userId] ? state.byId[userId].slice() : [];

    action.payload.data.forEach(item => {
      items = {
        ...items,
        [item.id]: item[item.type],
      };
      if (!itemsById.includes(item.id)) {
        itemsById.push(item.id);
      }
    });

    return {
      ...state,
      isLoading: false,
      [userId]: {
        ...state[userId],
        ...items,
      },
      byId: {
        ...state.byId,
        [userId]: itemsById,
      },
    };
  }
  return state;
}

function gettingUsersComments(state, action) {
  if (action.payload.path.includes(screenNames.geherztkommentiert)) {
    const userId = action.payload.path.split("/")[1];
    if (action.payload.refreshing && state.byId[userId]) { // Pull-to-refresh functionality resets pagination
      return {
        ...state,
        byId: {
          ...state.byId,
          [userId]: [],
        },
        isLoading: true,
      }
    }
    return {
      ...state,
      isLoading: true,
    };
  }
  return state;
}

const usersComments = createReducer(initialState, {
  [ActionTypes.VIEW_GET]: gettingUsersComments,
  [ActionTypes.VIEW_GET_SUCCESS]: storeUsersComments,
});

export default usersComments;
