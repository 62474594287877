import React, { Component } from 'react';
import { Text, View } from 'react-native';
import Styles from "../../constants/Styles";

const DiscountItem = ({ color, label, price }) => {
  return (
    <View style={Styles.inlineText}>
      <Text style={{color: color}}>{label}</Text>
      <Text style={{color: color}}>{price} €</Text>
    </View>
  );
}

export default DiscountItem;
