import React, { PureComponent } from 'react';
import {
  Alert,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import FitImage from 'react-native-fit-image';
import { Thumbnail } from "../components/Thumbnail";
import moment from 'moment';

import * as Config from '../Config';

import CustomIndicator from "../components/CustomIndicator";
import CollapsilbleText from "../components/CollapsilbleText";
import { Icon } from '../components/Icon';

import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import {debounce} from 'lodash';

export default class CommentItem extends PureComponent {
  state = {
    liked: this.props.comment.liked,
    likes: this.props.comment.likes
  };
  constructor(props) {
    super(props);
    this.delayLike = debounce(this.onPressLike, 50);
  }

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.state.liked) {
        this.props.likeComment({comment: this.props.comment, op: 'unflag'});
        this.setState({liked: false});
        if (this.state.likes) {
          this.setState({likes: +this.state.likes - 1});
        }
      }
      else {
        this.props.likeComment({comment: this.props.comment, op: 'flag'});
        this.setState({liked: true, likes: +this.state.likes + 1});
      }
    }
  };

  render() {
    const { comment } = this.props;
    if (comment.userId === null || !comment.userId) {
      return null;
    }

    return (
      <View style={{marginBottom: 10, marginTop: 10, marginLeft: comment.parent ? 40 : 0}}>
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity onPress={this.gotoComment}>
            <Thumbnail source={{uri: comment.userPictureUri}} onPress={this.onPressAvatar} />
          </TouchableOpacity>
          <View style={{flex:1, padding: 10}}>
            <TouchableOpacity onPress={this.gotoComment}>
              <Text style={Styles.username}>{ comment.userLabel }</Text>
            </TouchableOpacity>
          </View>
        </View>

        <CollapsilbleText text={comment.body} collapse={false} align={'left'} style={{marginTop: 5}} navigation={this.props.navigation}/>

        {comment.imageStyles1200x900Conditional &&
          <View style={{ maxWidth: Layout.half.width}}>
            <FitImage
              resizeMode='contain'
              source={{ uri: comment.imageStyles1200x900Conditional }}
              style={{ maxWidth: Layout.half.width}}
            />
          </View>
        }

        <View style={{flexDirection: 'row', marginTop: 20, justifyContent: 'space-between'}}>
          <Text>{moment.unix(comment.timestamp).format("HH[.]mm [Uhr - ]DD[.]MM[.]YYYY")}</Text>
          <View style={{flexDirection: 'row'}}>
            <Text style={[Styles.iconCounter, { paddingRight: 2, paddingBottom: 5}]}>{this.state.likes > 0 && this.state.likes}</Text>
            <TouchableOpacity  onPress={this.delayLike} style={{padding: 5}}>
              <Icon
                active={this.state.liked}
                name='heart'
                size={Config.SIZE_ICON}
                color={this.state.liked ? Colors.iconSelected : Colors.iconDefault}
              />
            </TouchableOpacity>
            <TouchableOpacity style={{padding: 5}} onPress={this.props.replyToComment}>
              <Icon name='comment' active={false} size={Config.SIZE_ICON} color={Colors.iconDefault} />
            </TouchableOpacity>
          </View>
        </View>

        {(comment.userId === this.props.logInState.uid) &&
          <View style={{flex: 1, alignSelf: 'flex-end', flexDirection: 'row'}} >
            <TouchableOpacity style={{padding: 5}} onPress={this.editComment}>
              <Text>Bearbeiten</Text>
            </TouchableOpacity>
            <Text style={{padding: 5}} >|</Text>
            <TouchableOpacity style={{padding: 5}} onPress={this.deleteComment}>
              <Text>Löschen</Text>
            </TouchableOpacity>
          </View>
        }   

        {this.props.isDeletingCommentId === this.props.comment.id &&
          <CustomIndicator color={Colors.border} />
        }

      </View>
    );
  }

  editComment = () => {
    this.props.navigation.navigate('CommentEdit', {comment: this.props.comment})
  };

  deleteComment = () => {
    Alert.alert(
      'Kommentar löschen',
      'Möchtest du den Kommentar wirklich löschen?',
      [
        {text: 'Cancel', onPress: () => {}, style: 'cancel'},
        {text: 'OK', onPress: () => {this.props.commentDelete({
            id: this.props.comment.id,
            nid: this.props.comment.nid,
        })}},
      ],
      { cancelable: false }
    )
  };

  gotoComment = () => {
    const { comment } = this.props;
    this.props.navigation.navigate({
      routeName: 'User',
      key: `user${comment.userId}`,
      params: {
        // TODO: is this ok?
        // user: comment.user,
        id: comment.userId
      }
    });
  };


}
