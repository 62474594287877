import React, { PureComponent } from 'react';
import { createIconSetFromIcoMoon } from 'react-native-vector-icons';

import * as Config from '../Config';
import Colors from '../constants/Colors';

import icoMoonConfig from '../assets/fonts/Solebich_Definition.json';
import icoMoonConfigBold from '../assets/fonts/Solebich_bold_Definition.json';

const IconNormal = createIconSetFromIcoMoon(icoMoonConfig, 'Solebich');
const IconBold = createIconSetFromIcoMoon(icoMoonConfigBold, 'SolebichBold');

export class Icon extends PureComponent {
  render() {
    if (this.props.active) {
      return(
        <IconBold
          name={ this.props.name }
          size={ this.props.size || Config.SIZE_ICON }
          color={ this.props.color || Colors.tintColor}
          onPress={this.props.onPress || null}
          style={ this.props.style }
        />
      );
    }
    else {
      return(
        <IconNormal
          name={ this.props.name }
          size={ this.props.size || Config.SIZE_ICON }
          color={ this.props.color || Colors.iconDefault }
          onPress={this.props.onPress || null}
          style={ this.props.style }
        />
      );
    }
  }
}
