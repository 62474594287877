import React, { Component } from 'react';
import {
  Alert,
  Image,
  Keyboard,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import CustomIndicator from "../components/CustomIndicator";
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import Layout from "../constants/Layout";
import Colors from "../constants/Colors";
import AutocompleteTagsComponent from "./AutocompleteTagsComponent";
import CustomTextInput from "./CustomTextInput";
import Styles from "../constants/Styles";
import CustomMentionsTextInput from "./CustomMentionsTextInput";
import CustomButton from './general/CustomButton';
import { chooseFileMethod, pickAndCompressImage } from '../helpers/images';

class NodeEditScreenComponent extends Component {

  errMsg = 'Bitte trage ein Stichwort zum Bild ein. Es muss zwischen 3 und 20 Zeichen haben.';
  state = {
    tags_text: '',
    tags: [],
    body_text: null,
    website_text: '',
    picked_image: null,
    errorMessage: null,
    keyword: '',
    tagsSelected : [],
    clearTagInput: false,
    query: '',
    selection:{start:0,end:0},
    editingImage: false,
    manipulating: false,
  };

  componentDidMount = () => {
    this.setState({
      tagsSelected: this.props.nodes[this.props.navigation.state.params.node.id].tags,
      body_text: this.props.nodes[this.props.navigation.state.params.node.id].textValue,
      website_text: this.props.nodes[this.props.navigation.state.params.node.id].link
    })
  };

  render() {
    let tags = (this.state.clearTagInput)?[]:this.props.tags || [];
    if (tags.length > 5)
      tags = tags.slice(0,5);
    const node = this.props.nodes[this.props.navigation.state.params.node.id];

    return (
      <View style={{ flex:1, justifyContent: 'space-around', alignItems: 'center', width: Layout.half.width, paddingTop: Constants.statusBarHeight, paddingLeft: 5, paddingRight: 5, backgroundColor: Colors.background, }}>
        <ScrollView
          style={Styles.pageScrollViewContainer}
          keyboardShouldPersistTaps={"handled"}
        >
          {this.props.upload.uploading?
            <CustomIndicator/> :
          <TouchableOpacity
            onPress={ () => chooseFileMethod('node', this.props.fileUpload) }
            style={{
              marginTop: 20,
              marginBottom: 20,
              overflow: 'hidden',
            }}>
            <Image
              source={{ uri: this.props.upload.image_uri ? this.props.upload.image_uri : (node && node.imageSelf ? node.imageSelf: '') }}
              style={{ width: (Layout.half.width - 30), height: 120 }}
              resizeMode="contain"/>
          </TouchableOpacity>
        }
          <View style={styles.autocompleteContainer}>
            <AutocompleteTagsComponent
              helpText='z.B. Wohnzimmer, Hay Sofa, Billy Regal, Muuto Vase, Dekoidee, DIY etc.'
              hideResults={this.state.tags_text === '' || this.state.tags_text === null || this.state.errorMessage !==null}
              tags={tags}
              handleAddition={this.handleAddition}
              handleDelete={this.handleDelete}
              tagsSelected={this.state.tagsSelected}
              tags_text={this.state.tags_text}
              clearTagInput={this.state.clearTagInput}
              handlePlainTextAddition={this.handlePlainTextAddition}
              _clearInputFalseAndTagsText={this._clearInputFalseAndTagsText}
              tagsGet={this.props.tagsGet}
              _setTagsText={this._setTagsText}
            />
          </View>
          <CustomMentionsTextInput
            label="Erzähle etwas über dein Bild"
            value={this.state.body_text}
            onChangeText={(val) => { this.setState({ body_text: val }) }}
            usersGetMentioning={this.props.usersGetMentioning}
            usersMentioning={this.props.usersMentioning}
          />
          <CustomTextInput
            label="Link zu deinem Blogpost"
            helpText='Du bist Blogger und zu deinem Bild gibt es noch mehr spannende Inhalte auf deinem Blog (z.B. eine DIY-Anleitung).'
            value={this.state.website_text}
            onChangeText={(website_text) => this.setState({website_text})}
            returnKeyType='done'
            blurOnSubmit={true}
            onSubmitEditing={(event) => {
              Keyboard.dismiss();
            }}
          />
          <CustomButton
            disabled={this.props.isGettingTag || this.props.creatingTag || this.props.upload.uploading || this.props.isUpdating}
            title={this.props.isUpdating || this.props.isGettingTag || this.props.creatingTag ? '' :(this.props.upload.uploading?'Bild wird hochgeladen':'Speichern')}
            onPress={this.saveChanges}
            loading={this.props.isUpdating}
          />
          <View>
            <Text style={{color: Colors.tintColor, paddingHorizontal: 5, textAlign: 'center'}}>
              {this.state.errorMessage}
            </Text>
          </View>
          <TouchableOpacity style={{alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}} onPress={this.deleteImage}>
            <Text style={Styles.simpleLink}>
              Bild löschen
            </Text>
          </TouchableOpacity>
          {this.props.isDeletingEntity &&
          <CustomIndicator/> }
          <View style={{ minHeight: 80 }} />
        </ScrollView>
      </View>
    );
  }

  componentWillUnmount = () => {
    this.setState({
      tags_text: '',
      tags: [],
      body_text: '',
      website_text: '',
      picked_image: null,
      errorMessage: null,
      tagsSelected : [],
      clearTagInput: false,
      query: '',
      manipulating: false,
    });
  };

  saveChanges = () => {
    Keyboard.dismiss();
    if(this.state.tagsSelected.length>0) {
      let data = {
        "image": this.props.upload.image_id?this.props.upload.image_id:this.props.navigation.state.params.node.id,
        "body": this.state.body_text,
        "link": this.state.website_text,
        "tags": this.state.tagsSelected.map(tag => {
          if (tag.id) {
            return Number(tag.id);
          }
          else {
            return this.props.newTags.filter(t => t.label.toLowerCase() === tag.toLowerCase()).map(t => t.id)[0];
          }
        }).filter((id, index, array) => array.indexOf(id) === index).join(","),
      };
      this.props.nodeEdit({
        data: data,
        token: this.props.logInState.token,
        imageId: this.props.navigation.state.params.node.id,
        index: this.props.navigation.state.params.index,
        path: this.props.navigation.state.params.path,
      });
    }else{
      this.setState({errorMessage: this.errMsg})
    }
  };

  handleDelete = index => {
    let tagsSelected = this.state.tagsSelected.slice();
    tagsSelected.splice(index, 1);
    this.setState({ tagsSelected });
  };

  handleAddition = suggestion => {
    this.props.getTagByName(suggestion);
    this.setState({
      tagsSelected: [
        ...this.state.tagsSelected,
        ...[suggestion],
      ],
      tags_text: '',
      clearTagInput: true ,
      query: ''
    });
  };

  handlePlainTextAddition = () => {
    let newTag = this.state.tags_text;
    if(newTag.length<3 || newTag.length>=20) {
      if(newTag.length !== 0) {
        this.setState({
          errorMessage: this.errMsg,
          tags_text: this.state.tags_text.trim()
        });
      }
    }
    else {
      this.props.getTagByName(newTag);
      this.setState({
        tagsSelected: [
          ...this.state.tagsSelected,
          ...[newTag],
        ],
        tags_text: '',
        clearTagInput: true,
        query: ''
      });
    }
  };

  _clearInputFalseAndTagsText = (text) => {
    this.setState({clearTagInput: false, tags_text:text});
  };

  _setTagsText = (text) => {
    this.setState({
      tags_text: text,
      errorMessage: null
    })
  };

  deleteImage = () => {
    Alert.alert(
      'Bild löschen',
      'Möchtest du das Bild wirklich löschen?',
      [
        {text: 'Cancel', onPress: () => {}, style: 'cancel'},
        {text: 'OK', onPress: () => {this.props.entityDel(this.props.nodes[this.props.navigation.state.params.node.id]);}},
      ],
      { cancelable: false }
    )
  };


};

export default NodeEditScreenComponent;

const styles = StyleSheet.create({
  container: {
    height: 300,
    justifyContent: 'flex-end',
    paddingTop: 100
  },
  suggestionsRowContainer: {
    flexDirection: 'row',
  },
  autocompleteContainer: {
    flex: 1,
    left: 0,
    // position: 'absolute',
    right: 0,
    marginTop: 10,
    zIndex: 1,
    minWidth: Layout.half.width-30,
    alignItems: 'center'
  },
});
