const getCountryName = (data) => {
  let countryName = '';
  switch (data) {
    case 'DE':
      countryName = 'Deutschland';
      break;
    case 'AT':
      countryName = 'Österreich';
      break;
    default:
      countryName = data;
  }
  return countryName;
}

const flattenAddress = (item) => {
  return {
    id: item.id ?? undefined,
    phone: item.phone ?? undefined,
    default: item.default ?? undefined,
    ...(!item.address ? {} : {
      country: item.address.country ?? undefined,
      countryName: item.address.country ? getCountryName(item.address.country) : undefined,
      first_name: item.address.first_name ?? undefined,
      last_name: item.address.last_name ?? undefined,
      locality: item.address.locality ?? undefined,
      postal_code: item.address.postal_code ?? undefined,
      premise: item.address.premise ?? undefined,
      self: item.self,
      street: item.address.thoroughfare ?? undefined,
    })
  }
};

export default flattenAddress;
