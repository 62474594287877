import {
  Alert,
} from 'react-native';

export function alertAfterRegistering() {
  Alert.alert(
    '',
    'Danke für deine Registrierung. Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos zugeschickt. Bitte klicke auf den Link in der E-Mail, um die Registrierung abzuschließen und ein Passwort festzulegen. Öffne danach die App erneut, um dich einzuloggen.',
    [
      {
        text: 'Okay',
        onPress: () => {},
        style: 'cancel',
      },
    ],
    {
      cancelable: false
    },
  );
}

export function alertAfterNewPasswordRequest() {
  Alert.alert(
    '',
    'In Kürze erhältst du eine E-Mail, mit der du ein neues Passwort festlegen kannst.',
    [
      {
        text: 'Okay',
        onPress: () => {},
        style: 'cancel',
      },
    ],
    {
      cancelable: false
    },
  );
}
