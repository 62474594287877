import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import screenNames from '../constants/screenNames';

const initialState = {
  messages: {
    pages: {},
    currentPage: 1,
  },
  messages_user: {
    pages: {},
    currentPage: 1,
  },
  messages_follow: {
    pages: {},
    currentPage: 1,
  },
  searchKeyword: undefined,
  fetching: {},
};

function dataRequest(state, action) {
  const screenName = action.payload.path;
  const currentPageFetched = Number(action.payload.page);
  return ({
    ...state,
    [screenName]: (action.payload.refreshing) ? {} 
    : {
      ...state[screenName],
    },
    fetching: {
      ...state.fetching,
      [screenName]: currentPageFetched,
    }
  });
}

function dataRequestSuccess(state, action) {
  const screenName = action.payload.path;
  const currentPageFetched = Number(action.payload.page);
  const fetchedIds = action.payload.data.map(fetchedItem => fetchedItem.id);
  return {
    ...state,
    [screenName]: {
      ...state[screenName],
      [currentPageFetched]: fetchedIds,
    },
    fetching: {
      ...state.fetching,
      [screenName]: -1,
    }
  };
}

function connectivityChanged(state, action) {
  // If connectivity just got restored reset all pending pages.
  const justGotRecconected = action.payload.isConnected && !state.isConnected;
  return {
    ...state,
    fetching: justGotRecconected ? {} : {...state.isFetching},
    isConnected: action.payload.isConnected,
  }
}

function messagesGet(state, action) {
  const { page, subcategory } = action.payload;
  return {
    ...state,
    [subcategory]: {
      ...state[subcategory],
      pages: {
        ...state[subcategory].pages,
        [page]: {
          fetching: true
        }
      },
      currentPage: page,
    },
  };
}

function messagesGetSuccess(state, action) {
  const { page, subcategory, data } = action.payload;
  const fetchedIds = data.map(message => message.id);
  return {
    ...state,
    [subcategory]: {
      ...state[subcategory],
      pages: {
        ...state[subcategory].pages,
        [page]: {
          ...state[subcategory].pages[page],
          ids: fetchedIds,
          fetching: false,
        }
      },
    }
  };
}

function messagesGetFail(state, action) {
  const { page, subcategory } = action.payload;
  return {
    ...state,
    [subcategory]: {
      ...state[subcategory],
      pages: {
        ...state[subcategory].pages,
        [page]: {
          ...state[subcategory].pages[page],
          ids: [],
          fetching: false,
        }
      },
    }
  };
}

function nodeUploadSuccess(state, action) {
  const newNodeId = Number(action.payload.data[0].id);
  const idsOfNodesInFirstPage = state.neu[0].slice();
  idsOfNodesInFirstPage.unshift(newNodeId);
  return {
    ...state,
    neu: {
      ...state.neu,
      0: idsOfNodesInFirstPage,
    }
  };
}


  // TODO: Entity del success should remove node id from pagination as well

const pagination = createReducer(initialState, {
  [ActionTypes.VIEW_GET]: dataRequest,
  [ActionTypes.VIEW_GET_SUCCESS]: dataRequestSuccess,
  [ActionTypes.MESSAGES_GET]: messagesGet,
  [ActionTypes.MESSAGES_GET_SUCCESS]: messagesGetSuccess,
  [ActionTypes.MESSAGES_GET_FAIL]: messagesGetFail,
  [ActionTypes.NODE_UPLOAD_SUCCESS]: nodeUploadSuccess,
  [ActionTypes.STORE_CONNECTIVITY_INFO]: connectivityChanged,
});

export default pagination;

