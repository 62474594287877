import  React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFollowers } from "../actions/index";
import UsersListComponent from '../components/UsersListComponent';

class FollowersScreen extends Component {
  componentDidMount() {
    const { state } = this.props.navigation;
    // if user exists in the DB
    if (state.params) {
      // if user ID is passed fetch user now
      if (state.params.id && !this.props.followers[state.params.id]) {
        this.props.getFollowers({ id: state.params.id });
      }
    }
  }
  render() {
    const { state } = this.props.navigation;
    const userName = (state.params && state.params.id)
      ? this.props.users[state.params.id] ? this.props.users[state.params.id].label : this.props.user.label
      : '';
    const followers = this.props.followers[state.params.id] ?? [];
    const data = followers.map(id => this.props.users[id]).filter(user => user && !user.blocked);
    return (
      <UsersListComponent
        title={`Mitglieder, die ${userName} folgen:`}
        data={data}
        navigation={this.props.navigation}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    followers: state.users.followers,
    user: state.user,
    usernames: state.users.usernames,
    users: state.users.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    getFollowers: (payload) => {
      dispatch(getFollowers(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowersScreen);
