import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';
import flattenSequentially from 'xstream/extra/flattenSequentially';
import sampleCombine from 'xstream/extra/sampleCombine';
import { simpleHeaderWithXcsrf } from './headers';

export function tagsGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.TAGS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      //TODO: why isn't there an API call?
      // let path = 'api/tags';
      let path = 'solebich_tags_vocab_autocomplete/taxonomy/field_stichworte/';
      if (payload.keyword) {
        // path += '?filter[label][value]=' + payload.keyword;
        // path += '&filter[label][operator]=CONTAINS';
        path += payload.keyword + '/4?term='+ payload.keyword +'&synonyms=0'
      }
      return ({
        url: Config.BASE_URL + path,
        category: 'tags',
      });
    });

  const response$ = sources.HTTP
    .select('tags')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.tagsGetSuccess(data);
        }
        else
          return actions.tagsGetFail(data);
      }catch (e) {
        return actions.tagsGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function createNewTag(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TAG_BY_NAME_FAIL)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let token = payload.token;
      delete payload['token'];
      return ({
        url: Config.BASE_URL + 'api/tags',
        method: 'POST',
        send: payload,
        headers: simpleHeaderWithXcsrf(token),
        category: 'newTag',
      });
    });

  const response$ = sources.HTTP
    .select('newTag')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data)
          return actions.createTagSuccess(data.data[0]);
        else
          return actions.createTagFail(data);
      }catch (e) {
        return actions.createTagFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getTag(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TAG)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/taxonomy_terms/' + payload.id;
      return ({
        url: Config.BASE_URL + path,
        category: 'tag',
        keyword: payload,
      });
    });

  const response$ = sources.HTTP
    .select('tag')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const state$ = sources.STATE;
  const token$ =state$.map(state => state.user.token);
  const action$ = xs.combine(response$)
    .compose(sampleCombine(token$))
    .map(([arr, token ])=> {
      try {
        let data = arr[0].body;
        if (data.data.length > 0) {
          return actions.getTagByNameSuccess(data);
        }
        else
          return actions.getTagByNameFail({label: arr[0].request.keyword, token});
      }catch(e){
        return actions.getTagByNameFail({label: arr[0].request.keyword, token});
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getTagByName(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TAG_BY_NAME)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/taxonomy_terms?filter[label][value]=' + payload;
      return ({
        url: Config.BASE_URL + path,
        category: 'tagByName',
        keyword: payload,
      });
    });

  const response$ = sources.HTTP
    .select('tagByName')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const state$ = sources.STATE;
  const token$ =state$.map(state => state.user.token);
  const action$ = xs.combine(response$)
    .compose(sampleCombine(token$))
    .map(([arr, token ])=> {
      try {
        let data = arr[0].body;
        if (data.data.length > 0) {
          return actions.getTagByNameSuccess(data);
        }
        else
          return actions.getTagByNameFail({label: arr[0].request.keyword, token});
      }catch(e){
        return actions.getTagByNameFail({label: arr[0].request.keyword, token});
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getTagName(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_TAG_NAME)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/categories/' + payload;
      return ({
        url: Config.BASE_URL + path,
        category: 'tagName'
      });
    });

  const response$ = sources.HTTP
    .select('tagName')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.getTagNameSuccess(data.data);
        }
        else
          return actions.getTagNameFail(data);
      }catch (e) {
        return actions.getTagNameFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getVocabulary(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_VOCABULARY)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let page = payload && payload.page ? payload.page : 0;
      let path = 'api/main_categories?filter[shopping]=1&onlySLI=1';
      if (page) {
        path += '&page=' + page;
      }

      return ({
        url: Config.BASE_URL + path,
        category: 'vocabulary',
        page: page,
      });
    });

  const response$ = sources.HTTP
    .select('vocabulary')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          data.page = arr[0].request.page;
          return actions.getVocabularySuccess(data);
        }
        else {
          return actions.getVocabularyFail(data);
        }
      }
      catch (e) {
        return actions.getVocabularyFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getVocabularySuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_VOCABULARY_SUCCESS)
    .map(action => action.payload)
    .map((arr) => {
      // fetch next links until there is no more to fetch
      if (arr.next && arr.page >= 0) {
        const next_page = parseInt(arr.page) + 1;
        return actions.getVocabulary({ page: next_page });
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}

export function termsGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.TERMS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      const ids = payload.ids.filter((num) => num > 0 && num % parseInt(num) === 0);
      let path = 'api/taxonomy_terms/' + ids.slice(0, 20).join(',');
      return ({
        url: Config.BASE_URL + path,
        category: 'terms',
        keyword: payload,
        // Passing the remaining IDs for a next fetch call.
        ids: ids.slice(20),
      });
    });

  const response$ = sources.HTTP
    .select('terms')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .compose(flattenSequentially)
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map((arr)=> {
      try {
        let data = arr[0].body;
        if (data.data.length > 0) {
          data.ids = arr[0].request.ids;
          return actions.termsGetSuccess(data);
        }
        else {
          return actions.termsGetFail();
        }
      }
      catch(e) {
        return actions.termsGetFail();
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function termsGetSuccess(sources) {
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.TERMS_GET_SUCCESS)
    .map(action => action.payload)
    .map(({ ids }) => {
      // fetch next links until there is no more to fetch
      if (ids && ids.length > 0) {
        return actions.termsGet({ ids });
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}
