import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  StyleSheet
} from 'react-native';
import Styles from '../constants/Styles';
import Colors from '../constants/Colors';
import { facetsKeyed } from '../constants/Facets';
import { sortValues, sortsKeyed } from '../constants/Sorts';
import Layout from '../constants/Layout';
import * as Config from '../Config';
import { Icon } from './Icon';
import Facet from './shopping/Facet';
import Sorts from './shopping/Sorts';
import CustomButton from "./general/CustomButton";

class ShoppingFacetSelection extends React.Component {

  renderFacetItem = (data) => {
    return <Facet
      facets={this.props.facets}
      index={data.index}
      selectedFacets={this.props.selectedFacets}
      facetId={data.item[0]}
      facetValues={data.item[1]}
      getTagName={this.props.getTagName}
      scrollToFacet={this.scrollToFacet}
      setChosenFacet={this.props.setChosenFacet}
      tagsdb={this.props.tagsdb}
    />
  };

  render() {
    const data = Object
      .entries(this.props.facets)
      .filter(facet => !facetsKeyed[facet[0]].hide)
      .sort((a, b) => facetsKeyed[a[0]].weight - facetsKeyed[b[0]].weight);

    return (
      <View style={{flex: 1, marginBottom: 10}}>
        <View style={{flexDirection: 'row', justifyContent: 'flex-start', width: Layout.half.width}}>
          <TouchableOpacity style={{padding: 5, marginLeft: 5, marginTop: 10, marginBottom: 20}} onPress={this.props.closeFacetMenu}>
            <Icon name='cancel' size={Config.SIZE_ICON} color='black' onPress={null}/>
          </TouchableOpacity>
          <View style={{marginTop: 20, marginBottom: 20, textAlign: 'center', flex: 1}}>
            <Text style={[Styles.gridTitle, Styles.textCenter]}>Filter</Text>
          </View>
          <TouchableOpacity style={{padding: 5, marginLeft: 5, marginTop: 20, marginBottom: 15}} onPress={this.props.facetsClear}>
            <Text>alle löschen</Text>
          </TouchableOpacity>
        </View>
        <Sorts
          sortValues={sortValues}
          sortSelected={this.props.chosenSort}
          setChosenSort={this.props.setChosenSort}
        />
        <FlatList
          backgroundColor={Colors.background}
          data={data}
          keyExtractor={data => `facet${data[0]}`}
          ref={(ref) => this.listRef = ref}
          renderItem={this.renderFacetItem}
          style={{ borderTopWidth: StyleSheet.hairlineWidth, borderColor: Colors.border, marginBottom: 60 }}
        />
        <View style={Styles.shopButtonWrap}>
          <CustomButton
            title="Anwenden"
            onPress={this.props.closeFacetMenu}
            buttonStyle={Styles.shopButton}
            fontFamily={"OpenSansRegular"}
          />
        </View>
      </View>
    );
  }

  scrollToFacet = (index) => {
    this.listRef.scrollToIndex({animated: true, index: index});
  }
}

export default ShoppingFacetSelection;
