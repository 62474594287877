import React, { Component } from 'react';
import DeliveryScreenComponent from "../components/shopping/DeliveryScreenComponent";
import {getShippingAddress, getBillingAddress, profileSelect, addressCreate} from "../actions";
import {connect} from "react-redux";


class DeliveryScreen extends Component {

  render() {
    return (
      <DeliveryScreenComponent
        billingAddresses={this.props.billingAddresses}
        cart={this.props.cart}
        getBillingAddress={this.props.getBillingAddress}
        getShippingAddress={this.props.getShippingAddress}
        isLoadingBillingAddress={this.props.isLoadingBillingAddress}
        isLoadingShippingAddress={this.props.isLoadingShippingAddress}
        navigation={this.props.navigation}
        shippingAddresses={this.props.shippingAddresses}
        shippingOptions={this.props.shippingOptions}
        profileSelect={this.props.profileSelect}
        addressCreate={this.props.addressCreate}
        logInState={this.props.logInState}
        lastAddressId={this.props.lastAddressId}
        addressSave={this.props.addressSave}
        billingAddressCount={this.props.billingAddressCount}
        shippingAddressCount={this.props.shippingAddressCount}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    billingAddresses: state.address.billingAddresses.map(id => state.address.addresses[id]),
    cart: state.checkout.cart,
    isLoadingBillingAddress: state.address.isLoadingBillingAddress,
    isLoadingShippingAddress: state.address.isLoadingShippingAddress,
    shippingAddresses: state.address.shippingAddresses.map(id => state.address.addresses[id]),
    shippingOptions: state.orders.shippingOptions,
    logInState: state.user,
    lastAddressId: state.address.lastAddressId,
    addressSave: state.address.addressSave,
    billingAddressCount: state.address.billingAddressCount,
    shippingAddressCount: state.address.shippingAddressCount,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShippingAddress: (payload) => {
      dispatch(getShippingAddress(payload))
    },
    getBillingAddress: (payload) => {
      dispatch(getBillingAddress(payload))
    },
    profileSelect: (payload) => {
      dispatch(profileSelect(payload))
    },
    addressCreate: (payload) => {
      dispatch(addressCreate(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryScreen);
