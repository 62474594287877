import { get } from 'lodash';
import { priceFormat100, priceFormat, dateFormat } from '../../helpers/formatNumbers';
import { orderStatusKeyed } from '../../constants/orderStatus'

const flattenCart = (item) => {
  const country = get(item, 'profile_shipping.address.country');
  const shippingCostObj = item.shipping ? item.shipping.filter(obj => obj !== null).pop() : 0;
  const vat = (country && country == 'AT') ? 21 : 19;
  return !item.id ? false : {
    coupons: [],
    date: item.date ? dateFormat(item.date): undefined,
    discount: [],
    email: item.mail ?? undefined,
    external_id: item.external_id ?? undefined,
    productsSum: item.total ? priceFormat100(item.total - (shippingCostObj ? shippingCostObj['price_total'] : 0)) : 0,
    items: [],
    order_id: item.id ?? undefined,
    order_number: item.order_number ?? undefined,
    payment_method: item.payment_method ?? undefined,
    profile_billing_id: item.profile_billing ? item.profile_billing.id : undefined,
    profile_shipping_id: item.profile_shipping ? item.profile_shipping.id : undefined,
    total: item.total ? priceFormat100(item.total) : 0,
    // Since Math.round() will only round a floating point value to the nearest integer value
    // we use the trick Math.round(Num * 100)/100 to round to two decimal places
    totalTax: item.total ? priceFormat(Math.round(((item.total/100) / ( 1 + vat/100 ) * vat/100) * 100).toFixed(2) / 100) : 0,
    vat: vat,
    status: item.status ? (orderStatusKeyed[item.status] ? orderStatusKeyed[item.status].translation : undefined) : undefined,
    statusInternal: item.status,
    order_number_dbs: item.order_number_dbs ?? undefined
  }
};

export default flattenCart;
