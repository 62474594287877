import React, { PureComponent } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import * as Config from "../Config";

export default class ProductSuggestion extends PureComponent {

  render() {
    let productWidthInner = Layout.productWidth - 5;
    if (this.props.product && this.props.product.imageStylesS2SlideSm) {
      return (
        <TouchableOpacity
          key={this.props.index}
          onPress={this.onPress}
          style={Styles.productSuggestion}
        >
          <Image
            source={{ uri: this.props.product.imageStylesS2SlideSm }}
            style={{
              height: productWidthInner,
              resizeMode: 'contain',
              width: productWidthInner,
            }}
          />
        </TouchableOpacity>
      );
    }
    // Empty product suggestions
    return (
      <View style={Styles.productSuggestion}/>
    );
  }

  onPress = () => {
    const node = this.props.product;
    let node_id = node.id;
    let node_uid = node.userId ?? 0;
    if (node.subProducts) {
      let keys = Object.keys(node.subProducts);
      node_uid = keys[Math.floor(Math.random()*keys.length)];
      node_id = node.subProducts[node_uid];
    }
    EventRegister.emit('analyticEvent', {
      category: (node.userId == '3317959') ? 'CommerceProductClick' : 'ProductClick',
      action: this.props.page ? this.props.page : ''
    });
    this.props.navigation.navigate({
      routeName: 'Product',
      key: `node${node_id}`,
      params: {
        index: this.props.index,
        nodes: node,
        parent: 'list'
      }
    });
  }

}
