import * as ActionTypes from './actionTypes';

export function productGet(payload) {
  return {
    type: ActionTypes.PRODUCT_GET,
    payload
  }
}

export function productGetSuccess(payload) {
  return {
    type: ActionTypes.PRODUCT_GET_SUCCESS,
    payload
  }
}

export function productGetFail(payload) {
  return {
    type: ActionTypes.PRODUCT_GET_FAIL,
    payload
  }
}

export function getProductSuggestions(payload) {
  return {
    type: ActionTypes.GET_PRODUCT_SUGGESTIONS,
    payload
  }
}

export function getProductSuggestionsSuccess(payload) {
  return {
    type: ActionTypes.GET_PRODUCT_SUGGESTIONS_SUCCESS,
    payload
  }
}

export function getProductSuggestionsFail(payload) {
  return {
    type: ActionTypes.GET_PRODUCT_SUGGESTIONS_FAIL,
    payload
  }
}
