import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FitImage from 'react-native-fit-image';

import * as Config from '../Config';
import Colors from '../constants/Colors';
import Layout from "../constants/Layout";

class BigBox extends React.Component {
  render() {

    // We support 2 styles:
    const extraStyle = (this.props.shopping === true)
    // - onestyle is alligned left and the background is not full width.
      ? styles.titleAlternative
    // - the other style is centered and full width.
      : styles.titleMain;
    // We also support big boxes with or without a title.
    const onlyImage = this.props.onlyImage ?? false;

    return (
      <TouchableOpacity style = {{ flex: 1, marginTop: 10, marginBottom: 0 }} onPress={this.props.onPress}>
        <FitImage
          indicator={true} // disable loading indicator
          indicatorColor="white" // react native colors or color codes like #919191
          indicatorSize="large" // (small | large) or integer
          source={{uri: this.props.image }}
          style={styles.image}
        />
        { !onlyImage &&
          <View style={styles.container}>
            <Text style = {[ styles.titleBase, extraStyle ]}>{ this.props.title }</Text>
          </View>
        }
      </TouchableOpacity>
    );
  }
}

const width = Math.floor(Layout.window.width-Config.STANDARD_MARGIN);
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 20,
  },
  image: {
    height: width/2.3,
    width: width,
  },
  titleBase: {
    backgroundColor: Colors.transparentBackground,
    fontFamily: 'MerriweatherBold',
    fontSize: Config.FONT_SIZE_BIG,
    lineHeight: 1.3*Config.FONT_SIZE_BIG,
    padding: Config.STANDARD_MARGIN,
  },
  titleAlternative: {
    alignSelf: 'flex-start',
    width: 0.8*width,
    textAlign: 'left'
  },
  titleMain: {
    width: width,
    textAlign: 'center'
  },
});

export default BigBox;
