import * as ActionTypes from './actionTypes';

export function commentsGet(payload){
  return {
    type: ActionTypes.COMMENTS_GET,
    payload
  }
}

export function commentsGetSuccess(payload){
  return {
    type: ActionTypes.COMMENTS_GET_SUCCESS,
    payload
  }
}

export function commentsGetFail(payload){
  return {
    type: ActionTypes.COMMENTS_GET_FAIL,
    payload
  }
}

export function commentSet(payload){
  return {
    type: ActionTypes.COMMENT_SET,
    payload
  }
}

export function commentSetSuccess(payload){
  return {
    type: ActionTypes.COMMENT_SET_SUCCESS,
    payload
  }
}

export function commentSetFail(payload){
  return {
    type: ActionTypes.COMMENT_SET_FAIL,
    payload
  }
}

export function commentDelete(payload){
  return {
    type: ActionTypes.COMMENT_DELETE,
    payload
  }
}

export function commentDeleteSuccess(payload){
  return {
    type: ActionTypes.COMMENT_DELETE_SUCCESS,
    payload
  }
}

export function commentDeleteFail(payload){
  return {
    type: ActionTypes.COMMENT_DELETE_FAIL,
    payload
  }
}

export function likeComment(payload) {
  return {
    type: ActionTypes.LIKE_COMMENT,
    payload
  }
}

export function likeCommentSuccess(payload) {
  return {
    type: ActionTypes.LIKE_COMMENT_SUCCESS,
    payload
  }
}

export function likeCommentFail(payload) {
  return {
    type: ActionTypes.LIKE_COMMENT_FAIL,
    payload
  }
}
