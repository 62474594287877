import React, {Component} from 'react';
import {
  Platform,
  Keyboard,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import * as Config from "../Config";
import AutocompleteTagsComponent from "./AutocompleteTagsComponent";
import CustomTextInput from "./CustomTextInput";
import Styles from "../constants/Styles";
import CustomMentionsTextInput from "./CustomMentionsTextInput";
import CustomButton from './general/CustomButton';
import FitImage from "react-native-fit-image";
import CustomIndicator from './CustomIndicator';
import { chooseFileMethod } from '../helpers/images';
import RestartMessage from "./general/RestartMessage";

const tagsMissingErrMsg = 'Bitte trage ein Stichwort zum Bild ein. Es muss zwischen 3 und 20 Zeichen haben.';
const imageMissingErrMsg = 'Bitte wähle erst ein Bild aus, bevor du auf "Speichern" klickst.';
const uploadOrSaveFailMsg = 'Das Speichern des Bildes ist fehlgeschlagen. Bitte überprüfe deine Internetverbindung und versuche es noch mal.';
const uploadOrSaveRetryMsg = 'Es gibt ein Problem mit der Internetverbindungen. Wir versuchen weiterhin das Bild zu speichern, danke für deine Geduld.';

class ImageUploadScreenComponent extends Component {
  state = {
    body_text: '',
    clearTagInput: false,
    errorMessage: null,
    query: '',
    // showAlert possible values:
    // - 0 / do not show or already shown
    // - 1 / show popup (default) when image is not already selected
    // - 2 / force show even if image was already selected
    showAlert: 1,
    tags: [],
    tagsSelected : [],
    tags_text: '',
    website_text: '',
  };

  static defaultNavigationOptions = ({navigation}) => ({
    tabBarVisible: true,
    animationEnabled: true
  });

  handleDelete = index => {
    let tagsSelected = this.state.tagsSelected;
    tagsSelected.splice(index, 1);
    this.setState({ tagsSelected });
  };

  handleAddition = suggestion => {
    this.props.getTagByName(suggestion);
    this.setState({
      tagsSelected: [
        ...this.state.tagsSelected,
        ...[suggestion],
      ],
      tags_text: '',
      clearTagInput: true ,
      query: '',
    });
  };

  handlePlainTextAddition = () => {
    let newTag = this.state.tags_text;
    if(newTag.length<3 || newTag.length>=20) {
      if(newTag.length !== 0) {
        this.setState({
          errorMessage: tagsMissingErrMsg,
          tags_text: this.state.tags_text.trim()
        });
      }
    }else {
      this.props.getTagByName(newTag);
      this.setState({
        tagsSelected: [
          ...this.state.tagsSelected,
          ...[newTag],
        ],
        tags_text: '',
        clearTagInput: true,
        query: ''
      });
    }
  };

  _clearInputFalseAndTagsText = (text) => {
    this.setState({clearTagInput: false, tags_text:text});
  };

  _setTagsText = (text) =>{
    this.setState({
      tags_text: text,
      errorMessage: null
    })
  };

  clearForm() {
    this.setState({
      tags_text: '',
      tags: [],
      body_text: '',
      website_text: '',
      picked_image: null,
      errorMessage: null,
      tagsSelected : [],
      clearTagInput: false,
      query: '',
      manipulating: false,
    });
    this.props.clearUploadedImage({id: 1});
  };

  componentDidMount () {
    this.props.whoAmI(false)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { navigation } = this.props;
    // Show the alert when the screen is focused and control variable is true.
    if (navigation.isFocused() && this.state.showAlert) {
      if (!this.props.upload.image_uri || this.state.showAlert == 2) {
        this.setState({ showAlert: 0 },
          () => chooseFileMethod(1, this.props.fileUpload)
        );
      }
    }

    if (!this.props.upload.image_uri) {
      // Reset the control variable if/when the user leaves the screen.
      if (!navigation.isFocused() && !this.state.showAlert) {
        this.setState({showAlert: 1});
      }
    }

    // Reset the control variable if/when the user retouches the camera icon.
    if (navigation.isFocused() && navigation.state.params && navigation.state.params.doubleClicked) {
      // Reset doubleClicked control var to avoid multiple popups.
      navigation.setParams({ doubleClicked: false });
      setTimeout(() => this.setState({showAlert: 2}), 50);
    }

    if (navigation.isFocused() &&
      this.props.newUploadedImage !== {} && this.props.newUploadedImage !== prevProps.newUploadedImage) {
      navigation.navigate('Home', {getView: false, redirectToNeu: true});
      this.clearForm();
    }

    if ((this.props.upload.uploading !== prevProps.upload.uploading)
      && !this.props.upload.uploading) {
      // If the file upload or the node save is not successful, so an alert message to the user
      if (this.props.upload.showFailMessage) {
        alert(uploadOrSaveFailMsg)
      }
      // If the file upload is successful send a Google Analytics event
      // passing a label with the current retryUpload value
      if (this.props.upload.image_uri && this.props.upload.image_uri != '') {
        EventRegister.emit('analyticEvent', {
          category: 'File Upload',
          action: 'Success',
          label: this.props.upload.retryUpload.toString()
        });
      }
      // If the node save is successful send a Google Analytics event
      // passing a label with the current retryNodeSave value
      if (!this.props.upload.showFailMessage && (this.props.upload.image_id == null)) {
        EventRegister.emit('analyticEvent', {
          category: 'Node Save',
          action: 'Success',
          label: this.props.upload.retryNodeSave.toString()
        });
      }

    }
  }

  render() {
    const { image_uri } = this.props.upload;
    let tags = (this.state.clearTagInput)?[]:this.props.tags || [];
    if (tags.length > 4) {
      tags = tags.slice(0,3);
    }
    return (
      <View style={Styles.pageViewContainer}>
        <ScrollView
          contentContainerStyle={Styles.pageScrollViewContainer}
          keyboardShouldPersistTaps={"handled"}
          >
          <View style={{flex:1}}>
            { (image_uri && image_uri != '') ?
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  overflow: 'hidden',
                  maxHeight: Layout.imageHeight,
                }}>
                <TouchableOpacity onPress={() => chooseFileMethod(1, this.props.fileUpload) } >
                  <FitImage
                    resizeMode='contain'
                    source={{ uri: image_uri }}
                    style={{ maxHeight: Layout.imageHeight, maxWidth: Layout.window.width}}
                  />
                </TouchableOpacity>
              </View>
            :
              <TouchableOpacity onPress={() => chooseFileMethod(1, this.props.fileUpload) } style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                <Text style={Styles.simpleLink}>Klicke hier, um ein Bild auszuwählen</Text>
              </TouchableOpacity>
           }
           </View>
           <View style={[Styles.autocompleteContainer, {marginBottom: 0}]}>
            <AutocompleteTagsComponent
              helpText='Einzelne Stichwörter mit Enter, Return oder Ok trennen, z.B. Wohnzimmer, Hay Sofa, Billy Regal, DIY, Dekoidee etc.'
              hideResults={this.state.tags_text === '' || this.state.tags_text === null || this.state.errorMessage !== null || this.state.tags_text === ','}
              tags={tags}
              handleAddition={this.handleAddition}
              handleDelete={this.handleDelete}
              tagsSelected={this.state.tagsSelected}
              tags_text={this.state.tags_text}
              clearTagInput={this.state.clearTagInput}
              handlePlainTextAddition={this.handlePlainTextAddition}
              _clearInputFalseAndTagsText={this._clearInputFalseAndTagsText}
              tagsGet={this.props.tagsGet}
              _setTagsText={this._setTagsText}
              errorMessage={this.state.errorMessage}
            />
          </View>
          <CustomMentionsTextInput
            label="Erzähle etwas über dein Bild"
            value={this.state.body_text}
            onChangeText={(val) => { this.setState({ body_text: val }) }}
            usersGetMentioning={this.props.usersGetMentioning}
            usersMentioning={this.props.usersMentioning}
          />
          <CustomTextInput
            label="Link zu deinem Blogpost"
            helpText='Du bist Blogger und zu deinem Bild gibt es noch mehr spannende Inhalte auf deinem Blog (z.B. eine DIY-Anleitung).'
            value={this.state.website_text}
            onChangeText={(website_text) => this.setState({website_text})}
            returnKeyType='done'
            blurOnSubmit={true}
            onSubmitEditing={(event) => {
              Keyboard.dismiss();
            }}
          />
          {((this.props.upload.retryUpload > 0) || (this.props.upload.retryNodeSave > 0)) && this.props.upload.uploading && <Text style={{marginTop: 10, color: 'red'}}>{uploadOrSaveRetryMsg}</Text> }
          <CustomButton
            title={this.props.creatingTag || this.props.gettingTag?"":(this.props.upload.uploading?'Bild wird hochgeladen':'Speichern')}
            onPress={this._savePhoto}
            buttonStyle={[Styles.regularButton, Styles.button]}
            titleStyle={Styles.regularButtonTitle}
            disabledTitleStyle={{color: 'white', fontWeight: 'bold'}}
            disabled={this.props.upload.uploading || this.props.creatingTag || this.props.gettingTag}
            disabledStyle={Styles.regularButtonGreyBorder}
            color={Colors.darkText}
            fontWeight='bold'
            loading={this.props.upload.uploading || this.props.creatingTag || this.props.gettingTag}
            loadingProps={{ color: Colors.iconLightGrey }}
            isOffline={this.props.isOffline}
          />
          <View style={{ minHeight: 80 }} />
        </ScrollView>
      </View>
    );
  };

  _savePhoto = () => {
    Keyboard.dismiss();
    if (!this.props.upload.image_id) {
      alert(imageMissingErrMsg)
    } else if (this.state.tagsSelected.length <= 0) {
      alert(tagsMissingErrMsg)
    } else {
      let data = {
        "image": this.props.upload.image_id,
        "body": this.state.body_text,
        "link": this.state.website_text,
        "tags": this.state.tagsSelected.map(tag => {
          if (tag && tag.id) {
            return tag.id;
          }
          else {
            return this.props.newTags.filter(t => tag && t && t.label && t.label.toLowerCase() === tag.toLowerCase()).map(t => t.id)[0];
          }
        }).filter((id, index, array) => array.indexOf(id) === index).join(","),
      };
      EventRegister.emit('analyticEvent', {
        category: 'Camera',
        action: 'Save new image node',
        label: 'Tap to save image with body, link and tags'
      });
      this.props.nodeSave({data: data, token: this.props.logInState.token});
    }
  }
}
export default ImageUploadScreenComponent;
