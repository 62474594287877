import React, { Component } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform
} from 'react-native';
import * as Config from '../Config';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import CollapsilbleText from "../components/CollapsilbleText";
import ProductSuggestions from '../components/ProductSuggestions';
import { ArrowLeft, ArrowRight } from './Arrows';
import NodeActionButtons from './general/NodeActionButtons';
import CommentsScreenComponent from "../components/CommentsScreenComponent";
import Colors from '../constants/Colors';
import { Thumbnail } from "../components/Thumbnail";
import TagItemInNode from './TagItemInNode';
import {isEmpty} from 'lodash';
import {Icon} from "./Icon";
import * as Animatable from 'react-native-animatable'
import { EventRegister } from 'react-native-event-listeners';
import ImageModal from "./general/ImageModal";


const AnimatedIcon = Animatable.createAnimatableComponent(Icon);

class ImageNode extends Component {

  state = {
    liked: this.props.node.liked,
    page: 0,
    commentShow: this.props.navigation.state.params.focusInputField || Platform.OS === 'ios' || false,
    focusComment: this.props.navigation.state.params.focusInputField,
  };

  static defaultNavigationOptions = ({ navigation }) => {
    const { params } = navigation.state;
    return params;
  };

  handleLargeAnimatedIconRef = (ref) => {
    this.largeAnimatedIcon = ref
  }

  animateIcon = () => {
    const { liked } = this.state
    this.largeAnimatedIcon.stopAnimation()
    this.largeAnimatedIcon.bounceIn()
      .then(() => this.largeAnimatedIcon.bounceOut());
    if (!liked) {
      EventRegister.emit('analyticEvent', {
        category: 'Flag',
        action: 'Herzen',
        label: 'ImageDetail'
      });
      this.props.likeNode({node: this.props.node, op: 'flag'});
      this.setState({liked: 1});
      }
    }

  componentDidMount() {
    const { node } = this.props;
    // #580 [performance] Do not refetch product suggestions if they are present.
    if ( !node.products
      || !node.products.length
      || !node.productsLastFetch
      || node.productsLastFetch !== new Date().getDate()
    ) {
      if (node.id) {
        this.props.getProductSuggestions({nid: node.id});
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if ( nextProps.currentNodeId() == nextProps.node.id
      && this.props.node !== nextProps.node
      || this.props.products !== nextProps.products
      || this.props.isLoadingComments !== nextProps.isLoadingComments
      || this.props.isDeletingCommentId !== nextProps.isDeletingCommentId
      || this.props.isSendingComment !== nextProps.isSendingComment
      || this.props.isEditingComment !== nextProps.isEditingComment
      || this.props.commentsById.length !== nextProps.commentsById.length
      || ((!isEmpty(nextProps.upload)) && this.props.upload !== nextProps.upload)
      || (this.props.usersMentioning !== nextProps.usersMentioning)
      || this.state.focusComment !== nextState.focusComment
      || this.state.liked !== nextState.liked
      || this.state.commentShow !== nextState.commentShow
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {node, navigation, products} = this.props;
    // combine/concat tags and product_tags
    const tags = node.tags && node.product_tags ? [ ...node.tags, ...node.product_tags ] : [];
    return (
      <ScrollView
        onScroll={this.toggleCommentShow}
        keyboardShouldPersistTaps={"handled"}
        style={{backgroundColor: Colors.background, flex:1}}
      >
        <View style={{marginVertical: Config.STANDARD_MARGIN_SMALL, zIndex:1}} >
          <View style={{height: Layout.imageHeight}}>
            <ImageModal
              animateIcon={() => this.animateIcon()}
              images={[node.imageStyles1200x900Conditional]}
            />
            <View style={{
              flexDirection: 'row',
              width: Layout.window.width,
              alignItems: 'center',
              position: 'absolute',
              top: Layout.imageHeight / 2,
              left: Layout.window.width / 2 - 40
            }}>
              <AnimatedIcon
                ref={this.handleLargeAnimatedIconRef}
                name="heart"
                color={Colors.iconSelected}
                size={80}
                style={{
                  position: 'absolute',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2,
                  opacity: 0
                }}
                duration={500}
                delay={200}
              />
            </View>

          </View>
          {this.props.showButtons &&
            <View
              style={{
                position: 'absolute',
                top: Layout.imageHeight / 2 - 60,
              }}>
              <View
                style={{
                  width: 64,
                  position: 'absolute',
                  left: 0
                }}>
                <TouchableOpacity onPress={this.props.scollToPrev}>
                  {this.props.index > 0 && <ArrowLeft/> }
                </TouchableOpacity>
              </View>
              <View
                style={{
                  width: 64,
                  position: 'absolute',
                  left: Layout.window.width - 64
                }}>
                <TouchableOpacity onPress={this.props.scollToNext}>
                  {!this.props.isLast && <ArrowRight/> }
                </TouchableOpacity>
              </View>
            </View>
          }
        </View>

        <ProductSuggestions page={'ImagePage'} navigation={navigation} products={products}/>
        <View style={{
          marginVertical: Config.STANDARD_MARGIN_BIG,
        }}>
          <NodeActionButtons
            navigateable={true}
            navigation={navigation}
            onPress={this.goToGeherzt}
            onPressLike={this.onPressLike}
            item={node}
            onPressComment={this.onPressComment}
            commentable={true}
          />
        </View>
        <View style={{margin: Config.STANDARD_MARGIN}}>
          <CollapsilbleText page='ImagePage' text={node.body} collapse={false}
                          navigation={navigation}/>
        </View>
        <View style={{
          flex: 1,
          alignItems: 'center',
        }}>
          <TouchableOpacity onPress={this.onPressAvatar}>
            <Thumbnail
              size="medium"
              source={{uri: node.userPictureUri || Config.DEFAULT_AVATAR}}
              type="avatar"
            />
            <Text style={Styles.username}>{node.userLabel}</Text>
          </TouchableOpacity>
          <View style={Styles.narrowCol}>
            <View style={Styles.centerHorizontalItems}>
              {tags.map((t, l) => (
                <TagItemInNode
                  key={l}
                  tags={tags}
                  t={t}
                  l={l}
                  navigation={this.props.navigation}
                />
              ))}
            </View>
            <View style={Styles.centerVerticalItems}>
              {this.props.node.link ?
              <Text onPress={this.gotoWebsite} style={Styles.simpleLink}>
                Mehr auf meinem Blog
              </Text> :
              null}
              <Text>{node.views}x angesehen - {node.time}</Text>
            </View>

          </View>
        </View>
        { this.state.commentShow && 
        <CommentsScreenComponent
          toggleCommentSent={this.toggleCommentSent}
          focusComment={this.state.focusComment}
          isLoading={this.props.isLoadingComments}
          isDeletingCommentId={this.props.isDeletingCommentId}
          isSendingComment={this.props.isSendingComment}
          isEditingComment={this.props.isEditingComment}
          logInState={this.props.logInState}
          comments={this.props.comments}
          commentsById={this.props.commentsById}
          commentSet={this.props.commentSet}
          commentsGet={this.props.commentsGet}
          entityDel={this.props.entityDel}
          navigation={this.props.navigation}
          node={node}
          usersMentioning={this.props.usersMentioning}
          usersGetMentioning={this.props.usersGetMentioning}
          upload={this.props.upload}
          fileUpload={this.props.fileUpload}
          clearUploadedImage={this.props.clearUploadedImage}
          commentDelete={this.props.commentDelete}
          likeComment={this.props.likeComment}
        />
        }
      </ScrollView>
    );
  }

  componentWillUnmount() {
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener.remove();
    }
  }

  toggleCommentShow = () => {
    if (!this.state.commentShow) {
      this.setState({ commentShow: true });
    }
  };
  
  goToGeherzt = () => this.props.navigation.navigate({
    routeName: 'Geherzt',
    params: {
      id: this.props.node.id
    },
    key: `geherzt${this.props.node.id}`
  });

  onPressComment = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      this.setState({
        focusComment: this.props.node.id,
      });
    }
  };

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.props.node.liked) {
        this.props.likeNode({node: this.props.node, op: 'unflag'});
        this.setState({liked: 0});
      }
      else {
        EventRegister.emit('analyticEvent', {
          category: 'Flag',
          action: 'Herzen',
          label: 'ImageDetail'
        });
        this.props.likeNode({node: this.props.node, op: 'flag'});
        this.setState({liked: 1});
      }
    }
  };

  onPressAvatar = () => {
    const { navigation, node } = this.props;
    navigation.navigate({
      routeName: 'User',
      key: `user${node.userId}`,
      params: { user: node.user, id: node.userId }
    });
  };

  gotoWebsite = () => {
    const { navigation, node } = this.props;
    if (node.link) {
      navigation.navigate('Webpage', {data: {uri: node.link}});
    }
  };
}

export default ImageNode;
