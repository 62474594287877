import React, { PureComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Styles from '../../constants/Styles';
import BackButton from '../../components/BackButton';

export default class NavHeaderLeft extends PureComponent {
  render() {
    const { navigation } = this.props;
    // Whether the route is of the common stack.
    const isCommonScreen = (typeof this.props.Screens[navigation.state.routeName] != "undefined");
    // Whether the screen is the last step of the checkout process (ThankYou screen).
    const isThankYouScreen = (navigation.state.routeName == 'ThankYou');
    if (isCommonScreen && !isThankYouScreen) {
      return (
        <View style={{ paddingLeft: 5 }}>
          <TouchableOpacity onPress={this.onPressBack} style={Styles.headerTopRightButton}>
            <BackButton />
          </TouchableOpacity>
       </View>
      );
    }
    else {
      return <View />
    }
  }
  onPressBack = () => this.props.navigation.goBack();
}
