export const orderStatus = [
  {
    status: 'pending',
    translation: 'In Bearbeitung',
  },
  {
    status: 'processing',
    translation: 'In Bearbeitung',
  },
  {
    status: 'canceled',
    translation: 'Storniert',
  },
  {
    status: 'completed',
    translation: 'Abgeschlossen',
  },
  {
    status: 'checkout_complete',
    translation: 'Abgeschlossen',
  },
];

export const orderStatusKeyed = orderStatus.reduce((object, orderStatus) => {
  object[orderStatus.status] = orderStatus;
  return object;
}, {});
