import React from 'react';
import {
  Text,
  View,
  TouchableOpacity
} from 'react-native';
import { StackActions } from 'react-navigation';
import IconBadge from 'react-native-icon-badge';

import * as Config from '../Config';
import { Icon } from './Icon';
import Layout from '../constants/Layout';
import Styles from '../constants/Styles';
import { Facets } from '../constants/Facets.js';
import MenuDynamic from './MenuDynamic';
import ShoppingFacetSelection from './ShoppingFacetSelection';
import ProductsList from './ProductsList';
import IndicatorPage from './general/IndicatorPage';

class ShoppingScreenComponent extends React.Component {

  constructor(props) {
    super(props);
    // Get the initial facets from any query params passed and calculate a count.
    const initialFacets = this.initialFacets(props);
    const initialFacetsCount = this.facetCount(initialFacets);
    // Set the initial state like so:
    this.state = {
      choosingCategory: false,
      choosingFacet: false,
      chosenCategory: props.navigation.getParam('categoryId', 0),
      chosenSort: 'best',
      selectedFacets: initialFacets,
      selectedFacetsCount: initialFacetsCount,
    };
  }

  componentDidMount() {
    // Fetch the term to be sure it can be displayed.
    if (!this.props.tagsdb || !this.props.tagsdb[this.state.chosenCategory]) {
      this.props.termsGet({ ids: [this.state.chosenCategory] });
    }
  }

  render()  {
    if (this.state.choosingCategory) {
      const { tagsdb } = this.props;
      const { chosenCategory } = this.state;
      const id = tagsdb[chosenCategory].vocabulary == 'tags' ? 0 : chosenCategory;
      return (
        <MenuDynamic
          chosenItem={'shopping/term/' + id}
          closeCategoriesMenu={this.closeCategoriesMenu}
          header={
            <View style={{flexDirection: 'row', justifyContent: 'space-between', width: Layout.half.width}}>
              <View style={{ width: 32 }}/>
              <View style={{marginTop: 20, marginBottom: 20}}>
                <Text style={[Styles.gridTitle, Styles.textCenter]}>Kategorien</Text>
              </View>
              <TouchableOpacity
                style={{padding: 5, marginRight: 5, marginTop: 10, marginBottom: 20}}
                onPress={this.closeCategoriesMenu}
              >
                <Icon
                  name='cancel'
                  size={Config.SIZE_ICON}
                  color='black'
                  onPress={null}
                />
              </TouchableOpacity>
            </View>
          }
          menuGet={this.props.menuGet}
          menuName={'menu-q7-shopping-menu'}
          menus={this.props.menus}
          setChosenCategoryAndClose={this.setChosenCategoryAndClose}
        />
      );
    }
    else if (this.state.choosingFacet) {
      return (
        <ShoppingFacetSelection
          chosenSort={this.state.chosenSort}
          closeFacetMenu={this.closeFacetMenu}
          facets={this.props.facets}
          facetsClear={this.facetsClear}
          facetKey={Object.keys(this.state.selectedFacets).map(key => this.state.selectedFacets[key].length).join('-')}
          selectedFacets={this.state.selectedFacets}
          setChosenFacet={this.setChosenFacet}
          setChosenSort={this.setChosenSort}
          tagsdb={this.props.tagsdb}
        />
      );
    }
    else {

      if (!this.props.tagsdb[this.state.chosenCategory]) {
        return (
          <IndicatorPage />
        );
      }

      const title = this.props.tagsdb[this.state.chosenCategory].label;

      return (
        <ProductsList
          chosenCategory={this.state.chosenCategory}
          chosenSort={this.state.chosenSort}
          contentContainerStyle={Styles.pageScrollViewContainer}
          data={this.props.data}
          facetKey={Object.keys(this.state.selectedFacets).map(key => this.state.selectedFacets[key].length).join('-')}
          facets={this.state.selectedFacets}
          header={
            <View style={{ marginBottom: Config.STANDARD_MARGIN}}>
              <Text style={Styles.pageTitle}>{title}</Text>
              <View style={{ display: 'flex',flexDirection: 'row', justifyContent: 'space-around',}}>
                <TouchableOpacity onPress={this.onPressToChooseCategory} style={Styles.greyButtonContainer}>
                  <Text style={Styles.pinkButtonText}>Kategorie wählen</Text>
                </TouchableOpacity>
                <IconBadge
                  MainElement={
                    <TouchableOpacity onPress={this.onPressToChooseFacet} style={Styles.greyButtonContainer}>
                      <Text style={Styles.pinkButtonText}>Filtern / Sortieren</Text>
                    </TouchableOpacity>
                  }
                  BadgeElement={
                    <Text style={{color:'#FFFFFF'}}>{this.state.selectedFacetsCount}</Text>
                  }
                  Hidden={this.state.selectedFacetsCount == 0}
                  IconBadgeStyle={Styles.IconBadgeStyle}
                />
              </View>
            </View>
          }
          isLikingNode={this.props.isLikingNode}
          isLoading={this.props.isLoading}
          likeNode={this.props.likeNode}
          navigation={this.props.navigation}
          nodes={this.props.nodes}
          productSearchGet={this.props.productSearchGet}
          tagsdb={this.props.tagsdb}
          viewGet={this.props.viewGet}
        />
      );
    }
  }

  facetCount = (facets) => Object.values(facets).reduce(
    (total, facetValuesArray) => total + facetValuesArray.length,
    0 // Initial value.
  );

  onPressToChooseCategory = () => this.setState({ choosingCategory: true });

  onPressToChooseFacet= () => this.setState({ choosingFacet: true });

  setChosenCategoryAndClose = ({ path }) => {
    const id = path.split('/').pop();
    const pushAction = StackActions.push({
      routeName: 'Shopping',
      params: {
        categoryId: id,
      },
    });

    this.setState(
      {
        choosingCategory: false,
      },
      () => this.props.navigation.dispatch(pushAction)
    );
  };

  closeCategoriesMenu = () => this.setState({ choosingCategory: false });

  setChosenSort = (chosenSort) => this.setState({ chosenSort });

  setChosenFacet = (facet, value) => {
    const selectedFacets = { ...this.state.selectedFacets };
    selectedFacets[facet] = selectedFacets[facet] || [];
    if (!selectedFacets[facet].includes(value)) {
      selectedFacets[facet].push(value);
    }
    else {
      selectedFacets[facet].splice(selectedFacets[facet].indexOf(value), 1);
    }
    this.setState({
      selectedFacets,
      selectedFacetsCount: this.facetCount(selectedFacets),
    });
  }

  closeFacetMenu = () => this.setState({ choosingFacet: false });

  facetsClear = () => {
    this.setState({
      selectedFacets: this.emptyFacets(),
      selectedFacetsCount: 0,
    });
  }

  /**
   * Parse the params and preselect any facets values.
   */
  initialFacets = (props) => {
    const params = props.navigation.getParam('params', {});
    const selectedFacets = Facets.reduce((object, facet) => {
      if (params && params[facet.url_parameters_web]) {
        object[facet.machine_name] = params[facet.url_parameters_web].pop().split('.');
      }
      else {
        object[facet.machine_name] = [];
      }
      return object;
    }, {});
    return selectedFacets;
  }

  /**
   * Created an object without any selected values for facets.
   */
  emptyFacets = () => Facets.reduce((object, facet) => {
    object[facet.machine_name] = [];
    return object;
  }, {})


}

export default ShoppingScreenComponent;
