import React, { Component } from 'react';
import ThankYouScreenComponent from "../components/shopping/ThankYouScreenComponent";
import {connect} from "react-redux";
import {cartGet} from "../actions";

class ThankYouScreen extends Component {

  render() {
    return (
      <ThankYouScreenComponent
        cartGet={this.props.cartGet}
        navigation={this.props.navigation}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouScreen);
