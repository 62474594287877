import xs from 'xstream/index';
import * as actions from '../actions/index';

export function catchErrors(sources) {

  const response$ = sources.HTTP
    .select()
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status >= 300)
    .map((response => {
      return response
    }));

  const action$ = xs.combine(response$)
    .map(arr => {
      return actions.errorReceived(arr[0]);
    });

  return {
    ACTION: action$,
  }
}
