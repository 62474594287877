import { priceFormat100 } from '../../helpers/formatNumbers'

const flattenShippingItem = (item) => {
  return !item.title ? false : {
    price: item.price ? priceFormat100(item.price) : 0,
    title: item.title ?? undefined,
    description: item.description ?? undefined,
  }
};

export default flattenShippingItem;
