import React, { PureComponent } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { blockNode, blockUser } from "../../actions/index";
import { Share } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import * as Config from '../../Config';
import Styles from '../../constants/Styles';
import { Icon } from '../../components/Icon';

class NavHeaderRight extends PureComponent {

  render() {
    const { navigation, uid, nodes } = this.props;
    const { routeName} = navigation.state;
    const shareIcon = this.shareIcon();
    switch (routeName) {
      case 'Me':
      case 'User':
        // On Own Profile Screen. Add a gear to edit profile.
        if (uid > 0 && (routeName == 'Me' || uid == navigation.getParam('id', null))) {
          return (
            <View style={Styles.headerTopRightContainer}>
              {shareIcon}
              <TouchableOpacity onPress={() => navigation.navigate('UserEdit')} style={Styles.headerTopRightButton}>
                <Icon name='gear' />
              </TouchableOpacity>
            </View>

          );
        }
        if (routeName != 'Me') {
          // On Other User Profile Screen. Add contextual menu.
          return (
            <View style={Styles.headerTopRightContainer}>
              {shareIcon}
              <View style={Styles.headerTopRightButton}>
                <Menu style={{alignSelf: 'center'}}>
                  <MenuTrigger>
                    <Icon name='dots' style={Styles.buttonTop} />
                  </MenuTrigger>
                  <MenuOptions>
                    <MenuOption style={{ padding: 10 }} onSelect={this.onSelectReportUser} text='Mitglied melden' />
                    <MenuOption style={{ padding: 10 }} onSelect={this.onSelectBlockUser} text='Mitglied und dessen Inhalte nicht mehr anzeigen' />
                  </MenuOptions>
                </Menu>
              </View>
            </View>
          );
        }
        return <View />
      case 'Node':
        const currentNodeId = navigation.getParam('nodeId', null);
        // Own node: Add gear that leads to edit page.
        if (currentNodeId && nodes[currentNodeId] && nodes[currentNodeId].userId === uid) {
          return (
            <View style={Styles.headerTopRightContainer}>
              {shareIcon}
              <TouchableOpacity onPress={this.onPressGearNode} style={Styles.headerTopRightButton}>
                <Icon name='gear' />
              </TouchableOpacity>
            </View>
          );
        }
        // Other users node: Add menu with options to block/report this content.
        else {
          return (
            <View style={Styles.headerTopRightContainer}>
              {shareIcon}
              <View style={Styles.headerTopRightButton}>
                <Menu style={{alignSelf: 'center'}}>
                  <MenuTrigger>
                    <Icon name='dots' style={Styles.buttonTop} />
                  </MenuTrigger>
                  <MenuOptions>
                    <MenuOption style={{ padding: 10 }} onSelect={this.onSelectReportNode} text='Inhalt melden' />
                    <MenuOption style={{ padding: 10 }} onSelect={this.onSelectBlockNode} text='Inhalt nicht mehr anzeigen' />
                  </MenuOptions>
                </Menu>
              </View>
            </View>
          );
        }
      case 'NodeSingle' :
      case 'Tag' :
      case 'Home' :
        return (
          <View style={Styles.headerTopRightContainer}>
            {shareIcon}
          </View>
        );
      default:
        return <View />
    }
  }

  onPressGearUser = () => {
    const id = navigation.getParam('id', null);
    const name = navigation.getParam('name', null);
    const user = this.props.users[(name) ? this.props.usernames[name] : (id) ?? null];
    this.props.blockUser(user.id); 
    this.props.navigation.navigate('Home');
  }

  shareIcon = () => {
    return (
      <TouchableOpacity onPress={this.onShare} style={Styles.headerTopRightButton}>
        <Icon name='share' />
      </TouchableOpacity>
    )
  }

  onPressGearNode = () => {
    const { navigation } = this.props;
    const currentNodeId = navigation.getParam('nodeId', null);
    navigation.navigate({
      routeName: 'NodeEdit',
      key: `nodeEdit${currentNodeId}`,
      params: {
        node: this.props.nodes[currentNodeId],
        forceNodeUpdate: navigation.state.params.forceNodeUpdate,
      },
    });
  }

  onSelectBlockNode = () => {
    const currentNodeId = this.props.navigation.getParam('nodeId', null);
    this.props.blockNode(currentNodeId);
    this.props.navigation.navigate('Home', { doubleClicked: true });
  }

  onSelectBlockUser = () => {
    const userId = this.props.navigation.getParam('id', null);
    this.props.blockUser(userId);
    this.props.navigation.navigate('Home', { doubleClicked: true });
  }

  onShare = async () => {
    let url = '';
    let label = '';
    const { navigation, uid } = this.props;
    switch (navigation.state.routeName) {
      case 'Node' :
      case 'NodeSingle':
        const currentNodeId = (navigation.state.params) ? navigation.state.params.nodeId : null;
        const currentNode  = this.props.nodes[currentNodeId];
        url = 'node/' + currentNodeId;
        label = currentNode.label ? currentNode.label : '';
        break;
      case 'User' :
        const currentUserId = (navigation.state.params) ? navigation.state.params.id : null;
        url = 'user/' + currentUserId;
        break;
      case 'Me':
        const myUserId = uid ? uid : null;
        url = 'user/' + myUserId;
        break;
      case 'Tag' :
        const currentTagId = (navigation.state.params) ? navigation.state.params.tag.id : null;
        url = 'taxonomy/term/' + currentTagId;
        break;
    }
    const result = await Share.share({
      message: 'Schau mal. Habe ich gerade auf SoLebIch entdeckt: ' + '\n' +'https://www.solebich.de/' + url,
    } , { subject: label });
    if (result.action === Share.sharedAction) {
      EventRegister.emit('analyticEvent', {
        category: 'Share',
        action: result.activityType ?? 'No Activity Type'
      });
    }
  };

  onSelectReportNode = () => {
    const nodeId = this.props.navigation.getParam('nodeId', null);
    this.props.navigation.navigate({
      routeName: 'ReportForm',
      key: `reportNode` + nodeId,
      params: { type: 'node', id: nodeId },
    });
  }

  onSelectReportUser = () => {
    const userId = this.props.navigation.getParam('id', null);
    this.props.navigation.navigate({
      routeName: 'ReportForm',
      key: `reportUser` + userId,
      params: { type: 'user', id: userId },
    });
  }

}

const mapStateToProps = (state) => {
  return {
    uid: state.user.uid,
    nodes: state.images.nodes,
    users: state.users.users,
    usernames: state.users.usernames,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    blockNode: (payload) => {
      dispatch(blockNode(payload))
    },
    blockUser: (payload) => {
      dispatch(blockUser(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavHeaderRight);
