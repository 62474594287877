import { Dimensions, Platform } from 'react-native';
import Device from 'react-native-device-detection';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
//const isSmallDevice = width < 375;
const isSmallDevice = true;
const IS_TABLET = Platform.isPad || Device.isTablet || Platform.OS === 'web';  // TODO avoid this duplication of code (i was getting "Require cycle" warning)
const maxProducts = IS_TABLET ? 8 : 4;
const width2 = IS_TABLET ? width/2 : width;
const productWidth = Math.floor((width2-10)/4) - 5;
const arrowWidth = Math.floor(width*10/100);
export default {
  arrowWidth,
  containedWidth: width - 20,
  half: {
    width: isSmallDevice ? width : Math.floor(width/2),
    height: Math.floor(height/2),
  },
  imageHeight: (height - productWidth - 135),
  imageZoomHeight: (height  - 135),
  isSmallDevice: isSmallDevice,
  maxProducts,
  productWidth,
  window: {
    width,
    height,
  },
  isWeb: Platform.OS === 'web'
};
