import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import { connect } from 'react-redux';
import { forgotPassword } from '../actions/index';
import Styles from "../constants/Styles";
import CustomButton from '../components/general/CustomButton';
import CustomTextInput from '../components/CustomTextInput';
import ContactText from "../components/general/ContactText";

class ForgotPasswordScreen extends React.Component {

  state = {
    email: '',
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( (this.props.logInState.switchForgotPasswordLogin !== prevProps.logInState.switchForgotPasswordLogin) && this.props.logInState.switchForgotPasswordLogin) {
      this.setState({forgotPassword: !this.props.logInState.switchForgotPasswordLogin});
      Alert.alert(
        '',
        'In Kürze erhältst du eine E-Mail, mit der du ein neues Passwort festlegen kannst.',
        [
          {
            text: 'Okay',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    }
  }

  handleReset = () => {
    if (this.state.email) {
      this.props.forgotPassword({
        mail: this.state.email
      });
    }
  };

  render() {
    return (
      <ScrollView style={Styles.pageScrollViewContainer} keyboardShouldPersistTaps={"handled"}>
        <View style={[Styles.padInputWidth, {marginTop: 100}]}>
          <CustomTextInput
            value={this.state.email}
            onChangeText={(text) => {this.setState({email: text})}}
            label="Email"
            clearButtonMode="always"
            selectTextOnFocus={true}
            autoCorrect={false}
            controlled={true}
          />
          {
            this.props.forgotPasswordErrorMessage && this.props.forgotPasswordErrorMessage !== null &&
            <Text style={{color: 'red', marginTop: 10}}>{this.props.forgotPasswordErrorMessage}</Text>
          }
          <View style={{ alignItems: 'center' }}>
            <CustomButton
              title="Neues Passwort per E-Mail zuschicken"
              onPress={this.handleReset}
            />
          </View>
        </View>
        <ContactText navigation={this.props.navigation}/>
      </ScrollView>
  );
  }
}

const mapStateToProps = (state) => {
  return {
    logInState: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    forgotPassword: (payload) => {
      dispatch(forgotPassword(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);
