import * as ActionTypes from "../actions/actionTypes";
import * as Config from "../Config";
import xs from "xstream";
import * as actions from "../actions";

export function ordersGet(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ORDERS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/orders';
        return ({
          url: Config.BASE_URL + path,
          category: 'ordersGet',
        });
      }
    );

  const response$ = sources.HTTP
    .select('ordersGet')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.ordersGetSuccess(data.data);
        }
        else {
          return actions.ordersGetFail(data);
        }
      }catch(e){
        return actions.ordersGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function shippingCollect(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SHIPPING_COLLECT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/cart-shipping-get-all';
        return ({
          url: Config.BASE_URL + path,
          category: 'shippingCollect',
        });
      }
    );

  const response$ = sources.HTTP
    .select('shippingCollect')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data) {
          return actions.shippingCollectSuccess(data);
        }
        else {
          return actions.shippingCollectFail(data);
        }
      }catch(e){
        return actions.shippingCollectFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
