import React, { Component } from 'react';
import {View, Text, FlatList, Image, ImageBackground, ScrollView, TouchableOpacity} from 'react-native';
import CustomButton from "../general/CustomButton";
import * as Config from '../../Config';
import {Icon} from "../Icon";
import LineItems from "./LineItems"
import CouponForm from "./CouponForm";
import OrderTotal from "./OrderTotal";
import ShippingMethods from "./ShippingMethods";
import PaymentMethods from "./PaymentMethods";
import CustomTextInput from "../CustomTextInput";
import ShopFooter from "./ShopFooter";
import Styles from "../../constants/Styles";
import CustomIndicator from "../CustomIndicator";

class CartScreenComponent extends Component {

  state = {
    searchterm: ''
  }

  componentDidUpdate(prevProps) {
    // Refresh the cart when icon is double tapped.
    if (this.props.navigation.getParam('cartIconPressed')){
      this.props.navigation.setParams({ cartIconPressed: false });
      setTimeout(() => this.props.cartGet(), 500);
    }
    if (Object.keys(this.props.shippingOptions).length === 0) {
      this.props.shippingCollect();
    }
    // Check the latest status of the cart/order. It may need reset if one of:
    // - checkout_payment
    // Comment in to update the cart status in case it stacks 
    // on checkout_payment (if there isn't a correct flow that causes this behaviour)
    // if (this.props.cart.statusInternal == 'checkout_payment' && prevProps.cart.statusInternal != 'checkout_payment') {
    //   this.props.cartUpdateStatus({ orderId: this.props.cart.order_id, status: 'cart' });
    // }
  }

  componentDidMount() {
    // When the cart is focusedm refresh it.
    this.didFocusListener = this.props.navigation.addListener(
      'didFocus',
      () => this.props.cartGet()
    );
    // Get a list of slides. To be shown when the cart is empty.
    this.props.shoppingSlideGet({ where: 'cart' });
  }

  componentWillUnmount() {
    this.didFocusListener.remove();
  }

  pressPaypalExpress = () => {
    // If paypal expres is already processed but not confirmed go directly to order review.
    if (this.props.cart.statusInternal == 'checkout_paypal_ec') {
      this.props.navigation.navigate("Order", { cart: true, paypal_express: true});
    }
    else {
      this.props.navigation.navigate('Webpage', { payment: 'paypal_express', data: { uri: Config.BASE_URL + 'cart' }});
    }
  }

  renderFooter = () => {
    const { cart, cartShippingOptions, navigation, addCoupon, removeCoupon, shippingOptions } = this.props
    return (
      <View style={{paddingVertical: 15}}>
        <CouponForm addCoupon={addCoupon} coupons={cart.coupons} removeCoupon={removeCoupon} isLoadingCoupon={this.props.isLoadingCoupon} cartGet={this.props.cartGet} isRemovingCoupon={this.props.isRemovingCoupon}/>
        <OrderTotal cart={cart} shippingOptions={shippingOptions} />
          <ShippingMethods 
            cartShippingOptions={cartShippingOptions}
            cartShippingSelect={this.props.cartShippingSelect}
            isCart={true}
            isLoadingShipping={this.props.isLoadingShipping}
            selectedShipping={cart.shipping_service}
          />
        <PaymentMethods />
        <ShopFooter navigation={navigation} />
      </View>
    )
  }

  renderSlideItem = ({ item }) => (
    <TouchableOpacity style={{marginBottom: 15}} onPress={() => this.props.navigation.navigate('Shopping', {categoryId: item.link})}>
      <ImageBackground
        source={{uri: item.imageStyles585x330}}
        style={Styles.imageBox}
      >
        <Text style={Styles.textBox}>
          {item.title}
        </Text>
      </ImageBackground>
    </TouchableOpacity>
  );

  render() {
    if (this.props.isLoadingCart) {
      return <CustomIndicator />
    }
    if (!this.props.cartCount) {
      return (
        <View style={[Styles.pageViewContainer]}>
          <ScrollView
            contentContainerStyle={Styles.pageScrollViewContainer}
            keyboardShouldPersistTaps={"handled"}
          >
          <Icon
            name='shopping_cart'
            size={50}
            color='#262626'
            style={{marginTop: 30, textAlign: 'center'}}
          />
          <Text style={{marginVertical: 20, textAlign: 'center'}}>Dein Warenkorb ist leer</Text>
          <CustomTextInput
            label='Suchbegriff eingeben'
            onChangeText={(searchTerm) => this.setState({searchTerm: searchTerm, submitted: false })}
            value={this.state.searchTerm}
            returnKeyType='search'
            clearButtonMode="always"
            onSubmitEditing = {() => {
              this.props.navigation.navigate('Search', {
                preFill: 1,
                type: 'product',
                keyword: this.state.searchTerm,
                showMenu: 2,
              });
              this.setState({ submitted: true });
            }}
            style={{ marginTop: 0 }}
          />
          <FlatList
            data={this.props.shoppingSlides}
            renderItem={this.renderSlideItem}
            style={{marginTop: 20}}
          />
          </ScrollView>
        </View>
      )
    } else {
      return (
        <View style={{flex: 1}}>
          <LineItems
            addCoupon={this.props.addCoupon}
            alterQuantity={this.props.alterQuantity}
            cart={this.props.cart}
            cartGet={this.props.cartGet}
            isCart={true}
            isLoadingQuantity={this.props.isLoadingQuantity}
            navigation={this.props.navigation}
            removeFromCart={this.props.removeFromCart}
            renderFooter={this.renderFooter}
          />
          <View style={Styles.shopButtonWrap}>
            <View style={{paddingHorizontal: 10}}>
              <CustomButton
                title="zur Kasse"
                onPress={() => this.props.navigation.navigate({routeName: 'Order', params: {cart: true}})}
                buttonStyle={[Styles.shopButton, {width: '100%', marginBottom: 10, marginTop: 10}]}
                fontFamily={"OpenSansRegular"}
              />
            </View>
          </View>
        </View>
      )
    }
  }
}
export default CartScreenComponent;
