import React from 'react';
import { StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';
import CommentEditScreen from "../containers/CommentEditScreen";
import CommentsScreen from '../containers/CommentsScreen';
import CommunityScreen from "../containers/CommunityScreen";
import FeedbackScreen from "../containers/FeedbackScreen";
import FollowersScreen from '../containers/FollowersScreen';
import FollowingScreen from '../containers/FollowingScreen';
import GeherztScreen from '../containers/GeherztScreen';
import PageScreen from "../containers/PageScreen";
import LoginScreen from "../containers/LoginScreen";
import RegisterEmailScreen from "../containers/RegisterEmailScreen";
import RegisterFacebookScreen from "../containers/RegisterFacebookScreen";
import RegisterAppleScreen from "../containers/RegisterAppleScreen";
import RegisterGoogleScreen from "../containers/RegisterGoogleScreen";
import RegisterScreen from "../containers/RegisterScreen";
import ForgotPasswordScreen from "../containers/ForgotPasswordScreen";
import MagazineScreen from "../containers/MagazineScreen";
import NavHeaderLeft from '../components/header/NavHeaderLeft';
import NavHeaderRight from '../components/header/NavHeaderRight';
import NavHeaderTitle from '../components/header/NavHeaderTitle';
import NodeEditScreen from "../containers/NodeEditScreen";
import NodeScreen from '../containers/NodeScreen';
import NodeSingleScreen from '../containers/NodeSingleScreen';
import ProductScreen from '../containers/ProductScreen';
import ReportFormScreen from '../containers/ReportFormScreen';
import ResetScreen from '../containers/ResetScreen';
import ShoppingScreen from "../containers/ShoppingScreen";
import TagScreen from '../containers/TagScreen';
import UserDiscussionScreen from '../containers/UserDiscussionScreen';
import UserEditScreen from "../containers/UserEditScreen";
import UserScreen from '../containers/UserScreen';
import SearchScreen from '../containers/SearchScreen';
import CheckoutScreen from '../containers/CheckoutScreen';
import ThankYouScreen from '../containers/ThankYouScreen';
import DeliveryScreen from '../containers/DeliveryScreen';
import PaymentScreen from '../containers/PaymentScreen';
import ShippingScreen from '../containers/ShippingScreen';
import OrderScreen from '../containers/OrderScreen';
import AddressFormScreen from '../containers/AddressFormScreen';
import OrderHistoryScreen from '../containers/OrderHistoryScreen';
import AddressBookScreen from '../containers/AddressBookScreen';
import { CardStyleInterpolators } from 'react-navigation-stack';

const navigationOptions = {
  cardStyle: { backgroundColor: Colors.background, flex: 1, opacity: 1 },
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
}

const Screens = {
  CommentEdit: { screen: CommentEditScreen, navigationOptions: navigationOptions },
  Comments: { screen: CommentsScreen, navigationOptions: navigationOptions },
  Community: { screen: CommunityScreen, navigationOptions: navigationOptions },
  Feedback: { screen: FeedbackScreen, navigationOptions: navigationOptions },
  Followers: { screen: FollowersScreen, navigationOptions: navigationOptions},
  Following: { screen: FollowingScreen, navigationOptions: navigationOptions },
  ForgotPassword: { screen: ForgotPasswordScreen, navigationOptions: navigationOptions },
  Geherzt: { screen: GeherztScreen, navigationOptions: navigationOptions },
  Page: { screen: PageScreen, navigationOptions: navigationOptions },
  Login: { screen: LoginScreen, navigationOptions: navigationOptions },
  Magazine: { screen: MagazineScreen, navigationOptions: navigationOptions },
  Node: { screen: NodeScreen, navigationOptions: navigationOptions },
  NodeEdit: { screen: NodeEditScreen, navigationOptions: navigationOptions },
  NodeSingle: { screen: NodeSingleScreen, navigationOptions: navigationOptions },
  Product: { screen: ProductScreen, navigationOptions: navigationOptions },
  Register: { screen: RegisterScreen, navigationOptions: navigationOptions },
  RegisterEmail: { screen: RegisterEmailScreen, navigationOptions: navigationOptions },
  RegisterFacebook: { screen: RegisterFacebookScreen, navigationOptions: navigationOptions },
  RegisterApple: { screen: RegisterAppleScreen, navigationOptions: navigationOptions },
  RegisterGoogle: { screen: RegisterGoogleScreen, navigationOptions: navigationOptions },
  ReportForm: { screen: ReportFormScreen, navigationOptions: navigationOptions },
  Reset: { screen: ResetScreen, navigationOptions: navigationOptions },
  Search: { screen: SearchScreen, navigationOptions: navigationOptions },
  Shopping: { screen: ShoppingScreen, navigationOptions: navigationOptions},
  Tag: { screen: TagScreen, navigationOptions: navigationOptions },
  User: { screen: UserScreen, navigationOptions: navigationOptions },
  UserDiscussion: { screen: UserDiscussionScreen, navigationOptions: navigationOptions },
  UserEdit: { screen: UserEditScreen, navigationOptions: navigationOptions },
  Order: { screen: OrderScreen, navigationOptions: navigationOptions },
  OrderHistory: { screen: OrderHistoryScreen, navigationOptions: navigationOptions },
};

const CommonStack = {
  Screens: Screens,
  Config: {
    defaultNavigationOptions: ({ navigation }) => ({
      headerTitleContainerStyle: {
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      headerStyle: {
        height: 48,
        borderBottomColor: Colors.border,
        borderBottomWidth: StyleSheet.hairlineWidth,
        elevation: 0,
        shadowColor: 'transparent',
      },
      headerMode: 'float',
      headerLeft: () => <NavHeaderLeft navigation={navigation} Screens={Screens} />,
      headerTitle: () => <NavHeaderTitle />,
      headerRight: () => <NavHeaderRight navigation={navigation} />,
    }),
  },
  navigationOptions: navigationOptions
};

export default CommonStack;
