import React from 'react';
import Layout from '../constants/Layout';
import { ScrollView, View } from 'react-native';
import CustomFlatlistMenuItem from './CustomFlatlistMenuItem';

class CustomFlatlistMenu extends React.Component{
  handleScrollViewRef = (ref) => {this.listRef = ref;};

  componentDidUpdate(prevProps) {
    if (prevProps.scrollToStart) {
      if (this.listRef) {
        this.listRef.scrollTo({x: 0,y: 0});
      }
    }
  }

  render() {
    return (
      (this.props.position != 'center' && Layout.isSmallDevice && this.props.menus.length > 2) ?
        <ScrollView
          ref={this.handleScrollViewRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{ flexDirection: 'row', paddingRight: 10 }}>
          {this.props.menus.map((menu, index) => {
            return (
              <CustomFlatlistMenuItem
                isSelected={menu.path === this.props.path && menu.type === this.props.type}
                key={index}
                menu={menu}
                onPressMenu={this.props.onPressMenu}
              />
            )
          })}
        </ScrollView>
        : (Layout.isSmallDevice && this.props.menus.length > 1) ?
        <View style={{
          flexDirection: 'row',
          justifyContent: 'center',
          paddingRight: 10
        }}>
          {this.props.menus.map((menu, index) => {
            return (
              <CustomFlatlistMenuItem
                isSelected={menu.path === this.props.path && menu.type === this.props.type}
                key={index}
                menu={menu}
                onPressMenu={this.props.onPressMenu}
              />
              )
          })}
        </View>
        :
        null


    );
  }
}

export default CustomFlatlistMenu;
