import React from 'react';
import MagazineScreenComponent from '../components/MagazineScreenComponent';

const MagazineScreen = (props) => {
  return (
    <MagazineScreenComponent
      {...props}
    />
  );
};

export default MagazineScreen;