import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';

export function shopGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.SHOP_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/v1.0/shops?range=50';
        if (payload.uid) {
          path += '&filter[user]=' + payload.uid;
        }
        if (payload.nids) {
          path = 'api/v1.0/shops/' + payload.nids;
        }
        return ({
          url: Config.BASE_URL + path,
          category: 'shop',
        });
      }
    );

  const response$ = sources.HTTP
    .select('shop')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data) {
          return actions.shopGetSuccess(data.data);
        }
        else
          return actions.shopGetFail(data);
      }catch (e) {
        return actions.shopGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

