import React from 'react';
import {CheckBox} from "react-native-elements";
import Colors from "../constants/Colors";
import {Icon} from "./Icon";
import * as Config from '../Config';
import Layout from "../constants/Layout";
import { View } from "react-native";
import Styles from '../constants/Styles';

const tabletLayout = Layout.window.width/2-2*Config.STANDARD_MARGIN;
const mobileLayout = Layout.window.width-2*Config.STANDARD_MARGIN;

const CustomCheckbox = (props) => {
  const containerWidth = props.checkboxWidth ? props.checkboxWidth : Config.IS_TABLET ? tabletLayout : mobileLayout;
  const titleWidth = props.titleWidth ? props.titleWidth : Config.IS_TABLET ? tabletLayout-35 : mobileLayout-35;
  return (
    <View style={{display: 'flex', flexDirection: 'row', paddingLeft: props.parentWidth }}>
      {props.color ? <View style={[Styles.colorBox, {backgroundColor: props.color}]}/> : null}
      <CheckBox
        checkedColor={Colors.tintColor}
        containerStyle={{
          backgroundColor: Colors.background,
          borderWidth: 0,
          alignSelf: 'center',
          width: containerWidth,
          maxWidth: containerWidth,
          minWidth: containerWidth,
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: props.paddingRight ? props.paddingRight : 0
        }}
        fontFamily={props.bold ? 'OpenSansBold' : 'OpenSansRegular'}
        textStyle={{
          textAlign: 'left',
          width: titleWidth,
          maxWidth: titleWidth,
          minWidth: titleWidth,
          paddingLeft: props.textPaddingLeft ? props.textPaddingLeft : 0,
          paddingRight: 0,
          color: Colors.darkText,
          fontWeight: props.bold ? 'bold' : 'normal',}}
        size={20}
        checkedIcon={<Icon name='checkbox_checked' color={Colors.tintColor} size={Config.FONT_SIZE_BIG} />}
        uncheckedIcon={<Icon name='checkbox_normal' color={Colors.border} size={Config.FONT_SIZE_BIG} />}
        {...props}
        iconRight={props.iconRight}
        right={props.right}
      />
    </View>
  );
};

export default CustomCheckbox;
