import React from 'react';
import { StyleSheet, View } from 'react-native';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from "react-navigation-stack";
import Colors from '../constants/Colors';
import FeedbackScreen from "../containers/FeedbackScreen";
import ForgotPasswordScreen from "../containers/ForgotPasswordScreen";
import PageScreen from "../containers/PageScreen";
import LoginScreen from "../containers/LoginScreen";
import NavHeaderLeft from '../components/header/NavHeaderLeft';
import NavHeaderRight from '../components/header/NavHeaderRight';
import NavHeaderTitle from '../components/header/NavHeaderTitle';
import RegisterEmailScreen from "../containers/RegisterEmailScreen";
import RegisterFacebookScreen from "../containers/RegisterFacebookScreen";
import RegisterScreen from "../containers/RegisterScreen";
import ResetScreen from '../containers/ResetScreen';
import RegisterAppleScreen from "../containers/RegisterAppleScreen";
import RegisterGoogleScreen from "../containers/RegisterGoogleScreen";
import CommonStack from '../navigation/CommonStack';

const Screens = {
  Login: { screen: LoginScreen, navigationOptions: CommonStack.navigationOptions },
  Page: { screen: PageScreen, navigationOptions: CommonStack.navigationOptions },
  Register: { screen: RegisterScreen, navigationOptions: CommonStack.navigationOptions },
  RegisterEmail: { screen: RegisterEmailScreen, navigationOptions: CommonStack.navigationOptions },
  RegisterFacebook: { screen: RegisterFacebookScreen, navigationOptions: CommonStack.navigationOptions },
  Reset: { screen: ResetScreen, navigationOptions: CommonStack.navigationOptions },
  ForgotPassword: { screen: ForgotPasswordScreen, navigationOptions: CommonStack.navigationOptions },
  Feedback: { screen: FeedbackScreen, navigationOptions: CommonStack.navigationOptions },
  RegisterApple: { screen: RegisterAppleScreen, navigationOptions: CommonStack.navigationOptions },
  RegisterGoogle: { screen: RegisterGoogleScreen, navigationOptions: CommonStack.navigationOptions },
};

// Remove Login from the screens that should show a back button.
const ScreensWithBackButton = { ...Screens };
delete ScreensWithBackButton.Login;

export default createAppContainer(createStackNavigator(
  Screens,
  {
    defaultNavigationOptions: ({ navigation }) => ({
      headerTitleContainerStyle: {
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      },
      headerStyle: {
        height: 48,
        borderBottomColor: Colors.border,
        borderBottomWidth: StyleSheet.hairlineWidth,
        elevation: 0,
        shadowColor: 'transparent',
      },
      headerMode: 'float',
      headerLeft: () => <NavHeaderLeft navigation={navigation} Screens={ScreensWithBackButton} />,
      headerTitle: () => <NavHeaderTitle />,
      headerRight: () => <NavHeaderRight navigation={navigation} />,
    })}
));
