import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Styles from "../../constants/Styles";
import * as Config from '../../Config';
class Autocomplete extends Component {
  static propTypes = {
    ...TextInput.propTypes,
    /**
     * These styles will be applied to the container which
     * surrounds the autocomplete component.
     */
    containerStyle: PropTypes.shape({
      style: PropTypes.any,
    }),
    /**
     * Assign an array of data objects which should be
     * rendered in respect to the entered text.
     */
    data: PropTypes.array,
    /**
     * Set to `true` to hide the suggestion list.
     */
    hideResults: PropTypes.bool,
    /*
     * These styles will be applied to the container which surrounds
     * the textInput component.
     */
    inputContainerStyle: PropTypes.shape({
      style: PropTypes.any,
    }),
    /*
     * Set `keyboardShouldPersistTaps` to true if RN version is <= 0.39.
     */
    keyboardShouldPersistTaps: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    /*
     * These styles will be applied to the container which surrounds
     * the result list.
     */
    listContainerStyle: PropTypes.shape({
      style: PropTypes.any,
    }),
    /**
     * These style will be applied to the result list.
     */
    listStyle: PropTypes.shape({
      style: PropTypes.any,
    }),
    // listStyle: {},
    /**
     * `onShowResults` will be called when list is going to
     * show/hide results.
     */
    onShowResults: PropTypes.func,
    /**
     * method for intercepting swipe on ListView. Used for ScrollView support on Android
     */
    onStartShouldSetResponderCapture: PropTypes.func,
    /**
     * `renderItem` will be called to render the data objects
     * which will be displayed in the result view below the
     * text input.
     */
    renderItem: PropTypes.func,
    /**
     * `renderSeparator` will be called to render the list separators
     * which will be displayed between the list elements in the result view
     * below the text input.
     */
    renderSeparator: PropTypes.func,
    /**
     * renders custom TextInput. All props passed to this function.
     */
    renderTextInput: PropTypes.func
  };

  static defaultProps = {
    data: [],
    defaultValue: '',
    keyboardShouldPersistTaps: 'always',
    onStartShouldSetResponderCapture: () => false,
    renderItem: rowData => <Text>{rowData}</Text>,
    renderSeparator: null,
    renderTextInput: props => <TextInput {...props} />
  };

  constructor(props) {
    super(props);

    this.state = { inputFocused: false };
    this.resultList = null;
  }

  /**
   * Proxy `blur()` to autocomplete's text input.
   */
  blur() {
    const { textInput } = this;
    textInput && textInput.blur();
  }

  /**
   * Proxy `focus()` to autocomplete's text input.
   */
  focus() {
    const { textInput } = this;
    textInput && textInput.focus();
  }

  renderResultList() {
    const { data } = this.props;
    const {
      listStyle,
      renderItem,
      renderSeparator,
      keyboardShouldPersistTaps,
      onEndReached,
      onEndReachedThreshold
    } = this.props;

    return (
      <FlatList
        ref={(resultList) => { this.resultList = resultList; }}
        data={data}
        keyboardShouldPersistTaps={keyboardShouldPersistTaps}
        renderItem={({item}) => renderItem(item)}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        style={[styles.list, listStyle]}
      />
    );
  }

  renderTextInput = () => {
    const { onEndEditing, renderTextInput, style } = this.props;
    const props = {
      style: [styles.input, style],
      ref: ref => (this.textInput = ref),
      onEndEditing: e => onEndEditing && onEndEditing(e),
      ...this.props,
      value: this.props.value===''?null:this.props.value,
    };

    return <TextInput
      onFocus={() => this.setState({inputFocused: true})}
      onBlur={() => this.setState({inputFocused: false})}
      {...props}
      placeholder={this.state.inputFocused?null:props.placeholder}
      placeholderStyle={{
        fontFamily: 'OpenSansRegular',
        alignSelf: 'center',
      }}
      // placeholderTextColor={Colors.BLACK}
      style={[props.style,
      {
        fontFamily: 'OpenSansRegular',
        // backgroundColor: 'white',
        // height: 40,
        // paddingLeft: 3,
        flex: 1,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        backgroundColor: '#fff',
        color: '#424242',
        minWidth: 30,
        width: (!this.state.inputFocused&&this.props.tagsSelected.length===0)?200:'auto',
      }
      ]}
      // minWidth={30}
      // textWrap={'noWrap'}
      // width={(!this.state.inputFocused&&this.props.tagsSelected.length===0)?200:'auto'}
    />;
  };

  render() {
    const { data } = this.props;
    const {
      containerStyle,
      hideResults,
      inputContainerStyle,
      listContainerStyle,
      onShowResults,
      onStartShouldSetResponderCapture
    } = this.props;
    const showResults = data.length > 0;

    // Notify listener if the suggestion will be shown.
    onShowResults && onShowResults(showResults);
    return (
      <View style={[styles.container, containerStyle]}>
        {!this.props.placeholder || this.state.inputFocused?
          <Text style={{fontSize: Config.FONT_SIZE_NORMAL, fontFamily: 'OpenSansBold', marginBottom: 5}}>{this.props.label}</Text>:
          <Text style={{fontSize: Config.FONT_SIZE_NORMAL, fontFamily: 'OpenSansBold', marginBottom: 5}}> </Text>}
        <View style={[styles.inputContainer, inputContainerStyle]}>
          {this.props.tagsSelected &&
          this.props.renderTags()}
          {this.renderTextInput()}
        </View>
        {!showResults && (<Text style={Styles.helpText}>{this.props.helpText}</Text>)}
        {!hideResults && (
          <View
            style={listContainerStyle}
            onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
          >
            {showResults && this.renderResultList()}
          </View>
        )}
      </View>
    );
  }
}

const border = {
  borderColor: '#b9b9b9',
  borderRadius: 1,
  borderWidth: 1
};

const androidStyles = {
  container: {
    flex: 1
  },
  inputContainer: {
    ...border,
    marginBottom: 0
  },
  list: {
    ...border,
    backgroundColor: 'white',
    borderTopWidth: 0,
    margin: 10,
    marginTop: 0
  }
};

const iosStyles = {
  container: {
    zIndex: 1
  },
  inputContainer: {
    ...border
  },
  input: {
    backgroundColor: 'white',
    height: 40,
    paddingLeft: 3
  },
  list: {
    ...border,
    backgroundColor: 'white',
    borderTopWidth: 0,
    left: 0,
    position: 'absolute',
    right: 0
  }
};

const styles = StyleSheet.create({
  input: {
    // backgroundColor: 'white',
    height: 40,
    // paddingLeft: 3,
    flex: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#424242',
  },
  ...Platform.select({
    android: { ...androidStyles },
    ios: { ...iosStyles }
  })
});

export default Autocomplete;
