import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  clearUploadedImage,
  createTag, fileUpload,
  getTagByName,
  nodeSave,
  tagsGet,
  usersGetMentioning, whoAmI
} from '../actions/index';
import ImageUploadScreenComponent from '../components/ImageUploadScreenComponent';
import RegisterScreen from '../containers/RegisterScreen';

class ImageUploadScreen extends Component {

  render() {
    if (!this.props.logInState.uid) {
      return (
        <RegisterScreen navigation={this.props.navigation}/>
      );
    }
    return (
      <ImageUploadScreenComponent
        clearUploadedImage={this.props.clearUploadedImage}
        creatingTag={this.props.creatingTag}
        createTag = {this.props.createTag}
        fileUpload={this.props.fileUpload}
        getTagByName = {this.props.getTagByName}
        gettingTag={this.props.gettingTag}
        logInState={this.props.logInState}
        navigation={this.props.navigation}
        newUploadedImage = {this.props.newUploadedImage}
        newTags = {this.props.newTags}
        nodeSave={this.props.nodeSave}
        tags={this.props.tags}
        tagsGet={this.props.tagsGet}
        usersMentioning={this.props.usersMentioning}
        usersGetMentioning={this.props.usersGetMentioning}
        upload={this.props.upload}
        isOffline={this.props.isOffline}
        whoAmI={this.props.whoAmI}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.images.isLoading,
    logInState: state.user,
    upload: state.images.upload[1],
    tags: state.tags.tags,
    usersMentioning: state.users.usersMentioning,
    newUploadedImage: state.images.newUploadedImage,
    newTags: state.tags.newTags,
    creatingTag: state.tags.creatingTag,
    gettingTag: state.tags.gettingTag,
    isOffline: state.device.isOffline
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    nodeSave: (payload) => {
      dispatch(nodeSave(payload))
    },
    tagsGet: (payload) => {
      dispatch(tagsGet(payload))
    },
    usersGetMentioning: (payload) => {
      dispatch(usersGetMentioning(payload))
    },
    createTag: (payload) => {
      dispatch(createTag(payload))
    },
    getTagByName: (payload) => {
      dispatch(getTagByName(payload))
    },
    clearUploadedImage: (payload) => {
      dispatch(clearUploadedImage(payload))
    },
    fileUpload: (payload) => {
      dispatch(fileUpload(payload))
    },
    whoAmI: (payload) => {
      dispatch(whoAmI(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadScreen);
