import React, { Component } from 'react';
import AddressBookScreenComponent from "../components/shopping/AddressBookScreenComponent";
import {addressDelete, addressUpdate, getBillingAddress, getShippingAddress, profileSetDefault} from "../actions";
import {connect} from "react-redux";


class AddressBookScreen extends Component {

  render() {
    return (
      <AddressBookScreenComponent
        addressDelete={this.props.addressDelete}
        billingAddresses={this.props.billingAddresses}
        getBillingAddress={this.props.getBillingAddress}
        getShippingAddress={this.props.getShippingAddress}
        navigation={this.props.navigation}
        shippingAddresses={this.props.shippingAddresses}
        addressUpdate={this.props.addressUpdate}
        profileSetDefault={this.props.profileSetDefault}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    billingAddresses: state.address.billingAddresses.map(id => state.address.addresses[id]),
    shippingAddresses: state.address.shippingAddresses.map(id => state.address.addresses[id]),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addressDelete: (payload) => {
      dispatch(addressDelete(payload))
    },
    getShippingAddress: (payload) => {
      dispatch(getShippingAddress(payload))
    },
    getBillingAddress: (payload) => {
      dispatch(getBillingAddress(payload))
    },
    addressUpdate: (payload) => {
      dispatch(addressUpdate(payload))
    },
    profileSetDefault: (payload) => {
      dispatch(profileSetDefault(payload))
    }

  }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddressBookScreen);
