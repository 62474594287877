import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Linking } from 'react-native';
import Styles from "../../constants/Styles";
import { Thumbnail } from "../../components/Thumbnail";
import InputSpinner from "react-native-input-spinner";
import Tooltip from 'react-native-walkthrough-tooltip';
import { themes } from "../../constants/variationThemes";
import Colors from "../../constants/Colors";
import DiscountItem from './DiscountItem';

class LineItem extends Component {

  state = {
    toolTipVisible: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.isLoadingQuantity !== prevProps.isLoadingQuantity) && !this.props.isLoadingQuantity) {
      setTimeout(() => this.props.cartGet(), 1000)
    }
  }

  navigateToPdp = () => {
    const { item } = this.props;
    this.props.navigation.navigate({
      routeName: 'NodeSingle',
      key: `node${item.id}`,
      params: {
        id: item.parentDBS ?? item.parentSLI,
        productId: item.id,
      }
    });
  }

  render () {
    const { item } = this.props;
    const color = item.delivery_time_id == 262701 ? '#54a76b' : '#000000';
    const data = themes
      .filter(theme => item[theme.key])
      .map(theme => {
        return ({
          label: theme.label,
          value: item[theme.key]
        })
      })
    return (
      <View style={[Styles.itemContainer]}>
        <View style={{ flexDirection: 'row'}}>
          <TouchableOpacity onPress={this.navigateToPdp}>
            <Thumbnail
              size="large"
              source={{uri: item.images[0].styles['s2_slide_sm_crop']}} // TODO Replace with style 150x150_crop
              type="square"
            />
          </TouchableOpacity>
          <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <TouchableOpacity onPress={this.navigateToPdp}>
              <Text style={[Styles.gridTitle, {textAlign: 'left', paddingBottom: 4}]}>{item.label}</Text>
            </TouchableOpacity>
            <Text style={{paddingTop: 4, paddingBottom: 4, fontSize: 14, color: '#818181'}}>Artikelnummer: {item.sku}</Text>
            { data.length > 0 && <Text style={{paddingVertical: 4}}>
              {data.map((item, index, {length}) => {
                return (
                  <Text key={index}>{item.label}: {item.value} {!(index + 1 === length) && '|'} </Text>
                )
              })}
            </Text>}
            <View style={{ display: 'flex', flexDirection: 'row', paddingVertical: 4}}>
              <View style={[Styles.circle, {backgroundColor: color}]} /><Text>{item.delivery_time_label}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', paddingVertical: 4 }}>
              <Tooltip
                isVisible={this.state.toolTipVisible}
                content={
                  <View>
                    <Text style={{fontWeight: 'bold', paddingBottom: 5}}>Mathes Design GmbH</Text>
                    <Text style={{paddingBottom: 3}}>Büchel 29-31</Text>
                    <Text style={{paddingBottom: 3}}>52062 Aachen</Text>
                    <Text style={{paddingBottom: 10}}>DE</Text>
                    <TouchableOpacity onPress={() => { Linking.openURL('https://www.design-bestseller.de/widerrufsrecht/') }}><Text style={[Styles.modalContentLink]}>Widerruf &amp; Rückgabe</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => { Linking.openURL('https://www.design-bestseller.de/agb/') }}><Text style={[Styles.modalContentLink]}>AGB</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => { Linking.openURL('https://www.design-bestseller.de/datenschutz/') }}><Text style={[Styles.modalContentLink]}>Datenschutz</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => { Linking.openURL('https://www.design-bestseller.de/impressum/') }}><Text style={[Styles.modalContentLink]}>Shop Impressum</Text></TouchableOpacity>
                  </View>
                }
                placement="bottom"
                onClose={() => this.setState({ toolTipVisible: false })}
              >
                <TouchableOpacity onPress={() => this.setState({ toolTipVisible: true })} style={{height: 35}}>
                  <Text style={Styles.info}>i</Text>
                </TouchableOpacity>
              </Tooltip>
            <Text style={{color: '#818181'}}>Verkauf & Versand durch <Text style={{fontWeight: 'bold'}}>design-bestseller.de </Text></Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
              { item.on_sale == true && <Text style={{textDecorationLine: 'line-through', paddingRight: 10, color: '#a9a9a9' }}>€ {item.original_price}</Text>}
              <Text>{item.current_price} €</Text>
            </View>
            { item.discount.map(discount => (
              <DiscountItem
                color={Colors.onSaleRed}
                label={discount.label}
                price={discount.price}
              />
            ))}
          </View>
        </View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            { this.props.isCart ?
              <View style={{ display: 'flex', flexDirection: 'row'}}>
                <InputSpinner
                  min={1}
                  step={1}
                  value={item.quantity}
                  onChange={(num) => {this.props.alterQuantity({lineItemId: item.lineItemId, quantity: num})}}
                  rounded={false}
                  showBorder={true}
                  buttonTextColor={'#000000'}
                  color={'#ffffff'}
                  buttonStyle={{borderWidth: 1, borderColor: '#d1d1d1', height: 39}}
                  inputStyle={{borderTopWidth: 1, borderBottomWidth: 1, borderColor: '#d1d1d1', minWidth: 39}}
                  height={39}
                />
                <TouchableOpacity
                  onPress={() => this.props.removeFromCart({orderNumber: this.props.orderNumber, lineItemId: item.lineItemId})}
                  style={[Styles.greyButtonContainer]}>
                  <Text>Entfernen</Text>
                </TouchableOpacity>
              </View> :
              <View>
                <Text>Anzahl: {item.quantity}</Text>
              </View>
            }
          <View style={{alignSelf: 'center'}}><Text style={{fontWeight: 'bold', fontSize: 17}}> {item.price_total} €</Text></View>
        </View>
      </View>
    )
  }
}

export default LineItem;
