import moment from 'moment';
import { getItemAsync, setItemAsync } from 'expo-secure-store';
import { requestReview } from 'expo-store-review';
import * as Config from '../Config';
import { Alert } from 'react-native';

export async function startRateFlow(navigation, user) {
  const userDidRate = await retrieveData('userDidRate');
  if (!userDidRate) {
    const userCanRate = await getUserContent(user);
    if (userCanRate) {
      const denyRateDate = await retrieveData('denyRateDate');
      const denyRateDateObj = moment(denyRateDate, 'ddd MMM DD YYYY HH:mm:ss');
      if (!denyRateDate || moment(new Date()).diff(moment(denyRateDateObj), 'days') > 14) {
        Alert.alert(
          'Gefällt dir die SoLebIch App?',
          '',
          [
            {
              text: 'Nein',
              onPress: () => showFeedbackAlertBox(navigation)
            },
            {
              text: 'Ja',
              onPress: () => showRatingAlertBox()
            },
          ],
          {
            cancelable: false
          },
        );
      }
    }
  }
}

async function storeData(key, value) {
  await setItemAsync(key, value);
};

async function retrieveData(key){
  const value = await getItemAsync(key);
  if (value !== null) {
    return value;
  } else {
    return false
  }
};

async function getUserContent(user) {
  let response = await fetch(
    Config.BASE_URL + '/api/v1.0/nodes?sort=-id&view_path=user/' + user.id + '&view_id=user_fluid_layouts&display_id=page_3&range=16',
  );
  let responseJson = await response.json();
  return responseJson.data.length >= 2;
};


function showFeedbackAlertBox(navigation) {
  Alert.alert(
    'Würdest du uns verraten, was wir besser machen können?',
    '',
    [
      {
        text: 'Nein',
        onPress: async () => {
          const date = moment(new Date()).toString();
          storeData('denyRateDate', date);
        }
      },
      {
        text: 'Ja, gerne',
        onPress: () => {
          storeData('userDidRate', 'true');
          navigation.navigate('Feedback')
        }
      },
    ],
    {
      cancelable: false
    },
  );
}

function showRatingAlertBox() {
  Alert.alert(
    'Wir würden uns über eine Bewertung von dir freuen!',
    '',
    [
      {
        text: 'Nein',
        onPress: async () => {
          const date = moment(new Date()).toString();
          storeData('denyRateDate', date);
        }
      },
      {
        text: 'Ja, gerne',
        onPress: async () => {
          storeData('userDidRate', 'true');
          requestReview();
        }
      },
    ],
    {
      cancelable: false
    },
  );
}
