import {Platform} from "react-native";
import * as Device from "expo-device";

export const AdForbiddenScreens = [
  'Camera',
  'Cart',
  'Checkout',
  'Delivery',
  'Payment',
  'Order',
  'ThankYou',
  'Me',
  'UserEdit',
  'Login',
  'Register',
  'RegisterEmail',
  'RegisterFacebook',
  'Reset',
  'ForgotPassword',
  'RegisterApple'
];

export const ScreensBeforeResettingCounter = 30;

export const AdsPerDay = 2;

const interstitialTestID = Platform.OS === 'ios' ? 'ca-app-pub-3940256099942544/4411468910' : 'ca-app-pub-3940256099942544/1033173712';
const interstitialProductionID = Platform.OS === 'ios' ? 'ca-app-pub-8731332961280460/1364498078' : 'ca-app-pub-8731332961280460/3181579413';
const blockTestID = Platform.OS === 'ios' ? 'ca-app-pub-3940256099942544/2934735716' : 'ca-app-pub-3940256099942544/6300978111';
const blockProductionID = Platform.OS === 'ios' ? 'ca-app-pub-8731332961280460/3795220297' : 'ca-app-pub-8731332961280460/6373567586';
// Is a real device and running in production.
export const interstitialAdUnitID = Device.isDevice && !__DEV__ ? interstitialProductionID : interstitialTestID;
export const blockAdUnitID = Device.isDevice && !__DEV__ ? blockProductionID : blockTestID;
