import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import flattenComment from "./helpers/flattenComment";

const initialState = {
  comments: {},
  commentsById: [],
  isLoadingComments: false,
  isDeletingCommentId: 0,
  isEditingComment: false,
  isSendingComment: false,
};

function likeComment(state, action) {
  const { comment, op } = action.payload;
  const commentLikes = parseInt(comment.likes);

  return {
    ...state,
    comments: {
      ...state.comments,
      [comment.id]: {
        ...state.comments[comment.id],
        likes: op === 'flag' ? commentLikes + 1 : commentLikes - 1,
        liked: op === 'flag'
      }
    },
  };
}

function commentsGet(state) {
  return {
    ...state,
    isLoadingComments: true,
  };
}

function commentsGetSuccess(state, action) {
  try {
    let commentsInState = Object.assign({}, state.comments);
    const commentsIdsInState = state.commentsById.slice();

    action.payload.data.map((comment) => {
      commentsInState = {
        ...commentsInState,
        [comment.id]: {
          ...commentsInState[comment.id],
          ...flattenComment(comment),
        },
      };
      if (!commentsIdsInState.includes(comment.id)) {
        commentsIdsInState.push(comment.id);
      }
    });

    return {
      ...state,
      isLoadingComments: false,
      comments: commentsInState,
      commentsById: commentsIdsInState,
    }
  } catch (e) {
    return {
      ...state,
      isLoadingComments: false,
    }
  }
}

function commentsGetFail(state) {
  return {
    ...state,
    isLoadingComments: false,
  };
}

function commentSet(state) {
  return {
    ...state,
    isSendingComment: true,
  };
}

function commentSetSuccess(state) {
  return {
    ...state,
    isSendingComment: false,
  }
}

function commentSetFail(state) {
  return {
    ...state,
    isSendingComment: false,
  };
}

function commentDelete(state, action) {
  return {
    ...state,
    isDeletingCommentId: action.payload.id,
  };
}

function commentDeleteSuccess(state, action) {
    let commentId = parseInt(action.payload[0].request.send.id);
    let { [commentId]: omit, ...updatedComments } = state.comments;
    const commentsIdsInState = state.commentsById.slice();
    return {
      ...state,
      isDeletingCommentId: 0,
      comments: updatedComments,
      commentsById: commentsIdsInState.filter(comment => comment !== commentId)
    };
  }

function entityUpdate(state, action) {
  if (action.payload.self.includes('comments')) {
    return {
      ...state,
      isEditingComment: true,
    }
  }
  return state;
}

function entityUpdateSuccess(state, action) {
  if (action.payload[0].self.includes('comments')) {
    let commentId = action.payload[0].id;
    return {
      ...state,
      isEditingComment: false,
      comments: {
        ...state.comments,
        [commentId]: {
          ...state.comments[commentId],
          ...flattenComment(action.payload[0]),
        }
      },
    };
  }
  return state;
}

/**
 * If an error was received cancel any interactive action that could be in progress.
 * Otherwise the user might find herself with an endless spinner.
 */
function errorReceived(state) {
  return {
    ...state,
    isLoadingComments: false,
    isDeletingCommentId: 0,
    isEditingComment: false,
    isSendingComment: false,
  };
}

const comments = createReducer(initialState, {
  [ActionTypes.LIKE_COMMENT]: likeComment,
  [ActionTypes.COMMENTS_GET]: commentsGet,
  [ActionTypes.COMMENTS_GET_SUCCESS]: commentsGetSuccess,
  [ActionTypes.COMMENTS_GET_FAIL]: commentsGetFail,
  [ActionTypes.COMMENT_SET]: commentSet,
  [ActionTypes.COMMENT_SET_SUCCESS]: commentSetSuccess,
  [ActionTypes.COMMENT_SET_FAIL]: commentSetFail,
  [ActionTypes.COMMENT_DELETE]: commentDelete,
  [ActionTypes.COMMENT_DELETE_SUCCESS]: commentDeleteSuccess,
  [ActionTypes.ENTITY_UPDATE]: entityUpdate,
  [ActionTypes.ENTITY_UPDATE_SUCCESS]: entityUpdateSuccess,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
});

export default comments;
