import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import Styles from '../constants/Styles';

class CustomFlatlistMenuItem extends React.Component {
  onPressItem = () => {
    this.props.onPressMenu(this.props.menu);
  };
  render() {
    return (
      <TouchableOpacity
        onPress={this.onPressItem}
        style={this.props.isSelected ? Styles.greyButtonContainerActive : Styles.greyButtonContainer }
      >
        <Text style={Styles.pinkButtonText}>{this.props.menu.title}</Text>
      </TouchableOpacity>

    );
  }
}

export default CustomFlatlistMenuItem;
