var URL = require('url-parse');

/**
 * Gets a URL and tries to indentiy a drupal internal entity type and id.
 *
 * url: can be any valid url
 *
 * returns an object with the following properties.
 *
 */
export function url_to_id (url) {

  var url = new URL(url, 'https://www.solebich.de');
  var path = url.pathname.split('/');

  // if a simple path there is nothing we can do
  if (path.length <= 2) return false;

  // if path's last part is an integer URL is a node
  if (isNumeric(path.slice(-1).pop())) {
    url.entity_type = 'node';
    url.id = path.slice(-1).pop()

    // return answers for node type that we do support/show in the app
    switch(path[1]) {
      case 'bild':
        url.type = 'image';
        break;
      case 'shopping':
        url.type = 'product';
        break;
      case 'wohnmagazin':
        url.type = 'article';
        break;
    }
  }
  else {
    // other than nodes
    switch(path[1]) {
      // support users
      case 'mitglied':
        url.entity_type = 'user';
        url.label = path[2];
        break;
      // support terms
      case 'wohnen':
      case 'tag':
        url.entity_type = 'term';
        url.label = path[2];
        break;
    }
  }
  // return 'url' object with the extra info
  return url;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function concatObjectOfArrays(object) {
  // if an object with at least one property move on
  if (object && object[1]) {
    // messages for eact subcategory is an object of arrays
    // each key is numeric like 0,1,2,3,4...n
    // each array is a page of messages
    const pages = Object.keys(object).map((key) => object[key]);
    // now concat an array of arrays
    return [].concat.apply([], pages);
  }
}

function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}
