import { priceFormat } from '../../helpers/formatNumbers'

const flattenDiscount = (obj) => {
  return {
    label: obj.label ?? undefined,
    price: priceFormat(obj.price) ?? undefined,
  }
};

export default flattenDiscount;
