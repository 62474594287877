import React from 'react';
import Styles from '../../constants/Styles';
import {TouchableOpacity, View, Image} from "react-native";
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from "react-native-simple-radio-button";
import {Icon} from "../Icon";
import { paymentMethodsKeyed } from "../../constants/paymentMethods"

const CustomRadioForm = ({radio_props, selectedProp, setSelectedProp, navigation, editButton, payment}) => (
  <RadioForm>
    {
      radio_props.map((obj, i) => (
        <View key={i} style={[Styles.checkoutBlocks, {paddingTop: 15}]}>
          <View style={editButton ? Styles.textNextToIcon : ''}>
            <RadioButton labelHorizontal={true} key={i} >
              <RadioButtonInput
                obj={obj}
                index={i}
                isSelected={selectedProp === obj.value}
                onPress={() => setSelectedProp(obj.value)}
                borderWidth={8}
                buttonInnerColor={'#ffffff'}
                buttonOuterColor={selectedProp === obj.value ? '#707070' : '#d1d1d1'}
                buttonSize={10}
                buttonOuterSize={25}
              />
              <RadioButtonLabel
                obj={obj}
                index={i}
                labelHorizontal={true}
                onPress={() => setSelectedProp(obj.value)}
                labelStyle={Styles.radioButtonLabel}
              />
            </RadioButton>
          </View>
          { editButton ? <TouchableOpacity onPress={() => navigation.navigate('AddressForm', {id: obj.value})} style={Styles.iconNextToText}>
            <Icon
              name='pencil'
              size={20}
              color='#818181'
            />
            </TouchableOpacity> : null }
          { payment ? <Image style={{position: 'absolute', top: 5, right: 0}}
               source={paymentMethodsKeyed[obj.value].image}  />
            : null }
        </View>
      ))
    }
  </RadioForm>
)

export default CustomRadioForm;
