import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-native-elements';

import Styles from '../../constants/Styles';
import Colors from '../../constants/Colors';

const CustomButton = (props) => (
  <Button
    title={props.title}
    onPress={props.onPress}
    buttonStyle={[Styles.regularButton, Styles.button, props.buttonStyle]}
    titleStyle={[Styles.regularButtonTitle, {fontFamily: props.fontFamily ?? 'OpenSansBold'}]}
    disabledTitleStyle={{color: Colors.darkText, fontWeight: 'bold'}}
    raised={false}
    color={Colors.darkText}
    fontWeight='bold'
    loading={props.loading}
    disabled={props.isOffline || props.disabled}
    disabledStyle={Styles.regularButtonGreyBorder}
    loadingProps={{ color: Colors.iconLightGrey }}
  />

);

const mapStateToProps = (state) => {
  return {
    isOffline: state.device.isOffline,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{}
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
