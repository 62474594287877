import React from 'react';
import {View, Text, TouchableOpacity} from "react-native";
import {Icon} from "../Icon";
import Styles from "../../constants/Styles";


const ShopFooter = ({ navigation }) => {
  return (
    <View>
      <Text style={[Styles.shopFooterTitle]}>Beratung und Kundenservice</Text>
      <View style={{display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center'}}>
        <Icon
          name='mobile'
          size={50}
          color='#818181'
          style={{padding: 10, paddingLeft: 0}}
        />
        <View>
          <Text style={[Styles.shopFooterText, {fontSize: 16}]}>+49 89 12085485 </Text>
          <Text style={[Styles.shopFooterText]}>(Mo. - Fr. 8:30 - 18:00 Uhr)</Text>
          <Text style={[Styles.shopFooterText]}>shop@solebich.de</Text>
        </View>
      </View>
      <Text style={[Styles.shopFooterTitle]}>Deine Vorteile</Text>
      <View style={{marginBottom: 10}}>
        <View>
          <View style={[Styles.shopFooterLine]}><Icon name='small_check' size={15} color={Styles.shopFooterText.color} style={{paddingRight: 5}}/><Text style={[Styles.shopFooterText]}>kostenloser DHL-Versand ab 80,- €</Text></View>
          <View style={[Styles.shopFooterLine]}><Icon name='small_check' size={15} color={Styles.shopFooterText.color} style={{paddingRight: 5}}/><Text style={[Styles.shopFooterText]}>‌kostenloser Speditionsversand ab 80,- €</Text></View>
          <View style={[Styles.shopFooterLine]}><Icon name='small_check' size={15} color={Styles.shopFooterText.color} style={{paddingRight: 5}}/><Text style={[Styles.shopFooterText]}>‌kostenlose DHL-Retoure</Text></View>
          <View style={[Styles.shopFooterLine]}><Icon name='small_check' size={15} color={Styles.shopFooterText.color} style={{paddingRight: 5}}/><Text style={[Styles.shopFooterText]}>‌kompetente Beratung und Service</Text></View>
        </View>
      </View>
      <View style={{marginBottom: 10}}>
        <Text style={[Styles.shopFooterTitle]}>Hilfe & Links</Text>
        <View>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 4988005})} }>
            <Text style={[Styles.shopFooterText]}>BESTELLUNG & VERSAND</Text>
          </TouchableOpacity>
          <View style={{borderBottomWidth: 1, borderBottomColor: '#d1d1d1', marginBottom: 5}}>
            <TouchableOpacity
              onPress={() => { navigation.navigate('Page', {pageId: 5358784})} }>
              <Text style={[Styles.shopFooterText]}>RETOURE</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 5358785})} }>
            <Text style={[Styles.shopFooterText]}>AGB</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 5358786})} }>
            <Text style={[Styles.shopFooterText]}>DATENSCHUTZ</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { navigation.navigate('Page', {pageId: 4427982})} }>
            <Text style={[Styles.shopFooterText]}>IMPRESSUM</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default ShopFooter;
