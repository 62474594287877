import React, { Component } from 'react';
import {Alert, Keyboard, ScrollView, View} from 'react-native';
import CustomTextInput from "../CustomTextInput";
import Styles from "../../constants/Styles";
import CustomButton from "../general/CustomButton";
import {AddressFields} from "../../constants/addressFields"
import CountryPicker from 'react-native-country-picker-modal'

class AddressFormScreenComponent extends Component {

  state = {
    id: '',
    email: this.props.email,
    first_name: '',
    last_name: '',
    street: '',
    premise: '',
    postal_code: '',
    locality: '',
    country: 'DE',
    phone: '',
    type: this.props.navigation.getParam('type', 'shipping')
  }

  constructor(props) {
    super(props);
    this.phoneRef = React.createRef();
  }

  componentDidMount() {
    const { navigation, shippingAddresses, billingAddresses } = this.props;
    const id = navigation.getParam('id', false)
    if (id) {
      // Merge shippingAddresses and billingAddresses into one array in order to filter the mergedArray using the address id
      const addresses = [...shippingAddresses, ...billingAddresses];
      const address = addresses.filter(obj => obj.id === id).pop();
      this.setState({ ...address });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.navigation.isFocused() && this.props.addressSave !== prevProps.addressSave) {
      this.props.navigation.goBack();
    }
  }

    render() {
    return (
      <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
        <ScrollView
          contentContainerStyle={Styles.pageScrollViewContainer}
          keyboardShouldPersistTaps={"handled"}
          style={{marginBottom: 60}}
        >
          {AddressFields.map(obj => {
            switch (obj.value) {
              case 'country':
                return (
                  <CountryPicker
                    translation={'deu'}
                    countryCodes={['DE', 'AT']}
                    countryCode={this.state.country}
                    withCountryNameButton
                    onSelect={(country) => this.setState({country: country.cca2})}
                    containerButtonStyle={[Styles.inputOneLineText, {marginTop: 25}]}
                    withFlagButton={false}
                  />
                )
              default:
                return (
                  <CustomTextInput
                    label={obj.label + (obj.required ? ' *' : '')}
                    value={this.state[obj.value]}
                    onChangeText={(item) => this.setState({ [obj.value]: item})}
                    returnKeyType='done'
                    blurOnSubmit={true}
                    onSubmitEditing={(event) => {
                      Keyboard.dismiss();
                    }}
                  />
                )
            }})
          }
        </ScrollView>
        <View style={Styles.shopButtonWrap}>
          <CustomButton
            title="Speichern"
            onPress={this.onPressSave}
            buttonStyle={Styles.shopButton}
            fontFamily={"OpenSansRegular"}
          />
        </View>
      </View>
    )
  }

  /**
   * When press is saved:
   * - Notify the backend.
   * - Go one screen back.
   */
  onPressSave = () => {
    // If email has changed update the order.
    // this.props.orderUpdate();
    // If any required fields are missing, notify the user about which of them do not have a value
    const fields = [];
    AddressFields.filter(obj => obj.required).map(obj => {
      if (!this.state[obj.value]) {
        fields.push(obj.label)
      }
    })
    if (fields.length) {
      const message = fields.length === 1 ? 'Das Feld '+ fields.toString() + ' ist erforderlich.' : 'Felder mit (*) sind erforderlich.'
      Alert.alert(
        '',
        message,
        [
          {
            text: 'Okay',
            onPress: () =>  {},
            style: 'cancel',
          },
        ],
        {
          cancelable: false
        },
      );
    } else {
      const id = this.props.navigation.getParam('id', false)
      if (id) {
        // If you are on address edit, update the address
        this.props.addressUpdate({data: this.state, token: this.props.logInState.token});
      } else {
        // If you are on address add, create an address.
        this.props.addressCreate({data: this.state, token: this.props.logInState.token});
      }
    }
  }
}

export default AddressFormScreenComponent;
