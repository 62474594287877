import React, {Component} from 'react';
import {
  Alert,
  Image,
  Keyboard,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import * as Permissions from 'expo-permissions';

import CustomButton from "./general/CustomButton";
import * as Config from '../Config';
import Colors from '../constants/Colors';
import Layout from "../constants/Layout";
import Styles from '../constants/Styles';
import CustomIndicator from '../components/CustomIndicator';
import CustomMentionsTextInput from "./CustomMentionsTextInput";
import { chooseFileMethod, rotateImage } from '../helpers/images';

export default class CommentForm extends Component {

  state = {
    text: this.props.initialText ? this.props.initialText : '',
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.commentsById.length !== nextProps.commentsById.length
      || this.props.isLoading !== nextProps.isLoading
      || this.props.isDeletingCommentId !== nextProps.isDeletingCommentId
      || this.props.isSendingComment !== nextProps.isSendingComment
      || this.props.isEditingComment !== nextProps.isEditingComment
      || this.state.text !== nextState.text
      || this.props.usersMentioning !== nextProps.usersMentioning
      || this.props.upload !== nextProps.upload
      || this.props.autoFocus !== nextProps.autoFocus
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (!this.props.logInState.uid) {
      return (
        <View style={{marginBottom: 20}}>
          <CustomButton
            title={"Melde dich jetzt an und hinterlasse einen Kommentar"}
            onPress={() => this.props.navigation.navigate('Register')}
          />
        </View>
      );
    }
    return (
      <View keyboardShouldPersistTaps={'always'} style={{ backgroundColor: Colors.background }} >
        <CustomMentionsTextInput
          label='Kommentar schreiben'
          onChangeText={this.onChangeText}
          ref={this.props.customMentionsRef}
          usersGetMentioning={this.props.usersGetMentioning}
          usersMentioning={this.props.usersMentioning}
          value={this.state.text}
          {...this.props}
        />
        {this.props.upload.image_uri ?
          <View
            style={{
              marginTop: 20,
              marginBottom: 20,
              overflow: 'hidden',
            }}>
            <TouchableOpacity onPress={() => chooseFileMethod(this.props.node.id, this.props.fileUpload) }>
              <Image source={{uri: this.props.upload.image_uri}} style={{width: (Layout.half.width - 30), height: 120}} resizeMode="contain"/>
            </TouchableOpacity>
          </View>
          : null
        }
        {this.props.upload.image_id ?
          <TouchableOpacity onPress={this.onPressImage}>
            <Text style={[Styles.simpleLink, {
              marginTop: 20,
              alignSelf: 'center'
            }]}>Bild entfernen</Text>
          </TouchableOpacity>
          : this.props.upload.uploading ?
            <CustomIndicator />
          :
          <TouchableOpacity onPress={() => chooseFileMethod(this.props.node.id, this.props.fileUpload) }>
            <Text style={[Styles.simpleLink, {
              marginTop: 20,
              alignSelf: 'center'
            }]}>Bild zum Kommentar hinzufügen</Text>
          </TouchableOpacity>
        }
        <CustomButton
          title={this.props.isSendingComment ? "" : "Kommentieren"}
          onPress={this.submitComment}
          loading={this.props.isSendingComment}
          disabled={this.props.upload.uploading || this.props.isSendingComment}
        />
      </View>
    );
  }

  submitComment = () => {
    if (this.state.text !== '' || this.props.upload.image_uri ) {
      EventRegister.emit('analyticEvent', {
        category: 'Comment',
        action: 'Posted',
        label: 'Comment has been posted'
      });
      // 1. Prepare comment data.
      const comment_data = {
        nid: this.props.node.id,
        token: this.props.logInState.token,
        body: this.state.text,
        parent: this.props.parent ?? null,
      };
      if (this.props.upload.image_uri) {
        comment_data.image = this.props.upload.image_id;
      }
      // 2. Send data to backend.
      this.props.commentSet(comment_data);
      // 3. Notify parent component that a comment was sent.
      this.props.toggleCommentSent(true);
      // 4. Close keyboard and clean any text.
      Keyboard.dismiss();
      setTimeout(() => {
        this.setState({text: ''});
      }, 200);
    }
  };

  // when a temporary image is pressed clear it from store
  onPressImage = () => {
    this.props.clearUploadedImage({id: this.props.node.id});
  };

  onChangeText = (text) => {
    this.setState({text});
  };

}
