import React from 'react';
import CommunityScreenComponent from "../components/CommunityScreenComponent";

const CommunityScreen = (props) => {
  return (
      <CommunityScreenComponent
          {...props}
      />
  );
};

export default CommunityScreen;
