import React from 'react';
import { Platform } from 'react-native';
import { connect } from "react-redux";
import NetInfo from '@react-native-community/netinfo';
import { EventRegister } from 'react-native-event-listeners';
import * as SecureStore from 'expo-secure-store';

import * as Config from '../Config';
import MainNavigator from './RootStackNavigation';
import AnonMainNavigator from './AnonMainNavigator';
import { Analytics, Event, ScreenHit } from 'expo-analytics';
import { getPermissionsAsync } from 'expo-notifications';
import { handleNavigation } from '../actions/index';
import { storeConnectivityInfo } from '../actions';
import { adMob } from '../helpers/adMob';
import { currentDay } from '../helpers/formatNumbers';
import { AdForbiddenScreens, AdsPerDay } from "../constants/AdConfig";
import remoteConfig from '@react-native-firebase/remote-config';
import { checkPermission } from "../helpers/notifications";

class RootNavigator extends React.Component {

  componentDidMount() {
    // 1. Listen to connectivity changes.
    this.unsubscribe = NetInfo.addEventListener(state => {
      this.handleFirstConnectivityChange(state);
    });
    // 2. Config analytics and reuse it for events and screenhits.
    this.analytics = new Analytics(
      Config.GOOGLE_ANALYTICS_ID,
      { uid: this.props.logInState.uuid }
    );
    // 3. Set the group custom dimension.
    this.setGroupDimension();
    // 4. Listen to our custom event for pushing analytics.
    this.listener = EventRegister.addEventListener('analyticEvent',
      ({ category, action, label, value }) => {
        this.analytics.event(new Event(
          category,
          action,
          label,
          value
        ));
      }
    );
    // 5. Connect to remote config.
    remoteConfig()
      .setDefaults({
        ads: 'OFF',
      })
      .then(() => remoteConfig().fetchAndActivate());
  }

  /**
   * Component will update if the user changes.
   *
   * It is then that we need to reset the analytics parameters.
   */
  componentDidUpdate(prevProps, prevState) {
    // Recreate the analytics config.
    this.analytics = new Analytics(
      Config.GOOGLE_ANALYTICS_ID,
      { uid: this.props.logInState.uuid }
    );
    // Reset the group dimension.
    this.setGroupDimension();
  }

  setGroupDimension = async () => {
    // 1. Group logged in users in A, B groups based on ending uid digit.
    if (this.props.logInState.uid) {
      if (this.props.logInState.uid % 2 == 1) {
        this.analytics.addCustomDimension(4, 'A');
      }
      else {
        this.analytics.addCustomDimension(4, 'B');
      }
      // 2. Add a custom dimension to indicate whether the app allows notifications.
      const settings = await getPermissionsAsync();
      const oldSetting = await SecureStore.getItemAsync('notifications_allowed');
      if (checkPermission(settings)) {
        this.analytics.addCustomDimension(6, 'ON');
        if (oldSetting == null || oldSetting == "0FF") {
          SecureStore.setItemAsync('notifications_allowed', "ON");
        }
      }
      else {
        this.analytics.addCustomDimension(6, 'OFF');
        // If the app previously allowed but it does not anymore also send a
        // deactivation event.
        if (oldSetting == "ON") {
          SecureStore.setItemAsync('notifications_allowed', "OFF");
          EventRegister.emit('analyticEvent', { category: 'Notifications', action: 'Disabled', label: this.props.logInState.username });
        }
      }
    }
    else {
      this.analytics.addCustomDimension(4, 'C');
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    EventRegister.removeEventListener(this.listener);
  }

  // gets the current screen from navigation state
  getActiveRoute = (navigationState) => {
    if (!navigationState) {
      return null;
    }
    const route = navigationState.routes[navigationState.index];
    // dive into nested navigators
    if (route.routes) {
      return this.getActiveRoute(route);
    }
    return route;
  };

  getActiveRouteName = (navigationState) => {
    const route = this.getActiveRoute(navigationState);
    return route ? route.routeName : null;
  }

  getContentTypeFromRoute = (navigationState) => {
    const route = this.getActiveRoute(navigationState);
    return route.params.type;
  }

  handleFirstConnectivityChange = (connectionInfo) => {
    this.props.storeConnectivityInfo(connectionInfo);
  };

  onNavigationStateChange = async (prevState, currentState) => {
    const currentScreen = this.getActiveRouteName(currentState);
    const prevScreen = this.getActiveRouteName(prevState);
    if (prevScreen !== currentScreen) {
      const adsEnabled = remoteConfig().getValue('ads');
      // Show Admob full screen Ad only for iOS in order to test
      // the hypothesis that this could cause the high rate of crashes on Android
      if (adsEnabled.asString() == 'ON') {
        const { adCounter, screenChanges } = this.props;
        if (!AdForbiddenScreens.includes(currentScreen)) {
          let adShown = false;
          // Show an AD conditionally.
          if (screenChanges == 4 && adCounter < AdsPerDay) {
            const response = await adMob();
            adShown = response.adShown;
            // Let analytics know what happened with the AD.
            EventRegister.emit('analyticEvent', {
              category: 'admob',
              action: adShown ? 'deliverySuccess' : 'deliveryFail',
              label: response.error ?? undefined,
            });
          }
          // Calling handleNavigation in order to increase the adCounter.
          // Passing adShown param to alter only the adCounter and not the screenCounter
          this.props.handleNavigation({ adShown, currentScreen });
        }
      }
      // If it a Node try to send the content type instead.
      if (currentScreen == 'Node' || currentScreen == 'NodeSingle') {
        let type = this.getContentTypeFromRoute(currentState);
        if (type) {
          type = type.charAt(0).toUpperCase() + type.slice(1);
          await this.analytics.hit(new ScreenHit(type));
          return;
        }
      }
      // If it wasn't a node or the above failed send the screen name.
      await this.analytics.hit(new ScreenHit(currentScreen));
    }
  };

  render() {
    // If PUBLIC_CONTENT matches the current OS or is set to 'all' skip the login screen.
    if ([Platform.OS, 'all'].includes(Config.PUBLIC_CONTENT) || this.props.logInState.uid) {
      return <MainNavigator onNavigationStateChange={this.onNavigationStateChange} />;
    }
    else {
      return <AnonMainNavigator onNavigationStateChange={this.onNavigationStateChange} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    logInState: state.user,
    screenChanges: state.tags.ads[currentDay()] ? state.tags.ads[currentDay()].screenChanges : 0,
    adCounter: state.tags.ads[currentDay()] ? state.tags.ads[currentDay()].adCounter : 0
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleNavigation: (payload) => {
      dispatch(handleNavigation(payload))
    },
    storeConnectivityInfo: (payload) => {
      dispatch(storeConnectivityInfo(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RootNavigator);
