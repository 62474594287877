// Badges - what is this doing? @Angelo
import * as Config from '../Config';
import * as ActionTypes from '../actions/actionTypes';

export function badgeReset(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.BADGE_RESET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => ({
      url: Config.BASE_URL + 'solebich_message/ajax/all-read',
      category: 'badge_reset',
    }))
    .map(payload => {return payload});

  const response$ = sources.HTTP
    .select('badge_reset')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200)
    .map((response => {return response}));

  return {
    HTTP: request$
  }
}