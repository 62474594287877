const priceFormat100 = (price) => {
  return Number(price/100).toLocaleString('de-DE',{minimumFractionDigits: 2});
};

const priceFormat = (price) => {
  return Number(price).toLocaleString('de-DE',{minimumFractionDigits: 2});
};

const dateFormat = (date) => {
  const dateVar = new Date(date*1000);
  return dateVar.toLocaleDateString('de-DE');
};

const currentDay = () => {
   // Get current date and deduct 5 hours, in order to get a new day at 5am
   const date = new Date();
   date.setHours(date.getHours() - 5);
   return date.toLocaleDateString()
}

export { priceFormat100, priceFormat, dateFormat, currentDay }
