import React, { Component } from 'react';
import {
  Animated,
  TextInput,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import * as Config from "../../Config";
import CustomTextArea from "../../components/CustomTextArea";
import Styles from "../../constants/Styles";
import {Avatar} from "react-native-elements";

export default class MentionsTextInput extends Component {
  constructor(props) {
    super();
    const numOfRows = props.MaxVisibleRowCount >= props.suggestionsData.length ? props.suggestionsData.length : props.MaxVisibleRowCount;
    this.state = {
      textInputHeight: "",
      isTrackingStarted: false,
      suggestionRowHeight: numOfRows > 0 ? numOfRows * props.suggestionRowHeight : new Animated.Value(0),
      cursorPosition: 0,
      previousChar: " "
    };
  }

  componentDidMount() {
    this.setState({
      textInputHeight: this.props.textInputMinHeight
    })
  };

  componentDidUpdate(prevProps) {
    // If passed value was just emptied then reset.
    if (!this.props.value && prevProps.value) {
      this.resetTextbox();
    }
    if (this.state.isTrackingStarted && !this.props.horizontal && this.props.suggestionsData.length !== 0) {
      const numOfRows = this.props.MaxVisibleRowCount >= this.props.suggestionsData.length ? this.props.suggestionsData.length : this.props.MaxVisibleRowCount;
      const height = numOfRows * this.props.suggestionRowHeight;
      this.openSuggestionsPanel(height);
    }
  };

  startTracking = () =>{
    this.openSuggestionsPanel();
    this.setState({
      isTrackingStarted: true
    });
  };

  stopTracking = () => {
    this.closeSuggestionsPanel();
    this.setState({
      isTrackingStarted: false
    })
  };

  openSuggestionsPanel = (height) => {
    Animated.timing(this.state.suggestionRowHeight, {
      toValue: height ? height : this.props.suggestionRowHeight,
      duration: 100,
      useNativeDriver: false
    }).start();
  };

  closeSuggestionsPanel = () => {
    Animated.timing(this.state.suggestionRowHeight, {
      toValue: 0,
      duration: 100,
      useNativeDriver: false
    }).start();
  };

  updateSuggestions = (lastKeyword) => {
    this.props.triggerCallback(lastKeyword);
  };

  identifyKeyword = (val) => {
    if (this.state.isTrackingStarted) {
      const boundary = this.props.triggerLocation === 'new-word-only' ? 'B' : '';
      const pattern = new RegExp(`\\${boundary}${this.props.trigger}[a-z0-9_-\\s]+|\\${this.props.trigger}`, `gi`);
      const keywordArray = val.slice(0, this.state.cursorPosition+1).match(pattern); //identify the keyword even if there are other mentions later on
      if (keywordArray && !!keywordArray.length) {
        const lastKeyword = keywordArray[keywordArray.length - 1];
        this.updateSuggestions(lastKeyword);
      }
    }
  };

  onChangeText = (val) => {
    const lastChar = (val.length - 1 === this.state.cursorPosition)
      ?
      val.substr(val.length - 1)
      :
      val.substr(this.state.cursorPosition-1, 1);
    const wordBoundry = (this.props.triggerLocation === 'new-word-only') ? this.state.previousChar.trim().length === 0 : true;

    const textBeforeCursor = val.substr(0,this.state.cursorPosition + 1);
    let isBracketCloserThanAt = ((textBeforeCursor.lastIndexOf(']')> textBeforeCursor.lastIndexOf('@')) || (textBeforeCursor.lastIndexOf('@')<0));

    if (lastChar === this.props.trigger && wordBoundry) {
      this.startTracking();
    } else if ((lastChar === ']' || isBracketCloserThanAt )&& this.state.isTrackingStarted || val === "") {
      this.stopTracking();
    }
    this.setState({previousChar: lastChar});
    this.identifyKeyword(val);
    this.props.onChangeText(val); // pass changed text back
  };

  _onSelectionChange = ({nativeEvent}) => {
    this.props.onSelectionChange(nativeEvent); // pass selection back
    if(nativeEvent.selection.start === nativeEvent.selection.end)
      this.setState({cursorPosition: nativeEvent.selection.start});
  };

  resetTextbox = () =>{
    this.stopTracking();
    this.setState({ textInputHeight: this.props.textInputMinHeight, previousChar: " " });
  };

  renderSuggestionsRow = ({ item }, hidePanel) => {
    return (
      <TouchableOpacity onPress={() => {
        this.props.onSuggestionTap(item.label, hidePanel);
        this.setState({previousChar: " "});
      }}>
        <View style={Styles.suggestionsRowContainer}>
          <View style={Styles.userIconBox}>
            <Avatar
              small
              rounded
              iconStyle={{ borderRadius: 200 }}
              source={{ uri: (item.picture) ? item.picture.uri : Config.DEFAULT_AVATAR }}
            />
          </View>
          <View style={Styles.userDetailsBox}>
            <Text style={Styles.usernameText}>@{item.label}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  };

  render() {
    return (
      <View >
        <CustomTextArea
          {...this.props}
          onChangeText={this.onChangeText}
          value={this.props.value}
          onSelectionChange={this._onSelectionChange}
          ref={this.props.textAreaRef}
        />
        {this.state.isTrackingStarted?
        <Animated.View style={[{ ...this.props.suggestionsPanelStyle }, { height: this.state.suggestionRowHeight, borderBottomWidth: this.state.isTrackingStarted?1:0 }]}>
          <FlatList
            ItemSeparatorComponent={this.props.renderSeparator}
            keyboardShouldPersistTaps={"always"}
            horizontal={this.props.horizontal}
            ListEmptyComponent={this.state.isTrackingStarted?this.props.loadingComponent: null}
            enableEmptySections={true}
            data={this.props.suggestionsData}
            keyExtractor={this.props.keyExtractor}
            renderItem={(rowData) => { return this.renderSuggestionsRow(rowData, this.stopTracking) }}
          />
        </Animated.View>
          :null}
      </View>
    )
  }
}

MentionsTextInput.propTypes = {
  textInputStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  suggestionsPanelStyle: PropTypes.shape({
    style: PropTypes.any,
  }),
  loadingComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  textInputMinHeight: PropTypes.number,
  textInputMaxHeight: PropTypes.number,
  trigger: PropTypes.string.isRequired,
  triggerLocation: PropTypes.oneOf(['new-word-only', 'anywhere']).isRequired,
  value: PropTypes.string,//.isRequired,
  onChangeText: PropTypes.func.isRequired,
  triggerCallback: PropTypes.func.isRequired,
  renderSuggestionsRow: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  suggestionsData: PropTypes.array.isRequired,
  keyExtractor: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  suggestionRowHeight: PropTypes.number.isRequired,
  MaxVisibleRowCount: function(props, propName, componentName) {
    if(!props.horizontal && !props.MaxVisibleRowCount) {
      return new Error(
        `Prop 'MaxVisibleRowCount' is required if horizontal is set to false.`
      );
    }
  }
};

MentionsTextInput.defaultProps = {
  textInputStyle: { borderColor: '#ebebeb', borderWidth: 1, fontSize: 15 },
  suggestionsPanelStyle: { backgroundColor: 'rgba(100,100,100,0.1)' },
  loadingComponent: () => <Text>Loading...</Text>,
  textInputMinHeight: 30,
  textInputMaxHeight: 80,
  horizontal: true,
};
