import * as Google from "expo-auth-session/providers/google";
import { useEffect } from 'react';

// Following the expo Αuthentication documentation https://docs.expo.dev/guides/authentication/#google
// for Google, I created the 4 different types of client IDs from the Google console credentials page.
// Then when a user clicks on the Google Login/Register buttons a request is triggered
// in order to get a token from Google.

export function useLoginGoogle(): object {
  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId: '1012475189186-erdu771t69ck5s0bu4kiialnfhagfir6.apps.googleusercontent.com',
    iosClientId: '1012475189186-nheb6mgavu38biefsj0m8sql0scbq220.apps.googleusercontent.com',
    androidClientId: '1012475189186-q5civp06oh94u36taq0ej28220lmphce.apps.googleusercontent.com',
    webClientId: '1012475189186-l0b87bts5gmdri5kub9d57l4ls64237f.apps.googleusercontent.com',
  });

  useEffect(() => {
    if (response?.type === 'success') {
      const { authentication } = response;
    }
  }, [response]);

  return {promptAsync, disabled: !request};
}
