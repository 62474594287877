import { Asset } from 'expo-asset';
import * as Font from 'expo-font';

export async function loadAssetsAsync(setAssetsAreLoaded) {
  try {
    await Promise.all([
      Asset.loadAsync([
        require('../assets/images/logo.png'),
      ]),
      Font.loadAsync({
        'Solebich': require('../assets/fonts/Solebich.ttf'),
        'SolebichBold': require('../assets/fonts/Solebich_bold.ttf'),
        'MerriweatherRegular': require('../assets/fonts/Merriweather-Regular.ttf'),
        'MerriweatherBold': require('../assets/fonts/Merriweather-Bold.ttf'),
        'OpenSansRegular': require('../assets/fonts/OpenSans-Regular.ttf'),
        'OpenSansLight': require('../assets/fonts/OpenSans-Light.ttf'),
        'Arial': require('../assets/fonts/OpenSans-Regular.ttf'),
        'OpenSansBold': require('../assets/fonts/OpenSans-Bold.ttf'),
        'OpenSansItalic': require('../assets/fonts/OpenSans-Italic.ttf'),
        'OpenSansBoldItalic': require('../assets/fonts/OpenSans-BoldItalic.ttf'),
      }),
    ]);
  }
  catch (e) {
    console.warn(
      'There was an error caching assets (see: App.js), perhaps due to a ' +
      'network timeout, so we skipped caching. Reload the app to try again.'
    );
    console.log(e);
  }
  finally {
    setAssetsAreLoaded();
  }
}
