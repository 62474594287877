import flattenShippingItem from './flattenShippingItem';

/**
 * Loop through Shipping object and return a shipping object with flattened Shipping items
 */

const processShipping = (data) => {
  let shipping = {}
    Object.keys(data).forEach(function(key) {
    shipping = { ...shipping, [key]: flattenShippingItem(data[key])}
    });
  return {
    shipping: shipping,
  }
};

export default processShipping;
