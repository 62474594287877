import { Alert } from 'react-native';

import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import * as Permissions from 'expo-permissions';

import { Platform } from 'react-native';
import * as Linking from 'expo-linking';

import * as Config from "../Config";
import screenNames from '../constants/screenNames';

const pickImageNoCompression = (pickerResult) => {
  const result = {
    uri: pickerResult.image.uri,
    mimeType: 'jpeg',
  };
  return result;
};

const pickAndCompressImage = async (pickerResult) => {
  let correctUri = pickerResult.image.uri;

  if (Platform.OS.toLowerCase() === 'ios') {
    var regex = /:\/\/(.{36})\//i;
    var found = pickerResult.image.uri.match(regex);
    correctUri = "assets-library://asset/asset.JPG?id="+found[1]+"&ext=JPG";
  }

  const manipResult = await ImageManipulator.manipulateAsync(
    correctUri,
    [],
    {
      compress: 0.2,
      format: ImageManipulator.SaveFormat.JPEG,
    }
  );
  let result = {
    uri: manipResult.uri,
    mimeType: ImageManipulator.SaveFormat.JPEG,
  };
  return result;
};

// TODO: Check if this works again after fixing https://gitlab.com/solebich/mobile/issues/608
const rotateImage = async (imageUri) => {
  const manipResult = await ImageManipulator.manipulateAsync(
    imageUri,
    [{ rotate: -90 }],
    { format: ImageManipulator.SaveFormat.JPEG, }
  );
  return {
    uri: manipResult.uri,
    mimeType: ImageManipulator.SaveFormat.JPEG,
  };
};

const uploadImage = async (id, fileUpload) => {

  const handleImagePicked = pickerResult => {
    if (!pickerResult.cancelled) {
      fileUpload({
        uri: pickerResult.uri,
        type: pickerResult.mimeType,
        id: id ? id : 'node',
      });
    }
  };

  Alert.alert(
    'Bild auswählen',
    '',
    [
      {
        text: 'Abbrechen',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: 'Foto aufnehmen',
        onPress: async() => {
          let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            quality: 0.3,
            originalRotation: 360,
          });
          handleImagePicked(result);
        }
      },
      {
        text: 'Fotomediathek',
        onPress: async() => {
          const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: Platform.OS==='android',
            quality: 0.3,
            originalRotation: 360,
          });
          handleImagePicked(result);
        }
      },
    ],
    {
      cancelable: false
    },
  );
}

const chooseFileMethod = async(id, fileUpload) => {

  const { status } = await Permissions.getAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL);
  if (status !== 'granted') {
      Alert.alert(
        '',
        'Die App benötigt deine Erlaubnis, um Bilder hochladen zu können. Bist du damit einverstanden?',
        [
          {
            text: 'Nein',
            onPress: () => {
            },
          },
          {
            text: 'Ja',
            onPress: async () => {
              const { status } = await Permissions.askAsync(Permissions.CAMERA, Permissions.CAMERA_ROLL);
              if (status !== 'granted') {
                if (Platform.OS === 'ios') {
                  Alert.alert(
                    '',
                    "Bitte gehe zu den App-Einstellungen auf deinem Gerät um den Zugriff auf die Kamera und Fotos zu erlauben",
                    [
                      {
                        text: "Abbrechen",
                        onPress: () => {}
                      },
                      {
                        text: "App-Einstellungen",
                        onPress: () => Linking.openURL("app-settings:")
                      },
                    ],
                    {cancelable: false}
                  );
                }
                return;
              }
              await uploadImage(id, fileUpload);
            }
          },
        ],
        {
          cancelable: false
        },
      );
    } else {
      await uploadImage(id, fileUpload);
    }
}

export { chooseFileMethod, pickImageNoCompression, pickAndCompressImage, rotateImage };
