import flattenNode from './flattenNode';

const processProductSuggestions = (stateNodes, stateNodesById, data) => {
  return  data.reduce((object, item) => {
    // If node comes with missing data bail out.
    if (!item || !item.type) {
      return;
    }
    if (!object.nodesById.includes(item.id)) {
      object.nodesById.push(item.id);
    }
    object.newNodes[item.id] = flattenNode(item, stateNodes[item.id]);
    return object;
  },
  // Initial object.
  {
    newNodes: {},
    nodesById: stateNodesById.slice(),
  });
};

export default processProductSuggestions;
