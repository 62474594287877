import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import flattenSlide from "./helpers/flattenSlide";

const initialState = {
  isGettingSlide: false,
  slides: [],
  slidesById: [],
};

function slideGet(state) {
  return {
    ...state,
    isGettingSlide: true,
  };
}

function slideGetSuccess(state, action) {
  let newSlides = Object.assign({}, state.slides);
  const slides = action.payload
    .filter(slide => slide.article[0] && slide.article[0] !== null && slide.article[0].status == 1);
  slides.map(slide => {
      newSlides ={
        ...newSlides,
        [slide.id]: {
          ...newSlides[slide.id],
          ...flattenSlide(slide)
        }
      }

    });

  return {
    ...state,
    isGettingSlide: false,
    slides: {
      ...state.slides,
      ...newSlides
    },
    slidesById: slides.map(slide => slide.id)
  };
}

function slideGetFail(state) {
  return {
    ...state,
    isGettingSlide: false,
  };
}

function shoppingSlideGet(state) {
  return {
    ...state,
  };
}

function shoppingSlideGetSuccess(state, action) {
  const { data, where } = action.payload;
  // The 'key' is the 'where' string or 'other' if undefined.
  // - 'where' can be either 'cart' or 'home'.
  const key = (where && where.length) ? where : 'other';
  return {
    ...state,
    [key]: data.filter(item => item.id).map(slide => flattenSlide(slide)),
  };
}

function shoppingSlideGetFail(state) {
  return {
    ...state,
  };
}

const slides = createReducer(initialState, {
  [ActionTypes.SLIDE_GET]: slideGet,
  [ActionTypes.SLIDE_GET_SUCCESS]: slideGetSuccess,
  [ActionTypes.SLIDE_GET_FAIL]: slideGetFail,
  [ActionTypes.SHOPPING_SLIDE_GET]: shoppingSlideGet,
  [ActionTypes.SHOPPING_SLIDE_GET_SUCCESS]: shoppingSlideGetSuccess,
  [ActionTypes.SHOPPING_SLIDE_GET_FAIL]: shoppingSlideGetFail,
});

export default slides;
