import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import screenNames from '../constants/screenNames';
import flattenNode from './helpers/flattenNode';

const initialState = {
  isLoading: false,
  byId: {},
};

function storeUsersContent(state, action) {
  if (action.payload.path.includes(screenNames.user)
    && !action.payload.path.includes(screenNames.geherztkommentiert)) {
    const userId = action.payload.path.split("/")[1];

    let items = {};
    const itemsById = state.byId[userId] ? state.byId[userId].slice() : [];

    action.payload.data.forEach(item => {
      items = {
        ...items,
        [item.id]: item[item.type],
      };
      if (!itemsById.includes(item.id)) {
        itemsById.push(item.id);
      }
    });

    return {
      ...state,
      isLoading: false,
      [userId]: {
        ...state[userId],
        ...items,
      },
      byId: {
        ...state.byId,
        [userId]: itemsById,
      },
    };
  }
  return state;
}

function gettingUserContent(state, action) {
  if (action.payload.path.includes(screenNames.user)
    && !action.payload.path.includes(screenNames.geherztkommentiert)) {
    const userId = action.payload.path.split("/")[1];
    if (action.payload.refreshing && state.byId[userId]) { // Pull-to-refresh functionality resets pagination
      return {
        ...state,
        byId: {
          ...state.byId,
          [userId]: [],
        },
        isLoading: true,
      }
    }
    return {
      ...state,
      isLoading: true,
    };
  }
  return state;
}

function nodeUploadSuccess(state, action) {
  const newNodeId = Number(action.payload.data[0].id);
  const newNodeData = flattenNode(action.payload.data[0], {});
  const userId = action.payload.data[0].user.id;
  const userNodesById = state.byId[userId] ?  state.byId[userId].slice() : [];
  if (userNodesById.indexOf(newNodeId) == -1) {
    userNodesById.unshift(newNodeId);
  }
  return {
    ...state,
    [userId]: {
      ...state[userId],
      [newNodeId]: newNodeData
    },
    byId: {
      ...state.byId,
      [userId]: userNodesById
    }
  }
}

function entityDelSuccess(state, action) {
  if (!action.payload.request.url.includes('image')) return state;
  const nodeId = parseInt(action.payload.request.send.id);
  const userId = parseInt(action.payload.request.send.userId);
  const usersContentIds = state.byId[userId].slice();
  let { [nodeId]: omit, ...userNodes } = state[userId];
  return {
    ...state,
    [userId]: userNodes,
    byId: {
      ...state.byId,
      [userId]: usersContentIds.filter(userContentId => String(userContentId) !== String(nodeId)),
      }
  }
}

const usersContent = createReducer(initialState, {
  [ActionTypes.VIEW_GET_SUCCESS]: storeUsersContent,
  [ActionTypes.VIEW_GET]: gettingUserContent,
  [ActionTypes.NODE_UPLOAD_SUCCESS]: nodeUploadSuccess,
  [ActionTypes.ENTITY_DEL_SUCCESS]: entityDelSuccess
});

export default usersContent;

