import React from 'react';
import {
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {  WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import { Icon } from '../components/Icon';
import * as Config from '../Config';
import IndicatorPage from '../components/general/IndicatorPage';
import { cartGet } from "../actions";
import PDFReader from 'rn-pdf-reader-js'

class Webpage extends React.Component {

  state = {
    minimized: this.props.navigation.getParam('payment', false),
  };

  render() {
    // If no URI has been bash show the spinner.
    const data = this.props.navigation.getParam('data', {});
    const pdf = this.props.navigation.getParam('pdf', false);
    if (!data.uri) {
      return (
        <IndicatorPage />
      );
    }

    const isNotPayment = !this.props.navigation.getParam('payment', false);
    const style = this.state.minimized ? { flex: 1 } : { height: 1 };
    return (
      <View style={{flex: 1}}>
        <StatusBar
          barStyle="dark-content"
          backgroundColor="#FFFFFF"
        />
        { isNotPayment &&
        <TouchableOpacity
          style={{
            flex: 1,
            flexDirection: 'row',
            marginTop: 0,
            paddingLeft: 10,
            height: 48,
            maxHeight: 50,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
          onPress={() => this.props.navigation.goBack()}
        >
          <Icon
            name='back'
            size={Config.SIZE_ICON}
            color='black'
          />
          <Text style={{marginLeft: Config.STANDARD_MARGIN}}>zurück zu SoLebIch</Text>
        </TouchableOpacity>
        }
        { this.state.minimized && <IndicatorPage /> }
        <View style={!this.state.minimized ? { flex: 1 } : { height: 1 }}>
          {pdf ?
            <PDFReader
              source={{uri: data.uri}}
            />
            :
            <WebView
              injectedJavaScript={this.injectedJS()}
              onMessage={(event) => {}}
              onNavigationStateChange={this.onNavigationStateChange}
              source={{uri: data.uri.substr(0, 4) === 'http' ? data.uri : `https://${data.uri}`}}
              useWebKit={true}
            />
          }
        </View>
      </View>
    );
  }

  /**
   * We need to monitor the URLs of the webview when it is a payment going.
   * We act:
   * - 1. When the user finishes paying and returns to solebich. We then will
   *   automatically close the webview on users behalf.
   * - 2. When a use rarrives to the external payment page. We then hide the
   *   spinner and maximize the webview which was initially minimized.
   */
  onNavigationStateChange = ({ loading, url }) => {
    const isPayment = this.props.navigation.getParam('payment', false);
    const isSolebichUrl = (url.indexOf('solebich') != -1);
    const isRedirectUrl = (url.indexOf('api/cart-goto-payment') != -1);
    const isPaymentUrl = (url.indexOf('/payment') != -1);
    const isCart = (url.indexOf('/cart') != -1);
    const isPaypalExpressReview = (url.indexOf('/paypal_ec') != -1);
    const redirectCallback = this.props.navigation.getParam('redirectCallback', null);
    const step = this.props.navigation.getParam('step', false);

    // Identify when user is back from the external payment page and act accordingly.
    if (isPayment && isSolebichUrl && !isRedirectUrl && !isPaymentUrl&& !isCart) {
      // If it is the "thank you" page forward the user in app as well.
      const isThankYouUrl = (url.indexOf('/complete') != -1) || (url.indexOf('/paypal_ec') != -1);
      if (isThankYouUrl && redirectCallback) {
        redirectCallback();
      }
      // Else just go back.
      this.props.navigation.goBack();
    }
    // When user has arrived to the external page, then show the webview.
    const isPaypalUrl = (url.indexOf('paypal.com') != -1);
    const isEmerchantPayUrl = (url.indexOf('merchant') != -1);
    const isMangoPayUrl = (url.indexOf('mango') != -1);
    if (!loading && (isPaypalUrl || isEmerchantPayUrl || isMangoPayUrl)) {
      setTimeout(() => this.setState({ minimized: false }), 1000);
    }
    // 3. If user has started from cart and used paypal express.
    if (this.props.navigation.getParam('payment', false) == 'paypal_express') {
      // 3.1 When a user arrives at paypal we update the step to 'paypal_payment' for use later.
      if (isPaypalUrl && step != 'paypal_payment') {
        this.props.navigation.setParams({ step: 'paypal_payment' });
      }
      // 3.2 When user cancelled a paypal express payment.
      else if (isSolebichUrl && isCart && step == 'paypal_payment') {
        this.props.navigation.goBack();
      }
      // 3.3. When user has paid successfully and arrived to paypal checkout review..
      else if (isPaypalExpressReview) {
        setTimeout(() => this.props.navigation.navigate("Order", { cart: true, paypal_express: true }), 200);
        this.props.navigation.goBack();
      }
    }
  }

  injectedJS = () => {
    if (this.props.navigation.getParam('payment', false) == 'paypal_express') {
      return `
        (function ($) {
          $('#edit-paypal-ec').click();
        })(jQuery);
      `;
    }
    if (this.props.navigation.getParam('step', false) == 'paypal_express_review') {
      return `
        (function ($) {
          $('#edit-pay-now').click();
        })(jQuery);
      `;
    }
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Webpage);
