import * as ActionTypes from './actionTypes';

export function inboxGet(payload){
  return {
    type: ActionTypes.INBOX_GET,
    payload
  }
}

export function inboxGetSuccess(payload){
  return {
    type: ActionTypes.INBOX_GET_SUCCESS,
    payload
  }
}

export function inboxGetFail(payload){
  return {
    type: ActionTypes.INBOX_GET_FAIL,
    payload
  }
}

export function postPM(payload){
  return {
    type: ActionTypes.POST_PM,
    payload
  }
}

export function postPMSuccess(payload){
  return {
    type: ActionTypes.POST_PM_SUCCESS,
    payload
  }
}

export function postPMFail(payload){
  return {
    type: ActionTypes.POST_PM_FAIL,
    payload
  }
}
