import React, { Component } from 'react';
import { connect} from 'react-redux';
import { nodesGet } from "../actions/index";
import PageScreenComponent from '../components/PageScreenComponent';

class PageScreen extends Component {

  componentDidMount() {
    const id = this.props.navigation.getParam('pageId', 0);
    if (id) {
      this.props.nodesGet({ nid: id });
    }
  };

  render() {
    const id = this.props.navigation.getParam('pageId', 0);
    const text = this.props.nodes[id] ? this.props.nodes[id].body : '';
    return (
      <PageScreenComponent
        isLoading={this.props.isLoading}
        navigation={this.props.navigation}
        text={text}
      />
    );
  };

};

const mapStateToProps = (state) => {
  return {
    nodes: state.images.nodes,
    isLoading: state.images.isLoading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    nodesGet: (payload) => {
      dispatch(nodesGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageScreen);
