import React, {Component} from 'react';
import { Text, View } from 'react-native';
import {connect} from 'react-redux';
import Styles from '../constants/Styles';
import CustomInteractionManager from '../components/CustomInteractionManager';
import ImagesList from '../components/ImagesList';
import { getTag, getTagByName, likeNode, viewGet } from "../actions/index";
import IndicatorPage from '../components/general/IndicatorPage';

class TagScreen extends Component {

  componentDidMount() {
    CustomInteractionManager.runAfterInteractions(() => {
      const { state } = this.props.navigation;
      // if user exists in the DB
      if (state.params) {
        // if user ID is passed fetch a tag now.
        if (state.params.id && !this.props.tagsdb[state.params.id]) {
          this.props.getTag({ id: state.params.id });
        }
        // if user name and there is no mention fetch one now.
        if (state.params.name && !this.props.tagnames[state.params.name]) {
          this.props.getTagByName(state.params.name);
        }
      }
    });
  }

  render() {
    const { state } = this.props.navigation;
    let tid = 0;
    // Even if we have been passed an id we check for a passed term name.
    if (state.params.name) {
      const nameLowerCase = state.params.name.toLowerCase();
      // We prefer main cateogories over tags when matching a term name to a tid.
      if (this.props.categoryNames[nameLowerCase]) {
        tid = this.props.categoryNames[nameLowerCase];
      }
      else if (this.props.tagnames[nameLowerCase]) {
        tid = this.props.tagnames[nameLowerCase];
      }
    }
    else {
      // If we have been passed an ID, we can use it.
      tid = state.params.id;
    }
    const tag = (tid && this.props.tagsdb[tid]) ?
      this.props.tagsdb[tid] : state.params.tag;

    // if tag is not loaded yet show a spinner.
    if (this.props.gettingTag || !tag) {
      return (
        <IndicatorPage />
      );
    }

    const menus = [
      {title: 'Beliebt', path: `taxonomy/term/${tag.id}`, view: 'search_views_main_search_', display: 'panel_pane_13'},
      {title: 'Neu', path: `recent/term/${tag.id}`, view: 'search_views_main_search_', display: 'panel_pane_14'},
    ];

    // If the term is supposed to show only articles pass set the 'type' param.
    if (tag.only_articles) {
      menus[0].type = 'article';
      menus[1].type = 'article';
    }

    return (
      <View style={{flex: 1}}>
        <ImagesList
          data={this.props.data}
          nodesById={this.props.nodesById}
          isLoading={this.props.isLoading}
          likeNode={this.props.likeNode}
          list={this.props.list}
          menus={menus}
          navigation={this.props.navigation}
          viewGet={this.props.viewGet}
          header={
            <View>
              <View style={{paddingTop: 30}}>
                <Text style={Styles.pageTitle}>{tag.label}</Text>
              </View>
            </View>
          }
          pagination={this.props.pagination}
          isLikingNode={this.props.isLikingNode}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryNames: state.tags.categoryNames,
    data: state.images.nodes,
    gettingTag: state.tags.gettingTag,
    isLikingNode: state.images.isLikingNode,
    isLoading: state.images.isLoading,
    list: state.images,
    logInState: state.user,
    nodesById: state.images.nodesById,
    pagination: state.pagination,
    tagnames: state.tags.tagnames,
    tagsdb: state.tags.tagsdb,
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    getTag: (payload) => {
      dispatch(getTag(payload))
    },
    getTagByName: (payload) => {
      dispatch(getTagByName(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TagScreen);
