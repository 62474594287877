export const simpleHeaderWithXcsrf = (token) => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': token,
});

export const postSimpleHeaderWithAuthorization = (btoaToken, token) => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': "Basic " + btoaToken,
  'X-CSRF-Token': token,
});

export const simpleHeaderWithXcsrfAndroidCompatible = (token) => ({
  'Accept': 'application/json',
  'Content-Type': 'multipart/form-data',
  'X-CSRF-Token': token,
});
