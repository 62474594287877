import React from 'react';
import { TouchableWithoutFeedback, Image } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';

const onPressTitle = () =>  EventRegister.emit('navTo', 'Home');

const NavHeaderTitle = (props) => (
  <TouchableWithoutFeedback
    onPress={onPressTitle}
    style={{alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}}
  >
    <Image
      source={require('../../assets/images/logo.png')}
      style={{ width: 28 * 2.84, height: 28, alignSelf: 'center' }}
      resizeMode="contain"
    />
  </TouchableWithoutFeedback>
);

export default NavHeaderTitle;
