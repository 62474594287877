import React, { Component } from 'react';
import {View, ScrollView} from 'react-native';
import CustomIndicator from "../CustomIndicator";
import { paymentMethodsKeyed } from "../../constants/paymentMethods"
import CustomRadioForm from "../general/CustomRadioForm";
import Styles from "../../constants/Styles";
import CustomButton from "../general/CustomButton";

class PaymentScreenComponent extends Component {

  state = {
    selectedPaymentMethod: this.props.selectedPaymentMethod ?? this.props.paymentMethods[0]
  }

  componentDidMount() {
    this.props.cartPaymentCollect();
  }

  setPaymentMethod (selection) {
    this.setState({selectedPaymentMethod: selection})
    this.props.cartPaymentSelect(selection)
  }

  render() {
    const payment_props = this.props.paymentMethods
      .filter(method => !paymentMethodsKeyed[method].hidden)
      .map(method => {
      return (
        {
          label: paymentMethodsKeyed[method].title + ' ' + paymentMethodsKeyed[method].subtitle + `\n\n` + paymentMethodsKeyed[method].description,
          value: paymentMethodsKeyed[method].method
        }
      )
    })
    return (
      this.props.isLoadingPayment ? <CustomIndicator /> :
        <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
          <ScrollView
            contentContainerStyle={Styles.pageScrollViewContainer}
            keyboardShouldPersistTaps={"handled"}
            style={{marginBottom: 60}}
          >
          <CustomRadioForm
            radio_props={payment_props}
            selectedProp={this.state.selectedPaymentMethod}
            setSelectedProp={(selection) => {this.setPaymentMethod(selection)}}
            navigation={this.props.navigation}
            payment={true}
          />
          </ScrollView>
          <View style={Styles.shopButtonWrap}>
            <CustomButton
              title="Weiter"
              onPress={() => this.props.navigation.goBack()}
              buttonStyle={Styles.shopButton}
              fontFamily={"OpenSansRegular"}
            />
          </View>
        </View>
    )
  }
}

export default PaymentScreenComponent;
