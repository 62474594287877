import React, { Component } from 'react';
import {
  Image,
  Text,
  View,
} from 'react-native';
import FitImage from 'react-native-fit-image';
import { connect } from 'react-redux';

import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import NodeActionButtons from './general/NodeActionButtons';

class ProductNodeThumb extends Component {
  state = {
    liked: this.props.item.liked
  };

  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.liked === this.state.liked && nextProps.images && this.props.images &&
      nextProps.images[this.props.item.id].comments ===  this.props.images[this.props.item.id].comments &&
      nextProps.images[this.props.item.id].likes ===  this.props.images[this.props.item.id].likes )
      return false;
    return true;
  }

  onPressLike = () => {
    if (!this.props.logInState.uid) {
      this.props.navigation.navigate('Register');
    }
    else {
      if (this.state.liked) {
        this.props.likeNode({node: this.props.item, op: 'unflag'});
        this.setState({liked: 0});
      }
      else {
        this.props.likeNode({node: this.props.item, op: 'flag'});
        this.setState({liked: 1});
      }
    }
  };

  onPressComment = () => {
  };

  render() {
    const { index, item, likeNode, navigation , path, view } = this.props;
    const priceStyle = item.onSale ? { color: Colors.onSaleRed } : {};
    return (
        <View style={{ width: '100%' }}>
          <Text style={Styles.gridTitleProduct} numberOfLines={2}>{item.label}</Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {item.onSale && <Text style={{ paddingRight: 10, textDecorationLine: 'line-through'}}>{item.priceOriginal} €</Text>}
            <Text style={ priceStyle }>{item.price} €</Text>
          </View>
        </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logInState: state.user,
    images: state.images.image,
  }
};

export default connect(mapStateToProps)(ProductNodeThumb)
