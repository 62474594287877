import React, { Component } from 'react';
import { AppState, Platform, View, Alert } from 'react-native';
import { connect } from 'react-redux';
import { NavigationActions } from 'react-navigation';
import { EventRegister } from 'react-native-event-listeners';
import * as Notifications from 'expo-notifications';
import { badgeSet, getVocabulary, handleReceivedNotification, likeNode, shoppingSlideGet, slideGet, shopGet, viewGet, urlToNav, cartGet, cartShippingCollect } from "../actions/index";
import { handleNotifications } from '../helpers/notifications.js';
import { startRateFlow } from '../helpers/ratings.js';
import { handleUrlFromHome } from '../helpers/urls.js';
import screenNames from '../constants/screenNames';
import BigBox from '../components/BigBox';
import CustomInteractionManager from '../components/CustomInteractionManager';
import ImagesList from '../components/ImagesList';
import CustomIndicator from '../components/CustomIndicator';

class HomeScreen extends Component {

  state = {
    appState: 'active',
    slides: this.props.slidesById,
    currentSlideId: null,
    hasBeenHandled: {},
    menus: [
      {title: 'Neu', path: screenNames.neu, view: 'frontpage_views_solr_version', display: 'panel_pane_1'},
      {title: 'Folge ich', path: screenNames.folgeIch, view: 'frontpage_views_v3', display: 'follow'},
      {title: 'Neu kommentiert', path: screenNames.neuKommentiert, view: 'frontpage_views_v3', display: 'page_2'},
      {title: 'Beliebt letzte Woche', path: screenNames.beliebtLetzteWoche, view: 'frontpage_views_solr_version', display: 'page_3'},
      {title: 'Die beliebtesten Solebich Bilder', path: screenNames.dieBeliebtestenSolebichBilder, view: 'frontpage_views_solr_version', display: 'page_4'}
    ]
  };

  componentDidMount() {
    // Get slides on the frontpage.
    this.props.slideGet();
    this.props.shoppingSlideGet({ where: 'home' });
    // Get affiliate shops definitions.
    this.props.shopGet({});
    // Listen on new notifications.
    if (Platform.OS !== 'web') {
      this.notificationSubscription = Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
    }
    // Allow interactions to finish.
    CustomInteractionManager.runAfterInteractions(async () => {
      // Check if the app was opened via a notification.
      // Avoid the case this was a reset internally in the app.
      if (Platform.OS !== 'web' && !this.props.navigation.getParam('isReset', false)) {
        const notificationResponse = await Notifications.getLastNotificationResponseAsync();
        if (notificationResponse) {
          this.handleNotificationResponse(notificationResponse);
        }
      }
      // Add custom listener for any component that wants to reset navigation to the frontpage."
      this.listener = EventRegister.addEventListener('navTo', (routeName) => {
        this.props.navigation.reset([NavigationActions.navigate({ routeName, params: { isReset: true }})], 0);
      });
      AppState.addEventListener('change', this.handleAppStateChange);
      // The initialUrl is set because a parent component handled it.
      if (this.props.user.initial) {
        this.findUrl(this.props.user.initial);
      }
    });
    if (this.props.appleConnectSuccess && (this.props.appleConnectType == 'register')) {
      this.props.navigation.navigate('UserEdit', {tab: 'login', showAppleAlertMessage: true})
    }
    // Fetch the shop cart
    this.props.cartGet();
    // Fetch the shipping methods available for the cart
    this.props.cartShippingCollect();
  }

  findUrl = ({url, time}) => {
    // bail out if url is empty or it has been handled.
    if (!url || this.state.hasBeenHandled[url] == time) {
      return;
    }
    this.setState({
      hasBeenHandled: {
        ...this.state.hasBeenHandled,
        [url]: time,
      }
    }, () => handleUrlFromHome(url, this.props.navigation, this.props.urlToNav, this.props.nodes))
  }

  componentWillUnmount() {
    if (Platform.OS !== 'web') {
      AppState.removeEventListener('change', this.handleAppStateChange);
      this.notificationSubscription && this.notificationSubscription.remove();
      EventRegister.removeEventListener(this.listener);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if ( this.props.isLoading !== nextProps.isLoading
      || this.props.isGettingSlide !== nextProps.isGettingSlide
      || this.props.user.initial !== nextProps.user.initial
      || this.props.nodesById.length !== nextProps.nodesById.length
      || (!this.props.navigation.isFocused() && nextProps.navigation.isFocused())
      || this.props.slidesById.length !== nextProps.slidesById.length
      || this.props.shoppingSlide !== nextProps.shoppingSlide
      || this.props.isLikingNode !== nextProps.isLikingNode
      || this.state.currentSlideId !== nextState.currentSlideId
      || this.state.appState !== nextState.appState
      || this.props.list[screenNames.neu + 'ById'].length !== nextProps.list[screenNames.neu + 'ById'].length
      || this.props.list[screenNames.folgeIch + 'ById'].length !== nextProps.list[screenNames.folgeIch + 'ById'].length
      || this.props.list[screenNames.neuKommentiert + 'ById'].length !== nextProps.list[screenNames.neuKommentiert + 'ById'].length
      || this.props.list[screenNames.beliebtLetzteWoche + 'ById'].length !== nextProps.list[screenNames.beliebtLetzteWoche + 'ById'].length
      || this.props.list[screenNames.dieBeliebtestenSolebichBilder + 'ById'].length !== nextProps.list[screenNames.dieBeliebtestenSolebichBilder + 'ById'].length
      || (nextProps.navigation.state.params && this.props.navigation.state.params !== nextProps.navigation.state.params)) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.slides && this.props.slides || this.props.slides != prevProps.slides){
      this.updateBigBoxSlide();
    }
    // 3. The prop initialUrl is set because a parent component handled it.
    if (this.props.user.initial) {
      this.findUrl(this.props.user.initial);
    }
  }

  render() {
    const { shoppingSlide } = this.props;
    return (
      <ImagesList
        data={this.props.nodes}
        nodesById={this.props.nodesById}
        isLoading={this.props.isLoading}
        likeNode={this.props.likeNode}
        list={this.props.list}
        menus={this.state.menus}
        updateBigBoxSlide={this.updateBigBoxSlideClicked}
        navigation={this.props.navigation}
        viewGet={this.props.viewGet}
        header={ <View>
          {/*Comment out AppBanner since we added the shopping Slide*/}
          {/*<AppBanner navigation={this.props.navigation} />*/}
          {(this.props.slides && this.props.slides[this.state.currentSlideId]) ?
            <BigBox
              image={this.props.slides[this.state.currentSlideId].imageStylesScalecrop1150x500}
              title={this.props.slides[this.state.currentSlideId].title}
              onPress={this.onPressBigBox}
            />
            : this.props.isGettingSlide && <CustomIndicator/>
          }
          {(shoppingSlide && shoppingSlide.title && shoppingSlide.title.length > 0) &&
            <BigBox
              image={shoppingSlide.imageStylesScalecrop1150x500}
              title={shoppingSlide.title}
              onlyImage={shoppingSlide.onlyImage}
              onPress={this.onPressBigBoxShopping}
            />
          }
        </View>}
        pagination={this.props.pagination}
        isLikingNode={this.props.isLikingNode}
        isBlockingNode={this.props.isBlockingNode}
      />
    );
  }

  handleAppStateChange = (nextAppState) => {
    this.setState({appState: nextAppState});

    if (nextAppState === 'active' && Platform.OS !== 'web') {
      startRateFlow(this.props.navigation, this.props.user);
    }

  };

  handleNotificationResponse = async (response) => {
    handleNotifications(response, this.props.badgeSet, this.props.handleReceivedNotification, this.props.navigation, this.props.nodes, this.props.urlToNav);
  };

  onPressBigBox = () => {
    this.updateBigBoxSlide(true);
    this.props.navigation.navigate({
      routeName: 'NodeSingle',
      key: `node${this.props.slides[this.state.currentSlideId].articleId}`,
      params: {
        id: this.props.slides[this.state.currentSlideId].articleId,
      }
    });
  };

  onPressBigBoxShopping = () => {
    handleUrlFromHome(this.props.shoppingSlide.fullLink, this.props.navigation, this.props.urlToNav, this.props.nodes);
  };

  updateBigBoxSlide = (remove) => {
    const stored_ids = this.state.slidesById ?? this.props.slidesById ?? [];
    const currentPosition = stored_ids.indexOf(this.state.currentSlideId);
    const ids = !remove ? stored_ids : [
      ...stored_ids.slice(0, currentPosition),
      ...stored_ids.slice(currentPosition+1, stored_ids.length),
    ];
    const randPosition = ids.length === 0 ? null : Math.floor(Math.random() * ids.length);
    this.setState({
      // use a random id each time.
      currentSlideId: ids[randPosition],
      // remove the item for further use.
      slidesById: ids,
    });
  };

  updateBigBoxSlideClicked = () => this.updateBigBoxSlide();

};

const mapStateToProps = (state) => {
  return {
    isLikingNode: state.images.isLikingNode,
    isLoading: state.images.isLoading,
    isGettingSlide: state.slides.isGettingSlide,
    list: state.images,
    nodes: state.images.nodes,
    nodesById: state.images.nodesById,
    isBlockingNode: state.images.isBlockingNode,
    pagination: state.pagination,
    shoppingSlide: state.slides.home ? state.slides.home[0] : undefined,
    slides: state.slides.slides,
    slidesById: state.slides.slidesById,
    user: state.user,
    appleConnectType: state.user.appleConnectType,
    appleConnectSuccess: state.user.appleConnectSuccess
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    badgeSet: (payload) => {
      dispatch(badgeSet(payload))
    },
    getVocabulary: (payload) => {
      dispatch(getVocabulary(payload))
    },
    likeNode: (payload) => {
      dispatch(likeNode(payload))
    },
    shoppingSlideGet: (payload) => {
      dispatch(shoppingSlideGet(payload))
    },
    slideGet: (payload) => {
      dispatch(slideGet(payload))
    },
    shopGet: (payload) => {
      dispatch(shopGet(payload))
    },
    viewGet: (payload) => {
      dispatch(viewGet(payload))
    },
    handleReceivedNotification: (payload) => {
      dispatch(handleReceivedNotification(payload))
    },
    urlToNav: (payload) => {
      dispatch(urlToNav(payload))
    },
    cartGet: (payload) => {
      dispatch(cartGet(payload))
    },
    cartShippingCollect: (payload) => {
      dispatch(cartShippingCollect(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
