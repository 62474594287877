import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import * as actions from '../actions/index';
import { simpleHeaderWithXcsrf } from './headers'
import sampleCombine from 'xstream/extra/sampleCombine';

export function usersGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USERS_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/users/';
      if (payload.ids.length) {
        // Filter out dupicate ids, then join them with comma.
        path += payload.ids.filter((v, i, a) => a.indexOf(v) === i).join(',');
      }
      else {
        path += 'me';
      }
      return ({
        url: Config.BASE_URL + path,
        category: 'users',
      });
    });

  const response$ = sources.HTTP
    .select('users')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.length) {
          return actions.usersGetSuccess(data.data);
        }
        else
          return actions.usersGetFail(data);
      }catch(e){
        return actions.usersGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function usersGetMentioning(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USERS_GET_MENTIONING)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/users';
      if (payload.keyword) {
        path += '?filter[username][value]=' + payload.keyword;
        path += '&filter[username][operator]=STARTS_WITH&sort=username';
      }
      return ({
        url: Config.BASE_URL + path,
        category: 'usersMentioning',
      });
    });

  const response$ = sources.HTTP
    .select('usersMentioning')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        return actions.usersGetMentioningSuccess(arr[0].body);
      }catch(e){
        return actions.usersGetMentioningFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
export function usersGetByTamperedUsername(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.USERS_GET_BY_TAMPERED_USERNAME)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = `mitglied/${payload.keyword}?api=1`;
      // if (payload.keyword) {
      //   path += '&filter[username][value]=' + ;
      //   path += '&filter[username][operator]=STARTS_WITH';
      // }
      return ({
        url: Config.BASE_URL + path,
        category: 'usersGetByTamperedUsername',
      });
    });

  const response$ = sources.HTTP
    .select('usersGetByTamperedUsername')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        return actions.usersGetByTamperedUsernameSuccess(arr[0].body);
      }catch(e){
        return actions.usersGetByTamperedUsernameFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function getFollowers(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_FOLLOWERS || (action.type === ActionTypes.GET_FOLLOWERS_SUCCESS && action.payload.page))
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: `${Config.BASE_URL}api/users?view_id=user_follow&view_path=user/${payload.id}/followers&display_id=panel_pane_2&page=${payload.page ? payload.page : 1}`,
        category: 'followers',
        id: payload.id,
      });
    });

  const response$ = sources.HTTP
    .select('followers')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = response$.compose(sampleCombine(payload$))
    .map(arr => {
      try {
        var data = arr[0].body;
        if (data && data.data && data.data.length) {
          return actions.getFollowersSuccess({data: data.data, id: arr[0].request.id, page: arr[1].page ? arr[1].page + 1 : 2 });
        }
        return actions.getFollowersFail(arr[0][0]);
      }
      catch (e) {
        return actions.getFollowersFail(arr[0][0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}


export function getFollowedUsers(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_FOLLOWED_USERS || (action.type === ActionTypes.GET_FOLLOWED_USERS_SUCCESS && action.payload.page))
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: `${Config.BASE_URL}api/users?view_id=user_follow&view_path=user/${payload.id}/following&display_id=panel_pane_1&page=${payload.page ? payload.page : 1}`,
        category: 'followed',
        id: payload.id,
      });
    });

  const response$ = sources.HTTP
    .select('followed')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = response$.compose(sampleCombine(payload$))
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.data && data.data.length) {
          return actions.getFollowedUsersSuccess({data: data.data, id: arr[0].request.id, page: arr[1].page ? arr[1].page + 1 : 2 });
        }
        return actions.getFollowedUsersFail(arr[0]);
      }
      catch (e) {
        return actions.getFollowedUsersFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}


export function getGeherztUsers(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.GET_GEHERZT_USERS || (action.type === ActionTypes.GET_GEHERZT_USERS_SUCCESS && action.payload.page))
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: `${Config.BASE_URL}api/users?view_id=users_that_liked_a_node&view_path=node/${payload.id}/geherzt&display_id=page_1&page=${payload.page ? payload.page : 1}`,
        category: 'geherzt',
        id: payload.id,
      });
    });

  const response$ = sources.HTTP
    .select('geherzt')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .flatten()
    .filter(response => response.status === 200);

  const action$ = response$.compose(sampleCombine(payload$))
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data && data.data && data.data.length) {
          return actions.getGeherztUsersSuccess({data: data.data, id: arr[0].request.id, page: arr[1].page ? arr[1].page + 1 : 2 });
        }
        return actions.getGeherztUsersFail(arr[0]);
      }
      catch (e) {
        return actions.getGeherztUsersFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function followUser(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.FOLLOW_USER)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/flags/follow/' + payload.op + '/' + payload.uid;
        return ({
          url: Config.BASE_URL + path,
          category: 'follow_user',
        });
      }
    );

  const response$ = sources.HTTP
    .select('follow_user')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0];
        if (data.body.ok === 'YES') {
          return actions.followUserSuccess(data);
        } else
          return actions.followUserFail(data);
      }
      catch (e) {
          return actions.followUserFail(arr[0]);
        }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function blockUser(sources) {
  const user$ = sources.STATE.map(state => state.user);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.BLOCK_USER)
    .compose(sampleCombine(user$))
    .filter(([action, user]) => (user.id > 0))
    .map(([action, user]) => action.payload);

  const request$ = payload$
    .map(payload => {
        return ({
          url: Config.BASE_URL + 'api/flags/block_user/flag/' + payload,
          category: 'block_user',
        });
      }
    );

  const response$ = sources.HTTP
    .select('block_user')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0];
        if (data.body.ok === 'YES') {
          return actions.blockUserSuccess(data);
        }
        else {
          return actions.blockUserFail(data);
        }
      }
      catch (e) {
        return actions.blockUserFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}
