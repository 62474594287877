import React, { Component } from 'react';
import {View, Text, FlatList, RefreshControl, TouchableOpacity} from 'react-native';
import Colors from "../../constants/Colors";
import CustomIndicator from "../CustomIndicator";
import MenuHorizontal from "../MenuHorizontal";
import BackToTopButton from "../BackToTopButton";
import Styles from "../../constants/Styles";
import {Icon} from "../Icon";
import CustomButton from "../general/CustomButton";

class AddressBookScreenComponent extends Component {

  state = {
    active_menu: 0,
    menus: [
      {title: 'Lieferadresse'},
      {title: 'Rechnungsadresse'},
    ]
  }

  componentDidMount() {
    this.props.getShippingAddress();
    this.props.getBillingAddress();
  }

  onMenuPress = (menu, index) => {
    this.setState({
      active_menu: index,
    });
  }

  handleFlatlistRef = ref => this.listRef = ref;

  renderItem = (data) => {
    const address = data.item;
    return (
     <View opacity={address.isDeleted ? 0.5 : 1} style={[Styles.checkoutBlocks, {paddingTop: 15}]}>
        <View style={Styles.textNextToIcon}>
          <Text>{address.first_name} {address.last_name}</Text>
          <Text>{address.street}</Text>
          <Text>{address.postal_code} {address.locality}</Text>
          <Text>{address.countryName}</Text>
          <Text>{address.phone}</Text>
          {!address.default && <TouchableOpacity style={[Styles.greyButtonContainer, {marginLeft: 0, marginTop: 15, padding: 5, maxWidth: 230}]} onPress={() => this.props.profileSetDefault({id: address.id})}>
            <Text>Als Standard verwenden</Text>
          </TouchableOpacity>}
        </View>
        <View style={Styles.iconNextToText}>
          <TouchableOpacity onPress={() => this.props.navigation.navigate('AddressForm', {id: address.id})}>
            <Icon name='pencil' size={20} color='#818181'/>
          </TouchableOpacity>
          <TouchableOpacity style={{marginTop: 30}} onPress={() => this.props.addressDelete(address)}>
            <Icon
              name='bin'
              size={20}
              color='#818181'
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  render() {
    return (
      <View style={{ flex: 1, marginBottom: 10  }}>
        <FlatList
          backgroundColor={Colors.background}
          contentContainerStyle={{ margin:4 }}
          data={this.state.active_menu === 0 ? this.props.shippingAddresses.filter(item => item?.id).sort((a,b) => { return b.default - a.default}) : this.props.billingAddresses.filter(item => item?.id).sort((a,b) => { return b.default - a.default})}
          horizontal={false}
          keyExtractor={data => `address${data.id}`}
          ListHeaderComponent={ <MenuHorizontal
              active_menu={this.state.active_menu}
              menus={this.state.menus}
              onMenuPress={this.onMenuPress}
            />
          }
          onEndReached={this.props.onEndReached}
          ref={this.handleFlatlistRef}
          renderItem={this.renderItem}
          style={{paddingHorizontal: 10, marginBottom: 60}}
        />
        {this.state.backToTopVisible ?
          <BackToTopButton listRef={this.listRef}/>
          : null
        }
        <View style={Styles.shopButtonWrap}>
          <CustomButton
            title="Neue Adressee"
            onPress={() => {this.props.navigation.navigate('AddressForm', {type: this.state.active_menu === 0 ? 'shipping' : 'billing'})}}
            buttonStyle={Styles.shopButton}
            fontFamily={"OpenSansRegular"}
          />
        </View>
      </View>
    )
  }
}

export default AddressBookScreenComponent;
