import {Text, TouchableOpacity, View} from "react-native";
import {Icon} from "./Icon";
import Collapsible from "react-native-collapsible";
import React from "react";


class CustomDropDownComponent extends React.Component {

  state = {
    collapsed: true
  }

  toggleExpanded = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return(
      <View>
        <TouchableOpacity onPress={this.toggleExpanded} style={{paddingTop: 10, paddingBottom: 10, borderTopWidth: 1, borderBottomWidth: this.state.collapsed ? 1 : 0, borderColor: '#a9a9a9', marginTop: 10, marginBottom: 10}}>
          <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Text style={{fontWeight: "bold"}}>{this.props.label}</Text>
            <Icon
              name='up_arrow'
              size={30}
              color='black'
              style={{transform: [{rotateX: this.state.collapsed ? "180deg" : "0deg"}]}}
            />
          </View>
        </TouchableOpacity>
        <Collapsible collapsed={this.state.collapsed} align="center">
          <View style={{marginBottom: 10}}>
            {this.props.content}
          </View>
        </Collapsible>
      </View>
    );
  }
}

export default CustomDropDownComponent;
