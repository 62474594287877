import * as ActionTypes from "../actions/actionTypes";
import { createReducer } from './helpers/createReducer';
import processInboxMessages from "./helpers/processInboxMessages";
import flattenInboxMessage from "./helpers/flattenInboxMessage";

const initialState = {
  isLoading: false,
  isPosting: false,
  allMessages: {},
  allMessagesPerUserById: [],
  mostRecentPerUserMessagesIds: [],
};

function inboxGet(state, action) {
  if (action.payload.refreshing) {
    return {
      ...state,
      isLoading: true,
      mostRecentPerUserMessagesIds: [],
      allMessagesPerUserById: {},
    };
  }
  return {
    ...state,
    isLoading: true,
  };
}

function inboxGetSuccess(state, action) {
  let processedInboxMessages = processInboxMessages(state.allMessages, state.allMessagesPerUserById, action.payload.data, action.payload.uid);
  // get the 1st PM per user ID
  let dataArray = Object.keys(processedInboxMessages.messagesById).map(userMessages => {
    return processedInboxMessages.messagesById[userMessages][0];
  }).sort((a,b) => {
    return processedInboxMessages.newMessages[b].timestamp - processedInboxMessages.newMessages[a].timestamp;
  });
  return {
    ...state,
    isLoading: false,
    allMessages: processedInboxMessages.newMessages,
    allMessagesPerUserById: processedInboxMessages.messagesById,
    mostRecentPerUserMessagesIds: dataArray,
  };
}

function inboxGetFail(state) {
  return {
    ...state,
    isLoading: false,
  };
}

function postPm(state) {
  return {
    ...state,
    isPosting: true,
  };
}

function postPmSuccess(state, action) {
  let newMessagesById = state.allMessagesPerUserById[action.payload.recipient.id] ? state.allMessagesPerUserById[action.payload.recipient.id].slice() : [];
  newMessagesById.push(action.payload.mid);
  let newMostRecentPerUserIds = state.mostRecentPerUserMessagesIds.slice();
  newMostRecentPerUserIds = newMostRecentPerUserIds.filter(n => newMessagesById.indexOf(n) < 0);
  newMostRecentPerUserIds.unshift(action.payload.mid);
  return {
    ...state,
    isPosting: false,
    allMessages: {
      ...state.allMessages,
      [action.payload.mid]: flattenInboxMessage(action.payload),
    },
    allMessagesPerUserById: {
      ...state.allMessagesPerUserById,
      [action.payload.recipient.id]: newMessagesById,
    },
    mostRecentPerUserMessagesIds: newMostRecentPerUserIds,
    // messages: {
    //   ...state.messages,
    //   [action.payload.recipient.id]: {
    //     ...state.messages[action.payload.recipient.id],
    //     [action.payload.mid]: action.payload
    //   }
    // }
  };
}

function postPmFail(state) {
  return {
    ...state,
    isPosting: false,
  };
}

/**
 * If an error was received cancel any interactive action that could be in progress.
 * Otherwise the user might find herself with an endless spinner.
 */
function errorReceived(state) {
  return{
    ...state,
    isLoading: false,
    isPosting: false,
  };
}

const inbox = createReducer(initialState, {
  [ActionTypes.INBOX_GET]: inboxGet,
  [ActionTypes.INBOX_GET_SUCCESS]: inboxGetSuccess,
  [ActionTypes.INBOX_GET_FAIL]: inboxGetFail,
  [ActionTypes.POST_PM]: postPm,
  [ActionTypes.POST_PM_SUCCESS]: postPmSuccess,
  [ActionTypes.POST_PM_FAIL]: postPmFail,
  [ActionTypes.ERROR_RECEIVED]: errorReceived,
});

export default inbox;
