import {Platform} from 'react-native';
import Layout from './constants/Layout';
import Device from 'react-native-device-detection';

// const dev = !Expo.Constants || !Expo.Constants.manifest || !Expo.Constants.manifest.packagerOpts || Expo.Constants.manifest.packagerOpts.dev;

// BUILD INFO
export const TIMESTAMP = "2019.12.17";
export const PROTOCOL = 'https';
export const SUBDOMAIN = 'www';
export const DOMAIN = 'solebich.de';
export const BASE_URL = PROTOCOL + '://' + SUBDOMAIN + '.' + DOMAIN + '/';
export const ANALYTICS_ID = 'UA-1481784-11';

// DEFAULTS
export const DEFAULT_AVATAR = 'http://www.solebich.de/sites/www.solebich.de/files/styles/s2_avatar_sm_crop/public/avatar_selection/userbild.png';

// URLS
export const URL_TOS = 'https://www.solebich.de/impressum#Nutzungsbedingungen?b=0';
export const URL_PRIVACY = 'https://www.solebich.de/impressum#Datenschutzerkl%C3%A4rung?b=0';
export const QUESTIONNAIRE = 'https://docs.google.com/forms/d/e/1FAIpQLSdKlIXXJSKua_zxTCrETURck9Ni-9wVNpD1wnZBwkWU550ulg/viewform?embedded=true';

// PUBLIC CONTENT. this can be any of the following values: android, ios, web, all.
export const PUBLIC_CONTENT = 'web';

// SIZES & LAYOUT
export const SIZE_ICON = 28;
export const SIZE_LOGO = 80
export const STANDARD_MARGIN = 10;
export const STANDARD_MARGIN_BIG = 20;
export const STANDARD_MARGIN_SMALL = 5;
export const STANDARD_PADDING = 10;
export const FONT_SIZE_BIG = 20;
export const FONT_SIZE_MEDIUM = 16;
export const FONT_SIZE_NORMAL = 15;
export const FONT_SIZE_SMALL = 12;
export const FONT_SIZE_SMALL_ICON = 8;
export const GLOBAL_PADDING_TOP = 20;

// TUNING
export const SLIDES_FOR_BIGBOX = 12;
export const NODES_PER_PAGE = 16;
export const PRODUCT_PER_PAGE = 3;
export const COMMENTS_PER_PAGE = 50;
export const MESSAGES_PER_PAGE = 20;
export const ENDLESS_SCROLL_THRESHOLD = 100;
export const MAX_PRODUCTS = Layout.maxProducts;
export const SHOW_MORE_WORDS = 30;
export const LOAD_MINIMAL = 0; // dev ? 0 : 2
export const COLUMNS = Platform.isPad ? 3 : Platform.OS === 'web' ? 4 : 2;
export const IS_TABLET = Platform.isPad || Device.isTablet;

// CACHING
export const CACHE_SHOPPING_CATEGORIES_IN_DAYS = 1;

// DEV & DEBUG
export const HYDRATE = false;
export const SENTRY_REPORT_DEV = false;

// ANALYTICS - Solebich Account
export const GOOGLE_ANALYTICS_ID = 'UA-1481784-4';
//ANALYTICS - Marianna test account
// export const GOOGLE_ANALYTICS_ID = 'UA-126618686-1';

// PLAIN FLATLIST SETTINGS
export const FLATLIST_ON_END_REACHED_THRESHOLD = 3;
export const FLATLIST_INITIAL_NUM_TO_RENDER = 4;
export const FLATLIST_MAX_TO_RENDER_PER_BATCH = 16;

// SWIPER CONFIG
export const SWIPER_ITEMS_BEFORE_CURRENT = 2;
export const SWIPER_ITEMS_AFTER_CURRENT = 10;
export const SWIPER_ITEMS_BEFORE_PRELOADING = 30;
export const SWIPER_SHOW_ARROWS = true;
export const SWIPER_IMAGE_CONFIG = 'contain';

// Time to wait on a spinner before considering the app as stuck.
export const TIMEOUT_TIME = '10000';

// How many times to retry a fileUpload
export const FILE_UPLOAD_MAX_RETRY = 5;

// How many times to retry an image node save
export const IMAGE_NODE_SAVE_MAX_RETRY = 5;

//LATEST VERSION
export const LATEST_VERSION = "1.10.1";
