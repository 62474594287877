import React, {Component} from 'react';
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";
import {Text, View } from "react-native";
import FloatingLabel from "./CustomFloatingLabelInput";

class CustomTextArea extends Component{

  state = {
    fontFamily:(this.props.value && this.props.value.length > 0) ? 'OpenSansBold' : 'OpenSansRegular'
  };

  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value !== prevProps.value) {
      this.setState({
        fontFamily: (this.props.value.length > 0) ? 'OpenSansBold' : 'OpenSansRegular'
      });
    }
  };

  render() {
    return (
      <View style={Styles.padInputWidth}>
        <FloatingLabel
          onFocus={() => this.setState({fontFamily:'OpenSansBold'})}
          onBlur={() => this.setState({fontFamily:(this.props.value && this.props.value.length > 0)? 'OpenSansBold' : 'OpenSansRegular'})}
          labelStyle={{
            color: Colors.darkText, fontFamily: this.state.fontFamily
          }}
          inputStyle={Styles.inputLongText}
          style={Styles.simpleTextAreaItem}
          multiline={true}
          underlineColorAndroid='transparent'
          allowFontScaling={false}
          labelDoubleTop={this.props.labelDoubleTop}
          {...this.props}
        >
          {this.props.label}
        </FloatingLabel>
        {this.props.helpText ?
          <Text style={Styles.helpText}>{this.props.helpText}</Text> :
          null
        }
      </View>
    );
  }
};

export default CustomTextArea;
