import * as ActionTypes from './actionTypes';

export function badgeSet(payload){
  return {
    type: ActionTypes.BADGE_SET,
    payload
  }
}

export function badgeReset(payload){
  return {
    type: ActionTypes.BADGE_RESET,
    payload
  }
}
