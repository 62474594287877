import React from 'react';
import {View, Text} from "react-native";
import Styles from "../../constants/Styles";
import Colors from "../../constants/Colors";
import DiscountItem from './DiscountItem';

const OrderTotal = ({cart, shippingOptions}) => {
  return (
    <View style={{paddingTop: 15}}>
      <View style={[Styles.inlineText]}>
        <Text>Artikelsumme</Text>
        <Text>{cart.productsSum} €</Text>
      </View>
      { cart.discount.map(discount => (
        <DiscountItem
          color={Colors.darkText}
          label={discount.label}
          price={discount.price}
        />
      ))}
      { cart.shipping_service ? <View style={[Styles.inlineText]}>
        { shippingOptions[cart.shipping_service] && <Text>{ shippingOptions[cart.shipping_service].title }</Text> }
        {/*TODO Provide default shipping value */}
        <Text>{ cart.shipping_price_total } €</Text>
      </View> : null }
      <View style={[Styles.inlineText, {paddingVertical: 5}]}>
        <Text style={{fontWeight: 'bold'}}>Gesamtsumme (inkl. MwSt.)</Text>
        <Text style={{fontWeight: 'bold', fontSize: 17}}>{cart.total} €</Text>
      </View>
      <View style={[Styles.inlineText, {paddingBottom: 15}]}>
        <Text>inkl. {cart.vat}% MwSt</Text>
        <Text>{cart.totalTax} €</Text>
      </View>
    </View>
  );
};

export default OrderTotal;
