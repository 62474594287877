import * as ActionTypes from './actionTypes';

export function addToCart(payload){
  return {
    type: ActionTypes.ADD_TO_CART,
    payload
  }
}

export function addToCartSuccess(payload){
  return {
    type: ActionTypes.ADD_TO_CART_SUCCESS,
    payload
  }
}

export function addToCartFail(payload){
  return {
    type: ActionTypes.ADD_TO_CART_FAIL,
    payload
  }
}

export function removeFromCart(payload){
  return {
    type: ActionTypes.REMOVE_FROM_CART,
    payload
  }
}

export function removeFromCartSuccess(payload){
  return {
    type: ActionTypes.REMOVE_FROM_CART_SUCCESS,
    payload
  }
}

export function removeFromCartFail(payload){
  return {
    type: ActionTypes.REMOVE_FROM_CART_FAIL,
    payload
  }
}

export function emptyCart(payload){
  return {
    type: ActionTypes.EMPTY_CART,
    payload
  }
}

export function cartGet(payload){
  return {
    type: ActionTypes.CART_GET,
    payload
  }
}

export function cartGetSuccess(payload){
  return {
    type: ActionTypes.CART_GET_SUCCESS,
    payload
  }
}

export function cartGetFail(payload){
  return {
    type: ActionTypes.CART_GET_FAIL,
    payload
  }
}

export function cartShippingCollect(payload){
  return {
    type: ActionTypes.CART_SHIPPING_COLLECT,
    payload
  }
}

export function cartShippingCollectSuccess(payload){
  return {
    type: ActionTypes.CART_SHIPPING_COLLECT_SUCCESS,
    payload
  }
}

export function cartShippingCollectFail(payload){
  return {
    type: ActionTypes.CART_SHIPPING_COLLECT_FAIL,
    payload
  }
}

export function cartPaymentCollect(payload){
  return {
    type: ActionTypes.CART_PAYMENT_COLLECT,
    payload
  }
}

export function cartPaymentCollectSuccess(payload){
  return {
    type: ActionTypes.CART_PAYMENT_COLLECT_SUCCESS,
    payload
  }
}

export function cartPaymentCollectFail(payload){
  return {
    type: ActionTypes.CART_PAYMENT_COLLECT_FAIL,
    payload
  }
}

export function addCoupon(payload){
  return {
    type: ActionTypes.ADD_COUPON,
    payload
  }
}

export function addCouponSuccess(payload){
  return {
    type: ActionTypes.ADD_COUPON_SUCCESS,
    payload
  }
}

export function addCouponFail(payload){
  return {
    type: ActionTypes.ADD_COUPON_FAIL,
    payload
  }
}

export function removeCoupon(payload) {
  return {
    type: ActionTypes.REMOVE_COUPON,
    payload
  }
}

export function removeCouponSuccess(payload) {
  return {
    type: ActionTypes.REMOVE_COUPON_SUCCESS,
    payload
  }
}

export function removeCouponFail(payload){
  return {
    type: ActionTypes.REMOVE_COUPON_FAIL,
    payload
  }
}

export function alterQuantity(payload){
  return {
    type: ActionTypes.ALTER_QUANTITY,
    payload
  }
}

export function alterQuantitySuccess(payload){
  return {
    type: ActionTypes.ALTER_QUANTITY_SUCCESS,
    payload
  }
}

export function alterQuantityFail(payload){
  return {
    type: ActionTypes.ALTER_QUANTITY_FAIL,
    payload
  }
}

export function cartShippingSelect(payload){
  return {
    type: ActionTypes.CART_SHIPPING_SELECT,
    payload
  }
}

export function cartShippingSelectSuccess(payload){
  return {
    type: ActionTypes.CART_SHIPPING_SELECT_SUCCESS,
    payload
  }
}

export function cartShippingSelectFail(payload){
  return {
    type: ActionTypes.CART_SHIPPING_SELECT_FAIL,
    payload
  }
}

export function cartPaymentSelect(payload){
  return {
    type: ActionTypes.CART_PAYMENT_SELECT,
    payload
  }
}

export function cartPaymentSelectSuccess(payload){
  return {
    type: ActionTypes.CART_PAYMENT_SELECT_SUCCESS,
    payload
  }
}

export function cartPaymentSelectFail(payload){
  return {
    type: ActionTypes.CART_PAYMENT_SELECT_FAIL,
    payload
  }
}

export function cartUpdateStatus(payload){
  return {
    type: ActionTypes.CART_UPDATE_STATUS,
    payload
  }
}

export function cartUpdateStatusSuccess(payload){
  return {
    type: ActionTypes.CART_UPDATE_STATUS_SUCCESS,
    payload
  }
}

export function cartUpdateStatusFail(payload){
  return {
    type: ActionTypes.CART_UPDATE_STATUS_FAIL,
    payload
  }
}
