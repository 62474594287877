import React, { Component } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Styles from "../constants/Styles";

export default class CustomTabBar extends Component {
  render() {
    let navigation = this.props.navigation;
    let titles = [
      'Benachrichtigungen',
      'Private Nachrichten',
    ];
    const { routes, index } = navigation.state;
    return (
      <View style={{alignItems: 'center', backgroundColor: '#FFF',  justifyContent: 'center', height: 60}}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{flexDirection: 'row'}}>
          {routes.map((route, idx) => {
            const isActive = index === idx;
            return (
              <TouchableOpacity
                onPress={() => {navigation.navigate(route.routeName, {bellIconPressed: route.routeName === 'Notification'});}}
                style={(!isActive) ? Styles.greyButtonContainer: Styles.greyButtonContainerActive}
                key={route.routeName}
              >
                <Text style={Styles.pinkButtonText}>{titles[idx]}</Text>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      </View>
    );
  }
}
