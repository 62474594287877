import React, {Component} from 'react';
import {FlatList, Text, View} from 'react-native';
import CustomIndicator from "../components/CustomIndicator";
import Colors from '../constants/Colors';
import Styles from '../constants/Styles';
import Layout from '../constants/Layout';
import UserListItem from './UserListItem';

export default class UsersListComponent extends Component {
  renderItem = ({item}) =>
    <UserListItem
      item={item}
      navigation={this.props.navigation}
  />;
  render() {
    const { data, title, navigation } = this.props;
    return (
      <View style={{flex: 1, backgroundColor: Colors.background, justifyContent: 'center', alignItems: 'center'}}>
        <FlatList
          ref={(ref) => {this.listRef = ref;}}
          contentContainerStyle={{ margin:4, width: Layout.window.width }}
          horizontal={false}
          backgroundColor={Colors.background}
          data={data}
          numColumns={1}
          ListHeaderComponent={
            <View>
              <Text style={Styles.pageTitle}>
                {title}
              </Text>
            </View>
          }
          initialNumToRender={120}
          maxToRenderPerBatch={120}
          keyExtractor={data => `user${data.id}` }
          style={{flex:1}}
          renderItem={this.renderItem}
          ListEmptyComponent={
            <View>
              {this.props.emptyListComponent}
              {(this.props.isLoading === undefined ||(this.props.isLoading && this.state.nodes===0)) ? <CustomIndicator />:<View />}
            </View>}
        />
      </View>
    );
  }

}
