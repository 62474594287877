import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import * as Config from '../Config';
import Colors from '../constants/Colors';
import CollapsilbleText from "../components/CollapsilbleText";
import IndicatorPage from './general/IndicatorPage';

class PageScreenComponent extends React.Component {
  render()  {
    if (this.props.isLoading) {
      return (
        <IndicatorPage />
      );
    }
    return (
      <ScrollView style={{ flex: 1, backgroundColor: Colors.background }}>
        <View style={{flex: 1, alignItems: 'center', margin: Config.STANDARD_MARGIN}}>
          <CollapsilbleText text={this.props.text} collapse={false} align={'left'} navigation={this.props.navigation} />
        </View>
      </ScrollView>
    );
  }
}

export default PageScreenComponent;
