const processMessages = (stateMessages, stateMessagesById, data) => {
  let newMessages = {};
  let messagesById = stateMessagesById.slice();
  try {
    data.forEach((msg) => {
      newMessages = {
        ...newMessages,
        [msg.id]: {
          ...newMessages[msg.id],
          ...msg,
        }
      };
      if (!messagesById.includes(String(msg.id))) {
        messagesById.push(String(msg.id));
      }
    });
  }catch (e) {
    //TODO: return some message?
  }
  return {
    newMessages,
    messagesById,
  }
};
export default processMessages;
