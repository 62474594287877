import React, {Component} from 'react';
import Layout from "../constants/Layout";
import * as Config from "../Config";
import Colors from "../constants/Colors";
import AutoTags from "../customModules/reactNativeTagAutoComplete";
import { TouchableOpacity, View} from "react-native";
import Text from "react-native-elements/src/text/Text";
import { Icon } from '../components/Icon';
const tagsInputWidth = Config.IS_TABLET ? Layout.window.width/2-20 : Layout.window.width-20;

export default class AutocompleteTagsComponent extends Component {

  render(){
    const label_and_placeholder = "Stichwörter zu deinem Bild";
    return(
      <AutoTags
        blurOnSubmit={false}
        placeholder={(this.props.tagsSelected.length === 0 && this.props.tags_text === '') ? label_and_placeholder : null}
        label={label_and_placeholder}
        suggestions={this.props.tags}
        tagsSelected={this.props.tagsSelected}
        handleAddition={this.props.handleAddition}
        handleDelete={this.props.handleDelete}
        renderTags={() => (
          this.props.tagsSelected ?
            this.props.tagsSelected.map((t, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  style={{
                    marginTop: 5,
                    marginRight: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    height: 30,
                    paddingVertical: 3,
                    paddingHorizontal: 8,
                    flexDirection: 'row',
                    backgroundColor: Colors.tagBackground,
                    borderRadius: 5,
                  }}
                  onPress={() => this.props.handleDelete(i)}
                >
                  <Text style={{fontSize: Config.FONT_SIZE_NORMAL}}>{t.label?t.label:t}   </Text>
                  <Icon
                    name='small_cancel'
                    size={Config.FONT_SIZE_SMALL_ICON}
                    color={Colors.darkText}
                  />
                </TouchableOpacity>
              );
              }):null
        )}
        ref='tagInput'
        inputContainerStyle={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: 5,
          paddingHorizontal: 15,
          minHeight: 50,
          maxHeight: 200,
          borderColor: this.props.errorMessage?'red':'#ccc',
          borderWidth: 1,
          minWidth: tagsInputWidth,
          maxWidth: tagsInputWidth,
          width: tagsInputWidth,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: Colors.background,
        }}
        style={{
          // flex: 1,
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 10,
          fontFamily: 'OpenSansRegular',
          backgroundColor: Colors.background,
        }}
        listStyle={{
          minWidth: tagsInputWidth,
          maxWidth: tagsInputWidth,
          width: tagsInputWidth,
          margin: 0,
          borderBottomWidth: 1,
        }}
        containerStyle={{
          backgroundColor: Colors.background,
          minWidth: tagsInputWidth,
          maxWidth: tagsInputWidth,
          width: tagsInputWidth,
        }}
        tagStyles={{padding: 10}}
        renderSuggestion={(suggestion) => (
          <View style={{ backgroundColor: Colors.background, height: 40, justifyContent: 'center', paddingLeft: 5 }}>
            <Text>
              {suggestion}
            </Text>
          </View>
        )}
        placeholderTextColor={Colors.darkText}
        data={Object.keys(this.props.tags)}
        value={this.props.tags_text}
        autoCorrect={false}
        onBlur={this.props.handlePlainTextAddition}
        onChangeText={(text) => {
          text = text.replace(/[^a-zA-Z0-9äöüÄÖÜß ]/gi, '');
          if (this.props.clearTagInput === true) {
            this.props._clearInputFalseAndTagsText(text);
            this.props.tagsGet({keyword: text});
          }
          else {
            const lastTyped = text.charAt(text.length - 1);
            const parseWhen = ['\n', '\r','\r\n','\n\r'];
            if (parseWhen.indexOf(lastTyped) > -1) {
              this.props.handlePlainTextAddition();
            }
            else {
              this.props._setTagsText(text);
              this.props.tagsGet({keyword: text});
            }
          }
        }}
        filterData={(query) => {
          if (query || query.trim() === '' || !this.props.tags) {
            return;
          }
          return this.props.tags.filter((tag) => {
            return tag.name.toUpperCase().includes(query.toUpperCase())
          });
        }}
        onSubmitEditing={this.props.handlePlainTextAddition}
        {...this.props}
      />
    )
  }
}
