import React from 'react';
import { View } from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import * as Config from '../../Config';
import Styles from "../../constants/Styles";
import CustomIndicator from '../CustomIndicator';
import RestartMessage from "./RestartMessage";

class IndicatorPage extends React.Component {

  state = {
    showReloadButton: false,
    timeout: setTimeout(() => this.onTimeout(), parseInt(this.props.timeout ?? Config.TIMEOUT_TIME)),
  };

  render() {
    if (this.state.showReloadButton) {
      return (
        <RestartMessage indicatorPlacement={this.props.indicatorPlacement} />
      );
    }
    else {
      return (
        <View style={Styles.indicatorPageContainer}>
          <CustomIndicator />
        </View>
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  onTimeout = () => {
    // End event. If we know the place the indicator was used send it as the event label.
    EventRegister.emit('analyticEvent', {
      category: 'error',
      action: 'timeout_reached ',
      label: this.props.place ?? 'place_unknown'
    });
    // Show the button.
    this.setState({ showReloadButton: true });
  }

}

export default IndicatorPage;
