import React, { Component } from 'react';
import {View, Text, ScrollView} from 'react-native';
import OrderCard from './OrderCard';
import CustomIndicator from "../CustomIndicator";
import Styles from "../../constants/Styles";

class OrderHistoryScreenComponent extends Component {

  componentDidMount() {
    this.props.ordersGet();
  }

  render() {
    const ordersIds = Object.keys(this.props.ordersById);
    if (this.props.isLoadingOrders) {
      return <CustomIndicator />
    }
    return (
        <View style={[Styles.pageViewContainer, {marginBottom: 10}]}>
          <ScrollView
            contentContainerStyle={Styles.pageScrollViewContainer}
            keyboardShouldPersistTaps={"handled"}
          >
            <Text style={Styles.pageTitle}>Meine Bestellungen</Text>
            { ordersIds.length ?
              ordersIds.map( id => { return <OrderCard navigation={this.props.navigation} order={this.props.ordersById[id]}/>}) :
              <Text style={Styles.textCenter}>Du hast noch keine Bestellung</Text>
            }
          </ScrollView>
      </View>
    )
  }
}

export default OrderHistoryScreenComponent;
