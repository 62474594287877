import React, { Component } from 'react';
import ShippingMethods from "../components/shopping/ShippingMethods";
import {connect} from "react-redux";
import {View} from "react-native";
import {cartShippingSelect} from '../actions/index';


class ShippingScreen extends Component {

  render() {
    return (
      <ShippingMethods
        selectedShipping={this.props.cart.shipping_service}
        cartShippingOptions={this.props.cartShippingOptions}
        cartShippingSelect={this.props.cartShippingSelect}
        navigation={this.props.navigation}
      />
    )}
}


const mapStateToProps = (state) => {
  return {
    cart: state.checkout.cart,
    cartShippingOptions: state.checkout.cartShippingOptions,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cartShippingSelect: (payload) => {
        dispatch(cartShippingSelect(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingScreen);
