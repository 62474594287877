import React from 'react';
import * as Notifications from 'expo-notifications';
import { Alert, AppState, Platform } from 'react-native';
import { connect } from 'react-redux';
import CustomInteractionManager from '../components/CustomInteractionManager';
import NotificationScreenComponent from '../components/NotificationScreenComponent';
import RegisterScreen from '../containers/RegisterScreen';
import { badgeReset, messagesGet, pushToken, usersGet } from "../actions/index";
import * as Config from '../Config';
import screenNames from "../constants/screenNames";
import { checkPermission } from "../helpers/notifications";

class NotificationScreen extends React.Component {

  state = {
    appState: 'active',
  };

  componentDidMount() {
    CustomInteractionManager.runAfterInteractions(() => {
      this.resetAndRefresh();
      AppState.addEventListener('change', this.handleAppStateChange);
      this.registerForPushNotifications();
      this.didFocusListener = this.props.navigation.addListener(
        'didFocus',
        () => this.resetAndRefresh(false)
      );
    });
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.handleAppStateChange);
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.didFocusListener.remove();
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.props.navigation.isFocused();
  }

  componentDidUpdate(prevProps) {
    // When the bell icon is pressed I send a random number.
    // So here the notifications update when the icon is pressed & the counter is > 0.
    if (this.props.navigation.getParam('bellIconPressed')){
      this.props.navigation.setParams({ bellIconPressed: false });
      this.resetAndRefresh();
    }

    // if users are missing fetch them now.
    const uids = this.props.messages[screenNames.messagesUser + 'ById']
      .map(data => this.props.users[data.user])
      .filter(user => user !== undefined);

    if (uids.length) {
      prevProps.usersGet({ids: uids});
    }
  }

  render() {

    if (!this.props.logInState.uid) {
      return (
        <RegisterScreen navigation={this.props.navigation}/>
      );
    }

    const messages = this.props.messages[screenNames.messagesUser + 'ById']
      .map(id => this.props.messages.messages[id])
      .filter(message => (message !== undefined));

    return (
      <NotificationScreenComponent
        isGettingMessages={this.props.isGettingMessages}
        nodes={this.props.nodes}
        messages={messages}
        messageType={messageType}
        navigation={this.props.navigation}
        onEndReached={this.onEndReached}
        onRefresh={this.onRefresh}
        users={this.props.users}
        usersGet={this.props.usersGet}
      />
    );
  }

  getDeviceToken = async () => {
    const token = await Notifications.getExpoPushTokenAsync();
    this.props.pushToken({token: token.data});
  }

  askForPermission = async () => {
    // Android remote notification permissions are granted during the app
    // install, so this will only ask on iOS
    const settings = await Notifications.requestPermissionsAsync();
    if (checkPermission(settings)) {
      this.getDeviceToken();
    }
    else {
      // If the user did not grant permissions setup a polling mechanism
      // to check periodically for new notifications.
      this.interval = setInterval(() => this.resetAndRefresh(true), 60000);
      // Get the token that uniquely identifies this device
    }
  }

  registerForPushNotifications = async () => {
    if (Platform.OS === 'ios') {
      const settings = await Notifications.getPermissionsAsync();
      if (checkPermission(settings)) {
        // Get the token that uniquely identifies this device
        this.getDeviceToken();
      } else if (settings.ios?.status !== Notifications.IosAuthorizationStatus.DENIED) {
        Alert.alert(
          '',
          'Die App benötigt deine Erlaubnis, um dir Benachrichtigungen zu deinen Inhalten senden zu dürfen. Bist du damit einverstanden?',
          [
            {
              text: 'Nein',
              onPress: () => {
                this.interval = setInterval(() => this.resetAndRefresh(true), 60000);
              },
            },
            {
              text: 'Ja',
              onPress: () => this.askForPermission()
            },
          ],
          {
            cancelable: false
          },
        );
      }
    }
    else {
      this.getDeviceToken();
    }
  }

  handleAppStateChange = (nextAppState) => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      this.resetAndRefresh(true);
    }
    this.setState({appState: nextAppState});
  };

  onRefresh = () => {
    this.resetAndRefresh();
  };

  onEndReached = () => {
    if (!this.props.isGettingMessages && this.props.messages.messages_user && this.props.messages.messages_user[this.props.messagesPage] && this.props.messages.messages_user[this.props.messagesPage].length >= Config.MESSAGES_PER_PAGE) {
      this.props.messagesGet({
        subcategory: screenNames.messagesUser,
        page: this.props.messagesPage + 1
      });
    }
  };

  resetAndRefresh = (noReset) => {
    this.props.messagesGet({
      page: 1,
      refreshingNotifications: true,
      reset: !noReset,
      subcategory: screenNames.messagesUser,
    });
    if (!noReset && Platform.OS === 'ios') {
       Notifications.setBadgeCountAsync(0);
    }
  };

}

const mapStateToProps = (state) => {
  return {
    isGettingMessages: state.messages.isGettingMessages.messages_user,
    logInState: state.user,
    messagesPage: state.messages.messagesPage.messages_user,
    messages: state.messages,
    nodes: state.images.nodes,
    users: state.users.users,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    badgeReset: (payload) => {
      dispatch(badgeReset(payload))
    },
    messagesGet: (payload) => {
      dispatch(messagesGet(payload))
    },
    pushToken: (payload) => {
      dispatch(pushToken(payload))
    },
    usersGet: (payload) => {
      dispatch(usersGet(payload))
    },
  }
};
   // Helper function that maps each message to a category.
export const messageType = (type) => {
  switch (type) {
    case 'comment_message':
    case 'comment_group_message':
    case 'comment_2_you_message':
      return 'comment';
    case 'follow_message':
      return 'user';
    case 'follow_node_message':
    case 'follow_node_group_message':
      return 'follow'
    case 'pm_2_you_message':
      return 'userDiscussion';
    case 'mention_2_you_message':
    case 'node_message':
    case 'favorite_comment_group_message':
    case 'favorite_comment_message':
    case 'mention_2_you_message_node_':
    case 'favorite_message':
    case 'favorite_group_message':
      return 'node';
    case 'mention_2_you_message_cafe':
    default:
      return 'other';
  }
}; 

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen);
