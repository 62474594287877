const screenNames = {
  neu: 'neu',
  folgeIch: 'folge-ich',
  neuKommentiert: 'neu_kommentiert',
  beliebtLetzteWoche: 'beliebte_woche',
  dieBeliebtestenSolebichBilder: 'beliebt',
  taxonomy: 'taxonomy',
  recent: 'recent',
  user: 'user',
  geherztkommentiert: 'geherztkommentiert',
  wohnmagazin: 'wohnmagazin',
  suche: 'suche',
  messagesGeneral: 'messages',
  messagesFollow: 'messages_follow',
  messagesUser: 'messages_user',
};

export default screenNames;
