import badges from "./badges";
import comments from "./comments";
import device from "./device";
import general from "./general";
import images from './images';
import inbox from "./inbox";
import messages from "./messages";
import pagination from "./pagination";
import shops from "./shops";
import slides from "./slides";
import tags from "./tags";
import user from './user';
import users from './users';
import usersComments from "./usersComments";
import usersContent from "./usersContent";
import commerceProducts from "./commerceProduct";
import productSearch from "./productSearch"
import checkout from "./checkout"
import orders from "./orders"
import address from "./address";

export default {
  badges,
  comments,
  device,
  general,
  images,
  inbox,
  messages,
  pagination,
  slides,
  shops,
  tags,
  user,
  users,
  usersContent,
  usersComments,
  commerceProducts,
  productSearch,
  checkout,
  orders,
  address
};
