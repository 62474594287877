import * as ActionTypes from '../actions/actionTypes';
import * as Config from '../Config';
import xs from 'xstream/index';
import flattenConcurrently from 'xstream/extra/flattenConcurrently';
import * as actions from '../actions/index';
import sampleCombine from 'xstream/extra/sampleCombine';
import { simpleHeaderWithXcsrf, simpleHeaderWithXcsrfAndroidCompatible } from './headers';
import {EventRegister} from "react-native-event-listeners";
const queryString = require('query-string');

export function viewGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.VIEW_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let path = 'api/v1.0/nodes?sort=-id&view_path=' + payload.path;
      if (payload.view) {
        path += '&view_id=' + payload.view;
      }
      if (payload.display) {
        path += '&display_id=' + payload.display;
      }
      if (payload.page) {
        path += '&view_page=' + payload.page;
      }
      if (payload.type) {
        path += '&f[0]=type:' + payload.type;
      }
      path +='&range='+ Config.NODES_PER_PAGE;
      return ({
        url: Config.BASE_URL + path,
        category: 'view',
        page: payload.page,
        path: payload.path,
      });
    });

  const response$ = sources.HTTP
    .select('view')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .compose(flattenConcurrently)
    .filter(response => response.status === 200)
    .map((response => {
      return response
    }));

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        const data = arr[0].body;
        if (data.data) {
          const res = {
            data: data.data,
            page: arr[0].request.page,
            path: arr[0].request.path,
          };
          return actions.viewGetSuccess(res);
        }
        else {
          return actions.viewGetFail(data);
        }
      }
      catch (e) {
        return actions.viewGetFail(arr);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function nodesGet(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.NODES_GET)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/v1.0/nodes?sort=-id&range=' + Config.NODES_PER_PAGE;
        if (payload && payload.uid) {
          path += '&filter[user]=' + payload.uid;
        }
        if (payload && payload.tag) {
          path += '&filter[tag]=' + payload.tag;
        }
        if (payload && payload.nid) {
          path = 'api/nodes/' + payload.nid;
        }
        if (payload && payload.ids) {
          // Filter out dupicate ids, then join them with comma.
          path = 'api/nodes/' + payload.ids.filter((v, i, a) => a.indexOf(v) === i).join(',');
        }
        return ({
          url: Config.BASE_URL + path,
          category: 'nodes',
        });
      }
    );

  const response$ = sources.HTTP
    .select('nodes')
    .map((response) => {
      return response.replaceError((err) => xs.of(err))
    })
    .compose(flattenConcurrently)
    .filter(response => response.status === 200)
    .map((response => {
      return response
    }));

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data && data.data.length) {
          return actions.nodesGetSuccess(data.data);
        }
        else
          return actions.nodesGetFail(data);
      }catch(e){
        return actions.nodesGetFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function nodesGetSuccess(sources) {
  const nodes$ = sources.STATE.map(state => state.images.nodes);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.NODES_GET_SUCCESS)
    .map(action => action.payload)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      let missing = [];
      arr
        .filter((node) => (node.type === 'article' || node.type === 'image') && node[node.type].products && node[node.type].products.length)
        .map((node, index) => {
          node[node.type].products.map((id) => {
            if (nodes && !nodes[id]) {
              missing.push(id);
            }
          });
        });

      if (missing && missing.length > 0) {
        return actions.nodesGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}



export function viewGetSuccess(sources) {
  const nodes$ = sources.STATE.map(state => state.images.nodes);
  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.VIEW_GET_SUCCESS)
    .map(action => action.payload.data)
    .compose(sampleCombine(nodes$))
    .map(([arr, nodes]) => {
      let missing = [];
      arr
        .filter((node) => (node.type === 'article' || node.type === 'image') && node[node.type].products && node[node.type].products.length)
        .map((node, index) => {
          node[node.type].products.map((id) => {
            if (nodes && !nodes[id]) {
              missing.push(id);
            }
          });
        });
      if (missing && missing.length > 0) {
        return actions.nodesGet({ids: missing});
      }
      else {
        return actions.doNothing();
      }
    });

  return {
    ACTION: action$,
  }
}




export function entityUpdate(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ENTITY_UPDATE)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      let token = payload.token;
      delete payload.nid;
      delete payload.token;

      return ({
        url: payload.self,
        method: 'PUT',
        send: payload,
        headers: simpleHeaderWithXcsrf(token),
        category: 'entityUpdate',
      });
    })
    .map(payload => {
      return payload;
    });

  const response$ = sources.HTTP
    .select('entityUpdate')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data)
          return actions.entityUpdateSuccess(data.data);
        else
          return actions.entityUpdateFail(data);
      }catch (e) {
        return actions.entityUpdateFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function entityDel(sources) {
  const token$ = sources.STATE.map(state => state.user.token);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.ENTITY_DEL)
    .map(action => action.payload)
    .compose(sampleCombine(token$))
    .map(([payload, token])=>{
      return {
        ...payload,
        token
      }
    });

  const request$ = payload$
    .map(payload => {
      return ({
        url: payload.self,
        method: 'DELETE',
        send: payload,
        headers: simpleHeaderWithXcsrf(payload.token),
        category: 'entityDel',
      });
    });

  const response$ = sources.HTTP
    .select('entityDel')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        return actions.entityDelSuccess(arr[0]);
      }catch (e) {
        return actions.entityDelFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}


export function fileUpload(sources) {
  const token$ = sources.STATE.map(state => state.user.token);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.FILE_UPLOAD)
    .map(action => action.payload)
    .compose(sampleCombine(token$))
    .map(([payload, token])=>{
      return {
        ...payload,
        token
      }
    });

  const request$ = payload$
    .map(payload => {
      const uriParts = (payload.type) ? payload.type.split('/') : payload.uri.split('.');
      const fileType = uriParts[uriParts.length - 1];
      let formData = new FormData();
      formData.append('photo', {
        uri: payload.uri,
        name: `photo.${fileType}`,
        type: `image/${fileType}`,
      });
      return ({
        url: Config.BASE_URL + 'api/file-upload',
        method: 'POST',
        field: formData,
        headers: simpleHeaderWithXcsrfAndroidCompatible(payload.token),
        category: 'file',
        id: payload.id,
        uri: payload.uri,
        retryUpload: payload.retryUpload ?? 0,
      });
    });

  const response$ = sources.HTTP
    .select('file')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        data.id = arr[0].request.id;
        // if file is uploaded API should return a valid file ID
        if (data.data[0][0].id) {
          let counter = arr[0].request.retryUpload;
          data = {...data, counter}
          return actions.fileUploadSuccess(data);
        }
        else {
          throw "Unexpected API response.";
        }
      }
      catch (e) {
        let { id, uri, retryUpload } = arr[0].request;
        if (retryUpload < Config.FILE_UPLOAD_MAX_RETRY) {
          retryUpload++;
          return actions.fileUpload({ id, uri, retryUpload });
        }
        else {
          return actions.fileUploadFail({ id });
        }
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function fileUploadRetry(sources) {
  const response$ = sources.HTTP
    .select('file')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status !== 200)
    .map((response => {
        return response
      }));

  const action$ = xs.combine(response$)
    .map(arr => {
      let { id, retryUpload, uri } = arr[0].response.request;
      if (retryUpload < Config.FILE_UPLOAD_MAX_RETRY) {
        retryUpload++;
        return actions.fileUpload({ id, retryUpload, uri });
      }
      else {
        return actions.fileUploadFail({ id });
      }

    });

  return {
    ACTION: action$,
  };
}

export function likeNode(sources) {

  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.LIKE_NODE)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
        let path = 'api/flags/favorite/' + payload.op + '/' + payload.node.id;
        return ({
          url: Config.BASE_URL + path,
          category: 'likeNode',
        });
      }
    );

  const response$ = sources.HTTP
    .select('likeNode')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.ok === 'YES') {
          return actions.likeNodeSuccess(arr[0]);
        }
        else
          return actions.likeNodeFail(data);
      }catch(e){
        return actions.likeNodeFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function blockNode(sources) {
  const user$ = sources.STATE.map(state => state.user);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.BLOCK_NODE)
    .compose(sampleCombine(user$))
    .filter(([action, user]) => (user.id > 0))
    .map(([action, user]) => action.payload);

  const request$ = payload$
    .map(payload => ({
      url: Config.BASE_URL + 'api/flags/block_content/flag/' + payload,
      category: 'blockNode',
    }));

  const response$ = sources.HTTP
    .select('blockNode')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.ok === 'YES') {
          return actions.blockNodeSuccess(arr[0]);
        }
        else
          return actions.blockNodeFail(data);
      }
      catch(e){
        return actions.blockNodeFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function nodeSave(sources) {
  const token$ = sources.STATE.map(state => state.user.token);
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.NODE_UPLOAD)
    .map(action => action.payload)
    .compose(sampleCombine(token$))
    .map(([payload, token])=>{
      return {
        ...payload,
        token
      }
    });

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/images',
        method: 'POST',
        send: payload.data,
        headers: simpleHeaderWithXcsrf(payload.token),
        category: 'nodeSave',
        retryNodeSave: payload.retryNodeSave ?? 0
      });
    });

  const response$ = sources.HTTP
    .select('nodeSave')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data) {
          let counter = arr[0].request.retryNodeSave;
          data = {...data, counter}
          return actions.nodeSaveSuccess(data);
        } else {
          throw "Unexpected API response.";
        }
      }
      catch (e) {
        let { send, retryNodeSave } = arr[0].request;
        if (retryNodeSave < Config.IMAGE_NODE_SAVE_MAX_RETRY) {
          retryNodeSave++;
          return actions.nodeSave({data: send, retryNodeSave})
        } else {
          return actions.nodeSaveFail(arr[0])
        }
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function nodeSaveRetry(sources) {
  const response$ = sources.HTTP
    .select('nodeSave')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status !== 200)
    .map((response => response));

  const action$ = xs.combine(response$)
    .map(arr => {
      let { send, retryNodeSave } = arr[0].response.request;
      if (retryNodeSave < Config.IMAGE_NODE_SAVE_MAX_RETRY) {
        retryNodeSave++
        return actions.nodeSave({data: send, retryNodeSave})
      }
      else {
        return actions.nodeSaveFail(arr[0])
      }
    });

  return {
    ACTION: action$,
  };
}


export function nodeEdit(sources) {
  const payload$ = sources.ACTION
    .filter(action => action.type === ActionTypes.NODE_EDIT)
    .map(action => action.payload);

  const request$ = payload$
    .map(payload => {
      return ({
        url: Config.BASE_URL + 'api/images/' + payload.imageId,
        method: 'PUT',
        send: payload.data,
        headers: simpleHeaderWithXcsrf(payload.token),
        category: 'nodeEdit',
      });
    });

  const response$ = sources.HTTP
    .select('nodeEdit')
    .map((response) =>
      response.replaceError((err) => xs.of(err))
    )
    .flatten()
    .filter(response => response.status === 200);

  const action$ = xs.combine(response$)
    .map(arr => {
      try {
        let data = arr[0].body;
        if (data.data)
          return actions.nodeEditSuccess(data);
        else
          return actions.nodeEditFail(data);
      }catch (e) {
        return actions.nodeEditFail(arr[0]);
      }
    });

  return {
    ACTION: action$,
    HTTP: request$
  }
}

export function nodeSaveSuccess(sources) {

  const action$ = sources.ACTION
    .filter(action => action.type === ActionTypes.NODE_UPLOAD_SUCCESS)
    .map(action => action.payload)
    .map(arr => actions.nodesGet());

  return {
    ACTION: action$,
  }
}
// TODO: Reenable to fix 566
// export function clearPossibleUploadedImageWhenGoingToHome(sources) {
//   const action$ = sources.ACTION
//     .filter(action => action.type === ActionTypes.HANDLE_NAVIGATION && action.payload === 'Home')
//     .map(action => action.payload)
//     .map(arr => actions.clearUploadedImage({id: 'node'}));
//
//   return {
//     ACTION: action$,
//   }
// }
