import * as Notifications from 'expo-notifications';
import { EventRegister } from 'react-native-event-listeners';

import { messageType } from "../containers/NotificationScreen";
import { handleUrlFromHome } from '../helpers/urls.js';

export function handleNotifications(response, badgeSet, handleReceivedNotification, navigation, nodes, urlToNav) { 

  const data = response.notification.request.content.data;

  // First of all send an event to Analytics.
  EventRegister.emit('analyticEvent', {
    category: 'NotificationOpened ',
    action: data.message_type,
    label: data.link ?? data.id
  });

  // If type is custom then the data should include a link and we should redirect there.
  if (data.message_type == 'custom' && data.link) {
    handleUrlFromHome (data.link, navigation, urlToNav, nodes);
    return;
  }

  // set the badge number to the sum of the user's unread messages
  badgeSet(data.badge);

  // handle comments and likes (counters)
  switch (data.message_type) {
    case 'comment_group_message':
    case 'comment_2_you_message':
      handleReceivedNotification({
        type: 'comments',
        nodeId: data.id,
      });
      break;
    case 'favorite_message':
    case 'favorite_group_message':
      handleReceivedNotification({
        type: 'likes',
        nodeId: data.id,
      });
      break;
  }

  // navigate the user to the target of the notification
  if (Platform.OS === 'ios') {
    Notifications.setBadgeCountAsync(0);
  }
  const { navigate } = navigation;
  switch (messageType(data.message_type)) {
    // Node based messages need to have a valid node_ref.
    case 'node':
      navigate({
        routeName: 'NodeSingle',
        key: `node${data.id}`,
        params: { id: data.id}
      });
      break;
    // Comment messages need a node_ref and startRateFlow.
    case 'comment':
      navigate({
        routeName: 'NodeSingle',
        key: `node${data.id}`,
        params: {
          id: data.id,
          focusInputField: true,
        },
      });
      break;
    // User based messages need a user_ref.
    case 'user':
      navigate({
        routeName: 'User',
        key: `user${data.id}`,
        params: {
          id: data.id,
        },
      });
      break;
    case 'userDiscussion':
      navigate({
        routeName: 'UserDiscussion',
        key: `discussion${data.id}`,
        params: { user: {id: data.id }}
      });
      break;
    // Nodes of users that you follow
    case 'follow':
      navigate({
        routeName: 'Home',
        params: { showMenu: 1 }
      });
      break;
    // The rest of the messages do not need any ref to be valid.
    default:
      navigate('Messages');
  }
}

export function checkPermission(settings) {
  return (settings.granted
    || settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL
    || settings.ios?.status === Notifications.IosAuthorizationStatus.AUTHORIZED
    || settings.ios?.status === Notifications.IosAuthorizationStatus.EPHEMERAL);
}
