import React from 'react';
import { connect } from 'react-redux';
import { submitReportForm } from "../actions/index";
import ReportFormComponent from "../components/ReportFormComponent";

const ReportFormScreen = (props) => {
  return (
    <ReportFormComponent
      isSubmittingReportForm={props.isSubmittingReportForm}
      navigation={props.navigation}
      submittedReportForm={props.submittedReportForm}
      submitReportForm={props.submitReportForm}
      user={props.user}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isSubmittingReportForm: state.general.isSubmittingReportForm,
    submittedReportForm: state.general.submittedReportForm,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitReportForm: (payload) => {
      dispatch(submitReportForm(payload))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportFormScreen);
